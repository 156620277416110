(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FiscalActivityCodeDetailController', FiscalActivityCodeDetailController);

    FiscalActivityCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FiscalActivityCode'];

    function FiscalActivityCodeDetailController($scope, $rootScope, $stateParams, previousState, entity, FiscalActivityCode) {
        var vm = this;

        vm.fiscalActivityCode = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:fiscalActivityCodeUpdate', function(event, result) {
            vm.fiscalActivityCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
