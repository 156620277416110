(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingFullPeriodDialogController', AccountingFullPeriodDialogController);

    AccountingFullPeriodDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccountingFullPeriod', 'globalCompany'];

    function AccountingFullPeriodDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AccountingFullPeriod, globalCompany) {
        var vm = this;

        vm.accountingFullPeriod = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;



        vm.autoConcept = function(){
            vm.accountingFullPeriod.name = "Periodo contable " +moment(vm.accountingFullPeriod.closeDate).format("YYYY")
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.accountingFullPeriod.companyId = globalCompany.getId();
            vm.accountingFullPeriod.status = 1;
            if (vm.accountingFullPeriod.id !== null) {
                AccountingFullPeriod.update(vm.accountingFullPeriod, onSaveSuccess, onSaveError);
            } else {
                AccountingFullPeriod.save(vm.accountingFullPeriod, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:accountingFullPeriodUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.openDate = false;
        vm.datePickerOpenStatus.closeDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
