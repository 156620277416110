(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingJournalController', AccountingJournalController);

    AccountingJournalController.$inject = ['globalCompany', '$rootScope', '$state', 'AccountingJournal', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'AccountingAccount', 'House', 'AccountingSeat', 'Modal'];

    function AccountingJournalController(globalCompany, $rootScope, $state, AccountingJournal, ParseLinks, AlertService, paginationConstants, pagingParams, AccountingAccount, House, AccountingSeat, Modal) {

        var vm = this;
        $rootScope.mainTitle = 'Libro diario';
        $rootScope.active = 'accountingJournal';
        vm.clientId = -1;
        vm.accountId = -1;
        vm.consulted = false;
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.loadAll = loadAll;

        vm.searchTerm = '';
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };
        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };

        function loadAllClients() {
            House.query({
                page: pagingParams.page - 1,
                size: 3000,
                companyId: globalCompany.getId()
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.clients = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAllDetailAccountingDetail();

        loadAllClients();

        vm.deleteManualSeat = function (seat) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el asiento manual?", "Una vez eliminado no podrá recuperarse.", function () {
                AccountingSeat.delete({id: seat.id}, function (result) {
                    Modal.toast("Eliminado correctamente");
                    loadAll();
                });
            });
        };

        function loadAllDetailAccountingDetail() {
            AccountingAccount.findDetailAccountsByCompanyId({
                companyId: globalCompany.getId(),
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.accountingDetailAccounts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        // loadAll();

        function loadAll() {
            vm.isReady = false;
            vm.consulted = true;
            AccountingJournal.getByCompanyIdAndBetweenDates({
                companyId: globalCompany.getId(),
                initialTime: moment(vm.dates.initial_time).format(),
                finalTime: moment(vm.dates.final_time).format(),
                clientId: vm.clientId,
                accountId: vm.accountId,
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.accountingJournal = data;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
