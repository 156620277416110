(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('egress-detail.deleteExpense', {
                parent: 'egress-detail',
                url: '/delete-expense/{expenseId}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/expense/expense-delete-dialog.html',
                        controller: 'ExpenseDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    concept: null,
                                    amount: null,
                                    date: null,
                                    reason: null,
                                    deleted: null,
                                    originalBillDate: null,
                                    referenceNumber: null,
                                    numericKey: null,
                                    electronicBillStatus: null,
                                    electronicBillResponse: null,
                                    isElectronicBillSend: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('egress-detail', null, {reload: 'egress-detail'});
                    }, function () {
                        $state.go('egress-detail');
                    });
                }]
            })
            .state('expense', {
                parent: 'entity',
                url: '/expense',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'aditumApp.expense.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/expense/expenses.html',
                        controller: 'ExpenseController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expense');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('expense-detail', {
                parent: 'expense',
                url: '/expense/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'aditumApp.expense.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/expense/expense-detail.html',
                        controller: 'ExpenseDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expense');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Expense', function ($stateParams, Expense) {
                        return Expense.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'expense',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('expense-detail.edit', {
                parent: 'expense-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/expense/expense-dialog.html',
                        controller: 'ExpenseDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Expense', function (Expense) {
                                return Expense.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('expense.new', {
                parent: 'expense',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/expense/expense-dialog.html',
                        controller: 'ExpenseDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    concept: null,
                                    amount: null,
                                    expenseDate: null,
                                    deleted: null,
                                    billNumber: null,
                                    paymentProofUrl: null,
                                    comments: null,
                                    folioNumber: null,
                                    paymentMethod: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('expense', null, {reload: 'expense'});
                    }, function () {
                        $state.go('expense');
                    });
                }]
            })
            .state('expense.edit', {
                parent: 'expense',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/expense/expense-dialog.html',
                        controller: 'ExpenseDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Expense', function (Expense) {
                                return Expense.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('expense', null, {reload: 'expense'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('expense.delete', {
                parent: 'expense',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/expense/expense-delete-dialog.html',
                        controller: 'ExpenseDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Expense', function (Expense) {
                                return Expense.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('expense', null, {reload: 'expense'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
