(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('ParkingSlot', ParkingSlot);

    ParkingSlot.$inject = ['$resource'];

    function ParkingSlot ($resource) {
        var resourceUrl =  'api/parking-slots/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByCompany': {
                method: 'GET',
                url:"api/parking-slots-by-company-id/:companyId"
                , isArray: true
            },
            'getByCompanyOfficers': {
                method: 'GET',
                url:"api/parking-slots-by-company-id-officers/:companyId"
                , isArray: true
            },
            'update': { method:'PUT' }
        });
    }
})();
