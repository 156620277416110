(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('FollowUpNextStep', FollowUpNextStep);

    FollowUpNextStep.$inject = ['$resource', 'DateUtils'];

    function FollowUpNextStep($resource, DateUtils) {
        var resourceUrl = 'api/follow-up-next-steps/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryByFollowUp': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-up-next-steps-by-follow-up/:followUpId'
            },
            'queryByHouseId': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-up-next-steps-by-house-id/:houseId'
            },
            'queryByCompanyOnly': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-up-next-steps-by-company-only/:companyId'
            },
            'queryByCompany': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-up-next-steps-by-company/:companyId'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                        data.reminderDate = DateUtils.convertDateTimeFromServer(data.reminderDate);
                        data.lastModification = DateUtils.convertDateTimeFromServer(data.lastModification);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
