(function() {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('accounting-period', {
            parent: 'entity',
            url: '/accounting-period',
            data: {
                authorities: ['ROLE_ACCOUNTANT'],
                pageTitle: 'aditumApp.accountingPeriod.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accounting-period/accounting-periods.html',
                    controller: 'AccountingPeriodController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accountingPeriod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('accounting-period-detail', {
            parent: 'accounting-period',
            url: '/detail/{id}',
            data: {
                authorities: ['ROLE_ACCOUNTANT'],
                pageTitle: 'aditumApp.accountingPeriod.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accounting-period/accounting-period-detail.html',
                    controller: 'AccountingPeriodDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accountingPeriod');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AccountingPeriod','CommonMethods', function($stateParams, AccountingPeriod, CommonMethods) {
                    var id = CommonMethods.decryptIdUrl($stateParams.id);
                    return AccountingPeriod.get({id : id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'accounting-period',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('accounting-period-detail.edit', {
            parent: 'accounting-period-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ACCOUNTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accounting-period/accounting-period-dialog.html',
                    controller: 'AccountingPeriodDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AccountingPeriod', function(AccountingPeriod) {
                            return AccountingPeriod.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('accounting-full-period.newMonthClosing', {
            parent: 'accounting-full-period',
            url: '/new/:periodId',
            data: {
                authorities: ['ROLE_ACCOUNTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accounting-period/accounting-period-dialog.html',
                    controller: 'AccountingPeriodDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                closeDate: null,
                                responsable: null,
                                status: null,
                                date: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('accounting-full-period', null, { reload: 'accounting-full-period' });
                }, function() {
                    $state.go('accounting-full-period');
                });
            }]
        })
        .state('accounting-period.edit', {
            parent: 'accounting-period',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ACCOUNTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accounting-period/accounting-period-dialog.html',
                    controller: 'AccountingPeriodDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AccountingPeriod', function(AccountingPeriod) {
                            return AccountingPeriod.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('accounting-period', null, { reload: 'accounting-period' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('accounting-period.delete', {
            parent: 'accounting-period',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ACCOUNTANT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/accounting-period/accounting-period-delete-dialog.html',
                    controller: 'AccountingPeriodDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AccountingPeriod', function(AccountingPeriod) {
                            return AccountingPeriod.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('accounting-period', null, { reload: 'accounting-period' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
