(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IssuedDocumentController', IssuedDocumentController);

    IssuedDocumentController.$inject = ['IssuingClient', 'CustomChargeType', 'Modal', '$timeout', '$scope', '$stateParams', '$rootScope', 'globalCompany', 'House'];

    function IssuedDocumentController(IssuingClient, CustomChargeType, Modal, $timeout, $scope, $stateParams, $rootScope, globalCompany, House) {
        var vm = this;
        $rootScope.active = "issuedDocuments";

        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.documents = [];
        vm.isReady = false;
        vm.houseId = "-1";
        vm.chargeType = {type: "-1"};
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.finalDocuments = [];
        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        }
        vm.loadHouses = function () {
            House.query({
                companyId: globalCompany.getId()
            }, function (data, headers) {
                vm.houses = data;
            })
        };

        vm.sendDocument = function (document) {
            Modal.confirmDialog("¿Está seguro que desea volver a emitir el documento?", "Asegurese de corregir cualquier error con el receptor antes de volver a emitir el documento.", function () {
                var data = {transactionId: document.id, houseId: document.houseId};
                IssuingClient.sendFiscalDocument(data,
                    function (data) {
                        Modal.toast("Se intentó volver a emitir el documento nuevamente");
                    }, function (error) {
                    }
                )
            })
        }

        vm.resendEmail = function (document) {
            Modal.confirmDialog("¿Está seguro que desea volver a enviar por correo el documento?", "", function () {
                var data = {transactionId: document.id, documentType: "Electronic Invoice", clientId: document.houseId};
                IssuingClient.resendDocument(data,
                    function (data) {
                        Modal.toast("Se reenvió el correo correctamente");
                    }, function (error) {
                    }
                )
            })
        }
        vm.loadHouses();

        vm.showMessageInfo = function (document) {
            Modal.dialog("Respuesta de GTI - Factura " + document.billNumber, document.electronicBillResponse, "Cerrar");
        }

        vm.loadDocuments = function () {
            vm.documents = [];
            vm.finalDocuments = [];
            vm.showLoading = true;
            IssuingClient.getFiscalDocuments({
                initialDate: moment(vm.dates.initial_time).format(),
                finalDate: moment(vm.dates.final_time).format(),
                companyId: globalCompany.getId()
            }, function (data) {
                for (var i = 0; i < data.length; i++) {
                    var document = data[i];
                    document.fiscalStatus = translateFiscalStatus(document.electronicBillStatus);
                    document.backgroundClass = getStatusBackground(document.electronicBillStatus)
                    document.validStatus = getValid(document.electronicBillStatus);
                    vm.documents.push(document);
                    vm.finalDocuments.push(document);
                }
                vm.showLoading = false;
                vm.isReady = true;
            })
        }

        CustomChargeType.getByCompany({companyId: globalCompany.getId()}, function (result) {
            vm.customChargeTypes = result;
        });

        vm.filter = function () {
            var filteredList = [];
            vm.showLoading = true;
            for (var i = 0; i < vm.finalDocuments.length; i++) {
                var document = vm.finalDocuments[i];
                var condition1 = document.houseId == vm.houseId && document.type == vm.chargeType.type;
                var condition2 = document.houseId == -1 && document.type == vm.chargeType.type;
                var condition3 = document.houseId == vm.houseId && vm.chargeType.type == -1;
                var condition4 = vm.houseId == -1 && vm.chargeType.type == -1;
                if (condition1 || condition2 || condition3 || condition4) {
                    filteredList.push(document);
                }
            }
            vm.documents = filteredList;
            vm.showLoading = false;
        }

        function getStatusBackground(status) {
            switch (status) {
                case "DOCUMENT_ACCEPTED_TAX_OFFICE":
                    return "background-success";
                case "ERROR_SENDING_TO_GTI":
                case "DOCUMENT_REJECTED_BY_TAX_OFFICE":
                case "DOCUMENT_ERROR_TAX_OFFICE":
                    return "background-error";
                default:
                    return "background-warning";
            }
        }

        function getValid(status) {
            return status == "DOCUMENT_ACCEPTED_TAX_OFFICE" || status == "SENT_TO_GTI";
        }

        function translateFiscalStatus(status) {
            switch (status) {
                case "SENT_TO_GTI":
                    return "Enviado a GTI";
                case "ERROR_SENDING_TO_GTI":
                    return "Error al enviar a GTI";
                case "DOCUMENT_ACCEPTED_TAX_OFFICE":
                    return "Aceptado";
                case "DOCUMENT_IN_PROCESS_AT_TAX_OFFICE":
                    return "En procesos";
                case "DOCUMENT_REJECTED_BY_TAX_OFFICE":
                    return "Rechazado";
                case "DOCUMENT_ERROR_TAX_OFFICE":
                    return "Error en el documento";
                case "DOCUMENT_NOT_FOUND_IN_GTI_DATABASE":
                    return "No encontrado";
                case "PENDING_SEND_TO_TAX_OFFICE":
                    return "Pendiente de enviar";
                case "AWAITING_TAX_OFFICE_RESPONSE":
                    return "Esperando respuesta";
                case "DOCUMENT_STATUS_UNKNOWN_TAX_OFFICE":
                    return "Documento desconocido";
                case "RETRY_DOCUMENT_QUERY_LATER":
                    return "Vuelva a intentar la consulta del documento más tarde";
                case "UNKNOWN_STATUS":
                    return "Estado desconocido";
                default:
                    return "Estado no reconocido";
            }
        }
    }
})();
