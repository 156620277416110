(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetExecutionAnalysisController', BudgetExecutionAnalysisController);

    BudgetExecutionAnalysisController.$inject = ['$rootScope', 'Modal', 'globalCompany', '$timeout', '$scope', '$stateParams', 'Budget'];

    function BudgetExecutionAnalysisController($rootScope, Modal, globalCompany, $timeout, $scope, $stateParams, Budget) {
        var vm = this;
        $rootScope.active = "budgetExecution";
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, m + 1, 0);

        vm.executionType = "ACCRUAL_BASIS";

        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.isReady = false;
        vm.notExportingExcel = true;

        vm.changeFormat = function () {
            vm.format = 'MMMM';
            vm.hideDate = false;
        };

        Budget.findByCompany({companyId: globalCompany.getId()}, function (result) {
            vm.budgets = result;
            if (result.length !== 0) {
                vm.selectedBudget = result[result.length - 1];
                vm.dates.final_time = new Date(vm.selectedBudget.finishDate);
                vm.defineDatesRange();
            }
        });

        vm.defineDatesRange = function () {
            vm.disableLastDate = new Date(vm.selectedBudget.finishDate).getTime() <= new Date().getTime();
            if (!vm.disableLastDate) {
                vm.minDate = new Date(vm.selectedBudget.startingDate);
                vm.maxDate = new Date();
                vm.dates.final_time = new Date();
            } else {
                vm.dates.final_time = new Date(vm.selectedBudget.finishDate);
                vm.minDate = new Date(vm.selectedBudget.startingDate);
                vm.maxDate = new Date(vm.dates.final_time);
            }
        }


        vm.format = 'L';
        moment.locale("es");

        vm.locale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(vm.format) : '';
            }
        };

        vm.createMonths = function () {
            vm.isReady = false;
            vm.dates.initial_time = new Date(vm.dates.initial_time.getFullYear(), vm.dates.initial_time.getMonth(), 1);
            vm.dates.final_time = new Date(vm.dates.final_time.getFullYear(), vm.dates.final_time.getMonth() + 1, 0);
            vm.changeFormat();
            $timeout(function () {
                vm.loadAll()
            }, 200)
        }

        vm.defineColSpan = function (account) {
            var colSpan = (account.category || account.subAccount) ? 5 : 1;
            if (vm.executionType != 'CASH_BASIS') {
                colSpan = colSpan - 1;
            }
            return colSpan;
        }

        vm.showTd = function (account) {
            return !(account.category || account.subAccount);
        }

        vm.defineRowClass = function (account) {
            if (account.subAccount || account.total) {
                return "background-lightgray-lighter";
            }
            if (account.category || account.totalCategory) {
                return "background-lightgray";
            }
        }

        vm.defineBoldClass = function (account) {
            if (account.category || account.total || account.subAccount) {
                return "bold-font";
            }
        }

        vm.tableToExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)))
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                })
                            }
                        var workSheetName = "Ejecución de presupuesto";
                        if (!table.nodeType) table = document.getElementById(table)
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx))
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1)
                    vm.notExportingExcel = true;
                }, 500)
            })
        }

        vm.loadAll = function () {
            vm.isReady = false;
            Budget.getExecutionAnalysis({
                companyId: globalCompany.getId(),
                budgetId: vm.selectedBudget.id,
                finalDate: moment(vm.dates.final_time).format(),
                reportType: vm.executionType
            }, function (data) {
                vm.execution = data;
                vm.notFound = !vm.execution.finalMonth;
                vm.isReady = true;
            }, function () {
                vm.notFound = !vm.execution.finalMonth;
                vm.isReady = true;
            })
        }
        vm.createMonths();
    }
})();
