(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IndividualReleaseDialogController', IndividualReleaseDialogController);

    IndividualReleaseDialogController.$inject = ['AditumStorageService', '$timeout', '$scope', '$stateParams', 'Complaint', 'House', 'Company', 'Resident', '$rootScope', '$state', 'globalCompany', 'Modal'];

    function IndividualReleaseDialogController(AditumStorageService, $timeout, $scope, $stateParams, Complaint, House, Company, Resident, $rootScope, $state, globalCompany, Modal) {
        var vm = this;
        $rootScope.mainTitle = "Enviar comunicado individual";
        vm.isReady = false;
        vm.complaint = {complaintType: "COMUNICADO A", complaintCategory: 3};
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        var file;
        vm.complaint.housesSelected = [];
        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        }
        Modal.enteringForm(save);

        $scope.$on("$destroy", function () {
            Modal.leavingForm();
        });
        vm.loadResidentsByHouse = loadResidentsByHouse;

        loadAll();


        function clear() {
            history.back();
        }
        function formatResidentList(role, data) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                var name = r.name?r.name:"";
                var lastname = r.lastname?r.lastname:"";
                var secondlastname = r.secondlastname?r.secondlastname:"";
                r.fullName = name + " " + lastname + " " + secondlastname;
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == vm.complaint.housesSelected[0]) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role)
                    r = vm.defineRoleUser(r);
                    list.push(r)
                }
            }
            return list;
        }
        function defineRole(role) {
            switch (role) {
                case "ROLE_OWNER":
                    return "Residente propietario";
                case "ROLE_RENTER":
                    return "Propietario arrendador";
                case "ROLE_TENANT":
                    return "Inquilino";
                case "ROLE_RESIDENT":
                    return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                    case "ROLE_OWNER":
                        resident.houses[i].role = "Residente propietario";
                        break;
                    case "ROLE_RENTER":
                        resident.houses[i].role = "Propietario arrendador";
                        break;
                    case "ROLE_TENANT":
                        resident.houses[i].role = "Inquilino";
                        break;
                    case "ROLE_RESIDENT":
                        resident.houses[i].role = "Residente autorizado";
                        break;
                }
            }
            return resident;
        }
        function loadResidentsByHouse() {
            vm.residentsAll = [];
            if (vm.complaint.housesSelected.length < 2) {
                Resident.getResidentsByHouse({houseId:  vm.complaint.housesSelected[0]}, function (data) {

                    vm.owners = formatResidentList("ROLE_OWNER", data);
                    vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data));
                    vm.tenants = formatResidentList("ROLE_TENANT", data);
                    vm.residents = formatResidentList("ROLE_RESIDENT", data);
                    vm.loading = false;
                    vm.data = data;
                    vm.loaded = true;
                    vm.residentsAll = vm.owners.concat(vm.tenants).concat(vm.residents);
                })
            }
        }

        vm.defineResidentType = function (type) {
            switch (type) {
                case 1:
                    return "Propietario"
                    break;
                case 2:
                    return "Propietario arrendador"
                    break;
                case 3:
                    return "Residente autorizado"
                    break;
                case 4:
                    return "Inquilino"
                    break;
            }
        }

        function loadAll() {
            House.query({
                sort: sort(),
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.houses = data;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function makeid(length, fileName) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result + "." + fileName.split('.').pop();
        }

        function upload() {
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/individual-release/' + vm.complaint.houseId + '/' + makeid(10, file.name)).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    vm.complaint.fileUrl = downloadURL;
                    if (vm.complaint.id !== null) {
                        Complaint.update(vm.complaint, onSaveSuccess, onSaveError);
                    } else {
                        Complaint.save(vm.complaint, onSaveSuccess, onSaveError);
                    }
                });
            });
        }

        function save() {
            Modal.confirmDialog("¿Está seguro que desea enviar el comunicado individual?", "",
                function () {
                    if (vm.complaint.housesSelected.length > 1) {
                        var toSend = "";
                        for (var i = 0; i < vm.complaint.housesSelected.length; i++) {
                            var h = vm.complaint.housesSelected[i];
                            toSend = toSend + h + ";"
                        }
                        vm.complaint.toSend = toSend;
                    } else {
                        vm.complaint.houseId = vm.complaint.housesSelected[0];
                    }
                    vm.isSaving = true;
                    vm.complaint.creationDate = moment(new Date).format();
                    vm.complaint.companyId = globalCompany.getId();
                    vm.complaint.status = 1;
                    vm.complaint.deleted = 0;
                    vm.complaint.readedAdmin = 1;
                    vm.complaint.readedResident = 0;
                    Modal.showLoadingBar();
                    if (file) {
                        upload();
                    } else {
                        if (vm.complaint.id !== null) {
                            Complaint.update(vm.complaint, onSaveSuccess, onSaveError);
                        } else {
                            Complaint.save(vm.complaint, onSaveSuccess, onSaveError);
                        }
                    }

                });


        }

        function onSaveSuccess(result) {
            Modal.hideLoadingBar();
            Modal.toast("Se envió el comunicado exitosamente.")
            $state.go('individual-release');
            vm.isSaving = false;
        }

        function onSaveError() {
            Modal.hideLoadingBar();
            vm.isSaving = false;
        }

        vm.options = {
            toolbar: [
                ['font', ['bold', 'underline', 'clear']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['fullscreen','undo','redo']],
            ]
        }
        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.resolutionDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
            }
        };
    }
})();
