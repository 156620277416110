(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('LoadAutomaticChargeController', LoadAutomaticChargeController);

        LoadAutomaticChargeController.$inject = ['ExchangeRateBccr', 'AdministrationConfiguration', 'CommonMethods', '$mdDialog', '$scope', '$state', 'House', 'globalCompany', '$rootScope', 'DataUtils', 'Modal', 'Charge', 'CustomChargeType'];

        function LoadAutomaticChargeController(ExchangeRateBccr, AdministrationConfiguration, CommonMethods, $mdDialog, $scope, $state, House, globalCompany, $rootScope, DataUtils, Modal, Charge, CustomChargeType) {
            $rootScope.active = "load-automatic-charge";
            var vm = this;
            var file;
            vm.fileName;
            vm.chargesList = [];
            vm.isReady = 0;
            House.query({companyId: globalCompany.getId()}).$promise.then(onSuccessHouses);
            vm.creatingCharges = false;
            vm.chargeCount = 0;
            vm.bccrUse = true;
            moment.locale("es");
            vm.today = new Date();

            vm.changeDateString = function(payment){
                moment.locale("es");
                var dateStringF = new Date(payment.date);
                payment.dateString =  moment(dateStringF).format('DD MMMM YYYY');
            }

            vm.changeDueDateString = function(payment){
                moment.locale("es");
                var dateStringF = new Date(payment.dueDate);
                payment.dueDateString =  moment(dateStringF).format('DD MMMM YYYY');
            }


            CustomChargeType.getByCompany({companyId: globalCompany.getId()}).$promise.then(onSuccessCustom);
            vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());

            vm.secondCurrency = vm.companyConfig.secondCurrency;
            vm.principalCurrency = vm.companyConfig.currency;
            vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
            vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

            vm.showBccrUse = function () {
                var currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
                if (currencies.length !== 2) {
                    return false;
                }
                var showBccrUse = currencies.some(function (moneda) {
                    return moneda.symbol === "₡";
                }) && currencies.some(function (moneda) {
                    return moneda.symbol === "$";
                });
                return showBccrUse;
            }

            vm.saveAdminConfig = function () {
                vm.adminConfig.exchangeRateDate = moment().format();
                if (vm.adminConfig.id !== null) {
                    AdministrationConfiguration.update(vm.adminConfig, function (result) {
                    }, function () {
                    });
                } else {
                    AdministrationConfiguration.save(vm.adminConfig, function () {
                    }, function () {
                    });
                }
            }

            AdministrationConfiguration.get({
                companyId: globalCompany.getId()
            }).$promise.then(function (result) {
                vm.adminConfig = result;
                vm.adminConfig.exchangeRate = parseFloat(vm.adminConfig.exchangeRate);
                if (vm.showBccrUse()) {
                    ExchangeRateBccr.get({
                        fechaInicio: moment(new Date()).format(),
                        fechaFinal: moment(new Date()).format(),
                    }, function (result) {
                        vm.tipoCambio = result;
                    })
                }
            })

            function onSuccessCustom(data) {
                vm.customCharges = data;
            }

            function onSuccessHouses(data, headers) {
                vm.houses = data;
            }

            vm.clearSearchTerm = function () {
                vm.searchTerm = '';
            };
            vm.searchTerm;
            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }

            vm.uploadFile = function ($file) {
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                vm.progressUpload = "Leyendo archivo..."
                if ($file) {
                    DataUtils.toBase64($file, function (base64Data) {
                        vm.progressUpload = "Convirtiendo datos..."
                        $scope.$apply(function () {
                            vm.displayImage = base64Data;
                            vm.displayImageType = $file.type;
                        });
                    });
                    file = $file;
                    vm.fileName = file.name;
                    vm.isReady = 1;
                    vm.parseExcel();
                }
            };

            vm.parseExcel = function () {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    vm.progressUpload = "Convirtiendo datos..."
                    /* DO SOMETHING WITH workbook HERE */
                    var first_sheet_name = workbook.SheetNames[0];
                    /* Get worksheet */
                    var worksheet = workbook.Sheets[first_sheet_name];
                    vm.charges = XLSX.utils.sheet_to_json(worksheet, {
                        raw: false
                    });
                    showCharges(formatCharges(vm.charges))
                };
                reader.onerror = function (ex) {
                    Modal.toast("Existe un error con el formato del archivo subido")
                    vm.isReady = 1;
                };
                reader.readAsBinaryString(file);
            };
            vm.charge = {
                type: "1",
                concept: "",
                ammount: "",
                date: "",
                dueDate: "",
                valida: true,
                state: 1,
                deleted: 0
            }


            function showCharges(charges) {
                $scope.$apply(function () {
                    vm.isReady = 2;
                    vm.chargesList = charges;
                })
            }


            vm.setHouses = function () {
                if (vm.chargesList.length > 0) {
                    for (var i = 0; i < vm.chargesList.length; i++) {
                        vm.chargesList[i].houseId = vm.houseId;
                    }

                }
            }
            vm.saveCharges = function () {
                Modal.confirmDialog("¿Está seguro que desea registrar las cuotas?", "", function () {
                    vm.error = false;
                    Modal.showLoadingBar();
                    vm.creatingCharges = true;
                    Modal.toast("Se están registrando las cuotas, por favor espere y no cierre la ventana.")
                    createCharge(vm.chargesList[0], 0, vm.chargesList.length);
                    if (vm.error) {
                        Modal.toast("Se han presentado un error.")
                    }
                })
            };

            function createCharge(charge, count, length) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.chargeCount = count;
                    })
                }, 10)
                if (count < length) {
                    charge.sendEmail = vm.sendEmail;
                    Charge.save(charge, function (result) {
                        count++;
                        if (count == length) {
                            Modal.hideLoadingBar();
                            vm.isReady = 0;
                            vm.creatingCharges = false;
                            Modal.toast("Se han ingresado las cuotas correctamente.")
                        } else {
                            createCharge(vm.chargesList[count], count, vm.chargesList.length)
                        }
                    }, function () {
                        Modal.hideLoadingBar();
                        vm.creatingCharges = false;
                        Modal.actionToastGiantStay("Ocurrio un error en la creación de las cuotas después de la línea " + (parseInt(count) + 2));
                        vm.error = true;
                    })
                }
            }

            function validateLineCharge(charge, i) {
                var variable = "";
                var errorCount = 0;
                if (charge.monto == undefined) {
                    variable = "monto";
                    errorCount++;
                } else {
                    console.log("CHARGE",charge.monto)
                    var num = parseFloat(charge.monto)
                    if (isNaN(num)) {
                        variable = "monto";
                        errorCount++;
                    }
                }
                if (charge.fechaCobro == undefined) {
                    variable = "fechaCobro";
                    errorCount++;
                }
                if (charge.fechaVencimiento == undefined) {
                    variable = "fechaVencimiento";
                    errorCount++;
                }
                if (charge.concepto == undefined) {
                    variable = "concepto";
                    errorCount++;
                }
                if (errorCount > 0) {

                }
                return errorCount > 0 ? "Error en la línea " + (parseInt(i) + 2) + " en el campo " + variable : "no"
            }

            function formatCharges(charges) {
                var formatedCharges = [];
                for (var i = 0; i < charges.length; i++) {
                    vm.readingCount = i;
                    var charge = charges[i];
                    if (validateLineCharge(charge, i) == "no") {
                        var formateCharge = {};
                        formateCharge.type = defineChargeType(charge) + "";
                        formateCharge.state = 1;
                        formateCharge.deleted = 0;
                        formateCharge.ammount = charge.monto.replace(/,/g, "");
                        formateCharge.date = moment(charge.fechaCobro, 'DD/MM/YYYY');
                        formateCharge.dueDate = moment(charge.fechaVencimiento, 'DD/MM/YYYY');
                        formateCharge.concept = charge.concepto;
                        formateCharge.consecutive = charge.noFAC_OPCIONAL;
                        formateCharge.companyId = globalCompany.getId();
                        var dateStringF = new Date(formateCharge.date);
                        formateCharge.dateString =  moment(dateStringF).format('DD MMMM YYYY');
                        var dueDateStringF = new Date(formateCharge.dueDate);
                        formateCharge.dueDateString =  moment(dueDateStringF).format('DD MMMM YYYY');
                        angular.forEach(vm.houses, function (house, key) {
                            if (house.housenumber == charge.filial) {
                                formateCharge.houseId = house.id;
                            }
                        });
                        formatedCharges.push(formateCharge)
                    } else {
                        vm.chargesList = [];
                        vm.isReady = 0;
                        Modal.toast(validateLineCharge(charge, i))
                        break;
                    }
                }
                vm.isReady = 1;
                return formatedCharges;
            }

            function defineChargeType(charge) {
                switch (charge.tipo) {
                    case "FAC":
                        return 1;
                        break;
                    case "EXTRA":
                        return 2;
                        break;
                    case "AGUA":
                        return 6;
                        break;
                    case "MULT":
                        return 5;
                        break;
                    case "INT":
                        return 8;
                        break;
                    case "AREA":
                        return 3;
                        break;
                }
                return charge.tipo;
            }
        }
    }

)();
