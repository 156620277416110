(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('ConfigureChargesController', ConfigureChargesController);

        ConfigureChargesController.$inject = ['DataUtils', 'ExchangeRateBccr', '$state', 'House', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$rootScope', '$scope', 'AdministrationConfiguration', 'Charge', 'CommonMethods', 'globalCompany', 'Modal'];

        function ConfigureChargesController(DataUtils, ExchangeRateBccr, $state, House, ParseLinks, AlertService, paginationConstants, pagingParams, $rootScope, $scope, AdministrationConfiguration, Charge, CommonMethods, globalCompany, Modal) {
            var vm = this;
            $rootScope.active = "configureCharges";
            vm.loadPage = loadPage;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.transition = transition;
            vm.radiostatus = true;
            $rootScope.mainTitle = "Configurar Filiales y cobro de cuotas";
            vm.isReady = false;
            vm.cuotaFija = true;
            vm.datePickerOpenStatus = {};

            vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());

            vm.openCalendar = openCalendar;
            vm.itemsPerPage = paginationConstants.itemsPerPage;
            vm.verificando = false;
            moment.locale("es");
            vm.bccrUse = true;
            var file;
            vm.fileName;
            vm.Today = new Date();
            vm.newHouses = [];
            vm.addingPlantilla = false;

            vm.uploadFile = function ($file) {
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                vm.progressUpload = "Leyendo archivo..."
                if ($file) {
                    DataUtils.toBase64($file, function (base64Data) {
                        vm.progressUpload = "Convirtiendo datos..."
                        $scope.$apply(function () {
                            vm.displayImage = base64Data;
                            vm.displayImageType = $file.type;
                            vm.addingPlantilla = true;
                        });
                    });
                    file = $file;
                    vm.fileName = file.name;
                    vm.isReady = 1;
                    vm.parseExcel();
                }
            };
            vm.parseExcel = function () {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    vm.progressUpload = "Convirtiendo datos..."
                    /* DO SOMETHING WITH workbook HERE */
                    var first_sheet_name = workbook.SheetNames[0];
                    /* Get worksheet */
                    var worksheet = workbook.Sheets[first_sheet_name];
                    vm.houseCharges = XLSX.utils.sheet_to_json(worksheet, {
                        raw: false
                    });
                    formatHouseCharges(vm.houseCharges);
                };
                reader.onerror = function (ex) {
                    Modal.toast("Existe un error con el formato del archivo subido")
                    vm.isReady = 1;
                };
                reader.readAsBinaryString(file);
            };

            function formatHouseCharges(charges) {
                var newAmmountsHouse = [];
                for (var i = 0; i < charges.length; i++) {
                    for (var j = 0; j < vm.houses.length; j++) {
                        if (charges[i].filial == vm.houses[j].housenumber) {
                            var newH = vm.houses[j];
                            if (charges[i].monto != undefined) {
                                var m = charges[i].monto.replace(/,/g, "");
                                if (m == undefined) {
                                    m = "FORMATO INVALIDO";
                                } else {
                                    newH.due = m;
                                }
                            }
                            if (charges[i].folio != "" && charges[i].folio != undefined) {
                                newH.folioNumber = charges[i].folio;
                            }
                            if (charges[i].m2 != "" && charges[i].m2 != undefined) {
                                newH.squareMeters = charges[i].m2;
                            }

                            if (charges[i].estado != "" && charges[i].estado != undefined) {
                                console.log(charges[i].estado)
                                newH.status = defineStatus(charges[i].estado);
                            }

                            if (charges[i].alquilada != "" && charges[i].alquilada != undefined) {
                                newH.hasOwner = charges[i].alquilada == "si" ? true : false;
                            }
                            if (charges[i].habitada != "" && charges[i].habitada != undefined) {
                                newH.isdesocupated = charges[i].habitada == "si" ? "0" : "1";
                            }
                            if (charges[i].cobraAgua != "" && charges[i].cobraAgua != undefined) {
                                newH.chargeWaterFee = charges[i].cobraAgua == "si" ? true : false;
                            }
                            newAmmountsHouse.push(newH)
                        }
                    }
                }
                vm.newHouses = newAmmountsHouse;
                vm.defineTotalToPay();
            }

            function defineStatus(status) {
                status = status.replace(/ /g, '');
                switch (status) {
                    case "enconstruccion":
                        return "En construcción";
                        break;
                    case "construida":
                        return "Construida";
                        break;
                    case "lotevacio":
                        return "Lote vacío";
                        break;
                }
                return "Sin definir";
            }

            vm.saveAdminConfig = function () {
                vm.adminConfig.exchangeRateDate = moment().format();
                vm.formatCurrencyToPay();
                if (vm.adminConfig.id !== null) {
                    AdministrationConfiguration.update(vm.adminConfig, function (result) {
                    }, function () {
                    });
                } else {
                    AdministrationConfiguration.save(vm.adminConfig, function () {
                    }, function () {
                    });
                }
            }

            vm.cancelPlantilla = function () {
                vm.addingPlantilla = false;
                vm.newHouses = [];
            }

            vm.saveNewMontos = function () {
                Modal.confirmDialog("¿Está seguro que desea modificar los montos a cobrar de cuota de mantenimiento de todas las filiales?",
                    "Una vez se modifique se cobrará ese monto mensual por filial de ahora en adelante.", function () {
                        vm.count = 0;
                        if (vm.newHouses.length > 0) {
                            Modal.showLoadingBar();
                            vm.savingNewCharges = true;
                            updateHouse(vm.newHouses[0], vm.count, vm.newHouses.length)
                        }
                    })
            }

            function updateHouse(house, count, total) {
                var due = parseFloat(house.due);
                if (!isNaN(due)) {
                    house.hasOwner = house.hasOwner == "1";
                    house.chargeWaterFee = house.chargeWaterFee == "1";
                    House.update(house, function (result) {
                        setTimeout(function () {
                            $scope.$apply(function () {
                                vm.countSaving = count;
                            })
                        })
                        count++;
                        if (count == total) {
                            Modal.toast("Guardado correctamente.")
                            loadAll();
                            vm.addingPlantilla = false;
                            vm.newHouses = [];
                            vm.savingNewCharges = false;
                            Modal.hideLoadingBar();
                        } else {
                            updateHouse(vm.newHouses[count], count, total)
                        }
                    })
                } else {
                    updateHouse(vm.newHouses[count + 1], count + 1, total)
                }
            }

            vm.formatCurrencyToPay = function () {
                var venta = vm.bccrUse ? vm.tipoCambio.venta : vm.adminConfig.exchangeRate;
                for (var i = 0; i < vm.houses.length; i++) {
                    if (vm.adminConfig.chargesCreateCurrency != vm.adminConfig.chargesCollectCurrency) {
                        vm.houses[i].dueCollect = "0";
                        if (vm.adminConfig.chargesCollectCurrency == "₡" && vm.adminConfig.chargesCreateCurrency == "$") {
                            vm.houses[i].dueCollect = vm.houses[i].due * venta;
                        }
                        if (vm.adminConfig.chargesCollectCurrency == "$" && vm.adminConfig.chargesCreateCurrency == "₡") {
                            vm.houses[i].dueCollect = vm.houses[i].due / venta;
                        }
                    } else {
                        vm.houses[i].dueCollect = vm.houses[i].due
                    }
                }
            }

            vm.formatearNumero = function (nStr) {
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? ',' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + '.' + '$2');
                }
                return x1 + x2;
            }
            vm.defineTotalToPay = function () {
                var totalToPay = 0;
                if (vm.addingPlantilla) {
                    for (var i = 0; i < vm.newHouses.length; i++) {
                        var house = vm.houses[i];
                        var collect = parseFloat(house.dueCollect)
                        if (!isNaN(collect)) {
                            totalToPay += collect;
                        }
                    }
                } else {
                    for (var i = 0; i < vm.houses.length; i++) {
                        var house = vm.houses[i];
                        var collect = parseFloat(house.dueCollect)
                        if (!isNaN(collect)) {
                            totalToPay += collect;
                        }
                    }
                }
                vm.totalToPay = totalToPay;
            }
            vm.validate = function (house, s, t) {
                var caracteres = ['´', 'Ç', '_', 'ñ', 'Ñ', '¨', ';', '{', '}', '[', ']', '"', "¡", "!", "¿", "<", ">", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", ",", ".", "?", "/", "-", "+", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "=", "|"]

                var invalido = 0;
                angular.forEach(caracteres, function (val, index) {
                    if (s != undefined) {
                        for (var i = 0; i < s.length; i++) {
                            if (s.charAt(i).toUpperCase() == val.toUpperCase()) {

                                invalido++;
                            }
                        }
                    }
                })
                if (invalido == 0) {
                    if (t == 1) {
                        house.validDue = true;
                        house.dirtyDue = true;

                    } else {
                        house.validSquare = true;
                        house.dirtySquare = true;
                    }
                } else {
                    if (t == 1) {
                        house.validDue = false;
                        house.dirtyDue = false;
                    } else {
                        house.validSquare = false;

                        house.dirtySquare = false;
                    }
                }
            }
            loadAll();

            vm.saveHouse = function (house, t) {
                if (house.validDue == true) {
                    house.dueCollect = house.due * vm.adminConfig.exchangeRate;
                    if (t == 1) {
                        if (house.dirtyDue == true) {
                            house.hasOwner = house.hasOwner == "1";
                            house.chargeWaterFee = house.chargeWaterFee == "1";
                            House.update(house, function (result) {
                                Modal.toast("Guardado.")
                                house = result;
                                house.dirtyDue = false;
                            })
                        }
                    } else {
                        if (house.dirtySquare == true) {
                            house.hasOwner = house.hasOwner == "1";
                            house.chargeWaterFee = house.chargeWaterFee == "1";
                            House.update(house, function (result) {
                                Modal.toast("Guardado.")
                                house = result;
                                house.dirtySquare = false;
                            })
                        }
                    }
                } else {
                    Modal.toast("Debe de ingresar solo números.")
                }
            }

            vm.saveHouseClean = function (house) {
                house.hasOwner = house.hasOwner == "1" ? true : false;
                house.chargeWaterFee = house.chargeWaterFee == "1" ? true : false;
                House.update(house, function (result) {
                    Modal.toast("Guardado.")
                    house.hasOwner = house.hasOwner ? "1" : "0";
                    house.chargeWaterFee = house.chargeWaterFee ? "1" : "0";
                    house.dirtyDue = false;
                })
            }

            function loadAll() {
                House.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    companyId: globalCompany.getId()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    AdministrationConfiguration.get({
                        companyId: globalCompany.getId()
                    }).$promise.then(function (result) {
                        vm.adminConfig = result;
                        if (vm.adminConfig.chargesCollectCurrency == null) {
                            vm.adminConfig.chargesCollectCurrency = "₡";
                        }
                        if (vm.adminConfig.chargesCreateCurrency == null) {
                            vm.adminConfig.chargesCreateCurrency = "₡";
                        }
                        vm.globalConceptNumber = 0;
                        vm.globalConcept = [{
                            date: "",
                            concept: "",
                            id: vm.globalConceptNumber,
                            datePickerOpenStatus: false
                        }];
                        vm.links = ParseLinks.parse(headers('link'));
                        vm.totalItems = headers('X-Total-Count');
                        vm.queryCount = vm.totalItems;
                        angular.forEach(data, function (value, key) {
                            value.validDue = true;
                            value.validSquare = true;
                            value.dirtyDue = false;
                            value.dirtySquare = false;
                            value.squareMeters = parseFloat(value.squareMeters);
                            value.hasOwner = value.hasOwner ? "1" : "0";
                            value.chargeWaterFee = value.chargeWaterFee ? "1" : "0";
                        })
                        vm.houses = data;
                        vm.page = pagingParams.page;
                        vm.isReady = true;
                        ExchangeRateBccr.get({
                            fechaInicio: moment(new Date()).format(),
                            fechaFinal: moment(new Date()).format(),
                        }, function (result) {
                            vm.tipoCambio = result;
                            vm.formatCurrencyToPay();
                            vm.defineTotalToPay()

                        })
                    })
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }

            function loadPage(page) {
                vm.page = page;
                vm.transition();
            }

            function transition() {
                $state.transitionTo($state.$current, {
                    page: vm.page,
                    sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                    search: vm.currentSearch
                });
            }

            function openCalendar(index) {
                vm.globalConcept[index].datePickerOpenStatus = true;
            }
        }
    }

)();
