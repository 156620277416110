(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('NotePerHouseController', NotePerHouseController);

    NotePerHouseController.$inject = ['DataUtils', 'globalCompany', '$scope', '$rootScope', '$localStorage', 'Modal', 'Note', 'House'];

    function NotePerHouseController(DataUtils, globalCompany, $scope, $rootScope, $localStorage, Modal, Note, House) {
        var vm = this;
        var file;

        vm.loadedPlantilla = false;

        vm.cancel = function () {
            vm.loadedPlantilla = false;
            vm.newNotes = [];
        }


        vm.loadAll = function () {
            vm.loadedPlantilla = false;
            vm.newNotes = [];
            if ($localStorage.houses) {
                onSuccess($localStorage.houses)
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                }, onSuccess);
            }
        }

        vm.loadAll();

        function onSuccess(data) {
            vm.houses = data;
        }

        vm.uploadFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            vm.progressUpload = "Leyendo archivo..."
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    vm.progressUpload = "Convirtiendo datos..."
                    $scope.$apply(function () {
                        vm.displayImage = base64Data;
                        vm.displayImageType = $file.type;
                        vm.addingPlantilla = true;
                    });
                });
                file = $file;
                vm.fileName = file.name;
                vm.isReady = 1;
                vm.parseExcel();
            }
        };
        vm.parseExcel = function () {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary'
                });
                vm.progressUpload = "Convirtiendo datos..."
                /* DO SOMETHING WITH workbook HERE */
                var first_sheet_name = workbook.SheetNames[0];
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name];
                vm.notes = XLSX.utils.sheet_to_json(worksheet, {
                    raw: false
                });
                setTimeout(function () {
                    $scope.$apply(function () {
                        formatNotes(vm.notes);
                    })
                })
            };
            reader.onerror = function (ex) {
                Modal.toast("Existe un error con el formato del archivo subido")
                vm.isReady = 1;
            };
            reader.readAsBinaryString(file);
        };

        vm.saveAllNotes = function () {
            Modal.confirmDialog("¿Está seguro que desea modificar todas esas notas de la plantilla?",
                "", function () {
                    vm.count = 0;
                    if (vm.newNotes.length > 0) {
                        Modal.showLoadingBar();
                        vm.savingNewNotes = true;
                        updateNote(vm.newNotes[0], vm.count, vm.newNotes.length)
                    }
                })
        }


        function updateNote(house, count, total) {
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.houseCount = count;
                })
            }, 10)
            House.saveClean(house, function (result) {
                count++;
                if (count == total) {
                    Modal.toast("Guardado correctamente.")
                    vm.loadAll();
                    vm.savingNewNotes = false;
                    Modal.hideLoadingBar();
                } else {
                    updateNote(vm.newNotes[count], count, total)
                }
            })
        }

        function formatNotes(notes) {
            vm.newNotes = [];
            if (notes) {
                for (var i = 0; i < vm.houses.length; i++) {
                    for (var j = 0; j < notes.length; j++) {
                        if (vm.houses[i].housenumber == notes[j].filial) {
                            if (notes[j].nota) {
                                var house = vm.houses[i];
                                house.accessDoorNote = notes[j].nota;
                                vm.newNotes.push(house);
                            }
                        }
                    }
                }
            }
            vm.loadedPlantilla = true;
        }

        vm.saveNote = function (house) {
            Modal.confirmDialog("¿Está seguro que desea guardar la nota de la filial?", "", function () {
                House.saveClean(house, onSaveSuccess);
            })
        }

        function onSaveSuccess() {
            Modal.toast("Guardado.")
        }

        var unsubscribe = $rootScope.$on('aditumApp:noteUpdate', function (event, result) {
            vm.note = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
