(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpCategoryController', FollowUpCategoryController);

    FollowUpCategoryController.$inject = ['$state', 'FollowUpCategory', 'ParseLinks', 'AlertService', '$uibModalInstance', 'globalCompany', 'Modal'];

    function FollowUpCategoryController($state, FollowUpCategory, ParseLinks, AlertService, $uibModalInstance, globalCompany, Modal) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.followUpCategory = {};
        vm.followUpCategory.color = "folder-gray";
        loadAll();

        function loadAll() {
            FollowUpCategory.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.recordCategories = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.newFolder = function () {
            vm.addingFolder = true;
            vm.titleForm = "Crear nueva categoría";
            vm.button = "Crear categoría";

        }

        vm.editFolder = function (recordCategory) {
            vm.addingFolder = true;
            vm.followUpCategory = recordCategory;
            vm.titleForm = "Editar categoría";
            vm.button = "Editar categoría";
        }

        vm.cancelNewFolder = function () {
            vm.addingFolder = false;
            vm.followUpCategory = {};
            vm.followUpCategory.color = "folder-gray";
        }


        vm.deleteFolder = function () {
            Modal.confirmDialog("¿Está seguro que desea eliminar esta categoría?", "Se perderá la posibilidad de consultar seguimientos por esta categoría", function () {
                Modal.showLoadingBar();
                var temporalCategory = vm.followUpCategory;
                FollowUpCategory.delete({id: vm.followUpCategory.id},
                    function () {
                        Modal.hideLoadingBar();
                        vm.isSaving = false;
                        Modal.actionToast("Carpeta eliminada.", "Deshacer", function () {
                            temporalCategory.deleted = false;
                            FollowUpCategory.update(temporalCategory, function () {
                                loadAll();
                                Modal.toast("Listo.")
                            }, onSaveError);
                        });
                        vm.addingFolder = false;
                        vm.followUpCategory = {};
                        loadAll();
                        vm.followUpCategory.color = "folder-gray";
                    });
            })
        }

        vm.saveFolder = function () {
            Modal.confirmDialog("¿Está seguro que desea crear esta categoría de seguimiento?", "", function () {
                vm.isSaving = true;
                vm.followUpCategory.companyId = globalCompany.getId();
                vm.followUpCategory.deleted = false;
                Modal.showLoadingBar();
                if (vm.followUpCategory.id !== null) {
                    FollowUpCategory.update(vm.followUpCategory, onSaveSuccess, onSaveError);
                } else {
                    FollowUpCategory.save(vm.followUpCategory, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess() {
            Modal.hideLoadingBar();
            vm.isSaving = false;
            Modal.toast("Carpeta creada.");
            vm.addingFolder = false;
            vm.followUpCategory = {};
            loadAll();
            vm.followUpCategory.color = "folder-gray";
        }

        function onSaveError() {
            Modal.toast("Ocurrio un error inesperado.");
        }

        vm.setColor = function (record, posColor) {
            var colors = ['folder-gray', 'folder-red', 'folder-orange', 'folder-yellow', 'folder-purple', 'folder-blue'];
            vm.followUpCategory.color = colors[posColor];
        }
        vm.close = function () {
            $uibModalInstance.close({});
        }
    }
})();
