(function () {
    'use strict';


    angular
        .module('aditumApp')
        .factory('WSNote', WSNote);

    WSNote.$inject = ['StompManager', 'globalCompany','StompManagerSecond'];

    function WSNote(StompManager, globalCompany,StompManagerSecond) {
        var SUBSCRIBE_TRACKER_URL = '/topic/notes/';
        var SEND_ACTIVITY_URL = '/topic/sendNote/';

        var subscribed = false;

        var service = {
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe,
        };
        return service;

        function receive() {
            return StompManagerSecond.getListener(SUBSCRIBE_TRACKER_URL + globalCompany.getId());
        }

        function sendActivity(entity) {
            StompManagerSecond.send(SEND_ACTIVITY_URL + globalCompany.getId(), entity);
        }

        function subscribe(companyId) {
            if (subscribed==false) {
                StompManagerSecond.subscribe(SUBSCRIBE_TRACKER_URL + companyId);
                subscribed = true;
            }
        }

        function unsubscribe(companyId) {
            StompManagerSecond.unsubscribe(SUBSCRIBE_TRACKER_URL + companyId);
            subscribed = false;
        }
    }
})();
