(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('StateOfIncomeController', StateOfIncomeController);

    StateOfIncomeController.$inject = ['globalCompany', '$rootScope', '$state', 'StateOfIncome', 'ParseLinks', 'AlertService', 'AccountingFullPeriod', '$timeout', '$scope', 'StateOfIncomePdf'];

    function StateOfIncomeController(globalCompany, $rootScope, $state, StateOfIncome, ParseLinks, AlertService, AccountingFullPeriod, $timeout, $scope, StateOfIncomePdf) {

        var vm = this;
        $rootScope.mainTitle = 'Estado de resultados Integral';
        $rootScope.active = 'stateOfIncome';
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.minDate = null;
        vm.maxDate = null;
        vm.disableLastDate = false;
        vm.notExportingExcel = true;
        vm.companyId = globalCompany.getId();



        vm.dates = {
            initial_time: new Date(),
            final_time: new Date()
        };

        vm.changeFormat = function () {
            vm.format = 'MMMM';
            vm.hideDate = false;
        };


        vm.format = 'L';
        moment.locale("es");

        vm.locale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(vm.format) : '';
            }
        };

        vm.loadPeriods = function () {
            AccountingFullPeriod.findByCompany({companyId: globalCompany.getId()}, function (data) {
                vm.accountingPeriods = data;
                if (data.length !== 0) {
                    vm.selectedPeriod = data[data.length - 1];
                    vm.dates.final_time = new Date(vm.selectedPeriod.openDate);
                    vm.defineDatesRange();
                }
            })
        }

        vm.loadPeriods();

        vm.defineDatesRange = function () {
            vm.disableLastDate = new Date(vm.selectedPeriod.closeDate).getTime() <= new Date().getTime();
            if (!vm.disableLastDate) {
                vm.minDate = new Date(vm.selectedPeriod.openDate);
                vm.maxDate = new Date();
                vm.dates.final_time = new Date();
            } else {
                vm.dates.final_time = new Date(vm.selectedPeriod.closeDate);
                vm.minDate = new Date(vm.selectedPeriod.openDate);
                vm.maxDate = new Date(vm.dates.final_time);
            }
        }

        vm.createMonths = function () {
            vm.isReady = false;
            vm.dates.initial_time = new Date(vm.dates.initial_time.getFullYear(), vm.dates.initial_time.getMonth(), 1);
            vm.dates.final_time = new Date(vm.dates.final_time.getFullYear(), vm.dates.final_time.getMonth() + 1, 0);
            vm.fileFinalDate = moment(vm.dates.final_time).format();
            vm.changeFormat()
            vm.loadAll()
        }

        vm.createMonthsFirst = function () {
            vm.isReady = false;
            vm.dates.initial_time = new Date(vm.dates.initial_time.getFullYear(), vm.dates.initial_time.getMonth(), 1);
            vm.dates.final_time = new Date(vm.dates.final_time.getFullYear(), vm.dates.final_time.getMonth() + 1, 0);
            vm.changeFormat()
            $timeout(function () {
                vm.loadAll()
            }, 200)
        }

        vm.defineColSpan = function (account) {
            return (account.categoryAccount || account.subAccount || account.mayorAccount) ? 6 : 1;
        }

        vm.showTd = function (account) {
            return !(account.categoryAccount || account.subAccount || account.mayorAccount);
        }

        vm.defineRowClass = function (account) {
            if (account.categoryAccount || account.totalCategoryAccount) {
                return "background-lightgray";
            }
            if (account.mayorAccount || account.totalMayorAccount) {
                return "background-lightgray-lighter";
            }
        }

        vm.defineBoldClass = function (account) {
            if (account.categoryAccount || account.totalCategoryAccount || account.mayorAccount || account.subAccount || account.totalSubAccount || account.totalMayorAccount) {
                return "bold-font";
            }
        }

        vm.tableToExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)))
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                })
                            }
                        var workSheetName = "Estado de resultados Integral";
                        if (!table.nodeType) table = document.getElementById(table)
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx))
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1)
                    vm.notExportingExcel = true;
                }, 500)
            })
        }

        function loadAll() {
            vm.isReady = false;
            StateOfIncome.getByCompanyIdAndBetweenDates({
                companyId: globalCompany.getId(),
                periodId: vm.selectedPeriod.id,
                finalTime: moment(vm.dates.final_time).format(),
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.stateOfIncome = data;
                vm.isReady = true;
                vm.notFound = !vm.stateOfIncome.currentPeriod;
            }

            function onError(error) {
                vm.notFound = true;
                AlertService.error(error.data.message);
            }
        }
                vm.exportPdf = function () {
                    StateOfIncomePdf.getByCompanyIdAndBetweenDates({
                        companyId: globalCompany.getId(),
                        periodId: vm.selectedPeriod.id,
                        finalTime: moment(vm.dates.final_time).format(),
                    }, function (data) {
                        console.log('PDF generado exitosamente');
                    }, function (error) {
                        console.error('Error al generar el PDF', error);
                    });
                };

               var formattedFinalTime = moment(vm.dates.final_time).format();
               vm.time_Final = formattedFinalTime;

        vm.loadAll = loadAll;
        vm.createMonthsFirst();


    }
})();
