(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('Expense', Expense);

    Expense.$inject = ['$resource', 'DateUtils'];

    function Expense($resource, DateUtils) {
        var resourceUrl = 'api/expenses/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'deleteExpense': {method: 'GET', url:"api/expense-delete/:id/:deletionDate", isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expenseDate = DateUtils.convertDateTimeFromServer(data.expenseDate);
                    }
                    return data;
                }
            },
            'generateReport': {
                method: 'GET',
                url: 'api/expenses/report/:initial_time/:final_time/:companyId',
                params: {
                    initial_time: '@initial_time',
                    final_time: '@final_time',
                    companyId: '@companyId',
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
