(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('GeneratePersonalQrController', GeneratePersonalQrController);

    GeneratePersonalQrController.$inject = ['$interval', '$scope', '$timeout', 'Principal', '$rootScope', 'CommonMethods', '$stateParams', 'AditumGate', '$http', 'WSQrCode', 'Auth'];

    function GeneratePersonalQrController($interval, $scope, $timeout, Principal, $rootScope, CommonMethods, $stateParams, AditumGate, $http, WSQrCode, Auth) {
        var vm = this;
        vm.noData = false;
        vm.showCode = false;
        vm.showCodeExit = false;
        vm.animationDone = true;
        vm.entered = false;
        vm.determinateValue = 0;

        // Función para actualizar la barra de progreso
        function updateProgressBar() {
            vm.determinateValue += 1;
            // Restablecer la barra de progreso cuando alcance el 100%
            if (vm.determinateValue >= 100) {
                vm.determinateValue = 0;
            }
        }

        // Iniciar un intervalo que actualiza la barra de progreso cada 100 milisegundos (0.1 segundos)


        vm.qrData = {
            houseNumber: $rootScope.houseSelected.housenumber,
            companyName: $rootScope.contextLiving,
            name: $rootScope.companyUser.name + ' ' + $rootScope.companyUser.lastname
        };
        $rootScope.active = "security-mobile-menu";
        vm.type = $stateParams.type;
        if ($stateParams.type == "entry") {
            $rootScope.mainTitle = "QR Personal Entrada";
        } else {
            vm.showCodeExit = true;
            $rootScope.mainTitle = "QR Personal Salida";
        }

        vm.sound_url = 'https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/open-gate.mp3?alt=media&token=52a1dd9c-c96f-4ddd-a54a-26fc400ab874';
        var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        angular.element(document).ready(function () {
            vm.audio = new Audio(vm.sound_url);
            vm.audio.load();
        });

        $rootScope.openedDoor = false;

        setTimeout(function () {
            $rootScope.code = $rootScope.companyUser.id;
            WSQrCode.subscribe($rootScope.code)
            WSQrCode.receive().then(null, null, openedDoor);
        }, 2000)


        vm.playAlert = function () {
            window.AudioContext = window.AudioContext || window.webkitAudioContext; //fix up prefixing
            var context = new AudioContext(); //context
            var source = context.createBufferSource(); //source node
            source.connect(context.destination); //connect source to speakers so we can hear it
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'arraybuffer'; //the  response is an array of bits
            request.onload = function () {
                context.decodeAudioData(request.response, function (response) {
                    source.buffer = response;
                    source.start(0); //play audio immediately
                }, function () {
                    console.error('The request failed.');
                    source.resume();
                });
            }
            request.send();
        }

        function vibrate() {
            if (window.navigator && window.navigator.vibrate) {
                navigator.vibrate([500]);
            } else {
            }
        }

        function openedDoor(data) {
            vm.entered = true;
            setTimeout(function(){
                vm.openedDoor = true;
                vm.animationDone = false;
                vm.audio.play()
                vibrate();
                vm.showCode = false;
                vm.showCodeExit = false;
            },300)
        }

        function removeElementById(id) {
            var element = document.getElementById(id);
            if (element) {
                element.parentNode.removeChild(element);
            }
        }

        function createDivInsideParent(parentId, childId) {
            var parentDiv = document.getElementById(parentId);
            if (parentDiv) {
                var childDiv = document.createElement('div');
                childDiv.id = childId;
                // Puedes personalizar el contenido y los atributos del childDiv aquí, si es necesario.
                parentDiv.appendChild(childDiv); // Agregar el childDiv como hijo del parentDiv
            }
        }

        function generateCode() {
            if ($stateParams.type == 'entry') {
                removeElementById("qrcode");
                createDivInsideParent("qrcodecontainer", "qrcode");
            } else {
                removeElementById("qrcodeExit");
                createDivInsideParent("qrcodecontainerExit", "qrcodeExit");
            }
            var qrcode = null;
            var text = "ADITUMGATE=";
            var currentDate = new Date().toISOString();
            var currentTime = new Date();
            currentTime.setHours(currentTime.getUTCHours() - 6); // Ajuste de -6 horas para Costa Rica
            var day = ("0" + currentTime.getUTCDate()).slice(-2);
            var minutes = ("0" + currentTime.getUTCMinutes()).slice(-2);
            var seconds = ("0" + currentTime.getUTCSeconds()).slice(-2);
            var formattedTime = day + "G" + minutes + "G" + seconds;
            var code = $rootScope.companyUser.id + "PR" + formattedTime;
            if ($stateParams.type == 'entry') {
                var qrcode = new QRCode("qrcode", {
                    text: text + code,
                    width: width - 100,
                    height: width - 100,
                    colorDark: "black",
                    colorLight: "white",
                    correctLevel: QRCode.CorrectLevel.L
                })
            } else {
                var qrcodeExit = new QRCode("qrcodeExit", {
                    text: text + code + "EXIT",
                    width: width - 100,
                    height: width - 100,
                    colorDark: "black",
                    colorLight: "white",
                    correctLevel: QRCode.CorrectLevel.L
                })
            }
            vm.showCode = true;
        }

        // var progressBarInterval = $interval(updateProgressBar, 100);
        generateCode();
        // Detener el intervalo después de 10 segundos
        var interval = $interval(function () {
            // $interval.cancel(progressBarInterval);
            // progressBarInterval = $interval(updateProgressBar, 100);
            generateCode();
        }, 10000);

        $scope.$on('$destroy', function () {
            $interval.cancel(interval);
        });

    }
})
();
