(function() {
    'use strict';

    angular
        .module('aditumApp')
        .filter('truncate', truncate);

    function truncate() {
        return function(text, length, end) {
            if (isNaN(length))
                length = 10;
            if (end === undefined)
                end = "...";

            if (text.length <= length || text.length - end.length <= length) {
                return text;
            } else {
                return String(text).substring(0, length - end.length) + end;
            }
        };
    }
})();


(function() {
    'use strict';

    angular
        .module('aditumApp')
        .filter('unique', unique);

    function unique() {
        return function(collection, keyname) {
            var output = [],
                keys = [];

            angular.forEach(collection, function(item) {
                var key = item[keyname];
                if (keys.indexOf(key) === -1) {
                    keys.push(key);
                    output.push(item);
                }
            });

            return output;
        };
    }
})();
