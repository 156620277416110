(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingPeriodDetailController', AccountingPeriodDetailController);

    AccountingPeriodDetailController.$inject = ['Modal', 'AccountFinancialClosing', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccountingPeriod', 'CommonMethods'];

    function AccountingPeriodDetailController(Modal, AccountFinancialClosing, $scope, $rootScope, $stateParams, previousState, entity, AccountingPeriod, CommonMethods) {
        var vm = this;

        vm.accountingPeriod = entity;
        vm.previousState = previousState.name;
        vm.saveAccount = function (account) {
            AccountFinancialClosing.update(account, function () {
                Modal.toast("Balance modificado");
                var id = CommonMethods.decryptIdUrl($stateParams.id);
                return AccountingPeriod.get({id: id}, function (data) {
                    vm.accountingPeriod = data;
                })
            });
        }

        vm.hideAccounts = function (account) {
            var accountsToHide = ["Perdidas y Ganancias","Excedente de periodo actual"];
            for (var i = 0; i < accountsToHide.length; i++) {
                if (accountsToHide[i] == account.accountName) {
                    return false;
                }
            }
            return true;
        }

        vm.closePeriod = function () {
            Modal.confirmDialog("¿Está seguro que desea realizar el cierre contable?", "", function () {
                Modal.confirmDialog("¿Confirmar cierre contable?", "Una vez se cierre no se podrán modificar los movimientos del periodo", function () {
                    vm.isSaving = true;
                    vm.accountingPeriod.status = 0;
                    vm.accountingPeriod.closeDate = new Date();
                    Modal.showLoadingBar();
                    if (vm.accountingPeriod.id !== null) {
                        AccountingPeriod.update(vm.accountingPeriod, function () {
                            Modal.toast("Se cerró el periodo correctamente.");
                            Modal.hideLoadingBar();
                        });
                    }
                })
            })
        }
        var unsubscribe = $rootScope.$on('aditumApp:accountingPeriodUpdate', function (event, result) {
            vm.accountingPeriod = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
