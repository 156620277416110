(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ComingSoonController', ComingSoonController);

    ComingSoonController.$inject = ['$scope','$rootScope'];

    function ComingSoonController($scope, $rootScope) {
        var vm = this;

        $rootScope.active = "coming-soon";
    }
})();
