(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingSeatDetailController', AccountingSeatDetailController);

    AccountingSeatDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccountingSeat', 'Company', 'DebitNote', 'CreditNote', 'Charge', 'Payment', 'Egress', 'House', 'AccountingAccount'];

    function AccountingSeatDetailController($scope, $rootScope, $stateParams, previousState, entity, AccountingSeat, Company, DebitNote, CreditNote, Charge, Payment, Egress, House, AccountingAccount) {
        var vm = this;

        vm.accountingSeat = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:accountingSeatUpdate', function(event, result) {
            vm.accountingSeat = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
