(function() {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('charge-specification', {
            parent: 'entity',
            url: '/charge-specification',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'aditumApp.chargeSpecification.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/charge-specification/charge-specifications.html',
                    controller: 'ChargeSpecificationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('chargeSpecification');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('charge-specification-detail', {
            parent: 'charge-specification',
            url: '/charge-specification/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'aditumApp.chargeSpecification.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/charge-specification/charge-specification-detail.html',
                    controller: 'ChargeSpecificationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('chargeSpecification');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ChargeSpecification', function($stateParams, ChargeSpecification) {
                    return ChargeSpecification.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'charge-specification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('charge-specification-detail.edit', {
            parent: 'charge-specification-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/charge-specification/charge-specification-dialog.html',
                    controller: 'ChargeSpecificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ChargeSpecification', function(ChargeSpecification) {
                            return ChargeSpecification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('charge-specification.new', {
            parent: 'charge-specification',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/charge-specification/charge-specification-dialog.html',
                    controller: 'ChargeSpecificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                description: null,
                                waterDetails: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('charge-specification', null, { reload: 'charge-specification' });
                }, function() {
                    $state.go('charge-specification');
                });
            }]
        })
        .state('charge-specification.edit', {
            parent: 'charge-specification',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/charge-specification/charge-specification-dialog.html',
                    controller: 'ChargeSpecificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ChargeSpecification', function(ChargeSpecification) {
                            return ChargeSpecification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('charge-specification', null, { reload: 'charge-specification' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('charge-specification.delete', {
            parent: 'charge-specification',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/charge-specification/charge-specification-delete-dialog.html',
                    controller: 'ChargeSpecificationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ChargeSpecification', function(ChargeSpecification) {
                            return ChargeSpecification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('charge-specification', null, { reload: 'charge-specification' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
