(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetItemDetailController', BudgetItemDetailController);

    BudgetItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BudgetItem', 'AccountingAccount', 'Budget'];

    function BudgetItemDetailController($scope, $rootScope, $stateParams, previousState, entity, BudgetItem, AccountingAccount, Budget) {
        var vm = this;

        vm.budgetItem = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:budgetItemUpdate', function(event, result) {
            vm.budgetItem = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
