(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpController', FollowUpController);

    FollowUpController.$inject = ['CommonMethods', 'Modal','$localStorage', 'House', 'Company', 'globalCompany', 'FollowUpCategory', '$rootScope', '$state', 'FollowUp', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function FollowUpController(CommonMethods, Modal,$localStorage, House, Company, globalCompany, FollowUpCategory, $rootScope, $state, FollowUp, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        moment.locale("es");
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.consulting = true;
        vm.isReady = true;
        $rootScope.mainTitle = "Reporte de seguimientos";
        $rootScope.active = "follow-up-report";
        loadAllCategory();
        loadHouses();
        vm.status = "-1";
        vm.category = "-1";
        vm.houseId = "-1";
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.generateReport = function () {
            FollowUp.report({
                page: pagingParams.page - 1,
                size: 5000,
                companyId: globalCompany.getId(),
                houseId: vm.houseId,
                category: vm.category,
                status: vm.status,
                initialDate: moment(vm.dates.initial_time).format(),
                finalDate: moment(vm.dates.final_time).format(),
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.followUps = [];
                for (var i = 0; i < data.length; i++) {
                    data[i].showingDetail = false;
                    vm.followUps.push(data[i]);
                }
                $localStorage.followUpReportData = {
                    followUps :  vm.followUps,
                    houseId : vm.houseId,
                    category: vm.category,
                    status: vm.status,
                    initialDate: vm.dates.initial_time,
                    finalDate: vm.dates.final_time
                }

                vm.consulting = false;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        if ($localStorage.followUpReportData) {
            vm.followUps = $localStorage.followUpReportData.followUps;
            vm.consulting = false;
            vm.isReady = true;
            vm.houseId = $localStorage.followUpReportData.houseId;
            vm.status = $localStorage.followUpReportData.status;
            vm.category = $localStorage.followUpReportData.category;
            vm.generateReport();
            setTimeout(function(){
                if(document.getElementById($localStorage.followId)!=null){
                    document.getElementById($localStorage.followId).scrollIntoView();
                }
            },200)
        }

        function loadHouses() {
            House.query({
                companyId: globalCompany.getId()
            }, function (data, headers) {
                vm.houses = data;
                Company.get({id: globalCompany.getId()}).$promise.then(function (result) {
                    vm.isReady = true;
                    vm.companyName = result.name;
                });
            })
        };

        vm.defineStatus = function(status){
            switch (status){
                case '0' :
                    return "Pendiente"
                case '1' :
                    return "En progreso"
                case '2' :
                    return "Finalizado"
            }
        }

        vm.defineStatusIcon = function(status){
            switch (status){
                case '0' :
                    return "schedule"
                case '1' :
                    return "hardware"
                case '2' :
                    return "done"
            }
        }

        vm.defineStatusColor = function(status){
            switch (status){
                case '0' :
                    return "color:rgb(229, 0, 0)"
                case '1' :
                    return "color:rgb(255,176,46)"
                case '2' :
                    return "color:rgb(107, 201, 80)"
            }
        }

        vm.goToFollowUpF = function (followUp) {
            if (followUp.houseId != null) {
                var houseIdF = followUp.houseId;
            } else {
                var houseIdF = -1
            }
            $localStorage.followId = followUp.id;
            var houseId = CommonMethods.encryptString(houseIdF + "");
            var id = CommonMethods.encryptString(followUp.id + "");
            $state.go("follow-up.edit", {houseId: houseId, id: id});
        }

        vm.toPdf = function () {
            for (var i = 0; i < vm.followUps.length; i++) {
                vm.followUps[i].showingDetail = true;
            }
            vm.exporting = true;
            Modal.toast("Exportando..")
            setTimeout(function(){
            var element = document.getElementById('element-to-print');
            var opt = {
                margin: 0.5,
                filename: 'Reporte de seguimientos '+vm.companyName+'.pdf',
                jsPDF: {unit: 'in', format: 'letter', orientation: 'landscape'},
                pagebreak: {avoid: '.makeBreak'}
            };
            html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
                var totalPages = pdf.internal.getNumberOfPages();
                //print current pdf width & height to console
                for (var i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    //divided by 2 to go center;
                    if(i!=1){
                        pdf.text('REPORTE DE SEGUIMIENTOS ' + vm.companyName.toUpperCase(), 0.6,
                            0.3);
                    }
                    pdf.text('Página ' + i + ' / ' + totalPages, pdf.internal.pageSize.getWidth() - 1,
                        pdf.internal.pageSize.getHeight() - 0.2);
                }
            }).save();
                Modal.toast("Listo")
                vm.exporting = false;
            },4000)
        }

        function loadAllCategory() {
            FollowUpCategory.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
            }, onSuccessCategory, onError);

            function onSuccessCategory(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.recordCategories = data;
            }
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }



        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
