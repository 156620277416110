(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AdministrationConfigurationDetailController', AdministrationConfigurationDetailController);

    AdministrationConfigurationDetailController.$inject = ['Modal','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AdministrationConfiguration', 'Company'];

    function AdministrationConfigurationDetailController(Modal,$scope, $rootScope, $stateParams, previousState, entity, AdministrationConfiguration, Company) {
        var vm = this;
        $rootScope.active = "administrationConfiguration";
        vm.administrationConfiguration = entity;

        vm.changeToBoolean = function(data){
            return data == 1;
        }

        vm.changeToInt = function(data){
            return data?1:0;
        }

        vm.administrationConfiguration.residentsViewComments = vm.changeToBoolean(vm.administrationConfiguration.residentsViewComments)

        vm.administrationConfiguration.hideRoutineCheck = vm.changeToBoolean(vm.administrationConfiguration.hideRoutineCheck)

        vm.administrationConfiguration.defaulterMaintenanceBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterMaintenanceBalance)

        vm.administrationConfiguration.defaulterPenaltyBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterPenaltyBalance)

        vm.administrationConfiguration.defaulterExtraordinaryBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterExtraordinaryBalance)

        vm.administrationConfiguration.defaulterCommonAreaBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterCommonAreaBalance)

        vm.administrationConfiguration.defaulterWaterBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterWaterBalance)

        vm.administrationConfiguration.defaulterOtherBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterOtherBalance)


        vm.usingSubchargePercentage = vm.administrationConfiguration.usingSubchargePercentage==false?0:1;
        vm.isReady = false;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.data = {
            cb1: true,
            cb4: true,
            cb5: false
        };

        vm.message = 'false';
        vm.isReady = true;
        vm.onChange = function(cbState) {
            vm.message = cbState;
        };
        var unsubscribe = $rootScope.$on('aditumApp:administrationConfigurationUpdate', function(event, result) {
            vm.administrationConfiguration = result;
        });
        $scope.$on('$destroy', unsubscribe);
        function save () {
            Modal.confirmDialog("¿Está seguro que desea guardar los cambios?","",
                function(){
                    vm.isSaving = true;
                    vm.administrationConfiguration.usingSubchargePercentage = vm.usingSubchargePercentage=="0"?false:true;
                    vm.administrationConfiguration.saveInBitacora = 1;
                    vm.administrationConfiguration.exchangeRateDate = moment().format();

                    vm.administrationConfiguration.residentsViewComments = vm.changeToInt(vm.administrationConfiguration.residentsViewComments)

                    vm.administrationConfiguration.hideRoutineCheck = vm.changeToInt(vm.administrationConfiguration.hideRoutineCheck)

                    vm.administrationConfiguration.defaulterMaintenanceBalance = vm.changeToInt(vm.administrationConfiguration.defaulterMaintenanceBalance)

                    vm.administrationConfiguration.defaulterPenaltyBalance = vm.changeToInt(vm.administrationConfiguration.defaulterPenaltyBalance)

                    vm.administrationConfiguration.defaulterExtraordinaryBalance = vm.changeToInt(vm.administrationConfiguration.defaulterExtraordinaryBalance)

                    vm.administrationConfiguration.defaulterCommonAreaBalance = vm.changeToInt(vm.administrationConfiguration.defaulterCommonAreaBalance)

                    vm.administrationConfiguration.defaulterWaterBalance = vm.changeToInt(vm.administrationConfiguration.defaulterWaterBalance)

                    vm.administrationConfiguration.defaulterOtherBalance = vm.changeToInt(vm.administrationConfiguration.defaulterOtherBalance)

                    if (vm.administrationConfiguration.id !== null) {
                        AdministrationConfiguration.update(vm.administrationConfiguration, onSaveSuccess, onSaveError);
                    } else {
                        AdministrationConfiguration.save(vm.administrationConfiguration, onSaveSuccess, onSaveError);
                    }

                });

        }

        function onSaveSuccess (result) {
           Modal.toast("Se han guardado los cambios existosamente.")
            vm.administrationConfiguration = result;
            if(vm.administrationConfiguration.usingSubchargePercentage===true){
                vm.administrationConfiguration.usingSubchargePercentage = "0";
            }else{
                vm.administrationConfiguration.usingSubchargePercentage = "1";
            }
            vm.administrationConfiguration.residentsViewComments = vm.changeToBoolean(vm.administrationConfiguration.residentsViewComments)

            vm.administrationConfiguration.hideRoutineCheck = vm.changeToBoolean(vm.administrationConfiguration.hideRoutineCheck)

            vm.administrationConfiguration.defaulterMaintenanceBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterMaintenanceBalance)

            vm.administrationConfiguration.defaulterPenaltyBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterPenaltyBalance)

            vm.administrationConfiguration.defaulterExtraordinaryBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterExtraordinaryBalance)

            vm.administrationConfiguration.defaulterCommonAreaBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterCommonAreaBalance)

            vm.administrationConfiguration.defaulterWaterBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterWaterBalance)

            vm.administrationConfiguration.defaulterOtherBalance = vm.changeToBoolean(vm.administrationConfiguration.defaulterOtherBalance)

            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
