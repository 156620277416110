(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpDetailController', FollowUpDetailController);

    FollowUpDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FollowUp', 'Company', 'House', 'FollowUpCategory'];

    function FollowUpDetailController($scope, $rootScope, $stateParams, previousState, entity, FollowUp, Company, House, FollowUpCategory) {
        var vm = this;

        vm.followUp = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:followUpUpdate', function(event, result) {
            vm.followUp = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
