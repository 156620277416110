(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('generatePayment.account-status', {
                parent: 'generatePayment',
                url: '/account-status/',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_JD','ROLE_ACCOUNTANT','ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.accountingNote.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/account-status/accountStatus.html',
                        controller: 'AccountStatusController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'xl',
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }],
            })
            .state('send-account-status', {
                parent: 'entity',
                url: '/send-account-status',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
                    pageTitle: 'Aditum'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/account-status/send-account-status.html',
                        controller: 'SendAccountStatusController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
    }
})();
