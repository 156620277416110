(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PaymentReportController', PaymentReportController);

    PaymentReportController.$inject = ['Company', 'Resident', 'Banco', 'House', '$timeout', '$scope', '$state', 'Payment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'CommonMethods', 'Proveedor', '$rootScope', 'globalCompany', 'Modal','$localStorage'];

    function PaymentReportController(Company, Resident, Banco, House, $timeout, $scope, $state, Payment, ParseLinks, AlertService, paginationConstants, pagingParams, CommonMethods, Proveedor, $rootScope, globalCompany, Modal,$localStorage) {
        $rootScope.active = "reporteIngresos";
        var vm = this;
        vm.sortHouse = false;
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false
        };
        vm.notExportingExcel = true;

        $rootScope.mainTitle = "Reporte de ingresos";
        vm.isReady = false;
        vm.isReady2 = false;

        vm.naturalCompare = function (a){
            if(vm.sortHouse){
                return parseInt(a.houseNumber)
            }
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.propertyName = 'id';
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.detailPayment = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $localStorage.idPayment = id;
            $state.go('payment-detail', {
                id: encryptedId
            })
        }

        vm.deletePayment = function (paymentId) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el otro ingreso realizado?", "Una vez eliminado no se podrá recuperar.", function () {
                Modal.showLoadingBar();
                Payment.delete({id: paymentId}, function () {
                    Modal.hideLoadingBar();
                    Modal.toast("La otro ingreso se ha eliminado correctamente.")
                    $state.reload()
                })
            })
        }

        vm.tableToExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)))
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                })
                            }
                        var workSheetName = vm.companyName + " - REPORTE DE INGRESOS - del " + moment(vm.fechaInicio).format("L") + " al " + moment(vm.fechaFin).format("L");
                        if (!table.nodeType) table = document.getElementById(table)
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx))
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1)
                    vm.notExportingExcel = true;
                }, 500)
            })

        }
        vm.reverse = true;
        vm.consulting = false;
        vm.banco = "empty";
        vm.paymentMethod = "empty";
        vm.houseId = "empty";
        vm.category = "empty";
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false,
        }
        vm.consultAgain = function () {
            vm.banco = "empty";
            vm.paymentMethod = "empty";
            vm.houseId = "empty";
            vm.category = "empty";
            vm.consulting = false;
            vm.isReady2 = false;
            vm.consultingBtn = false;
        }
        vm.updatePicker = function () {
            vm.picker1 = {
                datepickerOptions: {
                    enableTime: false,
                    showWeeks: false,
                }
            };
            vm.picker2 = {
                datepickerOptions: {

                    minDate: vm.dates.initial_time,
                    enableTime: false,
                    showWeeks: false,
                }
            }
        }
        vm.updatePicker();

        vm.loadHouses = function () {
            House.query({
                companyId: globalCompany.getId()
            }, function (data, headers) {
                vm.houses = data;
                loadAccounts();
            })
        }

        function loadAccounts() {
            Banco.query({
                companyId: globalCompany.getId()
            }, function (data, headers) {
                vm.bancos = data;
                Company.get({id: globalCompany.getId()}).$promise.then(function (result) {
                    vm.isReady = true;
                    vm.companyName = result.name;
                });
            });
        }

        vm.printPayment = function (paymentId) {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                })
            }, 8000)
            printJS({
                printable: '/api/payments/file/' + paymentId,
                type: 'pdf',
                modalMessage: "Obteniendo comprobante de pago"
            })
        }


        vm.print = function () {
            vm.exportActions.printing = true;
            $timeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                })
            }, 6000)
            printJS({
                printable: vm.urlToDownload(),
                type: 'pdf',
                modalMessage: "Obteniendo comprobante de pago"
            })
        }
        vm.urlToDownload = function () {
            return '/api/payments/incomeReport/file/' + moment(vm.dates.initial_time).format() + "/" + moment(vm.dates.final_time).format() + "/" + globalCompany.getId() + "/" + vm.banco + "/" + vm.paymentMethod + "/" + vm.houseId + "/" + vm.category;
        }
        vm.download = function () {
            vm.exportActions.downloading = true;
            $timeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.downloading = false;
                })
            }, 6000)
        }
        vm.loadHouses();
        vm.sendEmail = function (payment) {
            Modal.confirmDialog("¿Está seguro que desea enviarle el comprobante del pago " + payment.receiptNumber + " al contacto principal de la filial " + payment.houseNumber + "?", "",
                function () {
                    vm.exportActions.sendingEmail = true;
                    Resident.findResidentesEnabledByHouseId({
                        houseId: parseInt(payment.houseId),
                    }).$promise.then(onSuccessResident, onError);

                    function onSuccessResident(data, headers) {
                        var thereIs = false;
                        angular.forEach(data, function (resident, i) {
                            if (resident.email != undefined && resident.email != "" && resident.email != null) {
                                resident.selected = false;
                                if (resident.principalContact == 1) {
                                    thereIs = true;
                                }
                            }
                        });
                        if (thereIs == true) {
                            Payment.sendPaymentEmail({
                                paymentId: payment.id
                            })
                            $timeout(function () {
                                $scope.$apply(function () {
                                    vm.exportActions.sendingEmail = false;
                                })
                                Modal.toast("Se ha enviado el comprobante por correo al contacto principal.")

                            }, 6000)
                        } else {
                            vm.exportActions.sendingEmail = false;
                            Modal.toast("Esta filial no tiene un contacto principal para enviarle el correo.")
                        }
                    }
                    function onError() {
                        Modal.toast("Esta filial no tiene un contacto principal para enviarle el correo.")
                    }
                });
        }

        function formatearNumero(nStr) {

            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? ',' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        }


        function onError(error) {
            AlertService.error(error.data.message);
        }
        vm.generateReportBtn = function(){
            vm.isReady2 = false;
            vm.consulting = true;
            vm.showLoading = true;
            vm.generateReport();
        }
        vm.generateReport = function () {
            if($localStorage.incomeReport==null){
                vm.isReady2 = false;
                vm.consulting = true;
                vm.consultingBtn = true;
                vm.showLoading = true;
            }
            if (vm.banco == "" || vm.banco == null) {
                vm.banco = "empty"
            }
            if (vm.houseId == "" || vm.houseId == null) {
                vm.houseId = "empty"
            }
            Payment.findIncomeReportBetweenDatesByCompany({
                initial_time: moment(vm.dates.initial_time).format(),
                final_time: moment(vm.dates.final_time).format(),
                companyId: globalCompany.getId(),
                account: vm.banco,
                paymentMethod: vm.paymentMethod,
                houseId: vm.houseId,
                category: vm.category
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                $localStorage.incomeReport = data;
                $localStorage.dates = vm.dates;
                $localStorage.banco = vm.banco;
                $localStorage.houseId = vm.houseId;
                $localStorage.paymentMethod = vm.paymentMethod;
                $localStorage.category = vm.category;
                $rootScope.incomeReport = $localStorage.incomeReport;
                $rootScope.payments = $localStorage.incomeReport.payments;
                angular.forEach($rootScope.payments, function (payment, i) {
                    payment.isShowingCharges = false;
                });
                vm.isReady2 = true;
                vm.showLoading = false;
            }

            function onError(error) {
                vm.isReady2 = true;
                Modal.toast("Ha ocurrido un error al generar el reporte de ingresos.")
                AlertService.error(error.data.message);
            }
        };
        if($localStorage.incomeReport!=null){
            $rootScope.incomeReport = $localStorage.incomeReport;
            $rootScope.payments = $localStorage.incomeReport.payments
            vm.dates = $localStorage.dates;
            vm.banco = $localStorage.banco;
            vm.houseId = $localStorage.houseId;
            vm.paymentMethod = $localStorage.paymentMethod;
            vm.category = $localStorage.category;
            vm.generateReport();
            vm.isReady2 = true;
            vm.isReady = true;
            vm.showLoading = false;
            vm.consulting = true;
            $timeout(function(){
                vm.consultingBtn = true;
            },400)
        }

        vm.showCharges = function (payment) {
            payment.isShowingCharges = !payment.isShowingCharges;
            angular.forEach(vm.payments, function (paymentIn, i) {
                if (paymentIn.id != payment.id) {
                    paymentIn.isShowingCharges = false;
                }
            })
        };

        vm.datePickerOpenStatus.initialtime = false;
        vm.datePickerOpenStatus.finaltime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
