(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EmailActivityController', EmailActivityController);

    EmailActivityController.$inject = ['Resident','$scope', 'EmailConfiguration', 'globalCompany', '$rootScope', 'Announcement', '$localStorage', 'House'];

    function EmailActivityController(Resident, $scope, EmailConfiguration, globalCompany, $rootScope, Announcement, $localStorage, House) {

        var vm = this;

        vm.emailActivity = [];
        vm.announcements = [];
        vm.dataResident = [];
        vm.companyId = globalCompany.getId();
        vm.loading = false;
        vm.isReady = false;
        vm.searching = false;
        vm.toEmail = "";
        vm.subject = "";
        $rootScope.active = "email-activity";
        $rootScope.mainTitle = "Actividad de correos";

        vm.searchHouse = '';

        vm.clearSearchTerm = function () {
            $scope.searchHouse = '';
        };
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }
        loadAllAnnouncements();
        loadHouses();

        function loadAllAnnouncements() {
            Announcement.queryAsAdmin({
                companyId: globalCompany.getId(),
                page: 0,
                size: 10,
            }, function (data) {
                vm.announcements = data;
            });
        }

        vm.loadAll = function () {
            vm.loading = true;
            vm.isReady = false;
            vm.searching = true;
            vm.emailActivity = [];
            var toEmail = vm.toEmail ? vm.toEmail : "-1";
            var subject = vm.subject ? vm.subject : "-1";
            EmailConfiguration.getEmailActivity({
                    companyId: vm.companyId,
                    toEmail: toEmail,
                    subject: subject
                },
                function (result) {
                    vm.emailActivity = result;
                    vm.loading = false;
                    vm.isReady = true;
                });
        }


        function loadHouses() {
            if ($localStorage.houses) {
                vm.houses = $localStorage.houses;
                onSuccessHouses(vm.houses, null)
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                }, onSuccessHouses);
            }

            function onSuccessHouses(data, headers) {
                vm.houses = data;
            }
        }

        function defineRole(role) {
            switch (role) {
                case "ROLE_OWNER":
                    return "Residente propietario";
                case "ROLE_RENTER":
                    return "Propietario arrendador";
                case "ROLE_TENANT":
                    return "Inquilino";
                case "ROLE_RESIDENT":
                    return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                    case "ROLE_OWNER":
                        resident.houses[i].role = "Residente propietario";
                        break;
                    case "ROLE_RENTER":
                        resident.houses[i].role = "Propietario arrendador";
                        break;
                    case "ROLE_TENANT":
                        resident.houses[i].role = "Inquilino";
                        break;
                    case "ROLE_RESIDENT":
                        resident.houses[i].role = "Residente autorizado";
                        break;
                }
            }
            return resident;
        }

        function formatResidentList(role, data) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == vm.houseSelected.id) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role)
                    r = vm.defineRoleUser(r);
                    list.push(r)
                }
            }
            return list;
        }

        vm.selectHouse = function (house) {
            vm.houseSelected = house;
            vm.owners = [];
            vm.tenants = [];
            vm.residents = [];
            vm.dataResident = [];
            vm.residentsFilter = [];
            vm.query = "";
            $localStorage.houseSelected = vm.houseSelected;
            if (house != -1 && house != undefined) {
                vm.loading = true;
                Resident.getResidentsByHouse({houseId: vm.houseSelected.id}, function (data) {
                    vm.owners = formatResidentList("ROLE_OWNER", data);
                    vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data));
                    vm.tenants = formatResidentList("ROLE_TENANT", data);
                    vm.residents = formatResidentList("ROLE_RESIDENT", data);
                    vm.loading = false;
                    vm.dataResident = vm.owners.concat(vm.tenants).concat(vm.residents);
                })
            }
        }

    }
})();
