(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('HousesInfoAccessDoorNewController', HousesInfoAccessDoorNewController);

    HousesInfoAccessDoorNewController.$inject = ['AditumGate', 'GateAccess', 'CompanyConfiguration', '$localStorage', 'ParkingSlotConfiguration', 'Pet', 'AdministrationConfiguration', 'Auth', '$timeout', '$state', '$scope', '$rootScope', 'CommonMethods', 'AccessDoor', 'Resident', 'House', 'Vehicule', 'Visitant', 'Note', 'AlertService', 'Emergency', 'Principal', '$filter', 'WSDeleteEntity', 'WSEmergency', 'WSHouse', 'WSResident', 'WSVehicle', 'WSNote', 'WSVisitorInvitation', 'ParseLinks', 'globalCompany', 'Modal', 'VisitantInvitation', 'ParkingSlot'];

    function HousesInfoAccessDoorNewController(AditumGate, GateAccess, CompanyConfiguration, $localStorage, ParkingSlotConfiguration, Pet, AdministrationConfiguration, Auth, $timeout, $state, $scope, $rootScope, CommonMethods, AccessDoor, Resident, House, Vehicule, Visitant, Note, AlertService, Emergency, Principal, $filter, WSDeleteEntity, WSEmergency, WSHouse, WSResident, WSVehicle, WSNote, WSVisitorInvitation, ParseLinks, globalCompany, Modal, VisitantInvitation, ParkingSlot) {
        var vm = this;

        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        vm.seeQrRequest = function(){
            vm.inExit=3;
            $rootScope.audio.pause();
            $rootScope.audio.currentTime = 0;
        };

        CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
            vm.companyConfig = data;
            if (vm.companyConfig.hasAditumGate) {
                GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                    if (data.length > 0) {
                        vm.menuGate = {
                            title: "Puertas de acceso",
                            icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fdoor-e4ac1b6781.svg?alt=media&token=663000e1-c2bd-4e5e-8577-75551b5d91a6",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            uisref: "emergency.new",
                            show: true,
                            class: "animate__animated animate__backInDown"
                        };
                        vm.menuGates = [];
                        for (var i = 0; i < data.length; i++) {
                            for (var j = 0; j < data[i].gates.length; j++) {
                                vm.menuGates.push(data[i].gates[j]);
                            }
                        }
                        vm.menuGate.show = true;
                        vm.accesDoorId = data[0].id;
                    } else {
                        vm.menuGate.show = false;
                    }
                });
            }
        });

        vm.openGate = function (gate) {
            Modal.confirmDialog("¿Está seguro que desea accionar la puerta?", "" +
                "", function () {
                    gate.disable = true;
                    AditumGate.openGate({gateId: gate.id}, function (data) {
                        if (data.result) {
                            Modal.toast("Puerta " + gate.description + " está en movimiento.");
                            gate.movement = true;
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    gate.movement = false;
                                    gate.disable = false;
                                });
                            }, 3000);
                        } else {
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    gate.movement = false;
                                    gate.disable = false;
                                    Modal.toast("No se pudo contactar con la puerta.");
                                });
                            }, 3000);
                        }
                    }, function () {
                    });
                });
        };

        vm.visitorQrRequest = $localStorage.visitorQrRequest;

        function loadParkingSlots() {
            vm.parkingSlots = [];
            ParkingSlot.getByCompanyOfficers({companyId: globalCompany.getId()}, function (parkings) {
                vm.parkingSlots = parkings;
            });
        }

        vm.goToRegisterVisitorWithQR = function (authorized) {
            $rootScope.qrOpenGate = authorized.qrOpenGate;
            $rootScope.selectedAuthorized = authorized;
            $state.go("access-door.register-visitor", {identificationNumber: "authorized", qrRead: true});
        };

        vm.companyId = globalCompany.getId();

        loadParkingSlots();

        vm.getColorName = function (selectedValue) {
            switch (selectedValue) {
            case 'darkred':
                return 'Rojo';
            case 'darkgreen':
                return 'Verde';
            case 'darkblue':
                return 'Azul';
            case 'yellow':
                return 'Amarillo';
            case 'white':
                return 'Blanco';
            case 'darkorange':
                return 'Naranja';
            case 'black':
                return 'Negro';
            default:
                return 'Color no definido';
            }
        };
        AdministrationConfiguration.get({companyId: globalCompany.getId()}, function (result) {
            vm.showMascotas = result.monthlyIncomeStatement;
        });
        vm.filter = "";
        vm.inExit = 1;
        vm.searchFor = "";

        $rootScope.mainTitle = "Visitantes";
        vm.searchTerm = '';
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };

        vm.goToRegisterVisitor = function (authorized) {
            if (authorized.visitorType == 'EXPRESS') {
                $rootScope.selectedAuthorized = authorized;
                var plate = "ND";
                if (authorized.licenseplate) {
                    plate = authorized.licenseplate;
                }
                $state.go("access-door.register-visitor", {identificationNumber: "EXPRESS@" + authorized.proveedor + "@" + authorized.houseId + "@" + plate});
            } else {
                $rootScope.selectedAuthorized = authorized;
                $state.go("access-door.register-visitor", {identificationNumber: "authorized"});
            }
        };

        vm.dataFound = false;

        function formatResidentFilter(r) {
            for (var j = 0; j < r.residentHousePK.length; j++) {
                var hPK = r.residentHousePK[j];
                for (var i = 0; i < r.houses.length; i++) {
                    var house = r.houses[i];
                    if (hPK.houseId == house.id) {
                        house.roleResident = hPK.role;
                    }
                }
            }
            r = vm.defineRoleUser(r);
            r.roleDescription = defineRole(r.role);
            return r;
        }

        vm.searching = false;

        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                case "ROLE_OWNER":
                    resident.houses[i].role = "Residente propietario";
                    break;
                case "ROLE_RENTER":
                    resident.houses[i].role = "Propietario arrendador";
                    break;
                case "ROLE_TENANT":
                    resident.houses[i].role = "Inquilino";
                    break;
                case "ROLE_RESIDENT":
                    resident.houses[i].role = "Residente autorizado";
                    break;
                }
            }
            return resident;
        };

        function defineRole(role) {
            switch (role) {
            case "ROLE_OWNER":
                return "Residente propietario";
            case "ROLE_RENTER":
                return "Propietario arrendador";
            case "ROLE_TENANT":
                return "Inquilino";
            case "ROLE_RESIDENT":
                return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.houseSelected = -1;
        $rootScope.houseSelected = -1;
        vm.condominiumSelected = -1;
        $rootScope.condominiumSelected = vm.condominiumSelected;
        vm.isReady = true;
        vm.consultingAll = true;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.itemsPerPage = 9;

        vm.checkEmptyFilter = function () {
            vm.filterChanged = true;
            if (vm.filter == "" && vm.showingData == false) {
                vm.firstLoadResidents = true;
                vm.filterInfo();
            }
        };

        vm.selectHouseInfo = function (house) {
            $rootScope.houseSelected = vm.houseSelected;
            if (vm.houseSelected == -1) {
                vm.isReady = true;
                vm.consultingAll = true;
                vm.searching = false;
                vm.dataFound = false;
                $rootScope.visitorHouseNotification = undefined;
            } else {
                vm.visitans = [];
                vm.searching = true;
                vm.filterVisitants();
            }
        };

        vm.filterByName = function () {
            vm.visitans = [];
            vm.searching = true;
            vm.searchFor = "name";
            VisitantInvitation.getActiveInvitedByName({
                page: vm.page,
                size: 500,
                companyId: globalCompany.getId(),
                name: vm.filter
            }, onSuccessVisitors, onError);
        };

        vm.filterByPlate = function () {
            vm.visitans = [];
            vm.searching = true;
            vm.searchFor = "plate";
            VisitantInvitation.getActiveInvitedByPlate({
                page: vm.page,
                size: 500,
                companyId: globalCompany.getId(),
                plate: vm.filter
            }, onSuccessVisitors, onError);
        };

        vm.filterByIdentificationNumber = function () {
            vm.visitans = [];
            vm.searching = true;
            vm.searchFor = "id";
            VisitantInvitation.getActiveInvitedByIdentificationNumber({
                page: vm.page,
                size: 500,
                companyId: globalCompany.getId(),
                identificationNumber: vm.filter
            }, onSuccessVisitors, onError);
        };

        vm.showKeys = function (houseSelected) {
            var emergencyKey, securityKey;
            emergencyKey = houseSelected.emergencyKey == null ? "No definida" : houseSelected.emergencyKey;
            securityKey = houseSelected.securityKey == null ? "No definida" : houseSelected.securityKey;
            Modal.customDialog("<md-dialog>" +
                "<md-dialog-content class='md-dialog-content text-center'>" +
                "<h1 class='md-title uppercase font-14'>Clave de seguridad Filial <b>" + houseSelected.housenumber + "</b></h1>" +
                "<div class='md-dialog-content-body'>" +
                "<p><span style='font-size: 20px'>" + securityKey + "</span></p>" +
                "</div>" +
                "</md-dialog-content>" +
                "</md-dialog>");
        };
        vm.showPhone = function (houseSelected) {
            var phone;
            phone = houseSelected.extension == null ? "No definido" : houseSelected.extension;
            Modal.customDialog("<md-dialog>" +
                "<md-dialog-content class='md-dialog-content text-center'>" +
                "<h1 class='md-title'>Filial <b>" + houseSelected.housenumber + "</b></h1>" +
                "<div class='md-dialog-content-body'>" +
                "<p>Número de teléfono: <b style='font-size: 20px'>" + phone + "</b></p>" +
                "</div>" +
                "</md-dialog-content>" +
                "</md-dialog>");
        };


        vm.filterVisitants = function () {
            vm.page = 0;
            vm.links = {
                last: 0
            };
            $rootScope.visitorInvited = [];
            vm.totalCountVisitors = 0;
            loadVisitors();
        };

        function loadVisitors() {
            var houseId = {};
            vm.isReady = false;
            if (vm.houseSelected == -1) {
                houseId.id = "empty";
            } else {
                houseId.id = vm.houseSelected.id;
            }
            var filter = vm.filter;
            if (vm.filter === "" || vm.filter === undefined) {
                filter = " ";
            }
            VisitantInvitation.getActiveInvitedByHouse({
                page: vm.page,
                size: 500,
                houseId: houseId.id,
            }, onSuccessVisitors, onError);
        }


        function formatPlates(visitor) {
            var plates = [];
            if (visitor.licenseplate != undefined) {
                var lc = visitor.licenseplate.split("/");
                for (var i = 0; i < lc.length; i++) {
                    if (lc[i].trim() != "") {
                        var plate = {licenseplate: lc[i].trim(), selected: false, valid: true};
                        plates.push(plate);
                    }
                }
                if (lc[0].trim() != "") {
                    visitor.licenseplate = plates[0].licenseplate;
                }
            }
            return plates;
        }

        vm.defineBackground = function (authorized) {
            return "card-visitor-access-door-" + authorized.visitorType.toLowerCase();
        };
        vm.defineType = function (authorized) {
            switch (authorized.visitorType) {
            case "PERMANENT":
                return "Permanente";
            case "VACATIONAL":
                return "Vacacional";
            case "BUS":
                return "Bus escolar";
            case "EMPLOYEE":
                return "Empleado";
            case "TEMPORAL":
                return "Temporal";
            case "EXPRESS":
                return "Express";
            case "IMPORTATION":
                return "Importación";
            case "NATIONAL_MERCHANDISE":
                return "Ingreso mercadería nacional";
            case "MERCHANDISE_EXIT":
                return "Ingreso para salida de mercadería";
            case "MAINTENANCE":
                return "Mantenimiento";
            default:
                return "Desconocido";
            }
        };

        vm.defineTypeExit = function (authorized) {
            switch (authorized.visitorType) {
            case "PERMANENT":
                return "Permanente";
            case "VACATIONAL":
                return "Vacacional";
            case "BUS":
                return "Bus escolar";
            case "EMPLOYEE":
                return "Empleado";
            case "TEMPORAL":
                return "Temporal";
            case "EXPRESS":
                return "Express";
            case "IMPORTATION":
                return "Importación";
            case "NATIONAL_MERCHANDISE":
                return "Ingreso mercadería nacional";
            case "MERCHANDISE_EXIT":
                return "Salida de mercadería";
            case "MAINTENANCE":
                return "Mantenimiento";
            default:
                return "";
            }
        };

        function onSuccessVisitors(data, headers) {
            var count = 0;
            if (vm.totalCountVisitors == 0) {
                $('.infinity-scroll-content').animate({scrollTop: 60}, 800);
            }
            vm.visitors = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].id != null) {
                    vm.visitors.push(formatVisitantInvited(data[i]));
                    count++;
                }
                vm.totalCountVisitors++;
            }
            if (vm.visitors.length === 0 && vm.filter !== undefined) {
                vm.dataFound = false;
            } else {
                vm.dataFound = true;
            }
            vm.searching = true;
            vm.isReady = true;
        }


        // VISITANTES EN TRANSITO
        vm.filterVisitantsIntransit = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            $rootScope.visitorInvitedByTransit = [];
            vm.totalCountVisitors = 0;
            loadVisitorsInTransit();
        };
        vm.stillIn = function (visitor) {
            if (visitor.isinvited == 4) {
                var milliseconds = Math.abs(new Date() - new Date(visitor.arrivaltime));
                var hours = milliseconds / 36e5;
                if (hours >= 24) {
                    visitor.stillIn = true;
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        };
        //MASCOTAS
        vm.filterPets = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.pets = [];
            // if (vm.condominiumSelected === -1) {
            //     loadResidentsMacro();
            // } else {
            loadPets();
            // }
        };

        function loadPets() {
            var houseId = {};
            if (vm.houseSelected == -1) {
                houseId.id = "empty";
            } else {
                houseId.id = vm.houseSelected.id;
            }
            var filter = vm.filter;
            if (vm.filter === "" || vm.filter === undefined) {
                filter = " ";
            }
            if (houseId.id == "empty") {
                Pet.getByCompany({
                    page: vm.page,
                    size: vm.itemsPerPage,
                    companyId: globalCompany.getId(),
                    name: filter,
                }, onSuccessPets, onError);
            } else {
                Pet.getByHouse({
                    page: vm.page,
                    size: vm.itemsPerPage,
                    houseId: houseId.id,
                    name: filter,
                }, onSuccessPets, onError);
            }
        }


        function onSuccessPets(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            for (var i = 0; i < data.length; i++) {
                vm.pets.push(data[i]);
            }
            if (vm.pets.length === 0 && vm.filter !== undefined) {
                vm.noDataFound = true;
            } else {
                vm.noDataFound = false;
            }
            vm.isReady = true;
            vm.showingData = true;
            vm.consulting = false;
        }

        vm.loadPagePets = function (page) {
            vm.page = page;
            vm.firstLoadResidents = false;
            loadPets();
        };


// RESIDENTES
        vm.filterResidents = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.residents = [];
            // if (vm.condominiumSelected === -1) {
            //     loadResidentsMacro();
            // } else {
            loadResidents();
            // }
        };


        function loadResidents() {
            var houseId = {};
            if (vm.houseSelected == -1) {
                houseId.id = "empty";
            } else {
                houseId.id = vm.houseSelected.id;
            }
            var filter = vm.filter;
            if (vm.filter === "" || vm.filter === undefined) {
                filter = " ";
            }
            Resident.getResidents({
                page: vm.page,
                size: 24,
                sort: sortResidents(),
                companyId: globalCompany.getId(),
                name: filter,
                houseId: houseId.id,
                owner: "empty",
                enabled: 1,
            }, onSuccessResidents, onError);
        }

        function sortResidents() {
            var result = [];
            if (vm.predicate !== 'name') {
                result.push('name,asc');
            }
            return result;
        }

        function onSuccessResidents(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            for (var i = 0; i < data.length; i++) {
                vm.residents.push(formatResidentFilter(data[i]));
            }

            if (vm.residents.length === 0 && vm.filter !== undefined) {
                vm.noDataFound = true;
            } else {
                vm.noDataFound = false;
            }
            vm.showingData = true;
            vm.isReady = true;
            vm.consulting = false;
        }

        vm.loadPageResidents = function (page) {
            vm.page = page;
            vm.firstLoadResidents = false;
            loadResidents();
        };

        vm.loadVisitorsInTransit = function () {
            vm.isReady = false;
            vm.inExit = 2;
            vm.visitorInvitedByTransit = [];
            var houseId = {};
            if (vm.houseSelected == -1) {
                houseId.id = "empty";
            } else {
                houseId.id = vm.houseSelected.id;
            }
            var filter = vm.filter;
            if (vm.filter === "" || vm.filter === undefined) {
                filter = " ";
            }
            Visitant.getVisitorsInTransitByCompanyFilter({
                page: 0,
                size: 500,
                sort: sortResidents(),
                companyId: globalCompany.getId(),
                name: filter,
                houseId: houseId.id,
                owner: "empty",
                enabled: 1,
            }, onSuccessVisitorsInTransit, onError);
        };


        function onSuccessVisitorsInTransit(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            for (var i = 0; i < data.length; i++) {
                if (data[i].id != null) {
                    vm.visitorInvitedByTransit.push(formatVisitantInvited(data[i]));
                }
                vm.totalCountVisitors++;
            }
            if (vm.visitorInvitedByTransit.length === 0 && vm.filter !== undefined) {
                vm.noDataFound = true;
            } else {
                vm.noDataFound = false;
            }
            vm.isReady = true;
            vm.showingData = true;
            vm.consulting = false;
        }


        function hasCaracterEspecial(s) {
            var caracteres = [",", ".", "-", "$", "@", "(", ")", "=", "+", "/", ":", "%", "*", "'", "", ">", "<", "?", "¿", "{", "}", "[", "]", "''"];
            var invalido = 0;
            angular.forEach(caracteres, function (val, index) {
                if (s != undefined) {
                    for (var i = 0; i < s.length; i++) {
                        if (s.charAt(i) == val) {
                            invalido++;
                        }
                    }
                }
            });
            if (invalido == 0) {
                return false;
            } else {
                return true;
            }
        }

        vm.validateVisitorCed = function (visitor) {
            if (hasCaracterEspecial(visitor.identificationnumber)) {
                visitor.validCed = false;
            } else {
                visitor.validCed = true;
            }
        };
        vm.validateVisitorPlate = function (visitor) {
            if (hasCaracterEspecial(visitor.licenseplate)) {
                visitor.validPlate = false;
            } else {
                visitor.validPlate = true;
            }
        };

        function formatVisitantInvited(itemVisitor) {
            itemVisitor.plates = formatPlates(itemVisitor);
            if (itemVisitor.plates.length == 0) {
                itemVisitor.hasLicense = false;
            } else {
                itemVisitor.hasLicense = true;
            }
            if (itemVisitor.identificationnumber == null || itemVisitor.identificationnumber == undefined || itemVisitor.identificationnumber == "") {
                itemVisitor.hasIdentification = false;
            } else {
                itemVisitor.identificationnumber;
                itemVisitor.hasIdentification = true;
            }
            itemVisitor.validCed = true;
            itemVisitor.onTime = true;
            itemVisitor.fullName = (itemVisitor.name !== null && itemVisitor.name !== undefined ? itemVisitor.name : "") + " " +
                (itemVisitor.lastname !== null && itemVisitor.lastname !== undefined ? itemVisitor.lastname : "");
            itemVisitor.ingressTime = moment(itemVisitor.arrivaltime).format('DD/MM/YYYY hh:mm a');
            return itemVisitor;
        }

        // VEHICULOS
        vm.filterVehicules = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.vehicules = [];
            // if (vm.condominiumSelected === -1) {
            //     loadVehiculesMacro();
            // } else {
            loadVehicules();
            // }
        };

        function loadVehicules() {
            var houseId = {};
            if (vm.houseSelected === -1) {
                houseId.id = "empty";
            } else {
                houseId.id = vm.houseSelected.id;
            }
            var filter = vm.filter;
            if (vm.filter === "" || vm.filter === undefined) {
                filter = " ";
            }
            Vehicule.getVehicules({
                page: vm.page,
                size: 27,
                houseId: houseId.id,
                licencePlate: filter,
                enabled: 1,
                sort: sortVehicules(),
                companyId: globalCompany.getId(),
            }, onSuccessVehicules, onError);
        }

        function sortVehicules() {
            var result = [];
            if (vm.predicate !== 'licenseplate') {
                result.push('licenseplate,asc');
            }
            return result;
        }

        function onSuccessVehicules(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            for (var i = 0; i < data.length; i++) {
                vm.vehicules.push(data[i]);
            }
            vm.isReady = true;
            vm.consulting = false;
            vm.showingData = true;
        }

        vm.loadPageVehicules = function (page) {
            vm.page = page;
            loadVehicules();
        };

        vm.loadPageVisitor = function (page) {
            vm.page = page;
            loadVisitors();
        };
        vm.loadPageVisitorIntransit = function (page) {
            vm.page = page;
            loadVisitorsInTransit();
        };

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.verifyVisitantInivitedDate = function (visitant) {
            if (visitant.onTime === true && visitant !== undefined) {
                var currentTime = new Date(moment(new Date()).format("YYYY-MM-DD") + "T" + moment(new Date()).format("HH:mm:ss") + "-06:00").getTime();
                var initTime = new Date(visitant.invitationstartingtime).getTime();
                var finishTime = new Date(visitant.invitationlimittime).getTime();
                if (initTime <= currentTime && currentTime <= finishTime) {
                    return true;
                } else {
                    visitant.onTime = false;
                    CommonMethods.deleteFromArrayWithId(visitant, vm.visitors);
                    return false;
                }
            } else {
                return false;
                visitant = undefined;
            }
        };
        vm.freeParkingSlot = function (parking) {
            Modal.confirmDialog("¿Está seguro que quiere liberarlo?", "", function () {
                parking.ocupated = false;
                parking.usedBy = null;
                parking.houseId = null;
                parking.deleted = false;
                parking.companyId = globalCompany.getId();
                vm.parkingSlots = [];
                ParkingSlot.update(parking, function () {
                    loadParkingSlots();
                    Modal.toast("Cono liberado");
                });
            });
        };

        vm.saveDataVisitor = function (visitor) {
            Modal.confirmDialog("¿Está seguro que desea registrar los datos?", "", function () {
                if (visitor.licenseplate == undefined) {
                    visitor.licenseplate = visitor.licenseplateNew;
                }
                if (visitor.parkingSlotId == undefined) {
                    if (visitor.parkingSelected) {
                        visitor.parkingSlotId = visitor.parkingSelected.id;
                        visitor.parkingSelected.ocupated = true;
                        visitor.parkingSelected.usedBy = visitor.name;
                        visitor.parkingSelected.companyId = globalCompany.getId();
                        visitor.parkingSelected.deleted = false;
                    }
                }
                Visitant.update(visitor, function () {
                    Modal.toastGiant("Se registraron los datos");
                    visitor.parkingSlotDTO = visitor.parkingSelected;
                    ParkingSlot.update(visitor.parkingSelected, function () {
                        loadParkingSlots();
                    });
                });
            });
        };
        vm.registerExitFromVisitantsInTransitList = function (visitant) {
            vm.visitantToInsert = visitant;

            Modal.confirmDialog("¿Está seguro que desea registrar la salida de " + visitant.name + " " + visitant.lastname + "?", "", function () {
                vm.insertingVisitant = 1;
                var temporalLicense;
                Modal.showLoadingBar();
                if (vm.visitantToInsert.licenseplate != undefined || vm.visitantToInsert.licenseplate != null) {
                    temporalLicense = vm.visitantToInsert.licenseplate.toUpperCase();
                }

                visitant.isinvited = 5;
                visitant.invitationlimittime = moment(new Date()).format();
                Visitant.update(visitant, onSaveSuccess, onSaveError);

                function onSaveSuccess(result) {
                    CommonMethods.deleteFromArray(visitant, vm.visitorInvitedByTransit);
                    Modal.toastGiant("Se registró la salida del visitante correctamente.");
                    Modal.hideLoadingBar();
                }

                function onSaveError(error) {
                    Modal.toastGiant("Se registrará la salida del visitante una vez la conexión haya vuelto.", "No hay conexión a internet");
                    Modal.hideLoadingBar();
                }
            });
        };

        vm.registerVisitantFromVisitantsList = function (visitant) {
            vm.visitantToInsert = visitant;

            Modal.confirmDialog("¿Está seguro que desea registrar la visita de " + visitant.name + " " + visitant.lastname + "?", "", function () {
                vm.insertingVisitant = 1;
                var temporalLicense;
                Modal.showLoadingBar();
                if (vm.visitantToInsert.licenseplate != undefined || vm.visitantToInsert.licenseplate != null) {
                    temporalLicense = vm.visitantToInsert.licenseplate.toUpperCase();
                }
                var visitante = {
                    name: vm.visitantToInsert.name,
                    lastname: vm.visitantToInsert.lastname,
                    secondlastname: vm.visitantToInsert.secondlastname,
                    identificationnumber: vm.visitantToInsert.identificationnumber.toUpperCase(),
                    licenseplate: temporalLicense,
                    companyId: globalCompany.getId(),
                    isinvited: 4,
                    arrivaltime: moment(new Date()).format(),
                    houseId: vm.visitantToInsert.houseId,
                    responsableofficer: vm.visitantToInsert.destiny,
                    proveedor: visitant.proveedor
                };
                visitante.invitationPicture = visitant.invitationPicture;
                Visitant.save(visitante, onSaveSuccess, onSaveError);

                function onSaveSuccess(result) {
                    Modal.toastGiant("Se registró la entrada del visitante correctamente.");

                    Modal.hideLoadingBar();
                }

                function onSaveError(error) {
                    Modal.toastGiant("Se registrará la visita una vez la conexión haya vuelto.", "No hay conexión a internet");
                    Modal.hideLoadingBar();
                }
            });
        };

        vm.showDataNewVisitorInvited = function () {
            $rootScope.visitorInvited = [];
            if ($rootScope.visitorHouseNotification == -1 || $rootScope.visitorHouseNotification == undefined) {
                $rootScope.houseSelected = -1;
                vm.queryType = 3;
            } else {
                for (var i = 0; i < $rootScope.houses.length; i++) {
                    if ($rootScope.visitorHouseNotification == $rootScope.houses[i].id) {
                        $rootScope.houseSelected = $rootScope.houses[i];
                    }
                }
                vm.queryType = 3;
            }
            vm.houseSelected = $rootScope.houseSelected;
            vm.filterInfo();
        };
    }
})();
