(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IssuingClientInvoiceItemConfigurationController', IssuingClientInvoiceItemConfigurationController);

    IssuingClientInvoiceItemConfigurationController.$inject = ['CabysCode', 'AccountingAccount', 'Modal', 'globalCompany', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CustomChargeType', 'Company', '$rootScope'];

    function IssuingClientInvoiceItemConfigurationController(CabysCode, AccountingAccount, Modal, globalCompany, $timeout, $scope, $stateParams, $uibModalInstance, entity, CustomChargeType, Company, $rootScope) {
        var vm = this;

        vm.customChargeType = entity;


        vm.clear = clear;
        vm.save = save;
        vm.validCode = false;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $rootScope.mainTitle = "Editar ítem de facturación"
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.validateCode = function (code) {
            if (code) {
                vm.cabysCode = code;
                CabysCode.getOneByCategoryCode({categoryCode: code}, function (data) {
                    if (data.id) {
                        vm.validCode = true;
                        vm.foundCabysCode = data;
                        vm.customChargeType.cabysCodeId = data.id;
                    } else {
                        vm.validCode = false;
                    }
                }, function () {
                    vm.validCode = false;
                })
            }
        }

        if (vm.customChargeType.cabysCode) {
            vm.validateCode(vm.customChargeType.cabysCode.categoryCode);
        }

        function save() {
            Modal.confirmDialog("¿Está seguro que desea editarlo?", "Verifique que haya definido correctamente el código CABYS", function () {
                vm.isSaving = true;
                vm.customChargeType.companyId = globalCompany.getId();
                if (vm.customChargeType.id !== null) {
                    CustomChargeType.update(vm.customChargeType, onSaveSuccess, onSaveError);
                } else {
                    CustomChargeType.save(vm.customChargeType, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:customChargeTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
