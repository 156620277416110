(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('GeneralLedgerController', GeneralLedgerController);

    GeneralLedgerController.$inject = ['globalCompany', '$rootScope', '$state', 'GeneralLedger', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'AccountingAccount', 'House'];

    function GeneralLedgerController(globalCompany, $rootScope, $state, GeneralLedger, ParseLinks, AlertService, paginationConstants, pagingParams, AccountingAccount, House) {

        var vm = this;
        $rootScope.mainTitle = 'Mayorización';
        $rootScope.active = 'generalLedger';
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.accountId = -1;
        vm.loadAll = loadAll;

        vm.searchTerm = '';
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }
        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        loadAllDetailAccountingDetail();

        function loadAllDetailAccountingDetail() {
            AccountingAccount.findSubAccountsByCompanyId({
                companyId: globalCompany.getId(),
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.accountingDetailAccounts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAll();
        function loadAll() {
            vm.isReady = false;

            GeneralLedger.getByCompanyIdAndBetweenDates({
                companyId:globalCompany.getId(),
                initialTime: moment(vm.dates.initial_time).format(),
                finalTime: moment(vm.dates.final_time).format(),
                accountId:vm.accountId,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.generalLedgerSummary = data;
                 vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
