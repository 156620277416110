(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('FollowUp', FollowUp);

    FollowUp.$inject = ['$resource', 'DateUtils'];

    function FollowUp($resource, DateUtils) {
        var resourceUrl = 'api/follow-ups/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'report': {
                method: 'GET',
                url: "api/follow-ups-report/:companyId/:houseId/:initialDate/:finalDate/:status/:category",
                isArray: true,
                params: {
                    initial_time: '@initial_time',
                    final_time: '@final_time',
                    companyId: '@companyId'
                }
            },
            'queryByHouse': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-ups-by-house/:houseId'
            },
            'queryByCompanyAndStatus': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-ups-by-company-and-status/:companyId/:status'
            },
            'queryByCompany': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-ups-by-company/:companyId'
            },
            'queryByCompanyOnly': {
                method: 'GET',
                isArray: true,
                url: 'api/follow-ups-by-company-only/:companyId'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.initialDate = DateUtils.convertDateTimeFromServer(data.initialDate);
                        data.finishDate = DateUtils.convertDateTimeFromServer(data.finishDate);
                        data.lastModifcation = DateUtils.convertDateTimeFromServer(data.lastModifcation);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
