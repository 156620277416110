(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ParkingSlotController', ParkingSlotController);

    ParkingSlotController.$inject = ['ParkingSlot'];

    function ParkingSlotController(ParkingSlot) {

        var vm = this;

        vm.parkingSlots = [];

        loadAll();

        function loadAll() {
            ParkingSlot.query(function(result) {
                vm.parkingSlots = result;
                vm.searchQuery = null;
            });
        }
    }
})();
