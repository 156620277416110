(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ExpenseDeleteController', ExpenseDeleteController);

    ExpenseDeleteController.$inject = ['Modal', 'CommonMethods', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CreditNote', 'Expense', 'House', 'Company', 'AccountingPeriod', 'globalCompany'];

    function ExpenseDeleteController(Modal, CommonMethods, $timeout, $scope, $stateParams, $uibModalInstance, entity, CreditNote, Expense, House, Company, AccountingPeriod, globalCompany) {
        var vm = this;
        vm.expense = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.saving = false;
        vm.today = new Date();
        var id = CommonMethods.decryptIdUrl($stateParams.expenseId);

        Expense.get({id: id}, function (result) {
            vm.expense = result;
            AccountingPeriod.getLastPeriodCloseDate({companyId: globalCompany.getId()}, function (result) {
                $timeout(function(){
                    $scope.$apply(function () {
                        vm.accountingPeriodCloseDate = new Date(result.finalDayCloseDate);
                        if(vm.accountingPeriodCloseDate<vm.expense.expenseDate){
                            vm.egressDeleteDate = vm.expense.expenseDate;
                        }
                    });
                });
            });
        });

        vm.deleteExpense = function(){
            Modal.confirmDialog("¿Está seguro que desea anular el egreso?","La fecha del asiento de anulación será la seleccionada",function(){
                Expense.deleteExpense({id:id,deletionDate:moment(vm.egressDeleteDate).format()},function(){
                    Modal.toast("Anulado correctamente");
                    $uibModalInstance.close();
                });
            });
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.originalBillDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
