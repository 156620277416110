(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FilePreviewController', FilePreviewController);

    FilePreviewController.$inject = ['$scope', '$sce', 'globalCompany', '$stateParams', '$state', 'RecordCategory', '$uibModalInstance', 'CommonMethods'];

    function FilePreviewController($scope, $sce, globalCompany, $stateParams, $state, RecordCategory, $uibModalInstance, CommonMethods) {

        var vm = this;
        vm.url = $sce.trustAsResourceUrl($stateParams.url);
        vm.type = $stateParams.type;
        vm.name = $stateParams.name;
        vm.isImage = false;
        vm.isVideo = false;
        vm.isFile = true;
        if (vm.type == undefined) {
            vm.type = vm.name.split(".")[1].toLowerCase();
        }
        $scope.$on("$destroy", function () {
            $uibModalInstance.close()
        });
        switch (vm.type) {
            case "fa-file-image-o":
                vm.isImage = true;
                vm.isFile = false;
                break;
            case "png":
                vm.isImage = true;
                vm.isFile = false;
                break;
            case "jpg":
                vm.isImage = true;
                vm.isFile = false;
                break;
            case "fa-file-video-o":
                vm.isVideo = true;
                vm.isFile = false;
                break;
        }
        vm.encodedUrl = encodeURIComponent(vm.url);
        vm.urlFile = "https://docs.google.com/gview?url=" + vm.encodedUrl + "&embedded=true";
        vm.urlFile = $sce.trustAsResourceUrl(vm.urlFile);
        vm.close = function () {
            $uibModalInstance.close();
        }
    }
})();
