(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EmergencyController', EmergencyController);

    EmergencyController.$inject = ['Emergency', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','globalCompany','$state','$localStorage','$rootScope'];

    function EmergencyController(Emergency, ParseLinks, AlertService, paginationConstants, pagingParams,globalCompany,$state,$localStorage, $rootScope) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        $rootScope.mainTitle = "Emergencias";
        $rootScope.active = "emergency";
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        loadAll();
        function loadAll () {
            Emergency.query({
                page: pagingParams.page - 1,
                size: 500,
                sort: sort(),
                companyId:globalCompany.getId()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.isReady = true;
                vm.emergencias = data;
                console.log(data)
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }


        vm.emergencyDetail = function (emergency) {

           $localStorage.editing = false;
            $state.go('emergency-detail', {
                id: emergency.id
            })
        };
        vm.emergencyEdit = function (emergency) {
            $localStorage.editing = true;
            $state.go('emergency-detail', {
                id: emergency.id
            })
        };
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
