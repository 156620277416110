(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingPeriodDialogController', AccountingPeriodDialogController);

    AccountingPeriodDialogController.$inject = ['globalCompany', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccountingPeriod', 'Modal', 'CommonMethods', 'AccountingFullPeriod'];

    function AccountingPeriodDialogController(globalCompany, $timeout, $scope, $stateParams, $uibModalInstance, entity, AccountingPeriod, Modal, CommonMethods, AccountingFullPeriod) {
        var vm = this;

        vm.accountingPeriod = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        var accountingFullPeriodId = CommonMethods.decryptIdUrl($stateParams.periodId);
        AccountingFullPeriod.get({id: accountingFullPeriodId}, function (data) {
            vm.accountingFullPeriod = data;
            vm.formatMonthPeriods(vm.accountingFullPeriod.accountingPeriods)
        })

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function getLastThreeMonths() {
            var months = [];
            var monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
            var currentDate = new Date();

            for (var i = 5; i >= 0; i--) {
                var month = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
                month.setMonth(currentDate.getMonth() - i);
                var formattedDate = monthNames[month.getMonth()] + ' ' + month.getFullYear();
                if (month.getTime() <= vm.accountingFullPeriod.closeDate.getTime()) {
                    months.push({
                        date: month,
                        formattedDate: formattedDate,
                        blocked: false
                    });
                }
                vm.closingDate = month;
            }
            return months;
        }

        vm.formatMonthPeriods = function (data) {
            vm.accountingPeriods = data;
            vm.months = getLastThreeMonths();
            vm.accountingPeriods.forEach(function (accountingPeriod) {
                var periodYear = new Date(accountingPeriod.date).getFullYear();
                var monthPeriod = new Date(accountingPeriod.date).getMonth();
                vm.months.forEach(function (month) {
                    if (periodYear === month.date.getFullYear() && monthPeriod === month.date.getMonth()) {
                        month.blocked = true;
                        month.formattedDate = month.formattedDate + "- YA SE CREÓ";
                        vm.lastDate = month.date;
                    }
                });
                vm.months.forEach(function (month) {
                    if (vm.lastDate.getTime()) {
                        if (vm.lastDate.getTime() >= month.date.getTime()) {
                            month.blocked = true;
                            month.formattedDate = month.formattedDate + "- CERRADO";
                        }
                    }
                });
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            Modal.confirmDialog("¿Está seguro que desea crear el cierre contable?", "", function () {
                vm.isSaving = true;
                vm.accountingPeriod.companyId = globalCompany.getId();
                vm.accountingPeriod.date = moment(vm.date).format();
                vm.accountingPeriod.accountingFullPeriodId = vm.accountingFullPeriod.id;
                Modal.showLoadingBar();
                if (vm.accountingPeriod.id !== null) {
                    AccountingPeriod.update(vm.accountingPeriod, onSaveSuccess, onSaveError);
                } else {
                    AccountingPeriod.save(vm.accountingPeriod, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:accountingPeriodUpdate', result);
            $uibModalInstance.close(result);
            Modal.hideLoadingBar();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.closeDate = false;
        vm.datePickerOpenStatus.date = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


    }
})();
