(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('RecordCategoryController', RecordCategoryController);

    RecordCategoryController.$inject = ['$stateParams', 'CommonMethods', '$state', 'RecordCategory', 'ParseLinks', 'AlertService', '$uibModalInstance', 'globalCompany', 'Modal'];

    function RecordCategoryController($stateParams, CommonMethods, $state, RecordCategory, ParseLinks, AlertService, $uibModalInstance, globalCompany, Modal) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.recordCategory = {};
        vm.recordCategory.color = "folder-gray";
        vm.houseId = CommonMethods.decryptString($stateParams.houseId);
        if (vm.houseId == -1) {
            vm.onlyCompany = -1;
        } else {
            vm.onlyCompany = 0;
        }
        loadAll();

        function loadAll() {
            RecordCategory.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
                onlyCompany: vm.onlyCompany+""
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.recordCategories = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.newFolder = function () {
            vm.addingFolder = true;
            vm.titleForm = "Crear nueva carpeta";
            vm.button = "Crear carpeta";
        }

        vm.editFolder = function (recordCategory) {
            vm.addingFolder = true;
            vm.recordCategory = recordCategory;
            vm.titleForm = "Editar carpeta";
            vm.button = "Editar carpeta";
        }

        vm.cancelNewFolder = function () {
            vm.addingFolder = false;
            vm.recordCategory = {};
            vm.recordCategory.color = "folder-gray";
        }


        vm.deleteFolder = function () {
            Modal.confirmDialog("¿Está seguro que desea eliminar esta carpeta?", "Se perderá la posibilidad de acceder a todos los documentos de los expedientes que se encuentren en ella.", function () {
                Modal.showLoadingBar();
                var temporalCategory = vm.recordCategory;
                RecordCategory.delete({id: vm.recordCategory.id},
                    function () {
                        Modal.hideLoadingBar();
                        vm.isSaving = false;
                        Modal.actionToast("Carpeta eliminada.", "Deshacer", function () {
                            temporalCategory.deleted = false;
                            RecordCategory.update(temporalCategory, function () {
                                loadAll();
                                Modal.toast("Listo.")
                            }, onSaveError);
                        });
                        vm.addingFolder = false;
                        vm.recordCategory = {};
                        loadAll();
                        vm.recordCategory.color = "folder-gray";
                    });
            })
        }


        vm.saveFolder = function () {
            Modal.confirmDialog("¿Está seguro que desea crear esta carpeta?", "Se creará la nueva carpeta a todos los expediente por filial.", function () {
                vm.isSaving = true;
                vm.recordCategory.companyId = globalCompany.getId();
                vm.recordCategory.deleted = false;
                if (vm.houseId == -1) {
                    vm.recordCategory.icon = -1;
                } else {
                    vm.recordCategory.icon = 0;
                }
                Modal.showLoadingBar();
                if (vm.recordCategory.id !== null) {
                    RecordCategory.update(vm.recordCategory, onSaveSuccess, onSaveError);
                } else {
                    RecordCategory.save(vm.recordCategory, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess() {
            Modal.hideLoadingBar();
            vm.isSaving = false;
            Modal.toast("Carpeta creada.");
            vm.addingFolder = false;
            vm.recordCategory = {};
            loadAll();
            vm.recordCategory.color = "folder-gray";
        }

        function onSaveError() {
            Modal.toast("Ocurrio un error inesperado.");
        }

        vm.setColor = function (record, posColor) {
            var colors = ['folder-gray', 'folder-red', 'folder-orange', 'folder-yellow', 'folder-purple', 'folder-blue'];
            vm.recordCategory.color = colors[posColor];
        }
        vm.close = function () {
            $uibModalInstance.close({});
        }
    }
})();
