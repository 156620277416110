(function () {
    'use strict';

    angular
            .module('aditumApp')
            .controller('RegisterVisitorMenuController', RegisterVisitorMenuController);

    RegisterVisitorMenuController.$inject = ['$timeout', '$scope', '$stateParams', '$rootScope', 'CommonMethods', 'globalCompany','$state', '$translate'];

    function RegisterVisitorMenuController(timeout, $scope, $stateParams, $rootScope, CommonMethods, globalCompany,$state, translate) {
        var vm = this;
        $rootScope.active = "register-visitor-mobile-menu";
        $rootScope.mainTitle = translate.instant('aditumApp.mobileMenu.security.reportAuthorized'), //"Reportar autorizado";
            vm.go = function (sref) {
                $rootScope.backState = $state.current.name;
                $state.go(sref);
            };

        vm.isFullAcess = $rootScope.company.fullControlAccess;

        if(vm.isFullAcess){
            vm.menu = [
                {
                    title: translate.instant('aditumApp.mobileMenu.security.temporarilyAuthorized'), //"Autorizado Temporal",
                    icon: "timer",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-temporal-new",
                    show: true,
                    class:"animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.temporarilyAuthorized'), //"Ingreso mercadería nacional",
                    icon: "local_shipping",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-national-merchandise-ingress-new",
                    show: true,
                    class:"animate__animated  animate__backInRight"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.shipmentMerchandise'), //"Salida de mercadería",
                    icon: "shopping_cart_checkout",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-national-merchandise-exit-new",
                    show: true,
                    class:"animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.import'), //"Importación",
                    icon: "luggage",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-importations-new",
                    show: true,
                    class:"animate__animated  animate__backInRight"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.maintenance'), //"Mantenimientos",
                    icon: "construction",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-maintenance-new",
                    show: true,
                    class:"animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.employee'), //"Empleado",
                    icon: "cleaning_services",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-employee-new",
                    show: true,
                    class:"animate__animated  animate__backInRight"
                },
            ];
        }else{
            vm.menu = [
                {
                    title: translate.instant('aditumApp.mobileMenu.security.express'), //"Express",
                    icon: "local_shipping",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-express-new",
                    show: true,
                    class:"animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.temporal'), //"Temporal",
                    icon: "hail",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-temporal-new",
                    show: true,
                    class:"animate__animated  animate__backInRight"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.permanent'), //"Permanente",
                    icon: "account_circle",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-permanent-new",
                    show: true,
                    class:"animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.employee'), //"Empleado",
                    icon: "cleaning_services",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-employee-new",
                    show: true,
                    class:"animate__animated  animate__backInRight"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.scholarBus'), //"Bus escolar",
                    icon: "directions_bus",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-bus-new",
                    show: true,
                    class:"animate__animated  animate__backInLeft"
                },
                {
                    title: translate.instant('aditumApp.mobileMenu.security.vacation'), // "Vacacional",
                    icon: "surfing",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    uisref: "visitant-invited-vacational-new",
                    show: globalCompany.getId()!=133,
                    class:"animate__animated  animate__backInRight"
                },
            ];
        }
    }
}
)();
