(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('follow-up', {
                parent: 'entity',
                url: '/follow-up?page&sort&search',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.followUp.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/follow-up/follow-ups.html',
                        controller: 'FollowUpController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('followUp');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('follow-up-detail', {
                parent: 'follow-up',
                url: '/follow-up/{id}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.followUp.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/follow-up/follow-up-detail.html',
                        controller: 'FollowUpDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('followUp');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FollowUp', function ($stateParams, FollowUp) {
                        return FollowUp.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'follow-up',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('follow-up-detail.edit', {
                parent: 'follow-up-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/follow-up/follow-up-dialog.html',
                        controller: 'FollowUpDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['FollowUp', function (FollowUp) {
                                return FollowUp.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('follow-up.new', {
                    parent: 'follow-up',
                    url: '/{houseId}/new',
                    data: {
                        authorities: ['ROLE_MANAGER', 'ROLE_JD']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/follow-up/follow-up-dialog.html',
                            controller: 'FollowUpDialogController',
                            controllerAs: 'vm',
                        }
                    },
                    resolve: {
                        entity: function () {
                            return {};
                        }
                    }
                }
            )
            .state('follow-up.edit.follow-up-category-configuration', {
                parent: 'follow-up.edit',
                url: '/follow-up-category-config',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.recordCategory.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/follow-up-category/follow-up-categories.html',
                        controller: 'FollowUpCategoryController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go('^', {}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state("follow-up.edit.record-file-preview", {
                parent: 'follow-up.edit',
                url: '/file-preview/{url}/{name}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal','$rootScope', function ($stateParams, $state, $uibModal,$rootScope) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-file/file-preview.html',
                        controller: 'FilePreviewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $rootScope.back()
                    }, function () {
                        $rootScope.back()
                    });
                }]
            })
            .state('follow-up.edit', {
                parent: 'follow-up',
                url: '/{houseId}/{id}/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                resolve: {
                    entity: ['FollowUp', '$stateParams','CommonMethods', function (FollowUp, $stateParams,CommonMethods) {
                        var id = CommonMethods.decryptString($stateParams.id);
                        return FollowUp.get({id: id}).$promise;
                    }]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/follow-up/follow-up-dialog.html',
                        controller: 'FollowUpDialogController',
                        controllerAs: 'vm',
                    }
                }
            })
            .state('follow-up.delete', {
                parent: 'follow-up',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/follow-up/follow-up-delete-dialog.html',
                        controller: 'FollowUpDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['FollowUp', function (FollowUp) {
                                return FollowUp.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('follow-up', null, {reload: 'follow-up'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})
();
