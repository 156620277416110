(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpTaskDetailController', FollowUpTaskDetailController);

    FollowUpTaskDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FollowUpTask', 'Company', 'House', 'FollowUp', 'AdminInfo'];

    function FollowUpTaskDetailController($scope, $rootScope, $stateParams, previousState, entity, FollowUpTask, Company, House, FollowUp, AdminInfo) {
        var vm = this;

        vm.followUpTask = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:followUpTaskUpdate', function(event, result) {
            vm.followUpTask = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
