(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('BalanceSheet', BalanceSheet)
        .factory('BalanceSheetPdf', BalanceSheetPdf);

    BalanceSheet.$inject = ['$resource', 'DateUtils'];
    BalanceSheetPdf.$inject = ['$resource', 'DateUtils'];

    function BalanceSheet ($resource, DateUtils) {
        var resourceUrl =  'api/balance-sheet/:id';

        return $resource(resourceUrl, {}, {
            'getByCompanyIdAndBetweenDates': { method: 'GET', url: "api/balance-sheet-by-company-and-dates/:companyId/:initialTime/:finalTime" , isArray: false},
        });
    }

        function BalanceSheetPdf ($resource, DateUtils) {
            var resourceUrl =  'api/balance-sheet/:id';

            return $resource(resourceUrl, {}, {
                'getByCompanyIdAndBetweenDates': { method: 'GET', url: "api/balance-sheet-by-company-and-dates/file/:companyId/:initialTime/:finalTime" , isArray: false},
            });
        }
})();
