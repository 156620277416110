(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountFinancialClosingController', AccountFinancialClosingController);

    AccountFinancialClosingController.$inject = ['AccountFinancialClosing'];

    function AccountFinancialClosingController(AccountFinancialClosing) {

        var vm = this;

        vm.accountFinancialClosings = [];

        loadAll();

        function loadAll() {
            AccountFinancialClosing.query(function(result) {
                vm.accountFinancialClosings = result;
                vm.searchQuery = null;
            });
        }
    }
})();
