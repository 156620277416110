(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('StateOfIncome', StateOfIncome)
        .factory('StateOfIncomePdf', StateOfIncomePdf)

    StateOfIncome.$inject = ['$resource', 'DateUtils'];

    function StateOfIncome ($resource, DateUtils) {
        var resourceUrl =  'api/state-of-income/:id';

        return $resource(resourceUrl, {}, {
            'getByCompanyIdAndBetweenDates': { method: 'GET', url: "api/state-of-income-by-company-and-dates/:companyId/:periodId/:finalTime" , isArray: false},
        });
    }

        function StateOfIncomePdf ($resource, DateUtils) {
            var resourceUrl =  'api/state-of-income/:id';

            return $resource(resourceUrl, {}, {
                'getByCompanyIdAndBetweenDates': { method: 'GET', url: "api/state-of-income-by-company-and-dates/file/:companyId/:periodId/:finalTime" , isArray: false},
            });
        }
})();
