(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('GeneratePenaltyFeeController', GeneratePenaltyFeeController);

        GeneratePenaltyFeeController.$inject = ['AdministrationConfiguration', 'ExchangeRateBccr', 'House', 'Modal', '$localStorage', '$state', '$rootScope', 'CommonMethods', 'globalCompany', 'PenaltyFeeConfiguration', 'Charge', 'Company'];

        function GeneratePenaltyFeeController(AdministrationConfiguration, ExchangeRateBccr, House, Modal, $localStorage, $state, $rootScope, CommonMethods, globalCompany, PenaltyFeeConfiguration, Charge, Company) {
            var vm = this;
            $rootScope.active = "generate-penalty";
            vm.sendByEmail = true;

            vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
            moment.locale("es");
            vm.tipoCambio = {};
            vm.bccrUse = true;
            vm.date = new Date();

            ExchangeRateBccr.get({
                fechaInicio: moment(vm.today).utc(-6).format(),
                fechaFinal: moment(vm.today).utc(-6).format(),
            }, function (result) {
                vm.tipoCambio = result;
            })

            vm.secondCurrency = vm.companyConfig.secondCurrency;
            vm.principalCurrency = vm.companyConfig.currency;
            vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
            vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

            vm.saveAdminConfig = function () {
                vm.adminConfig.exchangeRateDate = moment().format();
            }

            AdministrationConfiguration.get({
                companyId: globalCompany.getId()
            }).$promise.then(function (result) {
                vm.adminConfig = result;
                vm.adminConfig.exchangeRate = parseFloat(vm.adminConfig.exchangeRate);
            })

            vm.showBccrUse = function () {
                var currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
                if (currencies.length !== 2) {
                    return false;
                }
                var showBccrUse = currencies.some(function (moneda) {
                    return moneda.symbol === "₡";
                }) && currencies.some(function (moneda) {
                    return moneda.symbol === "$";
                });
                return showBccrUse;
            }


            PenaltyFeeConfiguration.getByCompany({companyId: globalCompany.getId()}, function (result) {
                vm.penaltyFeeConfiguration = result;
                vm.penaltyFeeConfiguration.interestRate = cStoN(vm.penaltyFeeConfiguration.interestRate);
                vm.penaltyFeeConfiguration.maximunDaysInterests = cStoN(vm.penaltyFeeConfiguration.maximunDaysInterests);
                vm.penaltyFeeConfiguration.maximunDays = cStoN(vm.penaltyFeeConfiguration.maximunDays);
                vm.penaltyFeeConfiguration.feeRatePercentage = cStoN(vm.penaltyFeeConfiguration.feeRatePercentage);
            })

            vm.generateFees = function () {
                Modal.confirmDialog("¿Está seguro que desea crear las multas / intereses?", "", function () {
                    Charge.generateFees({
                        final_time: vm.finalTimeFormatted,
                        companyId: vm.companyId,
                        type: vm.chargeType,
                        houseId: vm.house == -1 ? -1 : vm.house.id,
                        sendEmail: vm.sendByEmail,
                        currency: vm.principalCurrency,
                        exchangeRate: vm.tipoCambio.venta
                    }, function () {
                        Modal.toast("Listo, se están creando las multas/intereses, puede tardar 1 minuto en verse reflejado.")
                    });
                })
            }

            function cStoN(str) {
                return Number(str);
            }

            vm.goToConfiguration = function () {
                $state.go("generate-penalty.new");
            }

            vm.loadAll = loadAll;
            vm.final_time = new Date();
            vm.chargeType = 10;
            vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
            vm.house = -1;
            vm.exportingExcel = false;
            vm.showNormalReport = false;

            vm.changeFeeSituation = function (charge) {
                var sDescription = !charge.hasFee ? "La cuota tendrá multa cuando se generen las multas" : "Ya la cuota no tendrá multa cuando se generen las multas";
                Modal.confirmDialogWithCancel("¿Está seguro que desea cambiar el estado de la cuota?", "" +
                    sDescription, function () {
                    Charge.updateFee(charge, function () {
                        Modal.actionToast("Listo.", 'Recargar reporte', loadAll);
                    })
                }, function () {
                    charge.hasFee = !charge.hasFee;
                })
            }

            vm.changeApplyFeeInHouse = function (house) {
                var sDescription = !house.applyDueFee ? "A la filial se le generarán multas e interes" : "A la filial no se le generarán multas e interes";
                Modal.confirmDialogWithCancel("¿Está seguro que desea cambiar el estado de la filial?", "" +
                    sDescription, function () {
                    House.changeHouseApplyFeeStatus({houseId: house.houseDTO.id}, function () {
                        Modal.actionToast("Listo.", 'Recargar reporte', loadAll);
                    })
                }, function () {
                    house.applyDueFee = !house.applyDueFee;
                })
            }

            vm.loadAll();

            vm.showYearDefaulter = function () {
                vm.loadDefaulters(vm.yearDefaulter)
            }

            vm.getCategory = function (type) {
                switch (type) {
                    case 1:
                        return "MANTENIMIENTO"
                        break;
                    case 2:
                        return "EXTRAORDINARIA"
                        break;
                    case 3:
                        return "ÁREAS COMUNES"
                        break;
                    case 5:
                        return "MULTA";
                        break;
                    case 6:
                        return "CUOTA AGUA";
                        break;
                }
            }

            if ($localStorage.houses) {
                vm.houses = $localStorage.houses;
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                }, function (result) {
                    vm.houses = result;
                });
            }

            vm.searchTerm;
            vm.searchTermFilial;

            vm.clearSearchTermFilial = function () {
                vm.searchTermFilial = '';
            };
            vm.typingSearchTermFilial = function (ev) {
                ev.stopPropagation();
            }
            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }

            function loadAll() {
                vm.isReady = false;
                vm.finalTimeFormatted = moment(vm.final_time).format();
                vm.companyId = globalCompany.getId();
                vm.morosidadTable = {
                    totalGeneral: [0, 0, 0, 0, 0, 0],
                    houses: []
                }
                if (vm.chargeType == 10) {
                    vm.filtering = false;
                } else {
                    vm.filtering = true;
                }
                vm.chargeTypeSetted = vm.chargeType;
                var houseId = vm.house == -1 ? -1 : vm.house.id;
                vm.fileUrl = "api/charges/chargesToPay/file/" + vm.finalTimeFormatted + "/" + vm.chargeType + "/byCompany/" + vm.companyId + "/house/" + houseId;
                Charge.calculateFees({
                    final_time: vm.finalTimeFormatted,
                    companyId: vm.companyId,
                    type: vm.chargeType,
                    houseId: vm.house == -1 ? -1 : vm.house.id
                }, onSuccess, onError);

                function onSuccess(data) {
                    vm.report = data;
                    Company.get({id: globalCompany.getId()}).$promise.then(function (result) {
                        vm.isReady = true;
                        vm.companyName = result.name;
                    });
                }


                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }

        }
    }
)();
