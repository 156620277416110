(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpNextStepDialogController', FollowUpNextStepDialogController);

    FollowUpNextStepDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FollowUpNextStep', 'Company', 'House', 'FollowUp', 'AdminInfo'];

    function FollowUpNextStepDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FollowUpNextStep, Company, House, FollowUp, AdminInfo) {
        var vm = this;

        vm.followUpNextStep = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.companies = Company.query();
        vm.houses = House.query();
        vm.followups = FollowUp.query();
        vm.admininfos = AdminInfo.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.followUpNextStep.id !== null) {
                FollowUpNextStep.update(vm.followUpNextStep, onSaveSuccess, onSaveError);
            } else {
                FollowUpNextStep.save(vm.followUpNextStep, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:followUpNextStepUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.reminderDate = false;
        vm.datePickerOpenStatus.lastModification = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
