(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BitacoraAccionesController', BitacoraAccionesController);

    BitacoraAccionesController.$inject = ['Principal', 'globalCompany', '$rootScope', 'Company', '$localStorage', 'CommonMethods', '$state', 'BitacoraAcciones', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'House'];

    function BitacoraAccionesController(Principal, globalCompany, $rootScope, Company, $localStorage, CommonMethods, $state, BitacoraAcciones, ParseLinks, AlertService, paginationConstants, pagingParams, House) {

        var vm = this;
        $rootScope.active = "bitacoraAcciones";
        vm.bitacoraAcciones = [];
        vm.hasContability = true;
        vm.houseId = "empty";
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 20;
        vm.isReady = false;
        vm.searching = false;
        vm.type = "7";
        vm.page = 0;
        vm.dates = {};
        vm.dates.initial_time = new Date();
        vm.dates.final_time = new Date();

        vm.newReportN = function(){
            vm.isReady = false;
            vm.searching = false;
            if(vm.houseId==-1){
                vm.houseId="empty"
            }
            vm.page = 0;
            vm.bitacoraAcciones = [];
        }

        vm.loadHouses = function () {
            House.query({
                companyId: globalCompany.getId()
            }, function (data, headers) {
                vm.houses = data;
            })
        }
        vm.loadHouses();

        vm.newReport = function () {
            vm.bitacoraAcciones = [];
            vm.isReady = false;
            vm.loadAll();
        };

        vm.loadAll = function () {
            vm.searching = true;
            if(vm.houseId=="empty"){
                vm.houseId = -1;
            }
            BitacoraAcciones.filter({
                page: vm.page,
                size: 15,
                companyId: globalCompany.getId(),
                initialDate: moment(vm.dates.initial_time).format(),
                finalDate: moment(vm.dates.final_time).format(),
                type: vm.type,
                houseId: vm.houseId,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                // vm.queryCount = vm.totalItems;
                for (var i = 0; i < data.length; i++) {
                    vm.bitacoraAcciones.push(data[i])
                }
                console.log(vm.bitacoraAcciones)
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        vm.detail = function (bitacoraAcciones) {
            if (bitacoraAcciones.type !== 3) {
                if (bitacoraAcciones.type === 4) {
                    $localStorage.budgetAction = 1;
                }
                var encryptedId = CommonMethods.encryptIdUrl(bitacoraAcciones.idReference)
                $state.go(bitacoraAcciones.urlState, {
                    id: encryptedId
                });

            } else {
                $state.go('administration-configuration-detail')
            }

        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            vm.loadAll();
        }
    }
})();
