(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingPeriodDeleteController',AccountingPeriodDeleteController);

    AccountingPeriodDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccountingPeriod'];

    function AccountingPeriodDeleteController($uibModalInstance, entity, AccountingPeriod) {
        var vm = this;

        vm.accountingPeriod = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccountingPeriod.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
