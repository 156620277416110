(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FiscalTerritorialDivisionDetailController', FiscalTerritorialDivisionDetailController);

    FiscalTerritorialDivisionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'FiscalTerritorialDivision'];

    function FiscalTerritorialDivisionDetailController($scope, $rootScope, $stateParams, previousState, entity, FiscalTerritorialDivision) {
        var vm = this;

        vm.fiscalTerritorialDivision = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:fiscalTerritorialDivisionUpdate', function(event, result) {
            vm.fiscalTerritorialDivision = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
