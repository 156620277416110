(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('RecordCategoryDetailController', RecordCategoryDetailController);

    RecordCategoryDetailController.$inject = ['Company', '$timeout', 'Modal', 'AditumStorageService', '$sce', 'House', '$scope', '$rootScope', '$stateParams', 'CommonMethods', 'RecordCategory', '$state', 'RecordFile', 'globalCompany'];

    function RecordCategoryDetailController(Company, $timeout, Modal, AditumStorageService, $sce, House, $scope, $rootScope, $stateParams, CommonMethods, RecordCategory, $state, RecordFile, globalCompany) {
        var vm = this;
        $rootScope.active = "house-record";
        $rootScope.mainTitle = "Expedientes";
        vm.houseId = CommonMethods.decryptString($stateParams.houseId);
        vm.categoryId = CommonMethods.decryptString($stateParams.categoryId);
        vm.isReady = false;
        var file = null;
        RecordCategory.get({id: vm.categoryId}, function (data) {
            vm.recordCategory = data;
        })
        if (vm.houseId != -1) {
            House.get({id: vm.houseId}, function (data) {
                vm.house = data;
            })
        } else {
            Company.get({id: globalCompany.getId()}, function (data) {
                vm.house = {}
                vm.house.id = -1;
                vm.house.housenumber = data.name;
            })
        }

        vm.goToHouse = function (houseId) {
            var id = CommonMethods.encryptString(houseId + "")
            $state.go("record-file", {houseId: id})
        }
        var unsubscribe = $rootScope.$on('aditumApp:recordCategoryUpdate', function (event, result) {
            vm.recordCategory = result;
        });

        vm.recordFiles = [];
        vm.creatingFolder = false;
        vm.isDetailFolder = false;
        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
                addFile();
            }
        };

        loadAll();
        vm.folderLevels = []
        vm.detailFolder = function (recordFile) {
            vm.folderLevels.push(recordFile);
            vm.isDetailFolder = true;
            vm.folder = recordFile;
            loadAllByFolder(recordFile);
        }

        vm.goToFolderfolderLever = function ($index) {
            vm.isReady = false;
            var newFolderLevels = [];
            for (var i = 0; i <= $index; i++) {
                newFolderLevels.push(vm.folderLevels[i]);
            }
            vm.folder = vm.folderLevels[$index];
            vm.folderLevels = newFolderLevels;
            loadAllByFolder(vm.folder);
        }
        vm.outFolder = function () {
            vm.isReady = false;
            vm.isDetailFolder = false;
            vm.folder = {};
            vm.folderLevels = [];
            loadAll();
        }

        function loadAllByFolder(recordFile) {
            RecordFile.getByCategoryAndHouseAndFolder({
                page: 0,
                size: 1000,
                houseId: parseInt(vm.houseId),
                categoryId: parseInt(vm.categoryId),
                folderId: parseInt(recordFile.id),
            }, onSuccess, onError);
        }

        function loadAll() {
            RecordFile.getByCategoryAndHouse({
                page: 0,
                size: 1000,
                houseId: parseInt(vm.houseId),
                categoryId: parseInt(vm.categoryId),
            }, onSuccess, onError);
        }

        function onSuccess(data) {
            vm.recordFiles = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                r.recordCategoryId = vm.categoryId;
                vm.recordFiles.push(r)
            }
            vm.isReady = true;
        }

        function onError() {

        }

        vm.addFolder = function () {
            vm.creatingFolder = true;
            vm.recordFile = {};
            vm.recordFile.name = null;
            vm.recordFile.type = "fa-folder";
            vm.recordFile.deleted = false;
            if (vm.house.id == -1) {
                vm.recordFile.houseId = null;
            } else {
                vm.recordFile.houseId = vm.house.id;
            }
            vm.recordFile.companyId = globalCompany.getId();
            vm.recordFile.isFolder = true;
            vm.recordFile.recordCategoryId = vm.categoryId;
            vm.recordFile.dateUploaded = new Date();
            vm.recordFile.lastModification = new Date();
            vm.recordFile.author = defineAuthor($rootScope.companyUser);
            vm.recordFile.type = "fa-folder";
            vm.recordFile.editing = true;
            vm.recordFile.modificationAuthor = "-";
            if (vm.isDetailFolder == true) {
                vm.recordFile.recordFileId = vm.folder.id;
            }
            vm.recordFiles.unshift(vm.recordFile);
            $timeout(function () {
                angular.element('#newFolder').focus();
            });
        }

        vm.saveFolder = function () {
            if (!vm.recordFile.name) {
                Modal.toast("Nombra la carpeta.")
            } else {
                saveRecordFile(vm.recordFile);
            }
        }

        vm.editFolder = function (recordFile) {
            vm.recordFile = {};
            vm.recordFile = recordFile;
            vm.recordFile.lastModification = new Date();
            vm.creatingFolder = true;
            recordFile.editing = true;
        }

        vm.deleteFile = function (recordFile) {

            var title = "¿Está seguro que desea eliminar el archivo?";
            var content = "";
            if (recordFile.isFolder) {
                title = "¿Está seguro que desea eliminar la carpeta?";
                content = "Todos los archivos dentro de ella se perderan.";
            }
            Modal.confirmDialog(title, content, function () {
                if (recordFile.id != null) {
                    RecordFile.delete({id: recordFile.id}, function () {
                        CommonMethods.deleteFromArray(recordFile, vm.recordFiles);
                        Modal.actionToast("Eliminado.", "Deshacer", function () {
                            recordFile.deleted = false;
                            recordFile.recordCategoryId = vm.categoryId;
                            RecordFile.update(recordFile,
                                function (result) {
                                    result.recordCategoryId = vm.categoryId;
                                    vm.recordFiles.push(result);
                                }, onSaveError);
                        })
                    })
                } else {
                    CommonMethods.deleteFromArray(recordFile, vm.recordFiles);
                    vm.creatingFolder = false;
                }
            })
        }

        function addFile() {
            vm.recordFile = {};
            vm.recordFile.name = vm.file.name;
            vm.recordFile.type = vm.file.type;
            vm.recordFile.deleted = false;
            if (vm.house.id == -1) {
                vm.recordFile.houseId = null;
            } else {
                vm.recordFile.houseId = vm.house.id;
            }
            vm.recordFile.companyId = globalCompany.getId();
            vm.recordFile.isFolder = false;
            vm.recordFile.recordCategoryId = vm.categoryId;
            vm.recordFile.dateUploaded = new Date();
            vm.recordFile.lastModification = new Date();
            vm.recordFile.author = defineAuthor($rootScope.companyUser);
            vm.recordFile.type = defineIconType(vm.file.type);
            vm.recordFile.modificationAuthor = bytesToSize(vm.file.size);
            if (vm.isDetailFolder == true) {
                vm.recordFile.recordFileId = vm.folder.id;
            }
            vm.recordFiles.unshift(vm.recordFile);
            upload(vm.recordFile);
        }

        vm.previewFile = function (recordFile) {
            $state.go("record-file.record-category.record-file-preview", {
                url: recordFile.fileUrl,
                type: recordFile.type
            })
        }

        function bytesToSize(bytes) {
            var decimals = 2;
            if (bytes === 0) return '0 Bytes';
            var k = 1024;
            var dm = decimals < 0 ? 0 : decimals;
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            var i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        function defineIconType(type) {
            switch (type) {
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    return "fa-file-word-o";
                    break;
                case "image/jpeg":
                    return "fa-file-image-o";
                    break;
                case "image/png":
                    return "fa-file-image-o";
                    break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    return "fa-file-excel-o";
                    break;
                case "application/pdf":
                    return "fa-file-pdf-o";
                    break;
                case "video/mp4":
                    return "fa-file-video-o";
                    break;
            }
            return "fa-file-o";
        }

        function defineAuthor(user) {
            var name = $rootScope.companyUser.name != null ? $rootScope.companyUser.name + " " : "";
            var lastName = $rootScope.companyUser.lastName != null ? $rootScope.companyUser.lastName + " " : " ";
            var secondLastName = $rootScope.companyUser.secondlastname != null ? $rootScope.companyUser.secondlastname : "";
            return name + lastName + secondLastName;
        }

        function makeid(length, fileName) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        function upload(recordFile) {
            var fileName = makeid(4, file.name) + " - " + vm.fileName;
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/record-file/' + vm.house.housenumber + '/' + fileName).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    recordFile.fileUrl = downloadURL;
                    saveRecordFile(recordFile);
                });
            });
        }

        function saveRecordFile(file) {
            vm.isSaving = true;
            if (vm.recordFile.id !== null) {
                RecordFile.update(file, onSaveSuccess, onSaveError);
            } else {
                RecordFile.save(file, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.recordFiles = [];
            vm.creatingFolder = false;
            if (vm.isDetailFolder) {
                loadAllByFolder(vm.folder)
            } else {
                loadAll();
            }
            Modal.toast("Listo.")
        }

        function onSaveError() {
            CommonMethods.deleteFromArray(vm.recordFiles[0], vm.recordFiles);
            Modal.toast("Ocurrio un error subiendo el archivo.")

        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
