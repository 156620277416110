(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('LoadAutomaticPaymentController', LoadAutomaticPaymentController);

        LoadAutomaticPaymentController.$inject = ['ExchangeRateBccr', 'AdministrationConfiguration', 'CommonMethods', '$localStorage', '$mdDialog', '$scope', '$state', 'House', 'globalCompany', '$rootScope', 'DataUtils', 'Modal', 'Payment', 'Banco'];

        function LoadAutomaticPaymentController(ExchangeRateBccr, AdministrationConfiguration, CommonMethods, $localStorage, $mdDialog, $scope, $state, House, globalCompany, $rootScope, DataUtils, Modal, Payment, Banco) {
            $rootScope.active = "load-automatic-payment";
            var vm = this;
            var file;
            vm.fileName;
            vm.chargesList = [];
            vm.isReady = 0;
            vm.bccrUse = true;
            vm.today = new Date();
            moment().locale("es");


            vm.positiveBalances = ["MANT", "AGUA","AREAS", "MULT", "EXTRA"];

            vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());

            vm.secondCurrency = vm.companyConfig.secondCurrency;
            vm.principalCurrency = vm.companyConfig.currency;
            vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
            vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

            vm.showBccrUse = function () {
                var currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
                if (currencies.length !== 2) {
                    return false;
                }
                var showBccrUse = currencies.some(function (moneda) {
                    return moneda.symbol === "₡";
                }) && currencies.some(function (moneda) {
                    return moneda.symbol === "$";
                });
                return showBccrUse;
            }

            vm.saveAdminConfig = function () {
                vm.adminConfig.exchangeRateDate = moment().format();
                if (vm.adminConfig.id !== null) {
                    AdministrationConfiguration.update(vm.adminConfig, function (result) {
                    }, function () {
                    });
                } else {
                    AdministrationConfiguration.save(vm.adminConfig, function () {
                    }, function () {
                    });
                }
            }

            AdministrationConfiguration.get({
                companyId: globalCompany.getId()
            }).$promise.then(function (result) {
                vm.adminConfig = result;
                vm.adminConfig.exchangeRate = parseFloat(vm.adminConfig.exchangeRate);
                if (vm.showBccrUse()) {
                    ExchangeRateBccr.get({
                        fechaInicio: moment(new Date()).format(),
                        fechaFinal: moment(new Date()).format(),
                    }, function (result) {
                        vm.tipoCambio = result;
                    })
                }
            })

            if ($localStorage.houses) {
                vm.houses = $localStorage.houses;
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                }, function (result) {
                    vm.houses = result;
                });
            }
            vm.creatingCharges = false;
            vm.chargeCount = 0;

            function loadBancos() {
                Banco.query({
                    companyId: globalCompany.getId()
                }, function (data) {
                    vm.bancos = data;
                });

            }

            loadBancos()

            vm.clearSearchTerm = function () {
                vm.searchTerm = '';
            };
            vm.searchTerm;
            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }

            vm.uploadFile = function ($file) {
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                vm.progressUpload = "Leyendo archivo..."
                if ($file) {
                    DataUtils.toBase64($file, function (base64Data) {
                        vm.progressUpload = "Convirtiendo datos..."
                        $scope.$apply(function () {
                            vm.displayImage = base64Data;
                            vm.displayImageType = $file.type;
                        });
                    });
                    file = $file;
                    vm.fileName = file.name;
                    vm.isReady = 1;
                    vm.parseExcel();
                }
            };

            vm.parseExcel = function () {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    vm.progressUpload = "Convirtiendo datos..."
                    /* DO SOMETHING WITH workbook HERE */
                    var first_sheet_name = workbook.SheetNames[0];
                    /* Get worksheet */
                    var worksheet = workbook.Sheets[first_sheet_name];
                    vm.payments = XLSX.utils.sheet_to_json(worksheet, {
                        raw: false
                    });
                    showCharges(formatPayments(vm.payments))
                };
                reader.onerror = function (ex) {
                    Modal.toast("Existe un error con el formato del archivo subido")
                    vm.isReady = 1;
                };
                reader.readAsBinaryString(file);
            };


            function showCharges(payments) {
                $scope.$apply(function () {
                    vm.isReady = 2;
                    vm.paymentList = payments;
                })
            }


            vm.setHouses = function () {
                if (vm.chargesList.length > 0) {
                    for (var i = 0; i < vm.chargesList.length; i++) {
                        vm.chargesList[i].houseId = vm.houseId;
                    }

                }
            }
            vm.saveCharges = function () {
                Modal.confirmDialog("¿Está seguro que desea registrar los pagos?", "", function () {
                    vm.error = false;
                    Modal.showLoadingBar();
                    vm.creatingCharges = true;
                    Modal.toast("Se están registrando las pagos, por favor espere y no cierre la ventana.")
                    createPayment(vm.paymentList[0], 0, vm.paymentList.length);
                    if (vm.error) {
                        Modal.toast("Se han presentado un error.")
                    }
                })
            };

            function createPayment(payment, count, length) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.chargeCount = count;
                    })
                }, 10)
                if (count < length) {
                    payment.account = defineBanco(payment.banco);
                    payment.currency =  vm.defineCurrency(payment.banco);
                    var venta = vm.bccrUse ? parseFloat(vm.tipoCambio.venta) : parseFloat(vm.adminConfig.exchangeRate);
                    payment.exchangeRate = venta;
                    Payment.saveByTemplate(payment, function (result) {
                        count++;
                        if (count == length) {
                            Modal.hideLoadingBar();
                            vm.isReady = 0;
                            vm.creatingCharges = false;
                            Modal.toast("Se han registrado todos los pagos correctamente.")
                        } else {
                            createPayment(vm.paymentList[count], count, vm.paymentList.length)
                        }
                    }, function () {
                        Modal.hideLoadingBar();
                        vm.creatingCharges = false;
                        Modal.actionToastGiantStay("Ocurrio un error en la creación de los pagos después de la línea " + (parseInt(count) + 2));
                        vm.error = true;
                    })
                }
            }

            function validateLinePayment(payment, i) {
                var variable = "";
                var errorCount = 0;

                if (payment.monto == undefined) {
                    variable = "monto";
                    errorCount++;
                } else {
                    var num = parseFloat(payment.monto)
                    if (isNaN(num)) {
                        variable = "monto";
                        errorCount++;
                    }
                }

                if (payment.fecha == undefined) {
                    variable = "fecha";
                    errorCount++;
                }

                if (definePaymentMethod(payment) == 1) {
                    variable = "metodo de pago";
                    errorCount++;
                }

                if (errorCount > 0) {

                }
                return errorCount > 0 ? "Error en la línea " + (parseInt(i) + 2) + " en el campo " + variable : "no"
            }

            function defineBanco(bancoId) {
                for (var i = 0; i < vm.bancos.length; i++) {
                    if (bancoId == vm.bancos[i].id) {
                        return vm.bancos[i].beneficiario + ";" + vm.bancos[i].id;
                    }
                }
            }


            vm.defineCurrency = function (bancoId) {
                for (var i = 0; i < vm.bancos.length; i++) {
                    if (bancoId == vm.bancos[i].id) {
                        return vm.bancos[i].accountingAccount.currency;
                    }
                }
            }

            vm.changeDateString = function(payment){
                moment.locale("es");
                var dateStringF = new Date(payment.date);
                payment.dateString =  moment(dateStringF).format('DD MMMM YYYY');
            }


            function formatPayments(payments) {
                var formatedPayments = [];
                for (var i = 0; i < payments.length; i++) {
                    vm.readingCount = i;
                    var payment = payments[i];
                    if (validateLinePayment(payment, i) == "no") {
                        var formatedPayment = {};
                        formatedPayment.method = definePaymentMethod(payment) + "";
                        formatedPayment.ammount = payment.monto.replace(/,/g, "");
                        formatedPayment.companyId = globalCompany.getId();
                        formatedPayment.companyId = globalCompany.getId();
                        formatedPayment.banco = payment.banco;
                        formatedPayment.currency = vm.defineCurrency(payment.banco);
                        formatedPayment.reference = payment.noRef;
                        formatedPayment.applyPositiveBalance = payment.aplicarSaldoFavor;
                        formatedPayment.date = moment(payment.fecha, 'MM/DD/YYYY');
                        var dateStringF = new Date(formatedPayment.date);
                        formatedPayment.dateString =  moment(dateStringF).format('DD MMMM YYYY');
                        angular.forEach(vm.houses, function (house, key) {
                            if (house.housenumber == payment.filial) {
                                formatedPayment.houseId = house.id;
                            }
                        });
                        formatedPayments.push(formatedPayment)
                    } else {
                        vm.paymentList = [];
                        vm.isReady = 0;
                        Modal.toast(validateLinePayment(payment, i))
                        break;
                    }
                }
                vm.isReady = 1;
                return formatedPayments;
            }

            function definePaymentMethod(payment) {
                switch (payment.metPago) {
                    case "efectivo":
                        return "Efectivo";
                        break;
                    case "cheque":
                        return "Cheque";
                        break;
                    case "transferencia":
                        return "Transferencia";
                        break;
                    case "tarjeta":
                        return "Tarjeta";
                        break;
                    case "deposito":
                        return "Deposito en banco";
                        break;
                }
                return 1;
            }
        }
    }

)();
