(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ParkingSlotConfigurationController', ParkingSlotConfigurationController);

    ParkingSlotConfigurationController.$inject = ['$localStorage','House','Modal','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ParkingSlotConfiguration', 'globalCompany','ParkingSlot','CommonMethods'];

    function ParkingSlotConfigurationController($localStorage,House, Modal,$scope, $rootScope, $stateParams, previousState, entity, ParkingSlotConfiguration, globalCompany, ParkingSlot,CommonMethods) {
        var vm = this;
        vm.save = save;
        vm.parkingSlotConfiguration = entity;
        vm.previousState = previousState.name;
        vm.parkingSlots = [];
        vm.clearSearchTerm = function(){
            vm.searchTerm = '';
        };
        vm.searchTerm;
        vm.typingSearchTerm = function(ev){
            ev.stopPropagation();
        }

        if ($localStorage.houses) {
            vm.houses = $localStorage.houses;
            onSuccessHouses(vm.houses,null)
        } else {
            House.getAllHousesClean({
                companyId: globalCompany.getId()
            },onSuccessHouses);
        }

        function onSuccessHouses(data, headers) {
            vm.houses = data;
            vm.isReady = true;
        }

        vm.addParkingSpot =function(){
            var newParkinSpot = {
                name: null,
                color: null,
                ocupated: null,
                usedBy: null,
                id: null,
                focusId:"focusId"
            }
            vm.parkingSlots.push(newParkinSpot);
            setTimeout(function(){
                angular.element('#focusId').focus();
            })
            vm.search=undefined;
        }


        loadAll();

        function loadAll() {
            ParkingSlot.getByCompany({companyId:globalCompany.getId()},function(result) {
                vm.parkingSlots = result;
                vm.searchQuery = null;
            });
        }


        vm.saveParkingSlot = function(parkingSlot){
            vm.isSaving = true;
            parkingSlot.companyId = globalCompany.getId();
            if (!parkingSlot.id) {
                ParkingSlot.update(parkingSlot, onSaveSuccess, function(){
                    Modal.toast("Un parqueo con ese identificador ya existe.")
                });
            } else {
                ParkingSlot.save(parkingSlot, onSaveSuccess, function(){
                    Modal.toast("Un parqueo con ese identificador ya existe.")
                });
            }
        }

        vm.deleteParkingSlot = function(parkingSlot){
            Modal.confirmDialog("¿Está seguro que desea eliminarlo?","",function(){
                ParkingSlot.delete({id: parkingSlot.id},
                    function () {
                    CommonMethods.deleteFromArray(parkingSlot,vm.parkingSlots)
                        Modal.toast("Eliminado")
                    });
            })
        }


        vm.freeParkingSlot = function(parkingSlot){
            console.log("Hola");
            Modal.confirmDialog("¿Está seguro que quiere liberarlo?","",function(){
                parkingSlot.ocupated = false;
                parkingSlot.usedBy = null;
                parkingSlot.houseId = null;
                ParkingSlot.update(parkingSlot)
            })
        }

        $rootScope.active = "parking-slot-configuration-detail";
        var unsubscribe = $rootScope.$on('aditumApp:parkingSlotConfigurationUpdate', function(event, result) {
            vm.parkingSlotConfiguration = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function save () {
            vm.isSaving = true;
            if (vm.parkingSlotConfiguration.id !== null) {
                ParkingSlotConfiguration.update(vm.parkingSlotConfiguration, onSaveSuccess, onSaveError);
            } else {
                ParkingSlotConfiguration.save(vm.parkingSlotConfiguration, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            loadAll();
            Modal.toast("Guardado.")

        }

        function onSaveError () {
            vm.isSaving = false;
            Modal.toast("Error al guardar.")
        }



    }
})();
