(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetItemDeleteController',BudgetItemDeleteController);

    BudgetItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'BudgetItem'];

    function BudgetItemDeleteController($uibModalInstance, entity, BudgetItem) {
        var vm = this;

        vm.budgetItem = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BudgetItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
