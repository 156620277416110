(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingAccountDetailController', AccountingAccountDetailController);

    AccountingAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccountingAccount', 'Company', 'AccountType'];

    function AccountingAccountDetailController($scope, $rootScope, $stateParams, previousState, entity, AccountingAccount, Company, AccountType) {
        var vm = this;

        vm.accountingAccount = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:accountingAccountUpdate', function(event, result) {
            vm.accountingAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
