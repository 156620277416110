(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state("record-file.record-category.record-file-preview", {
                parent: 'record-file.record-category',
                url: '/file-preview/{url}/{type}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-file/file-preview.html',
                        controller: 'FilePreviewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('record-file', {
                parent: 'entity',
                url: '/record/:houseId',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.recordFile.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/record-file/record-files.html',
                        controller: 'RecordFileController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('recordFile');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('record-file.record-category-config', {
                parent: 'record-file',
                url: '/record-category-config',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.recordCategory.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-category/record-categories.html',
                        controller: 'RecordCategoryController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go('^', {}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('record-file.newFollowUp', {
                parent: 'record-file',
                url: '/new-follow-up-modal/:houseId',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.recordCategory.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/follow-up/follow-up-modal.html',
                        controller: 'FollowUpModalController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('record-file.follow-up-category-configuration', {
                parent: 'record-file',
                url: '/follow-up-category-config',
                data: {
                    authorities: ['ROLE_MANAGER','ROLE_JD'],
                    pageTitle: 'aditumApp.recordCategory.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/follow-up-category/follow-up-categories.html',
                        controller: 'FollowUpCategoryController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function() {
                        $state.go('^', {}, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('record-file.record-category', {
                parent: 'record-file',
                url: '/record-category/:categoryId',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.recordFile.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/record-category/record-category-detail.html',
                        controller: 'RecordCategoryDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('recordFile');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('record-file-detail', {
                parent: 'record-file',
                url: '/record-file/{id}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD'],
                    pageTitle: 'aditumApp.recordFile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/record-file/record-file-detail.html',
                        controller: 'RecordFileDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('recordFile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'RecordFile', function ($stateParams, RecordFile) {
                        return RecordFile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'record-file',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('record-file-detail.edit', {
                parent: 'record-file-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-file/record-file-dialog.html',
                        controller: 'RecordFileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['RecordFile', function (RecordFile) {
                                return RecordFile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('record-file.new', {
                parent: 'record-file',
                url: '/new',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-file/record-file-dialog.html',
                        controller: 'RecordFileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    fileUrl: null,
                                    dateUploaded: null,
                                    lastModification: null,
                                    author: null,
                                    modificationAuthor: null,
                                    type: null,
                                    isFolder: null,
                                    deleted: null,
                                    description: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('record-file', null, {reload: 'record-file'});
                    }, function () {
                        $state.go('record-file');
                    });
                }]
            })
            .state('record-file.edit', {
                parent: 'record-file',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-file/record-file-dialog.html',
                        controller: 'RecordFileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['RecordFile', function (RecordFile) {
                                return RecordFile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('record-file', null, {reload: 'record-file'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('record-file.delete', {
                parent: 'record-file',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_JD']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/record-file/record-file-delete-dialog.html',
                        controller: 'RecordFileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['RecordFile', function (RecordFile) {
                                return RecordFile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('record-file', null, {reload: 'record-file'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
