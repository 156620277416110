(function() {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('penalty-fee-configuration', {
            parent: 'entity',
            url: '/penalty-fee-configuration',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'aditumApp.penaltyFeeConfiguration.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/penalty-fee-configuration/penalty-fee-configurations.html',
                    controller: 'PenaltyFeeConfigurationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('penaltyFeeConfiguration');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('penalty-fee-configuration-detail', {
            parent: 'penalty-fee-configuration',
            url: '/penalty-fee-configuration/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'aditumApp.penaltyFeeConfiguration.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/penalty-fee-configuration/penalty-fee-configuration-detail.html',
                    controller: 'PenaltyFeeConfigurationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('penaltyFeeConfiguration');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PenaltyFeeConfiguration', function($stateParams, PenaltyFeeConfiguration) {
                    return PenaltyFeeConfiguration.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'penalty-fee-configuration',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('penalty-fee-configuration-detail.edit', {
            parent: 'penalty-fee-configuration-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/penalty-fee-configuration/penalty-fee-configuration-dialog.html',
                    controller: 'PenaltyFeeConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PenaltyFeeConfiguration', function(PenaltyFeeConfiguration) {
                            return PenaltyFeeConfiguration.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('generate-penalty.new', {
            parent: 'generate-penalty',
            url: '/config',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/penalty-fee-configuration/penalty-fee-configuration-dialog.html',
                    controller: 'PenaltyFeeConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                applyFee: null,
                                whereToFind: null,
                                maximunDays: null,
                                typeOfDay: null,
                                feeRateType: null,
                                feeRatePercentage: null,
                                feeRateFixedAmmount: null,
                                applyInterest: null,
                                interestRate: null,
                                fromWhereMeasureAreTaken: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('generate-penalty', null, { reload: 'generate-penalty' });
                }, function() {
                    $state.go('generate-penalty');
                });
            }]
        })
        .state('penalty-fee-configuration.edit', {
            parent: 'penalty-fee-configuration',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/penalty-fee-configuration/penalty-fee-configuration-dialog.html',
                    controller: 'PenaltyFeeConfigurationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PenaltyFeeConfiguration', function(PenaltyFeeConfiguration) {
                            return PenaltyFeeConfiguration.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('penalty-fee-configuration', null, { reload: 'penalty-fee-configuration' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('penalty-fee-configuration.delete', {
            parent: 'penalty-fee-configuration',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/penalty-fee-configuration/penalty-fee-configuration-delete-dialog.html',
                    controller: 'PenaltyFeeConfigurationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PenaltyFeeConfiguration', function(PenaltyFeeConfiguration) {
                            return PenaltyFeeConfiguration.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('penalty-fee-configuration', null, { reload: 'penalty-fee-configuration' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
