(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EgressDialogController', EgressDialogController);

    EgressDialogController.$inject = ['AditumStorageService', 'ExchangeRateBccr', 'AccountingAccount', 'CommonMethods', '$timeout', '$state', '$scope', '$stateParams', 'previousState', 'entity', 'Egress', 'Company', 'Principal', 'Proveedor', '$rootScope', 'Banco', 'EgressCategory', 'globalCompany', 'AdministrationConfiguration', 'Modal'];

    function EgressDialogController(AditumStorageService, ExchangeRateBccr, AccountingAccount, CommonMethods, $timeout, $state, $scope, $stateParams, previousState, entity, Egress, Company, Principal, Proveedor, $rootScope, Banco, EgressCategory, globalCompany, AdministrationConfiguration, Modal) {
        var vm = this;
        $rootScope.active = "newEgress";
        vm.isReady = false;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.egress = entity;
        vm.bccrUse = true;
        vm.bccrUseExpense = true;
        vm.makingPayment = false;
        vm.today = new Date();
        var file;

        ExchangeRateBccr.get({
            fechaInicio: moment(new Date()).format(),
            fechaFinal: moment(new Date()).format(),
        }, function (result) {
            vm.tipoCambio = result;
        })

        vm.loadDateExchangeRate = function (date) {
            if(vm.bccrUse) {
                ExchangeRateBccr.get({
                    fechaInicio: moment(date).format(),
                    fechaFinal: moment(date).format(),
                }, function (result) {
                    vm.tipoCambio = result;
                })
            }
        }

        vm.loadDateExchangeRateExpense = function (date) {
            if(vm.bccrUseExpense) {
                ExchangeRateBccr.get({
                    fechaInicio: moment(date).format(),
                    fechaFinal: moment(date).format(),
                }, function (result) {
                    vm.tipoCambioExpense = result;
                })
            }
        }
        vm.bccrUse;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        Modal.enteringForm(confirmCreateEgress);
        $scope.$on("$destroy", function () {
            Modal.leavingForm();
        });

        vm.notMoreThanLeftToPay = function () {
            if (vm.expense.amount > vm.egress.total) {
                vm.expense.amount = vm.egress.total;
            }
            vm.leftToPay = parseFloat(vm.egress.total) - parseFloat(vm.expense.amount);
            vm.calculateTotal();
        }

        vm.calculateTotal = function () {
            vm.formatCurrencyToPayExpense()
        }

        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
            }
        };

        vm.formatCurrencyToPayExpense = function () {
            var venta = vm.bccrUseExpense ? vm.tipoCambioExpense.venta : vm.egress.account.saleExchangeRate;
            vm.venta = venta;
            vm.expense.exchangeRate = venta;
            if (vm.egress.currency != "$") {
                vm.expense.ammountDoubleMoney = vm.expense.amount / venta;
            } else {
                vm.expense.ammountDoubleMoney = vm.expense.amount * venta;
            }
        }

        vm.loadExpense = function () {
            vm.expense = {
                id: null, // Long
                concept: vm.egress.concept, // String
                amount: null, // BigDecimal
                expenseDate: null, // ZonedDateTime
                deleted: false, // Boolean
                billNumber: vm.egress.billNumber, // String
                paymentProofUrl: '', // String
                comments: '', // String
                folioNumber: vm.egress.folio, // String
                paymentMethod: '', // String
                egressId: null, // Long
                companyId: null, // Long
                expenseAccountId: null, // Long
                proveedorId: null, // Long
                bankAccountId: null,
                commission: 0// Long
            };
            vm.leftToPay = vm.egress.total;
        }
        vm.gastosVariables = [];
        vm.gastosFijos = [];
        vm.gastosOtros = [];
        vm.hasIva = true;
        CommonMethods.validateNumbers();
        CommonMethods.formatCurrencyInputs();

        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.secondCurrency = vm.companyConfig.secondCurrency;
        vm.principalCurrency = vm.companyConfig.currency;
        vm.secondCurrencyInfo = CommonMethods.getFullCurrencyInfo(vm.secondCurrency);
        vm.principalCurrencyInfo = CommonMethods.getFullCurrencyInfo(vm.principalCurrency);

        if (vm.egress.id == null) {
            vm.egress.currency = vm.companyConfig.currency;
        }

        vm.loadEgressAccounts = function () {
            AccountingAccount.findEgressAccountsByCompanyId({companyId: globalCompany.getId()}, function (accounts) {
                vm.egressAccounts = accounts[0].accountingAccounts;
            })
        }

        function saveExpense() {
            Modal.showLoadingBar();
            vm.expense.bankAccountId = vm.expense.bank.accountingAccount.id;
            vm.expense.amountSecondaryCurrency = vm.expense.ammountDoubleMoney;
            vm.expense.exchangeRate = vm.tipoCambioExpense.venta;
            vm.expense.currency = vm.expense.bank.accountingAccount.currency;
            vm.egress.immediateExpense = vm.expense;
            save();
        }

        vm.loadEgressAccounts();

        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };
        vm.searchTerm;
        vm.typingSearchTermCategory = function (ev) {
            ev.stopPropagation();
        }

        vm.clearSearchTermCategory = function () {
            vm.searchTermCategory = '';
        };
        vm.searchTermCategory;
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }
        vm.showCurrencyColones = function () {
            if (vm.egress.currency == "₡") {
                return true;
            } else {
                return false;
            }
        }

        function loadAdminConfig() {
            AdministrationConfiguration.get({
                companyId: globalCompany.getId()
            }).$promise.then(function (result) {
                vm.admingConfig = result;
                if (result.egressFolio == true) {
                    vm.egressFolioSerie = result.egressFolioSerie;
                    vm.egressFolioNumber = result.egressFolioNumber;
                    vm.egress.folio = vm.egressFolioSerie + "-" + vm.egressFolioNumber;
                }
            })
        }

        loadAdminConfig();

        function increaseFolioNumber(success) {
            vm.admingConfig.egressFolioNumber = parseInt(vm.egressFolioNumber) + 1;
            vm.admingConfig.egressFolioSerie = vm.egressFolioSerie;
            AdministrationConfiguration.update(vm.admingConfig, success);
        }

        // setTimeout(function () {

        Proveedor.query({companyId: globalCompany.getId(), size: 500}).$promise.then(onSuccessProveedores);

        function onSuccessProveedores(data, headers) {
            vm.proveedores = data;

            Banco.query({companyId: globalCompany.getId()}).$promise.then(onSuccessBancos);

            function onSuccessBancos(data, headers) {
                vm.bancos = data;
                if (vm.egress.id != undefined) {
                    if (vm.egress.account != 0) {
                        for (var i = 0; i < vm.bancos.length; i++) {
                            if (vm.egress.account == vm.bancos[i].id) {
                                vm.egress.account = vm.bancos[i];
                            }
                        }
                    }
                }
                EgressCategory.query({companyId: globalCompany.getId()}).$promise.then(onSuccessEgressCategories);
            }


        }

        vm.calculateWithIVA = function (subtotal) {
            vm.egress.iva = subtotal * 0.13;
            vm.egress.total = vm.egress.iva + subtotal + "";
        };


        function onSuccessEgressCategories(data, headers) {
            angular.forEach(data, function (value, key) {
                if (value.group == 'Gastos fijos') {
                    vm.gastosFijos.push(value)
                }
                if (value.group == 'Gastos variables') {
                    vm.gastosVariables.push(value)
                }

                if (value.group == 'Otros gastos') {
                    vm.gastosOtros.push(value)
                }

            })
            vm.egressCategories = data;
            vm.isReady = true;
        }


        vm.formatearNumero = function (nStr) {
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? ',' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            vm.egress.total = x1 + x2;
        }


        if (vm.egress.id != null) {
//          vm.egress.account = null;
//          vm.egress.paymentMethod = null;
//          vm.egress.paymentDate = null;
            vm.title = 'Registrar egreso';
            vm.button = "Registrar";
            vm.picker3 = {
                datepickerOptions: {
                    minDate: vm.egress.date,
                    enableTime: false,
                    showWeeks: false,
                }
            }
            Proveedor.get({id: vm.egress.proveedor}, onSuccessProovedor)

            if (vm.egress.folio == null || vm.egress.folio == 'undefined') {
                vm.egress.folio = 'Sin Registrar'
            }
            if (vm.egress.billNumber == null || vm.egress.billNumber == 'undefined' || vm.egress.billNumber == '') {
                vm.egress.billNumber = 'Sin Registrar'
            }
        } else {
            vm.title = "Registrar cuenta por pagar";
            vm.button = "Registrar";
        }
        $rootScope.mainTitle = vm.title;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function onSuccessProovedor(proovedor, headers) {
            vm.egress.empresa = proovedor.empresa;

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.confirmMessage = function () {
            if (vm.egress.id != null) {
                confirmReportPayment();
            } else {
                confirmCreateEgress();
            }
        }

        function upload() {
            var today = new Date();
            moment.locale("es");
            vm.direction = globalCompany.getId() + '/expenses/' + moment(today).format("YYYY") + '/' + moment(today).format("MMMM") + '/' + vm.expense.proveedorId + '/';
            var uploadTask = AditumStorageService.ref().child(vm.direction + file.name).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    vm.expense.paymentProofUrl = downloadURL;
                    vm.expense.fileName = vm.fileName;
                    saveExpense();
                });
            });
        }

        function confirmCreateEgress() {
            Modal.confirmDialog("¿Está seguro que desea registrar la cuenta por pagar?", "",
                function () {
                    if (vm.makingPayment) {
                        if (vm.fileName) {
                            vm.isSaving = true;
                            upload();
                        } else {
                            saveExpense();
                        }
                    } else {
                        save();
                    }
                });
        }

        function confirmReportPayment() {
            var title = "¿Está seguro que desea reportar el pago de este egreso?";
            if ($state.$current.name == "egress-tabs.edit") {
                title = "¿Está seguro que desea modificar este egreso?";
            }
            Modal.confirmDialog(title, "",
                function () {
                    save();
                });

        }

        vm.formatCurrencyToPay = function () {
            var venta = vm.bccrUse ? vm.tipoCambio.venta : vm.egress.account.saleExchangeRate;
            if (vm.egress.account.currency != vm.egress.currency) {
                vm.egress.exchangeRate = venta;
                if (vm.egress.account.currency == "₡" && vm.egress.currency == "$") {
                    vm.egress.ammountDoubleMoney = vm.egress.total * venta;
                    vm.egress.ivaDoubleMoney = vm.egress.iva * venta;
                    vm.egress.subtotalDoubleMoney = vm.egress.subtotal * venta;
                }
                if (vm.egress.account.currency == "$" && vm.egress.currency == "₡") {
                    if (vm.egress.subtotalDoubleMoney == 0 && vm.egress.ammountDoubleMoney == 0) {
                        vm.egress.ammountDoubleMoney = 0;
                        vm.egress.ivaDoubleMoney = 0;
                        vm.egress.subtotalDoubleMoney = 0;
                    } else {
                        vm.egress.ammountDoubleMoney = vm.egress.total / venta;
                        vm.egress.ivaDoubleMoney = vm.egress.iva / venta;
                        vm.egress.subtotalDoubleMoney = vm.egress.subtotal / venta;
                    }
                }
            }
        }

        function save() {
            Modal.showLoadingBar();
            var currentTime = new Date(moment(new Date()).format("YYYY-MM-DD") + "T" + moment(new Date()).format("HH:mm:ss") + "-06:00").getTime();
            var expirationTime = new Date(vm.egress.expirationDate).getTime();
            if (vm.egress.currency != vm.companyConfig.currency) {
                vm.egress.doubleMoney = 1;
            } else {
                vm.egress.doubleMoney = 0;
            }
            if (currentTime <= expirationTime) {
                vm.egress.state = 1;
            } else {
                vm.egress.state = 3;
            }
            if (vm.egress.paymentDate != null || vm.egress.paymentDate == 'undefined') {
                vm.egress.state = 2;
            }
            if (vm.egress.account != undefined) {
                vm.egress.account = vm.egress.account.id;
            }
            vm.egress.exchangeRate = vm.tipoCambio.venta;
            vm.isSaving = true;
            if (vm.egress.id != null) {
                Egress.update(vm.egress, onSaveReport, onSaveError);
            } else {
                vm.egress.companyId = globalCompany.getId();
                vm.egress.paymentMethod = 0;
                vm.egress.account = 0;
                vm.egress.deleted = 0;
                if (!vm.hasIva) {
                    vm.egress.subtotal = 0;
                    vm.egress.iva = 0;
                }
                Egress.save(vm.egress, onSaveSuccess, onSaveError);
            }
        }


        function onSaveReport(result) {
            Modal.hideLoadingBar();
            $scope.$emit('aditumApp:egressUpdate', result);
            $state.go('bills-to-pay');
            Modal.toast("Se reportó el pago correctamente");
            vm.isSaving = false;

        }

        function onSaveSuccess(result) {
            if (vm.admingConfig.egressFolio == true) {
                increaseFolioNumber(function (admin) {
                    vm.admingConfig = admin;
                    vm.egressfolioSerie = admin.egressfolioSerie;
                    vm.egressfolioSerie = admin.egressfolioSerie;
                    Modal.hideLoadingBar();
                    $scope.$emit('aditumApp:egressUpdate', result);
                    $state.go('bills-to-pay');
                    Modal.toast("Se registró el gasto correctamente");
                    vm.isSaving = false;
                })
            } else {
                Modal.hideLoadingBar();
                $scope.$emit('aditumApp:egressUpdate', result);
                Modal.hideLoadingBar();
                Modal.toast("Se registró el gasto correctamente");
                $state.go('bills-to-pay');

                vm.isSaving = false;
            }

        }

        function onSaveError() {
            Modal.hideLoadingBar();
            Modal.toast("Un error inesperado ocurrió");
            vm.isSaving = false;
        }

        vm.updatePicker = function () {
            vm.picker1 = {
                datepickerOptions: {
                    maxDate: vm.egress.expirationDate == undefined ? new Date() : vm.egress.expirationDate,
                    enableTime: false,
                    showWeeks: false,
                }
            };
            vm.picker2 = {
                datepickerOptions: {
                    minDate: vm.egress.date,
                    enableTime: false,
                    showWeeks: false,
                }
            }
        }
        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.paymentDate = false;
        vm.datePickerOpenStatus.expirationDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
