(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PenaltyFeeConfigurationDialogController', PenaltyFeeConfigurationDialogController);

    PenaltyFeeConfigurationDialogController.$inject = ['Modal','globalCompany', '$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'PenaltyFeeConfiguration', 'Company'];

    function PenaltyFeeConfigurationDialogController(Modal, globalCompany, $timeout, $scope, $stateParams, $uibModalInstance, $q, entity, PenaltyFeeConfiguration, Company) {
        var vm = this;

        vm.penaltyFeeConfiguration = entity;

        vm.clear = clear;
        vm.save = save;

        function cStoN(s){var n=Number(s);return isNaN(n)?null:n;}

        function booleanToString(booleanValue) {
            return booleanValue ? 'true' : 'false';
        }
        PenaltyFeeConfiguration.getByCompany({companyId: globalCompany.getId()}, function (result) {
            vm.penaltyFeeConfiguration = result;
            vm.penaltyFeeConfiguration.interestRate = cStoN(vm.penaltyFeeConfiguration.interestRate);
            vm.penaltyFeeConfiguration.maximunDaysInterests = cStoN(vm.penaltyFeeConfiguration.maximunDaysInterests);
            vm.penaltyFeeConfiguration.maximunDays = cStoN(vm.penaltyFeeConfiguration.maximunDays);
            vm.penaltyFeeConfiguration.feeRatePercentage = cStoN(vm.penaltyFeeConfiguration.feeRatePercentage);
            vm.penaltyFeeConfiguration.applyFeeInTotal = booleanToString(vm.penaltyFeeConfiguration.applyFeeInTotal);
        })


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function stringToBoolean(stringValue) {
            return stringValue.toLowerCase() === 'true';
        }
        function save() {
            Modal.confirmDialog("¿Está seguro que desea guardar la configuración?","",function(){
                vm.isSaving = true;
                vm.penaltyFeeConfiguration.applyFeeInTotal = stringToBoolean(vm.penaltyFeeConfiguration.applyFeeInTotal);
                vm.penaltyFeeConfiguration.companyId = globalCompany.getId();
                if (vm.penaltyFeeConfiguration.id !== null) {
                    PenaltyFeeConfiguration.update(vm.penaltyFeeConfiguration, onSaveSuccess, onSaveError);
                } else {
                    PenaltyFeeConfiguration.save(vm.penaltyFeeConfiguration, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:penaltyFeeConfigurationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
