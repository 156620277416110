(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingAccountController', AccountingAccountController);

    AccountingAccountController.$inject = ['CommonMethods', 'Modal', 'BankAccount', '$rootScope', 'globalCompany', '$state', 'AccountingAccount', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function AccountingAccountController(CommonMethods, Modal, BankAccount, $rootScope, globalCompany, $state, AccountingAccount, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        $rootScope.active = "accounting-account";
        vm.accountingAccountsCategories = [];
        vm.loadingAccounts = false;

        loadAllAccounts();


        vm.zeroModifabilityAccountsList = ['Excedente de periodo actual', 'Perdidas y Ganancias', 'Utilidad / Perdida Acumulada', 'SUPERÁVIT/DÉFICIT','Gastos bancarios','Comisiones Bancarias'];

        vm.hasZeroModifiability = function (detailAccount) {
            for (var i = 0; i < vm.zeroModifabilityAccountsList.length; i++) {
                if (detailAccount.description != null) {
                    if (detailAccount.description.toUpperCase().includes(vm.zeroModifabilityAccountsList[i].toUpperCase())) {
                        return true;
                    }
                }
            }
            return false;
        }

        vm.createAccountingAccount = function (type) {
            $state.go("accounting-account.newAccount", {type: type})
        }


        vm.editBank = function (bankAccount) {
            var id = CommonMethods.encryptIdUrl(bankAccount.id);
            $state.go("accounting-account.edit-bank", {id: id});
        }

        vm.editAccount = function (account) {
            var id = CommonMethods.encryptIdUrl(account.id);
            var type;
            if (account.mayorAccount) {
                type = "mayor";
            } else if (account.subAccount) {
                type = "sub-account";
            } else {
                type = "detail-account"
            }
            $state.go("accounting-account.edit-account", {id: id, type: type});
        }

        vm.deleteAccount = function (account) {
            Modal.confirmDialog("¿Está seguro que desea eliminar la cuenta?", "Una vez eliminada ya no saldrá en los estados financieros", function () {
                AccountingAccount.delete({id: account.id}, function () {
                    loadAllAccounts();
                    Modal.toast("Cuenta eliminada correctamente")
                })
            })
        }


        function loadAllAccounts() {
            vm.accountingAccountsCategories = [];
            vm.loadingAccounts = true;
            AccountingAccount.findAllByCompanyIdOrderByCategory({
                companyId: globalCompany.getId()
            }, onSuccessAccounts, onErrorAccounts);

            function onSuccessAccounts(data, headers) {
                for (var i = 0; i < data.length; i++) {
                    data[i].modifiable = true;
                    for (var j = 0; j < data[i].accountingAccounts.length; j++) {
                        data[i].accountingAccounts[j].modifiable = true;
                    }
                    vm.accountingAccountsCategories.push(data[i]);
                }
                vm.loadingAccounts = false;
            }

            function onErrorAccounts(error) {
                Modal.toast("Ocurrio un error cargando las cuentas contables.")
            }
        }

    }
})();
