(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('AccountingAccount', AccountingAccount);

    AccountingAccount.$inject = ['$resource'];

    function AccountingAccount ($resource) {
        var resourceUrl =  'api/accounting-accounts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'findAllByCompanyIdOrderByCategory': { method: 'GET', isArray: true, url:'api/accounting-accounts-by-company-order-by-category-all/:companyId'},
            'findAllByCompanyId': { method: 'GET', isArray: true, url:'api/accounting-accounts-by-company-all/:companyId'},
            'findMayorsByCompanyId': { method: 'GET', isArray: true, url:'api/accounting-accounts-by-company-mayor/:companyId'},
            'findSubAccountsByCompanyId': { method: 'GET', isArray: true, url:'api/accounting-accounts-by-company-sub-account/:companyId'},
            'findDetailAccountsByCompanyId': { method: 'GET', isArray: true, url:'api/accounting-accounts-by-company-detail-account/:companyId'},
            'findEgressAccountsByCompanyId': { method: 'GET', isArray: true, url:'api/accounting-accounts-by-company-egress-accounts/:companyId'},
            'createStandardsAccounts': { method: 'GET', isArray: false, url:'api/accounting-accounts-create-standards/:firstCondo/:lastCondo'},
            'createStandardsAccountsCorrect': { method: 'GET', isArray: false, url:'api/accounting-accounts-create-standard-for-company/:companyId'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
