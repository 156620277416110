(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingAccountDialogController', AccountingAccountDialogController);

    AccountingAccountDialogController.$inject = ['CommonMethods', 'Modal', 'globalCompany', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccountingAccount', 'AccountingAccountsCategories'];

    function AccountingAccountDialogController(CommonMethods, Modal, globalCompany, $timeout, $scope, $stateParams, $uibModalInstance, entity, AccountingAccount, AccountingAccountsCategories) {
        var vm = this;

        vm.accountingAccount = entity;
        vm.clear = clear;
        vm.save = save;
        vm.accountType = $stateParams.type;

        vm.searchTerm = '';

        vm.zeroModifabilityAccountsList = ['Excedente de periodo actual', 'Perdidas y Ganancias', 'Utilidad / Perdida Acumulada', 'SUPERÁVIT/DÉFICIT','Gastos Bancarios','Comisiones Bancarias'];

        vm.hasZeroModifiability = function (detailAccount) {
            for (var i = 0; i < vm.zeroModifabilityAccountsList.length; i++) {
                if (detailAccount.description != null) {
                    if (detailAccount.description.toUpperCase().includes(vm.zeroModifabilityAccountsList[i].toUpperCase())) {
                        return true;
                    }
                }
            }
            return false;
        }

        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }
        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());

        vm.defineAccountBehavior = function () {
            for (var i = 0; i < vm.accountingAccountsTypes.length; i++) {
                if (vm.accountingAccountsTypes[i].accountType == vm.accountingAccount.mayorAccountType) {
                    vm.accountingAccount.mayorAccountBehavior = vm.accountingAccountsTypes[i].behavior
                }
            }
        }


        if (!vm.accountingAccount.id) {
            vm.accountingAccount.showInBudget = false;
            vm.accountingAccount.isModifiable = true;
        }
        vm.accountingAccountsTypes = AccountingAccountsCategories.getCategories();
        vm.defineAccountBehavior()
        switch ($stateParams.type) {
            case "mayor":
                vm.accountTitle = "Cuenta mayor";
                vm.accountingAccount.mayorAccount = true;
                vm.accountingAccount.subAccount = false;
                break;
            case "sub-account":
                vm.accountTitle = "Subcuenta";
                vm.accountingAccount.mayorAccount = false;
                vm.accountingAccount.subAccount = true;
                loadMayorsAccounts();
                break;
            case "detail-account":
                vm.accountTitle = "Cuenta de detalle";
                vm.accountingAccount.mayorAccount = false;
                vm.accountingAccount.subAccount = false;
                loadSubAccountsAccounts();
                loadDetailAccount();
                break;
            default:
                $state.go("accounting-account")
                break;
        }

        vm.selectMayorAccount = function (accountId) {
            for (var i = 0; i < vm.mayorAccountingAccounts.length; i++) {
                if (accountId == vm.mayorAccountingAccounts[i].id) {
                    vm.selectedParentAccount = vm.mayorAccountingAccounts[i];
                    vm.accountingAccount.mayorAccountBehavior = vm.selectedParentAccount.mayorAccountBehavior;
                    vm.accountingAccount.mayorAccountType = vm.selectedParentAccount.mayorAccountType;
                    vm.fullAccountNumber = vm.selectedParentAccount.fullAccountNumber + "-";
                }
            }
        }

        vm.defineMayorAccountTypeName = function (mayorAccount) {
            var accountingAccountsEnum = AccountingAccountsCategories.accountingAccountsTypes;
            for (var key in accountingAccountsEnum) {
                if (accountingAccountsEnum.hasOwnProperty(key)) {
                    if (mayorAccount.mayorAccountType === accountingAccountsEnum[key].type) {
                        return accountingAccountsEnum[key].name;
                    }
                }
            }
            return null;
        };

        vm.showInBudgetOption = function () {
            var selectedAccount = vm.selectedParentAccount;
            if (selectedAccount === undefined) {
                selectedAccount = vm.accountingAccount;
            }
            if (selectedAccount && (
                selectedAccount.mayorAccountType === AccountingAccountsCategories.accountingAccountsTypes.EXPENSES.type ||
                selectedAccount.mayorAccountType === AccountingAccountsCategories.accountingAccountsTypes.INCOME.type
            )) {
                return true;
            }
            return false;
        };


        function loadMayorsAccounts() {
            AccountingAccount.findMayorsByCompanyId({
                companyId: globalCompany.getId()
            }, onSuccessAccounts, onErrorAccounts);

            function onSuccessAccounts(data, headers) {
                for (var i = 0; i < data.length; i++) {
                    data[i].mayorAccountTypeName = vm.defineMayorAccountTypeName(data[i]);
                }
                vm.mayorAccountingAccounts = data;
                if (vm.accountingAccount.id && vm.accountingAccount.type != "mayor") {
                    vm.selectMayorAccount(vm.accountingAccount.accountingAccountId);
                }
                vm.defineAccountBehavior()
            }

            function onErrorAccounts(error) {
                Modal.toast("Ocurrio un error cargando las cuentas contables.")
            }
        }


        function loadDetailAccount() {
            AccountingAccount.findDetailAccountsByCompanyId({
                companyId: globalCompany.getId()
            }, onSuccessAccounts, onErrorAccounts);

            function onSuccessAccounts(data, headers) {
                vm.detailsAccount = data;
            }

            function onErrorAccounts(error) {
                Modal.toast("Ocurrio un error cargando las cuentas contables.")
            }
        }

        function loadSubAccountsAccounts() {
            AccountingAccount.findSubAccountsByCompanyId({
                companyId: globalCompany.getId()
            }, onSuccessAccounts, onErrorAccounts);

            function onSuccessAccounts(data, headers) {
                for (var i = 0; i < data.length; i++) {
                    data[i].mayorAccountTypeName = vm.defineMayorAccountTypeName(data[i]);
                }
                vm.mayorAccountingAccounts = data;
                if (vm.accountingAccount.id && vm.accountingAccount.type != "mayor") {
                    vm.selectMayorAccount(vm.accountingAccount.accountingAccountId);
                }
                vm.defineAccountBehavior()
            }

            function onErrorAccounts(error) {
                Modal.toast("Ocurrio un error cargando las cuentas contables.")
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            Modal.confirmDialog("¿Está seguro que quiere guardar los cambios?", "", function () {
                vm.isSaving = true;
                vm.accountingAccount.deleted = false;
                vm.accountingAccount.companyId = globalCompany.getId();
                if (vm.accountingAccount.id !== null) {
                    AccountingAccount.update(vm.accountingAccount, onSaveSuccess, onSaveError);
                } else {
                    AccountingAccount.save(vm.accountingAccount, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:accountingAccountUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
