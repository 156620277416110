(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('RoundsController', RoundsController);

    RoundsController.$inject = ['$scope', 'Modal', '$rootScope', 'firebase', 'Rounds', '$state', 'CommonMethods', 'globalCompany'];

    function RoundsController($scope, Modal, $rootScope, firebase, Rounds, $state, CommonMethods, globalCompany) {

        var vm = this;
        vm.isReady = false;
        $rootScope.active = "rounds";
        $rootScope.mainTitle = 'Rondas de oficiales';
        vm.filter = true;

        vm.rounds = [];
        vm.isReady = false;
        var initial_time = new Date();
        initial_time.setHours(0, 0, 0, 0);
        var final_time = new Date();
        final_time.setHours(23, 59, 0, 0);

        vm.dates = {
            initial_time: initial_time,
            final_time: final_time
        };

        function calculateLate(startingTime, finishTime) {
            var ms = moment(startingTime).diff(moment(finishTime));
            var d = moment.duration(ms);
            var minutesDifference = d.asMinutes();
            return minutesDifference > 40;
        }

        function calculateDifBetweenHours(startingTime, finishTime) {
            var ms = moment(startingTime).diff(moment(finishTime));
            var d = moment.duration(ms);
            var s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
            return s;
        }

        vm.loadAll = function () {
            vm.isReady = false;
            vm.rounds = [];
            Rounds.getAllByCompanyAndDates({
                companyId: globalCompany.getId(),
                initialDate: vm.dates.initial_time,
                finalDate: vm.dates.final_time
            }, function (data) {
                for (var i = 0; i < data.length; i++) {
                    var round = data[i];
                    round.isLate = calculateLate(round.startingTime, round.executionDate);
                    round.lateTime = calculateDifBetweenHours(round.startingTime, round.executionDate);
                    if (vm.filter) {
                        vm.rounds.push(round);
                    } else {
                        if (round.isLate && round.inProgress != true) {
                            vm.rounds.push(round);
                        }
                    }
                }
                vm.isReady = true;
            })
        };
        vm.loadAll();
        vm.viewDetail = function (round) {
            var encryptedId = CommonMethods.encryptIdUrl(round.uid)
            $state.go('round-detail', {
                id: encryptedId
            })
        }

        vm.tableToExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)))
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                })
                            }
                        var workSheetName = "Rondas de - " + $rootScope.company.name + " - del " + moment(vm.dates.initial_time).format("L") + " al " + moment(vm.dates.final_time).format("L");
                        if (!table.nodeType) table = document.getElementById(table)
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx))
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1)
                    vm.notExportingExcel = true;
                }, 500)
            })
        }
    }
})();
