(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('VisitorAuthorizationRequestController', VisitorAuthorizationRequestController);

    VisitorAuthorizationRequestController.$inject = ['Modal', '$scope', '$timeout', 'WSVisitorAuthorizationRequest', '$rootScope', 'globalCompany', 'VisitorAuthorizationRequest', 'ParseLinks', 'AlertService', 'paginationConstants'];

    function VisitorAuthorizationRequestController(Modal, $scope, $timeout, WSVisitorAuthorizationRequest, $rootScope, globalCompany, VisitorAuthorizationRequest, ParseLinks, AlertService, paginationConstants) {

        var vm = this;

        $rootScope.active = "visitor-authorization-request";
        vm.counter = 45;
        var timer;

        vm.findAuthorization = function () {
            VisitorAuthorizationRequest.findNew({houseId: globalCompany.getHouseId()}, function (data) {
                vm.setAuthorization(data);
            })
        }

        $timeout(function () {
            WSVisitorAuthorizationRequest.subscribe(globalCompany.getHouseId())
            $timeout(function () {
                WSVisitorAuthorizationRequest.receive(globalCompany.getHouseId()).then(null, null, receiveAuthorization);
            }, 500)
        }, 2000)

        function receiveAuthorization(data) {
            $timeout(function () {
                $scope.$apply(function () {
                    vm.setAuthorization(data);
                })
            })
        }

        vm.acceptAuthorization = function () {
            Modal.confirmDialog("¿Está seguro que desea autorizar el ingreso a su filial?", "Autorizará a los oficiales en caseta a dejar ingresar al visitante.", function () {
                vm.visitorAuthorization.status = 1;
                vm.visitorAuthorization.residentId = $rootScope.companyUser.id;
                WSVisitorAuthorizationRequest.sendActivityResponse(vm.visitorAuthorization, globalCompany.getHouseId());
                vm.showVisitorAuthorization = false;
                reset();
                loadAll();
                Modal.toast("Ingreso autorizado.")
            })
        }
        vm.declineAuthorization = function () {
            Modal.confirmDialog("¿Está seguro que desea NO autorizar el ingreso de esta persona su filial?", "Los oficiales en caseta NO permitirán el ingreso del visitante.", function () {
                vm.visitorAuthorization.status = 2;
                vm.visitorAuthorization.residentId = $rootScope.companyUser.id
                WSVisitorAuthorizationRequest.sendActivityResponse(vm.visitorAuthorization, globalCompany.getHouseId());
                vm.showVisitorAuthorization = false;
                reset();
                loadAll();
                Modal.toast("Ingreso NO autorizado.")
            })

        }

        vm.setAuthorization = function (data) {
            vm.visitorAuthorization = data;
            vm.counter = vm.visitorAuthorization.secondsAgo;
            if (vm.counter <= 0) {
                vm.cancelCountDown()
            } else {
                vm.countDown();
                vm.showVisitorAuthorization = true;
            }
        }


        vm.countDown = function () {
            timer = $timeout(function () {
                --vm.counter;
                if (vm.counter > -1) {
                    vm.countDown()
                } else {
                    vm.cancelCountDown()
                }
            }, 1000)
        }

        vm.cancelCountDown = function () {
            $timeout.cancel(timer);
            if (vm.showVisitorAuthorization) {
                vm.counter = 45;
                vm.showVisitorAuthorization = false;
                vm.visitorAuthorization.status = 3;
                VisitorAuthorizationRequest.update(vm.visitorAuthorization, function () {
                    reset();
                    loadAll();
                });
            } else {
                reset();
                loadAll();
            }
        }

        vm.findAuthorization();

        vm.visitorAuthorizationRequests = [];
        vm.loadPage = loadPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        $rootScope.mainTitle = "Autorizaciones"
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;

        loadAll();


        function onEnter () {
            if (document.visibilityState == "visible" && vm.counter==45) {
                $timeout.cancel(timer);
                $timeout(function(){
                    vm.findAuthorization()
                },100)
            }
        }
        document.addEventListener("visibilitychange", onEnter);

        $scope.$on('$destroy', function () {
            document.removeEventListener("visibilitychange",onEnter)
        });

        function loadAll() {
            VisitorAuthorizationRequest.getByHouse({
                page: vm.page,
                size: vm.itemsPerPage,
                houseId: globalCompany.getHouseId(),
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    if(!verifyIfInArray(data[i])){
                        vm.visitorAuthorizationRequests.push(data[i]);
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function verifyIfInArray(data){
            for (var i = 0; i < vm.visitorAuthorizationRequests.length; i++) {
                if(vm.visitorAuthorizationRequests[i].id == data.id){
                    return true;
                }
            }
            return false;
        }

        function reset() {
            vm.page = 0;
            vm.visitorAuthorizationRequests = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
    }
})();
