(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetItemController', BudgetItemController);

    BudgetItemController.$inject = ['BudgetItem'];

    function BudgetItemController(BudgetItem) {

        var vm = this;

        vm.budgetItems = [];

        loadAll();

        function loadAll() {
            BudgetItem.query(function(result) {
                vm.budgetItems = result;
                vm.searchQuery = null;
            });
        }
    }
})();
