(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BalanceSheetController', BalanceSheetController);

    BalanceSheetController.$inject = ['globalCompany', '$rootScope', '$state', 'BalanceSheet', 'ParseLinks', 'AlertService', '$scope', 'BalanceSheetPdf'];

    function BalanceSheetController(globalCompany, $rootScope, $state, BalanceSheet, ParseLinks, AlertService, $scope, BalanceSheetPdf) {

        var vm = this;
        $rootScope.mainTitle = 'Balance de posición financiera';
        $rootScope.active = 'balanceSheet';
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, m + 1, 0);

        vm.notExportingExcel = true;
        vm.companyId = globalCompany.getId();

        vm.tableToExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)))
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                })
                            }
                        var workSheetName = "Balance de posición financiera";
                        if (!table.nodeType) table = document.getElementById(table)
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx))
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1)
                    vm.notExportingExcel = true;
                }, 500)
            })
        }

        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };

        vm.changeFormat = function () {
            vm.format = 'MMMM';
            vm.hideDate = false;
        };


        vm.format = 'L';
        moment.locale("es");

        vm.locale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(vm.format) : '';
            }
        };

        vm.createMonths = function () {
            vm.isReady = false;
            vm.dates.initial_time = new Date(vm.dates.initial_time.getFullYear(), vm.dates.initial_time.getMonth(), 1);
            vm.dates.final_time = new Date(vm.dates.final_time.getFullYear(), vm.dates.final_time.getMonth() + 1, 0);
            vm.fileInitialDate = moment(vm.dates.initial_time).format()
            vm.fileFinalDate = moment(vm.dates.final_time).format()
            vm.changeFormat();
            vm.loadAll()
        }

        vm.defineColSpan = function (account) {
            return (account.categoryAccount || account.subAccount || account.mayorAccount) ? 4 : 1;
        }

        vm.showTd = function (account) {
            return !(account.categoryAccount || account.subAccount || account.mayorAccount);
        }

        vm.defineRowClass = function (account) {
            if (account.categoryAccount || account.totalCategoryAccount) {
                return "background-lightgray";
            }
            if (account.mayorAccount || account.totalMayorAccount) {
                return "background-lightgray-lighter";
            }
        }

        vm.defineBoldClass = function (account) {
            if (account.categoryAccount || account.totalCategoryAccount || account.mayorAccount || account.subAccount || account.totalSubAccount || account.totalMayorAccount) {
                return "bold-font";
            }
        }

        function loadAll() {
            vm.isReady = false;
            BalanceSheet.getByCompanyIdAndBetweenDates({
                companyId: globalCompany.getId(),
                initialTime: moment(vm.dates.initial_time).format(),
                finalTime: moment(vm.dates.final_time).format(),
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.balanceSheet = data;
                vm.isReady = true;
                vm.notFound = !vm.balanceSheet.currentPeriod;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.exportPdf = function () {
            BalanceSheetPdf.getByCompanyIdAndBetweenDates({
                companyId: globalCompany.getId(),
                initialTime: moment(vm.dates.initial_time).format(),
                finalTime: moment(vm.dates.final_time).format(),
            }, function (data) {
                console.log('PDF generado exitosamente');
            }, function (error) {
                console.error('Error al generar el PDF', error);
            });
        };

        vm.loadAll = loadAll;
        vm.createMonths();

    }
})();
