(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ResidentAllController', ResidentAllController);

    ResidentAllController.$inject = ['$timeout', '$localStorage', '$scope', '$state', 'DataUtils', 'Resident', 'User', 'CommonMethods', 'House', 'Principal', 'Company', 'MultiCompany', '$rootScope', 'WSResident', 'WSDeleteEntity', 'Modal', 'globalCompany', '$mdDialog'];

    function ResidentAllController($timeout, $localStorage, $scope, $state, DataUtils, Resident, User, CommonMethods, House, Principal, Company, MultiCompany, $rootScope, WSResident, WSDeleteEntity, Modal, globalCompany, $mdDialog) {

        var vm = this;

        $rootScope.active = "resident-all";
        $rootScope.mainTitle = !$rootScope.company.fullControlAccess?'Residentes':'Usuarios';
        vm.loading = false;
        vm.searchHouse = '';
        vm.owners = [];
        vm.tenants = [];
        vm.residents = [];
        vm.data = [];
        vm.residentsFilter = [];
        vm.houseSelected = -1;
        vm.loaded = false;
        vm.hideFilterHouse = false;
        vm.downloading = false;
        vm.clearSearchTerm = function () {
            $scope.searchHouse = '';
        };
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }

        vm.urlToDownload = function () {
            var encryptedId = CommonMethods.encryptSDownload(globalCompany.getId());
            const encodedCompanyId = encodeURIComponent(encryptedId);
            return "/api/residents/download-excel/"+encodedCompanyId;
        }

        vm.download = function () {
            vm.downloading = true;
            $timeout(function () {
                $scope.$apply(function () {
                    vm.downloading = false;
                })
            }, 1000)
        }

        vm.houseSelected = $localStorage.houseSelected;

        vm.selectHouse = function (house) {
            vm.houseSelected = house;
            vm.owners = [];
            vm.tenants = [];
            vm.residents = [];
            vm.data = [];
            vm.residentsFilter = [];
            vm.query = "";
            $localStorage.houseSelected = vm.houseSelected;
            vm.loaded = false;
            if (house != -1 && house!=undefined) {
                vm.loading = true;
                Resident.getResidentsByHouse({houseId: vm.houseSelected.id}, function (data) {
                    vm.owners = formatResidentList("ROLE_OWNER", data);
                    vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data));
                    vm.tenants = formatResidentList("ROLE_TENANT", data);
                    vm.residents = formatResidentList("ROLE_RESIDENT", data);
                    vm.loading = false;
                    vm.data = data;
                    vm.loaded = true;
                })
            }else{
                $timeout(function () {
                    $scope.$apply(function () {
                        vm.houseSelected = -1;
                    })
                }, 10)
            }
        }


        if ($localStorage.houseSelected) {
            if ($state.includes('houseAdministration.residentsByHouse')) {
                vm.hideFilterHouse = true;
                $rootScope.active = "houseAdministration";
                $rootScope.mainTitle = "Contabilidad filiales";
                vm.selectHouse($localStorage.houseSelected)
            } else {
                loadHouses();
            }
        } else {
            vm.houseSelected = -1;
            loadHouses();
        }

        $scope.$watch(function () {
            return $rootScope.houseSelected;
        }, function () {
            if ($state.includes('houseAdministration.residentsByHouse')) {
                vm.selectHouse($localStorage.houseSelected)
            }
        });


        function defineSelectedHouse(houseId,houses){
            for (var i = 0; i < houses.length; i++) {
                if(houses[i].id==houseId){
                    return houses[i];
                }
            }
        }

        function loadHouses() {
            if ($localStorage.houses) {
                vm.houses = $localStorage.houses;
                onSuccessHouses(vm.houses, null)
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                }, onSuccessHouses);
            }

            function onSuccessHouses(data, headers) {
                vm.houses = data;
                if ($localStorage.houseSelected) {
                    $timeout(function () {
                        $scope.$apply(function () {
                            vm.houseSelected = defineSelectedHouse($localStorage.houseSelected.id,vm.houses);
                            vm.selectHouse(vm.houseSelected)
                        })
                    }, 100)
                }
            }
        }

        function formatResidentList(role, data) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == vm.houseSelected.id) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role)
                    r = vm.defineRoleUser(r);
                    list.push(r)
                }
            }
            return list;
        }

        function formatResidentFilterList(data) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == vm.houseSelected.id) {
                        r.role = h.role;
                    }
                }
                r.roleDescription = defineRole(r.role)
                r = vm.defineRoleUser(r);
                list.push(r)
            }
            return list;
        }

        function defineRole(role) {
            switch (role) {
                case "ROLE_OWNER":
                    return "Residente propietario";
                case "ROLE_RENTER":
                    return "Propietario arrendador";
                case "ROLE_TENANT":
                    return "Inquilino";
                case "ROLE_RESIDENT":
                    return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }


        vm.filterResidents = function () {
            vm.owners = [];
            vm.tenants = [];
            vm.residents = [];
            vm.data = [];
            vm.residentsFilter = [];
            vm.loading = true;
            vm.loaded = false;
            Resident.getResidentsByFilter({
                companyId: globalCompany.getId(),
                filter: vm.query
            }, function (data) {
                vm.loading = false;
                vm.data = data;
                vm.loaded = true;
                vm.residentsFilter = formatResidentFilterList(data);
            })
        }


        vm.resendInvitation = function (resident) {
            Modal.confirmDialog("¿Está seguro que desea reenviar la invitación de bienvenida?", "", function () {
                Resident.resendInvitation({userId: resident.userId})
                Modal.toast("Invitación reenviada.")
            })
        }

        vm.formatMobileDevices = function (resident) {
            Modal.confirmDialog("¿Está seguro que desea formatear el acceso de los dispositivos móviles de este usuario?", "" +
                "Esto borrará los dispositivos móviles del usuario actuales y le permitirá acceder nuevamente", function () {
                Resident.formatMobileDevices({userId: resident.userId})
                Modal.toast("Dispositivos móviles formateados correctamente.")
            })
        }


        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                    case "ROLE_OWNER":
                        resident.houses[i].role = "Residente propietario";
                        break;
                    case "ROLE_RENTER":
                        resident.houses[i].role = "Propietario arrendador";
                        break;
                    case "ROLE_TENANT":
                        resident.houses[i].role = "Inquilino";
                        break;
                    case "ROLE_RESIDENT":
                        resident.houses[i].role = "Residente autorizado";
                        break;
                }
            }
            return resident;
        }

        function findHouse(houseId) {
            for (var i = 0; i < vm.houses.length; i++) {
                if (vm.houses[i].id == houseId) {
                    return vm.houses[i];
                }
            }
        }

        vm.setPrincipalContact = function (resident) {
            var houseId = vm.houseSelected.id;
            Modal.confirmDialog("¿Está seguro que desea que " + resident.name + " sea el nuevo contacto principal de la filial?", "", function () {
                Modal.showLoadingBar();
                Resident.setPrincipalContact({houseId: vm.houseSelected.id, residentId: resident.id},
                    function (data) {
                        Modal.toast("Se ha establecido a " + resident.name + " como contacto principal correctamente");
                        House.getAllHousesClean({
                            companyId: globalCompany.getId()
                        }, function (data) {
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    vm.houses = data;
                                    $localStorage.houses = vm.houses;
                                    vm.houseSelected = findHouse(houseId);
                                    vm.selectHouse(vm.houseSelected);
                                    Modal.hideLoadingBar();
                                })
                            }, 10)
                        });
                    })
            })
        }

        vm.detailResident = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $state.go('owner-detail', {
                id: encryptedId
            })
        };
        vm.resetPassword = function (resident) {
            Modal.confirmDialog("¿Está seguro que desea establecer una contraseña temporal a este usuario?", "Se le establecerá al usuario una contraseña temporal , recuerde recomendar al usuario cambiarla una vez ingrese al sistema.",
                function () {
                    Modal.showLoadingBar();
                    Resident.resetPassword({
                        id: resident.id
                    }, function (result) {
                        Modal.toast("Se ha establecido la contraseña a " + result.name + " correctamente.");
                        Modal.customDialog("<md-dialog>" +
                            "<md-dialog-content class='md-dialog-content text-center'>" +
                            "<h1 class='md-title'>Contraseña Temporal: <b>" + result.name + "</b></h1>" +
                            "<div class='md-dialog-content-body'>" +
                            "<p>Por favor no cierre esta ventana hasta que haya anotado la contraseña y recuerde al usuario cambiar la contraseña una vez ingrese al sistema.</p>" +
                            "</div>" +
                            "</md-dialog-content>" +
                            "</md-dialog>")
                        Modal.hideLoadingBar();
                    });
                });
        };
        vm.editResident = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $state.go('owner.edit', {
                id: encryptedId
            })
        };

        vm.deleteResident = function (resident) {
            vm.residentToDelete = resident;
            Modal.confirmDialog("¿Está seguro que desea eliminar al usuario " + resident.name + "?", "Una vez eliminado no podrá recuperar los datos",
                function () {
                    Modal.showLoadingBar();
                    vm.login = resident.userLogin;
                    Resident.delete({
                        id: resident.id
                    }, function () {
                        if (vm.login !== null) {
                            User.getUserById({
                                id: resident.userId
                            }, function (data) {
                                data.activated = 0;
                                data.login = data.login + Math.floor(Math.random() * 1000000000);
                                data.email = data.email + Math.floor(Math.random() * 1000000000);
                                User.update(data, onSuccessDisabledUser);

                                function onSuccessDisabledUser(data, headers) {
                                    Modal.toast("Se ha eliminado el usuario correctamente.");
                                    Modal.hideLoadingBar();
                                    vm.selectHouse(vm.houseSelected);
                                }
                            });
                        } else {
                            Modal.toast("Se ha eliminado el usuario correctamente.");
                            vm.selectHouse(vm.houseSelected);
                            Modal.hideLoadingBar();
                            WSDeleteEntity.sendActivity({type: 'resident', id: vm.residentToDelete.id})
                        }
                    });
                });
        };
    }
})();
