(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('LoadAutomaticVisitorController', LoadAutomaticVisitorController);

    LoadAutomaticVisitorController.$inject = ['$mdDialog', '$scope', '$state', 'House', 'globalCompany', '$rootScope', 'DataUtils', 'Modal', '$timeout', 'VisitantInvitation', 'InvitationSchedule', 'Company', '$http'];

    function LoadAutomaticVisitorController($mdDialog, $scope, $state, House, globalCompany, $rootScope, DataUtils, Modal, $timeout, VisitantInvitation, InvitationSchedule, Company, $http) {
        $rootScope.active = "load-automatic-visitor";
        var vm = this;
        var file;
        vm.fileName;
        vm.visitors = []; // Store visitors from the Excel file
        vm.visitorsFormatted = [];
        vm.totalVisitors = 0; // Track total number of visitors
        vm.isReady = 0;
        vm.validationErrorMessage = ""; // Variable para el mensaje de error
        vm.showTable = true;

        // Handle company change and fetch houses based on the companyId
        vm.changeCompany = function () {
            House.query({companyId: vm.companyId}).$promise.then(onSuccessHouses);
        };

        // Fetch companies
        Company.query({page: 0, size: 300}, onSuccess);

        function onSuccess(data) {
            vm.companies = data;
        }

        function onSuccessHouses(data) {
            vm.houses = data;
        }

        // Upload file handler
        // Upload file handler
        vm.uploadFile = function ($file) {
            if ($file && $file.$error === 'pattern') return;
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.displayImage = base64Data;
                        vm.displayImageType = $file.type;
                    });
                });
                file = $file;
                vm.fileName = file.name;

                // Restablecer la visibilidad de la tabla antes de procesar el nuevo archivo
                vm.showTable = true;

                // Parsear el archivo Excel
                vm.parseExcel();
            }
        };

        // Parse the uploaded Excel file
        vm.parseExcel = function () {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {type: 'binary'});

                // Get first sheet and extract data
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                vm.visitors = XLSX.utils.sheet_to_json(worksheet, {raw: false});

                // Format visitors for the table
                formatVisitants(vm.visitors);
            };
            reader.readAsBinaryString(file);
            vm.isReady = 1;
        };

        function formatVisitants(visitors) {
            vm.visitorsFormatted = visitors.map(function(visitor, index) {
                var visitorFormatted = {
                    fila: index + 1,
                    Invitado: visitor.Invitado,
                    Cedula: visitor.Cedula,
                    Tipo: visitor.Tipo,
                    Dias: visitor.Dias,
                    Filial: visitor.Filial,
                    fechaInicio: visitor.fechaInicio,
                    Inicio: visitor.Inicio,
                    fechaFin: visitor.fechaFin,
                    Fin: visitor.Fin,
                    valid: true,
                    validationError: ""
                };

                // Validaciones
                if (visitorFormatted.Tipo.toUpperCase() === 'PERMANENTE') {
                    if (!visitorFormatted.Filial) {
                        visitorFormatted.valid = false;
                        visitorFormatted.validationError = "Filial es obligatorio para visitantes permanentes.";
                    }
                } else if (visitorFormatted.Tipo.toUpperCase() === 'TEMPORAL') {
                    if (!visitorFormatted.Inicio || !visitorFormatted.Fin || !visitorFormatted.fechaInicio || !visitorFormatted.fechaFin) {
                        visitorFormatted.valid = false;
                        visitorFormatted.validationError = "Fecha y Hora de Inicio y Fin son obligatorios para visitantes temporales.";
                    }
                } else if (visitorFormatted.Tipo.toUpperCase() === 'EMPLEADO') {
                    if (!visitorFormatted.Dias || !visitorFormatted.Inicio || !visitorFormatted.Fin) {
                        visitorFormatted.valid = false;
                        visitorFormatted.validationError = "Días, Inicio y Fin son obligatorios para empleados.";
                    }
                }

                return visitorFormatted;
            });


            // Actualizar el número total de visitantes
            vm.totalVisitors = vm.visitorsFormatted.length;

            // Verificar si existen errores de validación
            // Verificar si hay errores de validación
            vm.hasValidationErrors = vm.visitorsFormatted.some(function(visitor) {
                return !visitor.valid;
            });

            // Recolectar números de fila con errores
            var errorRows = vm.visitorsFormatted
                .filter(function(visitor) {
                    return !visitor.valid;
                })
                .map(function(visitor) {
                    return visitor.fila;
                });


            // Crear un mensaje con las filas con errores
            if (errorRows.length > 0) {
                vm.validationErrorMessage = "Hay errores de validación en los datos. Filas: " + errorRows.join(", ");
            } else {
                vm.validationErrorMessage = "";
            }

            // Actualizar la interfaz para mostrar los datos
            $timeout(function () {
                vm.isReady = 2; // Listo para mostrar la tabla
            }, 10);
        }

        // Function to save visitors after confirmation
        vm.saveVisitors = function () {
            if (vm.hasValidationErrors) {
                Modal.toast(vm.validationErrorMessage); // Mostrar el mensaje de error si hay errores
                return; // Salir de la función si hay errores
            }

            Modal.confirmDialog("¿Está seguro que desea registrar los visitantes?", "", function () {
                Modal.showLoadingBar();
                // Recorrer los visitantes y enviarlos en el formato adecuado
                var visitantsData = vm.visitorsFormatted.map(function(visitor) {
                    return {
                        invitado: visitor.Invitado || '',  // Asignar '' si no hay valor
                        cedula: visitor.Cedula ? parseInt(visitor.Cedula) || null : null,
                        tipo: visitor.Tipo || '',
                        dias: Array.isArray(visitor.Dias) ? visitor.Dias : (visitor.Dias === 'n/a' ? [] : [visitor.Dias]),
                        fechaInicio: visitor.fechaInicio || '',
                        inicio: visitor.Inicio || '',
                        fechaFin: visitor.fechaFin || '',
                        fin: visitor.Fin || '',
                        company: vm.companyId || '',
                        filial: visitor.Filial || ''
                    };
                });
                var visitantExcel = {visitantExcelList:visitantsData};
                VisitantInvitation.saveExcelList(visitantsData, function (response) {
                    Modal.hideLoadingBar();
                    Modal.toast("Se han ingresado los visitantes correctamente.");
                    vm.showTable = false; // Ocultar la tabla después de guardar
                }, function (error) {
                    Modal.hideLoadingBar();
                    Modal.toast("Error al registrar los visitantes: " + error.message);
                });
            });
        };

        // Recursive function to create visitors
        function createVisitor(visitor, count, length) {
            if (count < length) {
                VisitantInvitation.save(visitor, function (result) {
                    count++;
                    if (count == length) {
                        Modal.hideLoadingBar();
                        Modal.toast("Se han ingresado los visitantes correctamente.");
                    } else {
                        createVisitor(vm.visitorsFormatted[count], count, length);
                    }
                });
            }
        }
    }
})();
