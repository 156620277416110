(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accounting-full-period', {
                parent: 'entity',
                url: '/accounting-full-period',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.accountingFullPeriod.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-periods.html',
                        controller: 'AccountingFullPeriodController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountingFullPeriod');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accounting-full-period-detail', {
                parent: 'accounting-full-period',
                url: '/accounting-full-period/{id}',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.accountingFullPeriod.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-period-detail.html',
                        controller: 'AccountingFullPeriodDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountingFullPeriod');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AccountingFullPeriod', function ($stateParams, AccountingFullPeriod) {
                        return AccountingFullPeriod.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'accounting-full-period',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('accounting-full-period-detail.edit', {
                parent: 'accounting-full-period-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-period-dialog.html',
                        controller: 'AccountingFullPeriodDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['AccountingFullPeriod', function (AccountingFullPeriod) {
                                return AccountingFullPeriod.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('accounting-full-period.new', {
                parent: 'accounting-full-period',
                url: '/new',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-period-dialog.html',
                        controller: 'AccountingFullPeriodDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    status: null,
                                    openDate: null,
                                    closeDate: null,
                                    responsible: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('accounting-full-period', null, {reload: 'accounting-full-period'});
                    }, function () {
                        $state.go('accounting-full-period', {reload: false});
                    });
                }]
            })
            .state('accounting-full-period.close', {
                parent: 'accounting-full-period',
                url: '/close/{lastPeriodId}',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-period-close.html',
                        controller: 'AccountingFullPeriodCloseController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    status: null,
                                    openDate: null,
                                    closeDate: null,
                                    responsible: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('accounting-full-period', null, {reload: 'accounting-full-period'});
                    }, function () {
                        $state.go('accounting-full-period');
                    });
                }]
            })
            .state('accounting-full-period.edit', {
                parent: 'accounting-full-period',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-period-dialog.html',
                        controller: 'AccountingFullPeriodDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['AccountingFullPeriod', function (AccountingFullPeriod) {
                                return AccountingFullPeriod.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('accounting-full-period', null, {reload: 'accounting-full-period'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('accounting-full-period.delete', {
                parent: 'accounting-full-period',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-full-period/accounting-full-period-delete-dialog.html',
                        controller: 'AccountingFullPeriodDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['AccountingFullPeriod', function (AccountingFullPeriod) {
                                return AccountingFullPeriod.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('accounting-full-period', null, {reload: 'accounting-full-period'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
