(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('RegulationSearchTabsController', RegulationSearchTabsController);

    RegulationSearchTabsController.$inject = [];

    function RegulationSearchTabsController() {

    }
})();
