(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EgressGenerateReportController', EgressGenerateReportController);

    EgressGenerateReportController.$inject = ['Company', '$scope', '$state', 'Banco', 'Expense', 'ParseLinks', 'AlertService', 'paginationConstants', 'CommonMethods', 'Proveedor', '$rootScope', 'globalCompany'];

    function EgressGenerateReportController(Company, $scope, $state, Banco, Expense, ParseLinks, AlertService, paginationConstants, CommonMethods, Proveedor, $rootScope, globalCompany) {
        $rootScope.active = "reporteGastos";
        var vm = this;
        vm.accountsToPay = false;
        vm.propertyName = 'id';
        $rootScope.mainTitle = "Reporte de egresos";
        vm.isReady = true;
        vm.isReady2 = true;
        vm.reverse = true;
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());

        vm.gastosQuantity = 0;
        vm.showNoResults = false;
        vm.hideReportForm = false;
        vm.loadingReport = false;
        vm.selectedProveedores = [];
        vm.selectedCampos = [];
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false,
        };
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.tableToExcel = function (table) {
            vm.exportingExcel = true;
            setTimeout(function () {
                var uri = 'data:application/vnd.ms-excel;base64,'
                    ,
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)))
                    }
                    , format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        })
                    }
                var workSheetName = $rootScope.companyName + " - REPORTE DE EGRESOS - del " + moment(vm.initial_time).format("L") + " al " + moment(vm.final_time).format("L");
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                var a = document.createElement('a');
                a.href = uri + base64(format(template, ctx))
                a.download = workSheetName + '.xls';
                //triggering the function
                a.click();
                vm.exportingExcel = false;
            }, 1)
        }
        vm.download = function () {
            vm.exportActions.downloading = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.downloading = false;
                })
            }, 7000)
        };

        vm.print = function () {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                })
            }, 7000);
            printJS({
                printable: vm.path,
                type: 'pdf',
                modalMessage: "Obteniendo reporte de egresos"
            })
        };

        vm.formatearNumero = function (nStr) {
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? ',' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        };
        vm.detailEgress = function (id, pay) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $state.go('egress-detail', {
                id: encryptedId,
                pay: pay
            })
        }
        vm.generateReport = function () {
            Expense.generateReport({
                initial_time: moment(vm.dates.initial_time).format(),
                final_time: moment(vm.dates.final_time).format(),
                companyId: globalCompany.getId(),
            }).$promise.then(onSuccess);

            vm.isReady2 = false;
            vm.loadingReport = true;

            function onSuccess(data) {
                vm.report = data;
                vm.superObject = moment(vm.dates.initial_time).format() + '}' + moment(vm.dates.final_time).format() + '}' + globalCompany.getId();
                vm.path = '/api/egresses/file/' + vm.superObject;
                vm.isReady2 = true;
                vm.hideReportForm = true;
                vm.loadingReport = false;
                vm.showNoResults = vm.report.expenses.length===0;
            }
        };
    }
})();
