(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EgressDetailController', EgressDetailController);

    EgressDetailController.$inject = ['Expense', 'AdministrationConfiguration', 'ExchangeRateBccr', 'AditumStorageService', 'CommonMethods', '$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'Egress', 'Company', 'Proveedor', 'Banco', 'Principal', 'Modal', 'globalCompany'];

    function EgressDetailController(Expense, AdministrationConfiguration, ExchangeRateBccr, AditumStorageService, CommonMethods, $scope, $state, $rootScope, $stateParams, previousState, entity, Egress, Company, Proveedor, Banco, Principal, Modal, globalCompany) {
        var vm = this;
        $rootScope.active = "newEgress";
        $rootScope.mainTitle = "Detalle de gasto";
        vm.isReady = true;
        vm.save = save;
        vm.makingPayment = false;
        if($stateParams.pay=='pay'){
            vm.makingPayment = true;
        }
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.companies = Company.query();
        vm.showOriginalCurrency = true;
        vm.comission = false;
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.secondCurrency = vm.companyConfig.secondCurrency;
        vm.principalCurrency = vm.companyConfig.currency;

        vm.deleteExpense = function(id){
            var idEncrypted = CommonMethods.encryptIdUrl(id);
            $state.go('egress-detail.deleteExpense',{expenseId: idEncrypted});

            // Modal.confirmDialog("¿Está seguro que desea anular el egreso?","La fecha del asiento de anulación será el día de hoy",function(){
            //     Expense.delete({id:id},function(){
            //         Egress.get({id: vm.egress.id}, function (data) {
            //             vm.egress = data;
            //             Modal.toast("Se anuló el egreso correctamente");
            //             Modal.hideLoadingBar();
            //             vm.leftToPay = vm.egress.leftToPay;
            //             vm.makingPayment = false;
            //             vm.defineCurrencyCommissionInExpenses();
            //         })
            //     })
            // })
        }
        function loadBancos() {
            Banco.query({
                companyId: globalCompany.getId()
            }, function onSuccess(data, headers) {
                vm.bancos = data;
                vm.defineCurrencyCommissionInExpenses()
            });
        }
       vm.defineCurrencyCommissionInExpenses = function(){
            for (var i = 0; i < vm.egress.expenses.length; i++) {
                vm.egress.expenses[i].currencyComission = vm.defineCurrencyCommission(vm.egress.expenses[i]);
            }
        }
        vm.defineCurrencyCommission = function(expense){
            for (var i = 0; i < vm.bancos.length; i++) {
                if(expense.bankAccountId == vm.bancos[i].accountingAccount.id){
                    return vm.bancos[i].accountingAccount.currency;
                }
            }
        }
        loadBancos();
        vm.showCurrencyColones = function () {
            if (vm.egress.currency == "₡") {
                return true;
            } else {
                return false;
            }
        }

        function loadAdminConfig() {
            vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
            if (vm.egress.currency == vm.companyConfig.currency) {
                vm.showOriginalCurrency = true;
            } else {
                vm.showOriginalCurrency = false;
            }
        }

        vm.egress = entity;
        vm.expense = {
            id: null, // Long
            concept: vm.egress.concept, // String
            amount: null, // BigDecimal
            expenseDate: null, // ZonedDateTime
            deleted: false, // Boolean
            billNumber: vm.egress.billNumber, // String
            paymentProofUrl: '', // String
            comments: '', // String
            folioNumber: vm.egress.folio, // String
            paymentMethod: '', // String
            egressId: vm.egress.id, // Long
            companyId: vm.egress.companyId, // Long
            expenseAccountId: vm.egress.expenseAccountId, // Long
            proveedorId: parseInt(vm.egress.proveedor), // Long
            bankAccountId: null ,
            commission : 0// Long
        };
        vm.leftToPay = vm.egress.leftToPay;
        loadAdminConfig();

        if (vm.egress.state == 1) {
            vm.egress.paymentMethod = "Transferencia";
        }
        if (vm.egress.state == 3) {
            vm.egress.ammountDoubleMoney = 0;
            vm.egress.ivaDoubleMoney = 0;
            vm.egress.subtotalDoubleMoney = 0;
            vm.egress.account = {currency: ""}
        }
        vm.previousState = previousState.name;
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.fileNameStart = vm.egress.fileName;
        vm.today = moment(new Date()).format();
        vm.bccrUse = true;

        vm.notMoreThanLeftToPay = function () {
            if (vm.expense.amount > vm.egress.leftToPay) {
                vm.expense.amount = vm.egress.leftToPay;
            }
            vm.leftToPay = parseFloat(vm.egress.leftToPay) - parseFloat(vm.expense.amount);
            vm.calculateTotal()
        }
        vm.formatCurrencyToPay = function () {
            var venta = vm.tipoCambio.venta;
            vm.venta = venta;
            vm.expense.exchangeRate = venta;
            if (vm.egress.currency != "$") {
                vm.expense.ammountDoubleMoney = vm.expense.amount / venta;
            } else {
                vm.expense.ammountDoubleMoney = vm.expense.amount * venta;
            }
        }

        vm.loadDateExchangeRate = function (date) {
            if(vm.bccrUse) {
                ExchangeRateBccr.get({
                    fechaInicio: moment(date).format(),
                    fechaFinal: moment(date).format(),
                }, function (result) {
                    vm.tipoCambio = result;
                    vm.calculateTotal();
                })
            }
        }

        vm.calculateTotal = function () {
            vm.formatCurrencyToPay()
        }

        ExchangeRateBccr.get({
            fechaInicio: moment(new Date()).format(),
            fechaFinal: moment(new Date()).format(),
        }, function (result) {
            vm.tipoCambio = result;
            vm.calculateTotal()
        })
        var file = null;
        if (vm.egress.subtotal == 0) {
            vm.hasIva = false;
        } else {
            vm.hasIva = true;
        }
        vm.datePickerOpenStatus = {};
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });
        vm.openCalendar = openCalendar;
        var unsubscribe = $rootScope.$on('aditumApp:egressUpdate', function (event, result) {
            vm.egress = result;
        });
        $scope.$on('$destroy', unsubscribe);
        if (vm.egress.folio == null || vm.egress.folio == 'undefined') {
            vm.egress.folio = 'Sin Registrar'
            vm.comission = true;
        }

        if (vm.egress.paymentDate == null || vm.egress.paymentDate == undefined || vm.egress.paymentDate == 'No pagado') {
            vm.egress.paymentDate = "No pagado";
        } else {
            Banco.get({id: vm.egress.account}, onSuccessAccount)
        }



        function save() {
            Modal.showLoadingBar();
            vm.expense.bankAccountId = vm.expense.bank.accountingAccount.id;
            vm.expense.amountSecondaryCurrency = vm.expense.ammountDoubleMoney;
            vm.expense.exchangeRate = vm.tipoCambio.venta;
            vm.expense.currency = vm.expense.bank.accountingAccount.currency;
            Expense.save(vm.expense, onSaveSuccess, onSaveError);
        }

        vm.calculateWithIVA = function (subtotal) {
            vm.formatCurrencyToPay();
            vm.egress.iva = subtotal * 0.13;
            vm.egress.total = vm.egress.iva + subtotal + "";
        }
        vm.saveExchangeRate = function () {
            vm.egress.account.exchangeRateDate = moment(new Date()).format()
            Banco.update(vm.egress.account, function () {
                Modal.toast("Monto de tipo de cambio actualizado.")
            }, function () {
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.confirmReportPayment = function () {
            Modal.confirmDialog("¿Está seguro que desea reportar el abono a esta cuenta por pagar?", "Una vez registrada esta información no se podrá editar",
                function () {
                    if (vm.fileName) {
                        vm.isSaving = true;
                        upload();
                    } else {
                        save();
                    }
                });
        }

        function upload() {
            var today = new Date();
            moment.locale("es");
            vm.direction = globalCompany.getId() + '/expenses/' + moment(today).format("YYYY") + '/' + moment(today).format("MMMM") + '/' + vm.expense.proveedorId + '/';
            var uploadTask = AditumStorageService.ref().child(vm.direction + file.name).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    vm.expense.paymentProofUrl = downloadURL;
                    vm.expense.fileName = vm.fileName;
                    save();
                });
            });
        }

        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
            }
        };

        function onSaveSuccess(result) {
            Egress.get({id: vm.egress.id}, function (data) {
                vm.egress = data;
                Modal.toast("Se reportó el egreso correctamente");
                Modal.hideLoadingBar();
                vm.makingPayment = false;
                vm.leftToPay = vm.egress.leftToPay;
                vm.expense = {
                    id: null, // Long
                    concept: vm.egress.concept, // String
                    amount: null, // BigDecimal
                    expenseDate: null, // ZonedDateTime
                    deleted: false, // Boolean
                    billNumber: vm.egress.billNumber, // String
                    paymentProofUrl: '', // String
                    comments: '', // String
                    folioNumber: vm.egress.folio, // String
                    paymentMethod: '', // String
                    egressId: vm.egress.id, // Long
                    companyId: vm.egress.companyId, // Long
                    expenseAccountId: vm.egress.expenseAccountId, // Long
                    proveedorId: parseInt(vm.egress.proveedor), // Long
                    bankAccountId: null ,
                    commission : 0// Long
                };
                vm.defineCurrencyCommissionInExpenses();
            })
        }

        function onAccountBalanceSuccess(result) {
            Modal.toast("Se reportó el pago correctamente");
            Modal.hideLoadingBar();
            vm.isSaving = false;
        }


        function onSuccessAccount(account, headers) {
            vm.egress.banco = account.beneficiario;
            vm.banco = account;
        }

        vm.datePickerOpenStatus.paymentDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }


})();
