(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('AccountingFullPeriod', AccountingFullPeriod);

    AccountingFullPeriod.$inject = ['$resource', 'DateUtils'];

    function AccountingFullPeriod($resource, DateUtils) {
        var resourceUrl = 'api/accounting-full-periods/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'findByCompany': {method: 'GET', isArray: true, url: "api/accounting-full-periods-by-company/:companyId"},
            'closePeriod': {
                method: 'GET',
                url: "api/accounting-full-periods-close-period/:periodId/:newPeriodName/:newPeriodOpenDate/:newPeriodCloseDate"
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.openDate = DateUtils.convertDateTimeFromServer(data.openDate);
                        data.closeDate = DateUtils.convertDateTimeFromServer(data.closeDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
