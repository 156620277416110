(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountStatusController', AccountStatusController);

    AccountStatusController.$inject = ['Modal','Resident','$rootScope', '$scope', '$state', 'AccountStatus', 'House', 'CommonMethods', '$localStorage','$uibModalInstance'];

    function AccountStatusController(Modal,Resident,$rootScope, $scope, $state, AccountStatus, House, CommonMethods, $localStorage,$uibModalInstance) {

        var vm = this;
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m - 2, 1);
        var lastDay = new Date(y+1, m , 0);
        vm.searchType = 1;
        vm.isReady = false;
        vm.expading = false;
        vm.openCalendar = openCalendar;
        vm.house = $localStorage.houseSelected;
        vm.loadAll = loadAll;
        vm.datePickerOpenStatus = {};
        vm.exportingExcel = false;
        vm.inModal = false;
        if($state.current.name=='generatePayment.account-status'){
            vm.inModal = true;
        }

        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false,
        }
        vm.download = function () {
            vm.exportActions.downloading = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.downloading = false;
                })
            }, 7000)
        };

        vm.close = function () {
            $uibModalInstance.close();
        }

        vm.tableToExcel = function (table) {
            vm.exportingExcel = true;
            setTimeout(function () {
                var uri = 'data:application/vnd.ms-excel;base64,'
                    ,
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)))
                    }
                    , format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        })
                    }
                var workSheetName = "Estado de cuenta Filial - "+vm.house.housenumber;
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                var a = document.createElement('a');
                a.href = uri + base64(format(template, ctx))
                a.download = workSheetName + '.xls';
                //triggering the function
                a.click();
                vm.exportingExcel = false;
            }, 1)
        }


        vm.print = function () {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                })
            }, 7000);
            printJS({
                printable: vm.path,
                type: 'pdf',
                modalMessage: "Obteniendo Estado de cuenta"
            })
        };
        loadAll();
        vm.findBootstrapEnvironment = function () {
            var envs = ['xs', 'sm', 'md', 'lg'];

            var $el = $('<div>');
            $el.appendTo($('body'));

            for (var i = envs.length - 1; i >= 0; i--) {
                var env = envs[i];

                $el.addClass('hidden-' + env);
                if ($el.is(':hidden')) {
                    $el.remove();
                    return env;
                }
            }
        }

        vm.isScreenSizeSmall = function () {
            var envs = ['xs', 'sm', 'md'];
            var e = 0;
            for (var i = 0; i < envs.length; i++) {
                if (envs[i] === vm.findBootstrapEnvironment()) {
                    e++;
                }
            }
            if (e > 0) {
                return true;
            }
            return false;
        }
        vm.expand = function () {

            setTimeout(function () {
                $scope.$apply(function () {
                    vm.expanding = !vm.expanding;
                });
            }, 200);

        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.datePassed = function (cuota) {
            var rightNow = new Date();
            var chargeDate = new Date(moment(cuota.date))
            return ((chargeDate.getTime() > rightNow.getTime()))
        }

        $scope.$watch(function () {
            return $rootScope.houseSelected;
        }, function () {
            loadAll();
            vm.house = $rootScope.houseSelected;
            vm.isEditing = false;
        });


        vm.showDetail = function (item) {
            item.showDetail = !item.showDetail;
        }
        vm.searchByType = function (type) {
            switch (type) {
                case 1:
                    vm.searchType = 1;
                    break;
                case 2:
                    vm.searchType = 2;
                    break;
                case 3:
                    vm.searchType = 3;
                    break;
                case 4:
                    vm.searchType = 4;
                    break;

            }

        }

        vm.consult = function () {
            $("#loading2").fadeIn(0);
            $("#accountStatusContainer").fadeOut(0);
            loadAll();
        }

        function loadAll() {
            AccountStatus.query({
                houseId: CommonMethods.encryptS($localStorage.houseSelected.id),
                initial_time: moment(vm.dates.initial_time).format(),
                final_time: moment(vm.dates.final_time).format(),
                resident_account: false,
                today_time: moment(new Date()).format()
            }, onSuccess, onError);


            function onSuccess(data) {
                vm.superObject = $localStorage.houseSelected.id +'}'+moment(vm.dates.initial_time).format()+'}'+moment(vm.dates.final_time).format()+'}'+false+'}'+moment(new Date()).format();
                vm.path = '/api/accountStatus/file/' + vm.superObject+'/'+1;
                vm.initial_time = vm.dates.initial_time;
                vm.final_time = vm.dates.final_time;
                var countPassedDate = 0;
                angular.forEach(data.listaAccountStatusItems, function (item, i) {
                    var rightNow = new Date();
                    var chargeDate = new Date(moment(item.date))
                    if (chargeDate.getTime() > rightNow.getTime()) {
                        item.datePassed = true;
                        if (countPassedDate == 0) {
                            item.definedFirstDatePassed = true;
                            countPassedDate++;
                        }
                    }
                });
                vm.accountStatusItems = data;
                vm.isReady = true;
            }

            vm.formatearNumero = function (nStr) {

                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? ',' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                return x1 + x2;
            }

            vm.updatePicker = function () {
                vm.picker1 = {
                    datepickerOptions: {
                        maxDate: vm.dates.final_time,
                        enableTime: false,
                        showWeeks: false,
                    }
                };
                vm.picker2 = {
                    datepickerOptions: {
                        minDate: vm.dates.initial_time,
                        enableTime: false,
                        showWeeks: false,
                    }
                }
            };




            vm.datePickerOpenStatus.initialtime = false;
            vm.datePickerOpenStatus.finaltime = false;

            function onError(error) {
                // AlertService.error(error.data.message);
            }
        }

    }
})();
