(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountFinancialClosingDetailController', AccountFinancialClosingDetailController);

    AccountFinancialClosingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccountFinancialClosing', 'Company', 'AccountingPeriod', 'AccountingAccount'];

    function AccountFinancialClosingDetailController($scope, $rootScope, $stateParams, previousState, entity, AccountFinancialClosing, Company, AccountingPeriod, AccountingAccount) {
        var vm = this;

        vm.accountFinancialClosing = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:accountFinancialClosingUpdate', function(event, result) {
            vm.accountFinancialClosing = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
