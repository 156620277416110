(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('CompanyDialogController', CompanyDialogController);

        CompanyDialogController.$inject = ['SaveImageCloudinary', 'DataUtils', 'AdminInfo', 'House', '$state', 'CompanyConfiguration', '$timeout', '$scope', '$stateParams', 'entity', 'Company', 'AdministrationConfiguration', 'EgressCategory', 'Banco', 'Modal'];

        function CompanyDialogController(SaveImageCloudinary, DataUtils, AdminInfo, House, $state, CompanyConfiguration, $timeout, $scope, $stateParams, entity, Company, AdministrationConfiguration, EgressCategory, Banco, Modal) {
            var vm = this;
            vm.required = 1;
            vm.clear = clear;
            vm.save = save;
            var fileImageCondo = null;
            var fileImageAdmin = null;

            Company.query(function(data){
                vm.companies = data;
                var companies = [];
                for (var i = 0; i < vm.companies.length; i++) {
                    if (vm.companies[i].companyId == vm.company.id) {
                        companies.push(vm.companies[i])
                    }
                }
                vm.company.companies = companies;
                console.log(vm.company);
            });


            vm.clearSearchTerm = function () {
                vm.searchTerm = '';
            };
            vm.searchTerm;
            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }
            vm.options = {
                height: 150,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['fontname', ['fontname']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link']],
                    ['view', ['fullscreen']],
                ]
            };

            function saveEmailConfig() {
                vm.isSaving = true;
                if (vm.emailConfiguration.id !== null) {
                    EmailConfiguration.update(vm.emailConfiguration, onSaveSuccess, onSaveError);
                } else {
                    EmailConfiguration.save(vm.emailConfiguration, onSaveSuccess, onSaveError);
                }
            }

            if (entity.logoUrl == undefined) {
                entity.logoUrl = null;
            }
            if (entity.adminLogoUrl == undefined) {
                entity.adminLogoUrl = null;
            }
            vm.company = entity;
            if (vm.company.id == null) {
                vm.company.emailConfiguration = {
                    email: null,
                    password: null,
                    customEmail: false,
                    emailCompany: null,
                    adminCompanyName: null,
                    id: null
                };
            }



            vm.imageCondoSet = vm.company.logoUrl != null;
            vm.imageAdminSet = vm.company.adminLogoUrl != null;

            vm.isSaving = false;

            vm.setImageCondo = function ($file) {
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                if ($file) {
                    DataUtils.toBase64($file, function (base64Data) {
                        $scope.$apply(function () {
                            vm.displayImageCondo = base64Data;
                            vm.displayImageTypeCondo = $file.type;
                            vm.imageCondoChanged = true;
                        });
                    });
                    fileImageCondo = $file;
                }
            };
            vm.setImageAdmin = function ($file) {
                if ($file && $file.$error === 'pattern') {
                    return;
                }
                if ($file) {
                    DataUtils.toBase64($file, function (base64Data) {
                        $scope.$apply(function () {
                            vm.displayImageAdmin = base64Data;
                            vm.displayImageTypeAdmin = $file.type;
                            vm.imageAdminChanged = true;
                        });
                    });
                    fileImageAdmin = $file;
                }
            };
            var egressCategories = [{
                id: null,
                group: 'Gastos fijos',
                category: 'Administración',
                companyId: null
            }, {id: null, group: 'Gastos fijos', category: 'Agua potable', companyId: null}, {
                id: null,
                group: 'Gastos fijos',
                category: 'Energía eléctrica',
                companyId: null
            }, {id: null, group: 'Gastos fijos', category: 'Seguridad', companyId: null}, {
                id: null,
                group: 'Gastos fijos',
                category: 'Jardinería',
                companyId: null
            }, {id: null, group: 'Gastos fijos', category: 'Sueldos y salarios', companyId: null}, {
                id: null,
                group: 'Gastos fijos',
                category: 'Mantenimiento áreas comunes',
                companyId: null
            }
                , {id: null, group: 'Gastos variables', category: 'Eventos', companyId: null}, {
                    id: null,
                    group: 'Gastos variables',
                    category: 'Gastos legales',
                    companyId: null
                }, {id: null, group: 'Gastos variables', category: 'Impuestos y comisiones', companyId: null}, {
                    id: null,
                    group: 'Gastos variables',
                    category: 'Papelería y copias',
                    companyId: null
                }, {id: null, group: 'Otros gastos', category: 'Devolución de dinero', companyId: null}
                , {id: null, group: 'Otros gastos', category: 'Comisiones Bancarias', companyId: null}];
            $timeout(function () {
                angular.element('.form-group:eq(1)>input').focus();
            });
            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            var firstDay = new Date(y, m, 1);
            loadQuantities();

            function loadQuantities() {
                House.query({companyId: vm.company.id}, onSuccess, onError);
            }

            function onSuccess(data) {
                vm.houseQuantity = data.length;
                if (vm.company.id != undefined) {
                    getAdmins();
                }
            }

            function getAdmins() {
                AdminInfo.getAdminsByCompanyId({
                    companyId: vm.company.id
                }, onSuccess);

                function onSuccess(data) {
                    vm.adminsQuantity = data.length;
                }
            }

            function clear() {
                $state.go("company");
            }

            getConfiguration();

            function getConfiguration() {
                CompanyConfiguration.getByCompanyId({companyId: vm.company.id}).$promise.then(onSuccessCompany, onError);
            }

            function onSuccessCompany(data) {
                vm.companyConfiguration = {};
                angular.forEach(data, function (configuration, key) {
                    vm.companyConfiguration = configuration;
                    if (vm.companyConfiguration.hasAditumGate == 1) {
                        vm.companyConfiguration.hasAditumGate = true;
                    } else {
                        vm.companyConfiguration.hasAditumGate = false;
                    }
                    if (vm.companyConfiguration.hasQrCodeGate == 1) {
                        vm.companyConfiguration.hasQrCodeGate = true;
                    } else {
                        vm.companyConfiguration.hasQrCodeGate = false;
                    }
                    if (vm.companyConfiguration.allowBtnOpenDoor == 1) {
                        vm.companyConfiguration.allowBtnOpenDoor = true;
                    } else {
                        vm.companyConfiguration.allowBtnOpenDoor = false;
                    }
                });

            }

            function onError() {

            }

            function save() {
                vm.isSaving = true;
                Modal.showLoadingBar();
                console.log(vm.company);
                console.log("AAA");
                if (vm.imageAdminChanged === true || vm.imageCondoChanged === true) {
                    vm.imageUser = {user: vm.company.name};
                    if (vm.imageCondoChanged === true) {
                        SaveImageCloudinary
                            .save(fileImageCondo, vm.imageUser)
                            .then(onSaveImageCondoSuccessSave, onSaveError, onNotify);
                    }
                    if (vm.imageAdminChanged === true) {
                        SaveImageCloudinary
                            .save(fileImageAdmin, vm.imageUser)
                            .then(onSaveImageAdminSuccessSave, onSaveError, onNotify);
                    }
                } else {
                    if (vm.company.id !== null) {
                        Company.update(vm.company, onUpdateSuccess, onSaveError);
                    } else {
                        Company.save(vm.company, onSaveCompanySuccess, onSaveError);
                    }
                }


                function onNotify(info) {
                    vm.progress = Math.round((info.loaded / info.total) * 100);
                }

                function onSaveImageAdminSuccessSave(data) {
                    vm.company.adminLogoUrl = "https://res.cloudinary.com/aditum/image/upload/v1501920877/" + data.imageUrl + ".jpg";
                    vm.imageAdminChanged = false;
                    if (vm.imageCondoChanged) {
                        SaveImageCloudinary
                            .save(fileImageAdmin, vm.imageUser)
                            .then(onSaveImageCondoSuccessSave, onSaveError, onNotify);
                    } else {
                        if (vm.company.id !== null) {
                            Company.update(vm.company, onUpdateSuccess, onSaveError);
                        } else {
                            Company.save(vm.company, onSaveCompanySuccess, onSaveError);
                        }
                    }
                }

                function onSaveImageCondoSuccessSave(data) {
                    vm.imageUser = {user: vm.company.name};
                    vm.company.logoUrl = "https://res.cloudinary.com/aditum/image/upload/v1501920877/" + data.imageUrl + ".jpg";
                    vm.imageCondoChanged = false;
                    if (vm.imageAdminChanged) {
                        SaveImageCloudinary
                            .save(fileImageAdmin, vm.imageUser)
                            .then(onSaveImageAdminSuccessSave, onSaveError, onNotify);
                    } else {
                        if (vm.company.id !== null) {
                            Company.update(vm.company, onUpdateSuccess, onSaveError);
                        } else {
                            Company.save(vm.company, onSaveCompanySuccess, onSaveError);
                        }
                    }
                }

                function onUpdateSuccess(result) {
                    if (vm.companyConfiguration.hasAditumGate) {
                        vm.companyConfiguration.hasAditumGate = 1;
                    } else {
                        vm.companyConfiguration.hasAditumGate = 0;
                    }
                    if (vm.companyConfiguration.hasQrCodeGate) {
                        vm.companyConfiguration.hasQrCodeGate = 1;
                    } else {
                        vm.companyConfiguration.hasQrCodeGate = 0;
                    }
                    if (vm.companyConfiguration.allowBtnOpenDoor) {
                        vm.companyConfiguration.allowBtnOpenDoor = 1;
                    } else {
                        vm.companyConfiguration.allowBtnOpenDoor = 0;
                    }
                    CompanyConfiguration.update(vm.companyConfiguration, onSaveSuccess, onSaveError);
                }

                function onSaveCompanySuccess(result) {
                    angular.forEach(egressCategories, function (value, key) {
                        value.companyId = result.id;
                        EgressCategory.save(value);
                    });
                    var date = moment(new Date(), 'DD/MM/YYYY').toDate()
                    var banco = {
                        id: null,
                        beneficiario: 'Caja chica',
                        cedula: null,
                        cuentaCorriente: 0,
                        cuentaCliente: 0,
                        moneda: null,
                        cuentaContable: null,
                        capitalInicial: 0,
                        mostrarFactura: null,
                        fechaCapitalInicial: date,
                        saldo: 0,
                        deleted: 1,
                        companyId: result.id,
                        currency: vm.companyConfiguration.currency
                    };
                    Banco.save(banco);
                    vm.companyConfiguration.companyId = result.id;
                    vm.companyConfiguration.minDate = firstDay;
                    if (vm.companyConfiguration.hasAditumGate) {
                        vm.companyConfiguration.hasAditumGate = 1;
                    } else {
                        vm.companyConfiguration.hasAditumGate = 0;
                    }
                    if (vm.companyConfiguration.hasQrCodeGate) {
                        vm.companyConfiguration.hasQrCodeGate = 1;
                    } else {
                        vm.companyConfiguration.hasQrCodeGate = 0;
                    }
                    if (vm.companyConfiguration.allowBtnOpenDoor) {
                        vm.companyConfiguration.allowBtnOpenDoor = 1;
                    } else {
                        vm.companyConfiguration.allowBtnOpenDoor = 0;
                    }
                    CompanyConfiguration.save(vm.companyConfiguration, onSaveSuccess, onSaveError);
                    var adminConfig = {
                        squareMetersPrice: 0,
                        companyId: result.id,
                        incomeFolio: true,
                        folioSerie: "A",
                        folioNumber: 1,
                        hasSubcharges: true,
                        daysTobeDefaulter: 15,
                        daysToSendEmailBeforeBeDefaulter: 5,
                        subchargeAmmount: 0,
                        subchargePercentage: 5,
                        usingSubchargePercentage: true,
                        bookCommonArea: true,
                        incomeStatement: true,
                        monthlyIncomeStatement: true,
                        egressReport: true,
                        egressFolio: true,
                        egressFolioSerie: 'E',
                        egressFolioNumber: 1,
                        initialConfiguration: 0,
                        waterPrice: 0,
                        residentsViewComments: 1,
                        chargesCreateCurrency: "₡",
                        chargesCollectCurrency: "₡",
                        defaulterMaintenanceBalance: 1,
                        defaulterPenaltyBalance: 1,
                        defaulterExtraordinaryBalance: 1,
                        defaulterCommonAreaBalance: 1,
                        defaulterWaterBalance: 1,
                        defaulterOtherBalance: 1,
                        maximumDevicesPerUser: 100
                    };
                    AdministrationConfiguration.save(adminConfig);
                }

                function onSaveSuccess(result) {
                    Modal.hideLoadingBar();
                    vm.isSaving = false;
                    $state.go("company");
                }

                function onSaveError() {
                    vm.isSaving = false;
                }


            }
        }
    }

)
();
