(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IssuingClientDetailController', IssuingClientDetailController);

    IssuingClientDetailController.$inject = ['FiscalActivityCode', '$state', 'CommonMethods', 'CustomChargeType', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'IssuingClient', 'globalCompany', 'Modal'];

    function IssuingClientDetailController(FiscalActivityCode, $state, CommonMethods, CustomChargeType, $scope, $rootScope, $stateParams, previousState, entity, IssuingClient, globalCompany, Modal) {
        var vm = this;

        vm.previousState = previousState.name;
        $rootScope.active = "feConfiguration";
        vm.save = save;
        vm.issuingClient = {};
        loadAll();
        vm.customChargeTypes = [];
        vm.errorGTI = false;
        vm.validActivityCode = false;
        vm.isReady = false;

        vm.verifyActivityCode = function (activityCode) {
            if (activityCode.length >= 5) {
                FiscalActivityCode.verifyData({activityCode: activityCode}, function (data) {
                    vm.validActivityCode = true;
                    vm.activityCode = data;
                }, function () {
                    vm.validActivityCode = false;
                })
            }
        }

        function loadAll() {
            CustomChargeType.getByCompany({companyId: globalCompany.getId()}, function (result) {
                for (var i = 0; i < result.length; i++) {
                    var cc = result[i];
                    cc.editing = false;
                    vm.customChargeTypes.push(cc);
                }
                vm.isReady = true;
            });
        }

        vm.editInvoiceConfiguration = function (cc) {
            var id = CommonMethods.encryptIdUrl(cc.id)
            $state.go("issuing-client-detail.edit-invoice-item", {id: id})
        }

        IssuingClient.getByCompanyId({companyId: globalCompany.getId()}, function (data) {
            vm.issuingClient = data;
            console.log(vm.issuingClient);
            vm.verifyActivityCode(vm.issuingClient.activityCode);
        })
        var unsubscribe = $rootScope.$on('aditumApp:issuingClientUpdate', function (event, result) {
            vm.issuingClient = result;
        });

        function save() {
            Modal.confirmDialog("¿Está seguro que desea guardar la configuración?", "", function () {
                var authenticationData = {
                    accountNumber: vm.issuingClient.accountNumber,
                    integrationClient: "GTI",
                    password: vm.issuingClient.password,
                    username: vm.issuingClient.username
                }
                IssuingClient.verifyData(authenticationData, function (data) {
                    vm.isSaving = true;
                    vm.errorGTI = false;
                    vm.issuingClient.companyId = globalCompany.getId();
                    vm.issuingClient.integrationClient = "GTI";
                    if (vm.issuingClient.id !== null) {
                        IssuingClient.update(vm.issuingClient, onSaveSuccess, onSaveError);
                    } else {
                        IssuingClient.save(vm.issuingClient, onSaveSuccess, onSaveError);
                    }
                }, function (error) {
                    vm.isSaving = false;
                    vm.errorGTI = true;
                    Modal.toast("Los datos de GTI no son válidos. Por favor verifique que los datos sean correctos.");
                })
            })
        }

        function onSaveSuccess(result) {
            Modal.toast("Guardado correctamente");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
