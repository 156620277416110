(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('IssuingClient', IssuingClient);

    IssuingClient.$inject = ['$resource'];

    function IssuingClient($resource) {
        var resourceUrl = 'api/issuing-clients/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'verifyData': {
                method: 'POST', url: 'api/fiscal-provider/ping-authentication'
            },
            'getFiscalDocuments': {
                method: 'GET', url: 'api/get-fiscal-documents/:initialDate/:finalDate/:companyId', isArray: true
            },
            'sendFiscalDocument': {
                method: 'GET',
                url: 'api/send-fiscal-document-bill/:transactionId/:houseId',
                transformResponse:
                    function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
            },
            'resendDocument': {
                method: 'GET',
                url: 'api/resend-email/:transactionId/:documentType/:clientId',
                transformResponse:
                    function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
            },
            'get':
                {
                    method: 'GET',
                    transformResponse:

                        function (data) {
                            if (data) {
                                data = angular.fromJson(data);
                            }
                            return data;
                        }
                }
            ,
            'getByCompanyId':
                {
                    method: 'GET',
                    url:
                        'api/issuing-clients-by-company-id/:companyId'
                }
            ,
            'update':
                {
                    method: 'PUT'
                }
        })
            ;
    }
})();
