(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('DelinquencyTableController', DelinquencyTableController);

    DelinquencyTableController.$inject = ['$localStorage', 'Modal', 'House', '$rootScope', '$state', 'Charge', 'globalCompany', 'Company', 'CommonMethods', 'AlertService', '$scope'];

    function DelinquencyTableController($localStorage, Modal, House, $rootScope, $state, Charge, globalCompany, Company, CommonMethods, AlertService, $scope) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.transition = transition;
        $rootScope.active = "delinquencyTable";
        vm.loadAll = loadAll;
        vm.final_time = new Date();
        vm.chargeType = 10;
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.house = -1;
        vm.exportingExcel = false;
        vm.showNormalReport = true;
        vm.detailResident = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('owner-detail', {
                id: encryptedId
            });
        };
        vm.exportActions = {
            downloading: false,
            printing: false,
        };
        vm.tableToExcel = function (table) {
            vm.exportingExcel = true;
            setTimeout(function () {
                var uri = 'data:application/vnd.ms-excel;base64,'
                    ,
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)));
                    }
                    , format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        });
                    };
                var workSheetName = vm.companyName + "- TABLA DE MORA POR DIAS - Previas al " + moment(vm.final_time).format("L");
                if (!table.nodeType) table = document.getElementById(table);
                var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML};
                var a = document.createElement('a');
                a.href = uri + base64(format(template, ctx));
                a.download = workSheetName + '.xls';
                //triggering the function
                a.click();
                vm.exportingExcel = false;
            }, 1);
        };
        vm.loadAll();
        vm.showYearDefaulter = function () {
            vm.loadDefaulters(vm.yearDefaulter);
        };
        vm.print = function () {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                });
            }, 7000);
            printJS({
                printable: vm.fileUrl,
                type: 'pdf',
                modalMessage: "Obteniendo reporte de cuotas por cobrar"
            });
        };
        vm.download = function () {
            vm.exportActions.downloading = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.downloading = false;
                });
            }, 7000);
        };
        vm.getCategory = function (type) {
            switch (type) {
            case 1:
                return "MANTENIMIENTO";
                break;
            case 2:
                return "EXTRAORDINARIA";
                break;
            case 3:
                return "ÁREAS COMUNES";
                break;
            case 5:
                return "MULTA";
                break;
            case 6:
                return "CUOTA AGUA";
                break;
            }
        };
        if ($localStorage.houses) {
            vm.houses = $localStorage.houses;
        } else {
            House.getAllHousesClean({
                companyId: globalCompany.getId()
            }, function (result) {
                vm.houses = result;
            });
        }

        vm.searchTerm;
        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };


        vm.resendEmail = function () {
            Modal.confirmDialog("¿Está seguro que desea reenviar las facturas nuevamente?", "Las facturas se reenviarán a todos los contactos principales de las filiales.", function () {
                Modal.toast("Se reenviaron las facturas nuevamente.");
                Charge.resendEmailChargesToPayReport({
                    final_time: vm.finalTimeFormatted,
                    companyId: vm.companyId,
                    type: vm.chargeType,
                    houseId: vm.house == -1 ? -1 : vm.house.id
                }, function () {
                }, function () {
                    Modal.toast("No se pudieron reenviar las facturas.");
                });
            });
        };

        function monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }


        function defineDuePosition(due) {
            var now = vm.final_time;
            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(0);
            var date = new Date(due.date);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            var monthDif = monthDiff(date, now);
            if (monthDif <= 3) {
                return monthDif;
            } else {
                return 4;
            }
        }

        function formatDuesHouse(dueHouse) {
            var arrayDues = [0, 0, 0, 0, 0, 0];
            var total = 0;
            for (var i = 0; i < dueHouse.dues.length; i++) {
                var due = dueHouse.dues[i];
                var pos = defineDuePosition(due);
                arrayDues[pos] = arrayDues[pos] + parseFloat(due.leftToPay);
                total = total + parseFloat(due.leftToPay);
            }
            arrayDues[5] = parseFloat(total);
            return arrayDues;
        }


        vm.toPdf = function () {

            vm.exporting = true;
            Modal.toast("Exportando..");
            setTimeout(function () {
                var element = document.getElementById('element-to-print');
                var opt = {
                    margin: 0.5,
                    filename: 'TABLA DE MORA POR DIAS' + vm.companyName + '.pdf',
                    jsPDF: {unit: 'in', format: 'letter', orientation: 'landscape'},
                    pagebreak: {avoid: '.makeBreak'}
                };
                html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();
                    //print current pdf width & height to console
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.setTextColor(150);
                        //divided by 2 to go center;
                        if (i != 1) {
                            pdf.text('TABLA DE MORA POR DIAS ' + vm.companyName.toUpperCase(), 0.6,
                                0.3);
                        }
                        pdf.text('Página ' + i + ' / ' + totalPages, pdf.internal.pageSize.getWidth() - 1,
                            pdf.internal.pageSize.getHeight() - 0.2);
                    }
                }).save();
                Modal.toast("Listo");
                vm.exporting = false;
            }, 4000);
        };

        vm.createTableMorosidad = function (report) {
            setTimeout(function () {
                $scope.$apply(function () {
                    for (var i = 0; i < report.dueHouses.length; i++) {
                        var house = report.dueHouses[i];
                        var houseFormated = {
                            number: house.houseDTO.housenumber,
                            dues: formatDuesHouse(house)
                        };
                        for (var j = 0; j < 6; j++) {
                            vm.morosidadTable.totalGeneral[j] = vm.morosidadTable.totalGeneral[j] + houseFormated.dues[j];
                        }
                        vm.morosidadTable.houses.push(houseFormated);
                    }
                });
            }, 10);
        };


        function loadAll() {
            vm.isReady = false;
            vm.finalTimeFormatted = moment(vm.final_time).format();
            vm.companyId = globalCompany.getId();
            vm.morosidadTable = {
                totalGeneral: [0, 0, 0, 0, 0, 0],
                houses: []
            };
            if (vm.chargeType == 10) {
                vm.filtering = false;
            } else {
                vm.filtering = true;
            }
            vm.chargeTypeSetted = vm.chargeType;
            var houseId = vm.house == -1 ? -1 : vm.house.id;
            vm.fileUrl = "api/charges/chargesToPay/file/" + vm.finalTimeFormatted + "/" + vm.chargeType + "/byCompany/" + vm.companyId + "/house/" + houseId;
            Charge.findChargesToPayReport({
                final_time: vm.finalTimeFormatted,
                companyId: vm.companyId,
                type: vm.chargeType,
                houseId: vm.house == -1 ? -1 : vm.house.id
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data) {
                vm.report = data;
                Company.get({id: globalCompany.getId()}).$promise.then(function (result) {
                    vm.isReady = true;
                    vm.companyName = result.name;
                });
                vm.createTableMorosidad(vm.report);
            }


            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
