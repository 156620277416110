(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('CreditNoteDialogController', CreditNoteDialogController);

    CreditNoteDialogController.$inject = ['Modal', 'CommonMethods', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CreditNote', 'Charge', 'House', 'Company', 'AccountingPeriod', 'globalCompany'];

    function CreditNoteDialogController(Modal, CommonMethods, $timeout, $scope, $stateParams, $uibModalInstance, entity, CreditNote, Charge, House, Company, AccountingPeriod, globalCompany) {
        var vm = this;

        vm.creditNote = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saving = false;
        vm.today = new Date();
        var id = CommonMethods.decryptIdUrl($stateParams.chargeId);

        Charge.get({id: id}, function (result) {
            vm.charge = result;
            AccountingPeriod.getLastPeriodCloseDate({companyId: globalCompany.getId()}, function (result) {
                $timeout(function(){
                    $scope.$apply(function () {
                        vm.accountingPeriodCloseDate = new Date(result.finalDayCloseDate);
                        if(vm.accountingPeriodCloseDate<vm.charge.date){
                            vm.creditNote.date = vm.charge.date;
                        }
                    })
                })
            })
        })



        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Modal.confirmDialog("¿Está seguro que desea anular la factura?", "", function () {
                vm.creditNote.canceledBillId = vm.charge.id;
                if (vm.creditNote.id !== null) {
                    CreditNote.update(vm.creditNote, onSaveSuccess, onSaveError);
                } else {
                    CreditNote.save(vm.creditNote, onSaveSuccess, onSaveError);
                }
            }, function () {
                vm.isSaving = false;
            })
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:creditNoteUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.originalBillDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
