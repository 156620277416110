(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('AccessDoorNotesController', AccessDoorNotesController);

        AccessDoorNotesController.$inject = ['Auth', '$state', '$scope', '$rootScope', 'CommonMethods', 'AccessDoor', 'Resident', 'House', 'Vehicule', 'Visitant', 'Note', 'AlertService', 'Emergency', 'Principal', '$filter', 'WSDeleteEntity', 'WSEmergency', 'WSHouse', 'WSResident', 'WSVehicle', 'WSNote', 'WSVisitor', 'PadronElectoral', 'Destinies', 'globalCompany', 'Modal', 'ParseLinks'];

        function AccessDoorNotesController(Auth, $state, $scope, $rootScope, CommonMethods, AccessDoor, Resident, House, Vehicule, Visitant, Note, AlertService, Emergency, Principal, $filter, WSDeleteEntity, WSEmergency, WSHouse, WSResident, WSVehicle, WSNote, WSVisitor, PadronElectoral, Destinies, globalCompany, Modal, ParseLinks) {
            var vm = this;
            vm.houseSelectedNote = -1;
            toastr.options = {
                "closeButton": true,
                "debug": false,
                "newestOnTop": false,
                "progressBar": false,
                "positionClass": "toast-bottom-full-width",
                "preventDuplicates": true,
                "onclick": null,
                "showDuration": "300",
                "hideDuration": "100000",
                "timeOut": "5000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
            };

            vm.officerResponses = ["¡Entendido!","¡Perfecto!","¡Con mucho gusto!","¡Anotado!","¡En camino!","Este no es el medio para realizar este reporte.","Recibido, hacer ticket a la administración para debido seguimiento"]
            vm.clearSearchTerm = function () {
                vm.searchTerm = '';
            };



            vm.sendResponseNote = function(note,officerResponse){
                Modal.confirmDialog('¿Está seguro que desea enviar la respuesta "'+officerResponse+'"'+' para esta nota?',"",function(){
                    note.officerResponse = officerResponse;
                    note.responseDate = CommonMethods.setTimezone(new Date());
                    note.read = 1
                    Note.update(note,function(data){
                        Modal.toast("Respuesta enviada")
                    })
                })

            }
            vm.searchTerm;
            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }
            vm.loadingForNotification = false;
            vm.noteCreatedBy = -1;
            $rootScope.noteCreatedBy = -1;
            $scope.$on("$destroy", function () {
                $rootScope.houseNoteNotification = undefined;
            });
            $rootScope.mainTitle = "Notas Actuales";
            moment.locale('es');
            vm.isReady = false;
            $rootScope.deletedStatusNote = 0;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.itemsPerPage = 6;
            vm.loadNotes = function () {
                Modal.showLoadingBar();
                if($rootScope.deletedStatusNote==0){
                    $rootScope.mainTitle = "Notas Actuales";
                }else{
                    $rootScope.mainTitle = "Notas Archivadas";
                }
                vm.isReady = false;
                vm.page = 0;
                vm.links = {
                    last: 0
                };
                $rootScope.notes = [];
                $rootScope.noteCreatedBy = vm.noteCreatedBy;

                if (vm.houseSelectedNote == -1) {
                    loadNotesByCompany();
                } else {
                    loadNotesByHouse();
                }
            }
            vm.showArchivedNotes = function () {
                $rootScope.deletedStatusNote = 1;
                $rootScope.mainTitle = "Notas Archivadas";
                vm.loadNotes();
            }
            vm.showActualNotes = function () {
                $rootScope.deletedStatusNote = 0;
                $rootScope.mainTitle = "Notas";
                vm.loadNotes();
            }

            function loadNotesByCompany() {
                Note.findAllByCompanyAndDeleted({
                    page: vm.page,
                    size: vm.itemsPerPage,
                    sort: sortNotes(),
                    companyId: globalCompany.getId(),
                    deleted: $rootScope.deletedStatusNote,
                    status: vm.noteCreatedBy
                }, onSuccessNotes, onError);
            }


            function loadNotesByHouse() {
                Note.findAllByHouseAndDeleted({
                    page: vm.page,
                    size: vm.itemsPerPage,
                    sort: sortNotes(),
                    houseId: vm.houseSelectedNote,
                    deleted: $rootScope.deletedStatusNote,
                    status: vm.noteCreatedBy
                }, onSuccessNotes, onError);
            }

            function sortNotes() {
                var result = [];
                if (vm.predicate !== 'creationdate') {
                    result.push('creationdate,desc');
                }
                return result;
            }


            function defineHouseNumber(houseId,house){
                if($rootScope.houses) {
                    for (var i = 0; i < $rootScope.houses.length; i++) {
                        if ($rootScope.houses[i].id == houseId) {
                            return $rootScope.houses[i].housenumber;
                        }
                    }
                }
                return house.housenumber;
            }
            function onSuccessNotes(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                var date = CommonMethods.setTimezone(new Date());
                for (var i = 0; i < data.length; i++) {
                    data[i].sinceDate = moment(data[i].creationdate).fromNow();
                    if(data[i].read==null) {
                        data[i].read = 1;
                        data[i].readDate = date;
                        Note.update(data[i]);
                    }
                    if(data[i].house) {
                        data[i].house.housenumber = defineHouseNumber(data[i].house.id, data[i].house)
                    }
                    $rootScope.notes.push(data[i]);
                }
                Modal.hideLoadingBar();
                vm.isReady = true;
            }

            function onError() {

            }

            vm.loadPageNotes = function (page) {
                vm.page = page;
                if ($rootScope.houseSelectedNote === -1) {
                    loadNotesByCompany();
                } else {
                    loadNotesByHouse();
                }
            };

            vm.delete = function (note) {
                vm.note = note;
                Modal.confirmDialog("¿Está seguro que desea archivar la nota?", "", function () {
                    Note.delete({
                        id: note.id
                    }, onSuccessDelete, OnError);
                })

            }

            function onSuccessDelete(result) {
                CommonMethods.deleteFromArray(vm.note, $rootScope.notes);
                Modal.actionToastGiant("Se archivo la nota correctamente", "Deshacer", function () {
                    Note.restore({id: vm.note.id}, function () {
                        vm.note.deleted = 0;
                        if($rootScope.deletedStatusNote==0){
                            $rootScope.notes.push(vm.note);
                        }else{
                            CommonMethods.deleteFromArray(vm.note, $rootScope.notes);
                        }
                    })
                })
            }

            vm.restore = function (note) {
                Modal.confirmDialog("¿Está seguro que desea restaurar  la nota?", "", function () {
                    Note.restore({id: note.id}, function () {
                        CommonMethods.deleteFromArray(note, $rootScope.notes);
                        Modal.actionToastGiant("Se restauro la nota correctamente", "Deshacer", function () {
                            Note.delete({
                                id: note.id
                            }, function(){
                                if($rootScope.deletedStatusNote==1){
                                    $rootScope.notes.push(note);
                                }else{
                                    CommonMethods.deleteFromArray(note, $rootScope.notes);
                                }
                            }, OnError);
                        })
                    });
                })
            };
            function OnError(result) {
                Modal.toastGiant("Ocurrio un error eliminando la nota");
            }
            $scope.$watch(function () {
                return $rootScope.houseNoteNotification;
            }, function () {
                if ($rootScope.houseNoteNotification != undefined) {
                    vm.checkNoteNotification();
                }
            }, true);

            vm.checkNoteNotification = function(){
                if($rootScope.houseNoteNotification==undefined){
                    $rootScope.houseSelectedNote = -1;
                    $rootScope.noteCreatedBy = -1;
                }else{
                    $rootScope.houseSelectedNote = $rootScope.houseNoteNotification;
                    $rootScope.noteCreatedBy = 1;
                }
                $rootScope.deletedStatusNote = 0;
                $rootScope.notes = [];
                vm.loadNotes();
            };
            if($rootScope.houseNoteNotification==undefined){
                vm.loadNotes();
            }
        }
    }

)();
