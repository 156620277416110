(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ContractDetailController', ContractDetailController);

    ContractDetailController.$inject = ['Modal','$scope', '$rootScope', '$stateParams', 'previousState', 'entity', '$state', 'Company'];

    function ContractDetailController(Modal,$scope, $rootScope, $stateParams, previousState, entity, $state, Company) {
        var vm = this;

        vm.contract = entity;
        vm.previousState = previousState.name;
        vm.isReady = true;
        var unsubscribe = $rootScope.$on('aditumApp:contractUpdate', function(event, result) {
            vm.contract = result;
        });

        vm.back = function(){
            $state.go("contract")
        }
        $rootScope.backState = "contract";

        vm.showFile = function(){
            $state.go("contract-detail.file-preview",
                {
                    url: vm.contract.fileUrl,
                })
        }

        $scope.$on('$destroy', unsubscribe);
        $rootScope.mainTitle = "Detalle del contrato";
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });
    }
})();
