(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ReceivingClientController', ReceivingClientController);

    ReceivingClientController.$inject = ['ReceivingClient', 'Company', '$mdDialog', '$scope', '$state', 'House', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal', '$rootScope', 'CommonMethods', 'Modal', 'globalCompany'];

    function ReceivingClientController(ReceivingClient, Company, $mdDialog, $scope, $state, House, ParseLinks, AlertService, paginationConstants, pagingParams, Principal, $rootScope, CommonMethods, Modal, globalCompany) {
        var vm = this;
        $rootScope.active = "feClients";
        $rootScope.mainTitle = "Clientes de facturación";
        vm.isReady = false;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.estado = "";
        vm.receivingClients = [];
        vm.ocultarACondos = true;
        vm.estadoTemporal = "2";
        vm.filter = {
            houseNumber: " ",
            desocupated: "empty"
        };
        vm.itemsPerPage = 12;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.houses = [];
        vm.housesDesorder = [];

        vm.goToDetailReceivingClient = function (house) {
            var id = CommonMethods.encryptIdUrl(house.id)
            $state.go("receiving-client-detail", {houseId: id, houseNumber: house.housenumber})
        }

        vm.close = function () {
            $mdDialog.hide();
        };

        vm.closeAndFilter = function () {
            vm.filterHouses();
            $mdDialog.hide();
        };

        vm.filterHouses = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.houses = [];
            loadAll();
        }

        vm.enabledOptions = true;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.houses = [];


        ReceivingClient.getByCompanyId({companyId: globalCompany.getId()}, function (data) {
            vm.receivingClients = data;
            loadAll();
        }, function () {
        })

        function loadAll() {
            if (vm.filter.houseNumber == "" || vm.filter.houseNumber == undefined) {
                vm.filter.houseNumber = " ";
            }
            House.getAllHouses({
                page: vm.page,
                size: 77,
                desocupated: vm.filter.desocupated,
                houseNumber: vm.filter.houseNumber,
                companyId: globalCompany.getId(),
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                angular.forEach(data, function (value, key) {
                    var foundBilling = false;
                    angular.forEach(vm.receivingClients, function (client, key) {
                        if (value.id == client.houseId) {
                            value.hasBilling = true;
                            foundBilling = true;
                        }
                    });
                    if (!foundBilling) {
                        value.hasBilling = false;
                    }
                    vm.housesDesorder.push(value)
                });
                vm.housesDesorder.sort(function (a, b) {
                    a.housenumber.localeCompare(b.housenumber, undefined, {numeric: true, sensitivity: 'base'})
                });
                vm.houses = vm.housesDesorder;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
