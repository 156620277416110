(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingAccountDeleteController',AccountingAccountDeleteController);

    AccountingAccountDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccountingAccount'];

    function AccountingAccountDeleteController($uibModalInstance, entity, AccountingAccount) {
        var vm = this;

        vm.accountingAccount = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccountingAccount.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
