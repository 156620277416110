(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('Complaint', Complaint);

    Complaint.$inject = ['$resource', 'DateUtils'];

    function Complaint($resource, DateUtils) {
        var resourceUrl = 'api/complaints/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', url: "api/complaints/admin/:companyId/:category", isArray: true},
            'queryByStatus': {
                method: 'GET',
                url: "api/complaints/admin/:companyId/status/:status/:category",
                isArray: true
            },
            'queryByTicketNumber': {
                method: 'GET',
                url: "api/complaints/admin-by-ticket-number/:companyId/:number/:category",
                isArray: true
            },
            'queryAsResident': {method: 'GET', url: "api/complaints/user/:residentId/:category", isArray: true},
            'queryAsResidentByStatus': {
                method: 'GET',
                url: "api/complaints/user/:residentId/status/:status/:category",
                isArray: true
            },
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'}
        });
    }
})();
