(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ChargeSpecificationController', ChargeSpecificationController);

    ChargeSpecificationController.$inject = ['ChargeSpecification'];

    function ChargeSpecificationController(ChargeSpecification) {

        var vm = this;

        vm.chargeSpecifications = [];

        loadAll();

        function loadAll() {
            ChargeSpecification.query(function(result) {
                vm.chargeSpecifications = result;
                vm.searchQuery = null;
            });
        }
    }
})();
