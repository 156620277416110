(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('ActionLogAdmin', ActionLogAdmin);

    ActionLogAdmin.$inject = ['$resource', 'DateUtils'];

    function ActionLogAdmin ($resource, DateUtils) {
        var resourceUrl =  'api/action-log-admins/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'verifyIsReportIsRunning': { method: 'GET', isArray: true, url:"api/action-log-admins-verify-report-executing/:type"},
            'queryByCompanyAndType': { method: 'GET', isArray: true, url:"api/action-log-admins/:companyId/:type"},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.actionDate = DateUtils.convertDateTimeFromServer(data.actionDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
