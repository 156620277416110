(function () {
    'use strict';

    angular
        .module('aditumApp')
        .provider('AccountingAccountsCategories', AccountingAccountsCategories);

    function AccountingAccountsCategories() {
        this.$get = getService;

        function getService() {
            return {
                getCategories: getCategories,
                accountingAccountsTypes: {
                    ASSETS: { name: 'Activos', type: 'ASSET_ACCOUNTS' },
                    LIABILITIES: { name: 'Pasivos', type: 'LIABILITY_ACCOUNTS' },
                    EQUITY: { name: 'Patrimonio', type: 'EQUITY_ACCOUNTS' },
                    INCOME: { name: 'Ingresos', type: 'INCOME_ACCOUNTS' },
                    EXPENSES: { name: 'Gastos', type: 'EXPENSE_ACCOUNTS' },
                    INCOME_STATEMENT: { name: 'Estado de Ingresos', type: 'INCOME_STATEMENT_ACCOUNTS' },
                    CONTINGENT: { name: 'Contingentes', type: 'CONTINGENT_ACCOUNTS' },
                    ADJUSTMENT: { name: 'Ajustes', type: 'ADJUSTMENT_ACCOUNTS' },
                    CONTROL: { name: 'Control', type: 'CONTROL_ACCOUNTS' },
                    RECONCILIATION: { name: 'Reconciliación', type: 'RECONCILIATION_ACCOUNTS' }
                }
            };
           function getCategories(){
               var behaviorType = {
                   DEBIT: 'DEBIT',
                   CREDIT: 'CREDIT',
               }
               var accountsOptions = {
                   ASSETS: 'ASSET_ACCOUNTS',
                   LIABILITIES: 'LIABILITY_ACCOUNTS',
                   EQUITY: 'EQUITY_ACCOUNTS',
                   INCOME: 'INCOME_ACCOUNTS',
                   EXPENSES: 'EXPENSE_ACCOUNTS',
                   INCOME_STATEMENT: 'INCOME_STATEMENT_ACCOUNTS',
                   CONTINGENT: 'CONTINGENT_ACCOUNTS',
                   ADJUSTMENT: 'ADJUSTMENT_ACCOUNTS',
                   CONTROL: 'CONTROL_ACCOUNTS',
                   RECONCILIATION: 'RECONCILIATION_ACCOUNTS'
               };

               var accountingAccountsTypes = [
                   {name: 'Activos', accountType: accountsOptions.ASSETS, behavior: behaviorType.DEBIT},
                   {name: 'Pasivos', accountType: accountsOptions.LIABILITIES, behavior: behaviorType.CREDIT},
                   {name: 'Patrimonio', accountType: accountsOptions.EQUITY, behavior: behaviorType.CREDIT},
                   {name: 'Ingresos', accountType: accountsOptions.INCOME, behavior: behaviorType.CREDIT},
                   {name: 'Gastos', accountType: accountsOptions.EXPENSES, behavior: behaviorType.DEBIT},
                   {name: 'Ajustes', accountType: accountsOptions.ADJUSTMENT, behavior: behaviorType.DEBIT},
               ];
              return accountingAccountsTypes;
           }
        }
    }

})();
