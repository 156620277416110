(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetDialogController', BudgetDialogController);

    BudgetDialogController.$inject = ['Modal','globalCompany','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Budget', 'Company'];

    function BudgetDialogController(Modal, globalCompany,$timeout, $scope, $stateParams, $uibModalInstance, entity, Budget, Company) {
        var vm = this;

        vm.budget = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.companies = Company.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.setFinishDate = function () {
            var date = new Date(vm.budget.startingDate);
            date.setMonth(date.getMonth() + 12);
            vm.budget.finishDate = date;
            vm.setConcept();
        }

        vm.setConcept = function () {
            vm.budget.year = "Presupuesto " + vm.budget.startingDate.getFullYear() + " - " + vm.budget.finishDate.getFullYear();
        }

        function save() {
            vm.isSaving = true;
            vm.budget.deleted = false;
            vm.budget.companyId = globalCompany.getId();
            if (vm.budget.id !== null) {
                Budget.update(vm.budget, onSaveSuccess, onSaveError);
            } else {
                Budget.save(vm.budget, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:budgetUpdate', result);
            $uibModalInstance.close(result);
            Modal.toast("Se creo el presupuesto exitosamente");
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startingDate = false;
        vm.datePickerOpenStatus.finishDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
