(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('SendAccountStatusController', SendAccountStatusController);

    SendAccountStatusController.$inject = ['$localStorage','ActionLogAdmin', 'Resident', '$state', 'House', 'ParseLinks', 'AlertService', 'paginationConstants', '$mdDialog', '$rootScope', '$scope', 'AdministrationConfiguration', 'AccountStatus', 'CommonMethods', 'globalCompany', 'Modal', 'Principal'];


    function SendAccountStatusController($localStorage,ActionLogAdmin, Resident, $state, House, ParseLinks, AlertService, paginationConstants, $mdDialog, $rootScope, $scope, AdministrationConfiguration, AccountStatus, CommonMethods, globalCompany, Modal, Principal) {
        var vm = this;

        $rootScope.mainTitle = "Enviar estados de cuenta";
        vm.currentDate = new Date();
        vm.month = new Date();
        vm.viewLog = false;
        vm.toTenant = false;

        Principal.identity().then((function (data) {
            vm.autor = data.firstName + " " + data.lastName;
        }))
        function formatResidentList(role, data) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == vm.houseSelected.id) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role)
                    r = vm.defineRoleUser(r);
                    list.push(r)
                }
            }
            return list;
        }
        function defineRole(role) {
            switch (role) {
                case "ROLE_OWNER":
                    return "Residente propietario";
                case "ROLE_RENTER":
                    return "Propietario arrendador";
                case "ROLE_TENANT":
                    return "Inquilino";
                case "ROLE_RESIDENT":
                    return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                    case "ROLE_OWNER":
                        resident.houses[i].role = "Residente propietario";
                        break;
                    case "ROLE_RENTER":
                        resident.houses[i].role = "Propietario arrendador";
                        break;
                    case "ROLE_TENANT":
                        resident.houses[i].role = "Inquilino";
                        break;
                    case "ROLE_RESIDENT":
                        resident.houses[i].role = "Residente autorizado";
                        break;
                }
            }
            return resident;
        }
        vm.open = function (house) {
            vm.checkedType = 3;
            vm.residents = [];
            vm.houseSelected = house;
            var houseId = house.id;

            Resident.getResidentsByHouse({houseId: house.id}, function (data) {
                vm.owners = formatResidentList("ROLE_OWNER", data);
                vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data));
                vm.tenants = formatResidentList("ROLE_TENANT", data);
                vm.residents = formatResidentList("ROLE_RESIDENT", data);
                vm.loading = false;
                vm.data = data;
                vm.loaded = true;
                vm.residentsAll = vm.owners.concat(vm.tenants).concat(vm.residents);
                $mdDialog.show({
                    templateUrl: 'app/entities/account-status/account-status-send-by-email-list.html',
                    scope: $scope,
                    preserveScope: true
                });
            })
        };

        function obtainEmailToList() {
            var residentsToSendEmails = "";
            angular.forEach(vm.residentsAll, function (resident, i) {
                if (resident.selected == true) {
                    if (residentsToSendEmails.indexOf(resident) === -1) {
                        residentsToSendEmails = residentsToSendEmails + resident.id + ",";
                    }
                }
            });
            return residentsToSendEmails;
        }

        vm.close = function () {
            Modal.hideLoadingBar();
            $mdDialog.hide();
        };

        vm.viewLogAction = function () {
            ActionLogAdmin.queryByCompanyAndType({companyId: globalCompany.getId(), type: "1"}, function (logs) {
                vm.viewLog = !vm.viewLog;
                vm.logs = logs;
            })
        }

        vm.sendByEmail = function () {
            var residentsToSendEmails = obtainEmailToList().slice(0, -1);
            if (residentsToSendEmails == "") {
                Modal.toast("Debes seleccionar al menos un correo electrónico");
            } else {
                Modal.confirmDialog("¿Está seguro que desea enviar el estado de cuenta?", "" +
                    "El estado de cuenta se enviará a los contactos seleccionados.", function () {
                    Modal.showLoadingBar();
                    AccountStatus.sendAccountStatus({
                        houseId: vm.houseSelected.id,
                        companyId: globalCompany.getId(),
                        emailTo: residentsToSendEmails,
                        monthDate: moment(vm.month).format(),
                    }, function (result) {
                        var actionLog = {};
                        actionLog.companyId = globalCompany.getId();
                        actionLog.responsable = vm.autor;
                        actionLog.actionDate = moment(new Date()).format();
                        actionLog.description = "Estado de cuenta de "+moment(vm.month).format("MMMM")+" enviado a la filial "+vm.houseSelected.housenumber+".";
                        actionLog.actionType = "1";
                        ActionLogAdmin.save(actionLog, function () {
                            $mdDialog.hide();
                            Modal.hideLoadingBar();
                            Modal.toast("Se envió el estado de cuenta por correo correctamente");
                        })
                    })
                })
            }
        };
        vm.sendByEmailAll = function () {
            Modal.confirmDialog("¿Está seguro que desea enviar los estados de cuenta a TODAS las filiales?", "" +
                "Los estados de cuenta se enviarán a todos los contactos de cada filial.", function () {
                Modal.showLoadingBar();
                AccountStatus.sendAccountStatusToAll({
                    companyId: globalCompany.getId(),
                    monthDate: moment(vm.month).format(),
                    toAll: true,
                    toTenant:vm.toTenant
                }, function (result) {
                    var actionLog = {};
                    actionLog.companyId = globalCompany.getId();
                    actionLog.responsable = vm.autor;
                    var toTenantSend = vm.toTenant?" (Enviado también a inquilinos)":"";
                    actionLog.description = "Estados de cuenta de "+moment(vm.month).format("MMMM")+" enviados a todas las filiales." + toTenantSend;
                    actionLog.actionType = "1";
                    actionLog.actionDate = moment(new Date()).format();
                    ActionLogAdmin.save(actionLog, function () {
                        $mdDialog.hide();
                        Modal.hideLoadingBar();
                        Modal.toast("Se envió el estado de cuenta por correo correctamente.");
                    })
                })
            })
        };

        vm.sendByEmailMorosas = function () {
            Modal.confirmDialog("¿Está seguro que desea enviar los estados de cuenta SOLO a las filiales morosas?", "" +
                "Los estados de cuenta se enviarán a todos los contactos de cada filial.", function () {
                Modal.showLoadingBar();
                AccountStatus.sendAccountStatusToAll({
                    companyId: globalCompany.getId(),
                    monthDate: moment(vm.month).format(),
                    toAll: false,
                    toTenant:vm.toTenant
                }, function (result) {
                    var actionLog = {};
                    actionLog.companyId = globalCompany.getId();
                    actionLog.responsable = vm.autor;
                    actionLog.actionDate = moment(new Date()).format();
                    var toTenantSend = vm.toTenant?" (Enviado también a inquilinos)":"";
                    actionLog.description = "Estados de cuenta de "+moment(vm.month).format("MMMM")+" enviados a las filiales morosas." + toTenantSend;
                    actionLog.actionType = "1";
                    ActionLogAdmin.save(actionLog, function () {
                        $mdDialog.hide();
                        Modal.hideLoadingBar();
                        Modal.toast("Se envió el estado de cuenta por correo correctamente.");
                    })
                })
            })
        };

        $rootScope.active = "sendAccountStatus";
        vm.changeFormat = function () {
            vm.format = 'MMMM';
            vm.hideDate = true;
            vm.hideDate = false;
        };

        vm.locale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(vm.format) : '';
            }
        };
        vm.createMonth = function () {
            vm.initial_time = new Date(vm.month.getFullYear(), vm.month.getMonth(), 1);
            vm.final_time = new Date(vm.month.getFullYear(), vm.month.getMonth() + 1, 0);
            vm.changeFormat()
        }
        vm.createMonth()
        moment.locale("es");

        function loadAll() {
            if ($localStorage.houses) {
                vm.houses = $localStorage.houses;
                onSuccess(vm.houses,null)
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                },onSuccess);
            }
            function onSuccess(data, headers) {
                vm.houses = data;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAll();

    }
})();
