(function() {
    'use strict';


    angular
        .module('aditumApp')
        .factory('WSQrCode', WSQrCode);

    WSQrCode.$inject = ['StompManager','$rootScope','StompManagerSecond'];

    function WSQrCode(StompManager,$rootScope,StompManagerSecond) {
        var SUBSCRIBE_ATTEND_TRACKER_URL = '/topic/qrResponse/';
        var SEND_ACTIVITY_ATTEND_URL = '/topic/qr/';

        var service = {
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe,
        };

        return service;

        function receive () {
            return StompManagerSecond.getListener(SUBSCRIBE_ATTEND_TRACKER_URL + $rootScope.code);
        }

        function sendActivity(code,entity) {
            StompManagerSecond.send(SEND_ACTIVITY_ATTEND_URL + code, entity);
        }

        function subscribe(code) {
            StompManagerSecond.subscribe(SUBSCRIBE_ATTEND_TRACKER_URL + code);
        }

        function unsubscribe(code) {
            StompManagerSecond.unsubscribe(SUBSCRIBE_ATTEND_TRACKER_URL + code);
        }
    }
})();
