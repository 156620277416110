(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AdministrationContactDialogController', AdministrationContactDialogController);

    AdministrationContactDialogController.$inject = ['globalCompany','Modal','$rootScope','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AdministrationContact', 'Company'];

    function AdministrationContactDialogController (globalCompany,Modal,$rootScope,$timeout, $scope, $stateParams, $uibModalInstance, entity, AdministrationContact, Company) {
        var vm = this;

        vm.administrationContact = entity;
        vm.clear = clear;
        vm.save = save;

        vm.regEx="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$"

        $rootScope.mainTitle = vm.administrationContact.id?"Modificar contacto":"Registrar contacto";

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            Modal.confirmDialog("¿Está seguro que desea guardarlo?","",function(){
                vm.isSaving = true;
                vm.administrationContact.companyId = globalCompany.getId();
                if (vm.administrationContact.id !== null) {
                    AdministrationContact.update(vm.administrationContact, onSaveSuccess, onSaveError);
                } else {
                    vm.administrationContact.deleted = 0;
                    AdministrationContact.save(vm.administrationContact, onSaveSuccess, onSaveError);
                }
            })
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:administrationContactUpdate', result);
            $uibModalInstance.close(result);
            Modal.toast("Guardado.")
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
