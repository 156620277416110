(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpDialogController', FollowUpDialogController);

    FollowUpDialogController.$inject = ['FollowUpNextStep', 'FollowUpTask', 'AditumStorageService', 'globalCompany', '$timeout', '$scope', '$stateParams', 'entity', 'FollowUp', 'Company', 'House', 'FollowUpCategory', 'CommonMethods', 'Principal', 'AdminInfo', '$rootScope', 'Modal', '$state'];

    function FollowUpDialogController(FollowUpNextStep, FollowUpTask, AditumStorageService, globalCompany, $timeout, $scope, $stateParams, entity, FollowUp, Company, House, FollowUpCategory, CommonMethods, Principal, AdminInfo, $rootScope, Modal, $state) {
        var vm = this;
        vm.followUp = entity;
        moment.locale("es")
        vm.lastModification = moment(vm.followUp.lastModification).format("MMM DD, hh:mm a");
        vm.creationDate = moment(vm.followUp.creationDate).format("MMM DD, hh:mm a");
        vm.finishDate = moment(vm.followUp.finishDate).format("MMM DD, hh:mm a");
        vm.saveTask = saveTask;
        vm.saveNextTask = saveNextTask;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.followUpNextTask = {};
        vm.houseId = CommonMethods.decryptString($stateParams.houseId);
        vm.hourSelected = 8;
        vm.reminderDate = new Date();
        vm.save = save;
        var file = {};
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });
        $rootScope.backState = "record-file";
        var idHouse = CommonMethods.encryptString(vm.houseId + "")
        $rootScope.backAtribute = {houseId: idHouse};

        vm.deleteFollowUp = function () {
            Modal.confirmDialog("¿Está seguro que desea eliminar este segumiento?", "Una vez eliminado no podrá recuperarlo.", function () {
                vm.followUp.deleted = true;
                vm.save();
                Modal.toast("Eliminado.");
                vm.followUpNextTask.deleted = 1;
                saveNextTask(vm.followUpNextTask);
                vm.goToHouse(vm.houseId);
            })
        }
        vm.defineHours = function () {
            vm.hours = [];
            for (var i = 5; i < 12; i++) {
                vm.hours.push(
                    {value: i, text: i + ":00 AM"}
                )
            }
            vm.hours.push(
                {value: 12, text: i + ":00 PM"}
            )
            for (var i = 1; i <= 11; i++) {
                vm.hours.push(
                    {value: i + 12, text: i + ":00 PM"}
                )
            }
        }

        vm.defineHours()


        vm.status = [
            {description: "Pendiente", icon: "schedule", color: "color:rgb(229, 0, 0)"},
            {description: "En progreso", icon: "hardware", color: "color:rgb(255,176,46)"},
            {description: "Finalizado", icon: "done", color: "color:rgb(107, 201, 80)"},
        ]

        vm.followUpTasks = [];

        FollowUpTask.queryByFollowUp({followUpId: vm.followUp.id}, function (result) {
            for (var i = 0; i < result.length; i++) {
                result[i].statusObj = vm.status[result[i].status];
                vm.followUpTasks.push(result[i])
            }
        })
        if (vm.followUp.hasNextStep) {
            FollowUpNextStep.queryByFollowUp({followUpId: vm.followUp.id}, function (result) {
                vm.followUpNextTask = result[0];
                vm.followUpNextTask.hasReminderDate = result.reminderDate != null;
                if (vm.followUpNextTask.reminderDate) {
                    if (vm.followUpNextTask.reminderDate) {
                        var date = new Date(vm.followUpNextTask.reminderDate);
                        vm.reminderDate = vm.followUpNextTask.reminderDate;
                        for (var i = 0; i < vm.hours.length; i++) {
                            if (vm.hours[i].value == date.getHours()) {
                                vm.hourSelected = vm.hours[i].value;
                            }
                        }
                        vm.followUpNextTask.hasReminderDate = true;
                    }
                }

            })
        }


        vm.statusSelected = vm.status[vm.followUp.status];
        AdminInfo.getAdminsByCompanyId({
            companyId: globalCompany.getId()
        }, onSuccessAdmins, onError);

        function onSuccessAdmins(data) {
            vm.admins = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        Principal.identity().then((function (data) {
            vm.autor = data.firstName + " " + data.lastName;
        }))

        vm.formatDateLastModification = function (lastModification) {
            return moment(lastModification).format("MMM DD, hh:mm a");
        }

        loadAllCategories();


        vm.transformToDoneTask = function () {
            Modal.confirmDialog("¿Está seguro que quiere marcar esta acción como realizada?", "", function () {
                var newFollowUpTask = vm.followUpNextTask;
                newFollowUpTask.status = 2;
                newFollowUpTask.creationDate = moment(new Date()).format();
                newFollowUpTask.authorModification = vm.autor;
                newFollowUpTask.lastModification = moment(new Date()).format();
                vm.followUpNextTask.deleted = true;
                vm.saveNextTask(vm.followUpNextTask);
                setTimeout(function () {
                    vm.addFollowUpTask(newFollowUpTask);
                }, 200)
                vm.followUp.hasNextStep = false;
                vm.save();
            })
        }

        vm.addFollowUpTaskNext = function () {
            if (vm.houseId == -1) {
                var houseId = null;
            } else {
                var houseId = vm.houseId;
            }
            var followUpTaskNext = {
                name: null,
                description: null,
                hasFile: false,
                fileUrl: null,
                fileName: null,
                creationDate: moment(new Date()).format(),
                author: vm.autor,
                authorModification: vm.autor,
                status: 0,
                deleted: 0,
                lastModification: moment(new Date()).format(),
                id: null,
                companyId: globalCompany.getId(),
                houseId: houseId,
                followUpId: vm.followUp.id,
                reminderDate: null,
                statusObj: {description: "Pendiente", icon: "schedule", color: "color:rgb(229, 0, 0)"},
            };
            vm.hourSelected = 8;
            vm.reminderDate = new Date();
            vm.followUp.hasNextStep = true;
            saveNextTask(followUpTaskNext)
            $timeout(function (){
                angular.element('#nextTask').focus();
            });
        }

        vm.addFollowUpTask = function (followUpTask) {
            if (!followUpTask) {
                if (vm.houseId == -1) {
                    var houseId = null;
                } else {
                    var houseId = vm.houseId;
                }
                var idFocus = Math.floor(Math.random() * 10000) + 1;
                var followUpTask = {
                    name: null,
                    description: null,
                    hasFile: false,
                    fileUrl: null,
                    fileName: null,
                    creationDate: moment(new Date()).format(),
                    author: vm.autor,
                    authorModification: vm.autor,
                    status: 0,
                    deleted: 0,
                    lastModification: moment(new Date()).format(),
                    id: null,
                    companyId: globalCompany.getId(),
                    houseId: houseId,
                    followUpId: vm.followUp.id,
                    statusObj: vm.status[0],
                    idFocus:idFocus
                };
            } else {
                followUpTask.id = null;
                followUpTask.deleted = false;
                followUpTask.statusObj = vm.status[2];
            }
            saveTask(followUpTask)
            vm.followUpTasks.push(followUpTask);
            $timeout(function (){
                angular.element('#'+idFocus).focus();
            });
        }

        function saveTask(followUpTask) {
            vm.isSaving = true;
            followUpTask.authorModification = vm.autor;
            followUpTask.lastModification = moment(new Date()).format();
            vm.modifiedTask = followUpTask;
            if (followUpTask.id !== null) {
                FollowUpTask.update(followUpTask, onSaveSuccessTask, onSaveError);
            } else {
                FollowUpTask.save(followUpTask, onSaveSuccessTask, onSaveError);
            }
        }

        function saveNextTask(followUpTask) {
            vm.isSaving = true;
            followUpTask.authorModification = vm.autor;
            followUpTask.lastModification = moment(new Date()).format();
            if (followUpTask.hasReminderDate) {
                var date = new Date(vm.reminderDate);
                date.setHours(vm.hourSelected)
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                followUpTask.reminderDate = date;
            } else {
                followUpTask.reminderDate = null;
                vm.reminderDate = null;
                vm.hourSelected = 8;
            }
            vm.modifiedTask = followUpTask;
            if (followUpTask.id !== null) {
                FollowUpNextStep.update(followUpTask, onSaveSuccessNextTask, onSaveError);
            } else {
                FollowUpNextStep.save(followUpTask, onSaveSuccessNextTask, onSaveError);
            }
        }

        function onSaveSuccessNextTask(result) {
            vm.isSaving = false;
            if (result.deleted) {
                vm.followUpNextTask = {};
            } else {
                vm.followUpNextTask = result;
                if (vm.followUpNextTask.reminderDate) {
                    var date = new Date(vm.followUpNextTask.reminderDate);
                    vm.reminderDate = vm.followUpNextTask.reminderDate;
                    for (var i = 0; i < vm.hours.length; i++) {
                        if (vm.hours[i].value == date.getHours()) {
                            vm.hourSelected = vm.hours[i].value;
                        }
                    }
                    vm.followUpNextTask.hasReminderDate = true;
                }
            }
            save();
        }


        function onSaveSuccessTask(result) {
            vm.isSaving = false;
            vm.modifiedTask.isSavingFileTask = false;
            var index = vm.followUpTasks.indexOf(vm.modifiedTask);
            if (vm.followUpTasks[index] != undefined) {
                vm.followUpTasks[index].id = result.id;
            }
            if (result.deleted) {
                CommonMethods.deleteFromArrayWithId(result, vm.followUpTasks)
            }
            save();
        }

        function loadAllCategories() {
            FollowUpCategory.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.recordCategories = data;
                for (var i = 0; i < vm.recordCategories.length; i++) {
                    if (vm.recordCategories[i].id == vm.followUp.followUpCategoryId) {
                        vm.recordCategory = vm.recordCategories[i];
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.goToHouse = function (houseId) {
            var id = CommonMethods.encryptString(houseId + "")
            $state.go("record-file", {houseId: id})
        }

        vm.priorityColors = ["black", "rgb(111, 221, 255)", "rgb(255, 204, 0)", "rgb(245, 0, 0)"];
        vm.priorityColor = vm.priorityColors[vm.followUp.priority];

        if (vm.houseId != -1) {
            House.getClean({id: vm.houseId}, function (data) {
                vm.house = data;
                $rootScope.mainTitle = vm.house.housenumber + "  >  Seguimiento";

            })
        } else {
            Company.get({id: globalCompany.getId()}, function (data) {
                vm.house = {};
                vm.house.id = -1;
                vm.house.housenumber = data.name;
                $rootScope.mainTitle = "Seguimiento";

            })
        }

        vm.definePriority = function (priority) {
            vm.followUp.priority = priority;
            vm.priorityColor = vm.priorityColors[vm.followUp.priority];
            vm.save()
        }

        vm.defineStatusFollowUp = function (followUp, status) {
            followUp.status = status;
            followUp.statusObj = vm.status[status];
            saveTask(followUp);
        }


        vm.defineAutorNext = function (followUpTask, admin) {
            followUpTask.adminInfoId = admin.id;
            followUpTask.author = admin.name + " " + admin.lastname;
            saveNextTask(followUpTask);
        }

        vm.defineAutor = function (followUpTask, admin) {
            followUpTask.adminInfoId = admin.id;
            followUpTask.author = admin.name + " " + admin.lastname;
            saveTask(followUpTask);
        }

        vm.defineStatus = function (status) {
            vm.statusSelected = vm.status[status];
            vm.followUp.status = status;
            if (status == 2) {
                vm.followUp.finishDate = moment(new Date()).format();
            } else {
                vm.followUp.finishDate = null
            }
            vm.save()
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.followUp.followUpCategoryId = vm.recordCategory.id;
            vm.followUp.lastModification = moment(new Date()).format();
            vm.followUp.authorLastModification = vm.autor;
            if (vm.followUp.id !== null) {
                FollowUp.update(vm.followUp, onSaveSuccess, onSaveError);
            } else {
                FollowUp.save(vm.followUp, onSaveSuccess, onSaveError);
            }
        }

        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            vm.editingFile = false;
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
                upload(vm.file)
            }
        };
        vm.setFileTask = function (followUp, $file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            vm.editingFile = false;
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
                uploadTaskFile(followUp, vm.file)
            }
        };
        vm.setFileTaskNext = function (followUp, $file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            vm.editingFile = false;
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
                uploadTaskFileNext(followUp, vm.file)
            }
        };

        vm.viewPreviewMainFile = function () {
            $state.go("follow-up.edit.record-file-preview", {
                url: vm.followUp.fileUrl,
                name: vm.followUp.fileName
            })
        }

        vm.viewPreviewTaskFile = function (followUpTask) {
            $state.go("follow-up.edit.record-file-preview", {
                url: followUpTask.fileUrl,
                name: followUpTask.fileName
            })
        }

        vm.deleteFileFollowUpTask = function (followUpTask) {
            Modal.confirmDialog("¿Está seguro que desea borrar el archivo?", "Una vez eliminado no lo podrá recuperar.", function () {
                followUpTask.fileUrl = null;
                followUpTask.fileName = null;
                followUpTask.hasFile = false;
                Modal.toast("Archivo eliminado.")
                saveTask(followUpTask);
            })
        }

        vm.defineIconType = function (type) {
            var defineType = type.split('.')[1];
            switch (defineType.toLowerCase()) {
                case "docx":
                    return "fa-file-word-o";
                    break;
                case "docx":
                    return "fa-file-word-o";
                    break;
                case "jpeg":
                    return "fa-file-image-o";
                    break;
                case "png":
                    return "fa-file-image-o";
                    break;
                case "xls":
                    return "fa-file-excel-o";
                    break;
                case "xlsx":
                    return "fa-file-excel-o";
                    break;
                case "pdf":
                    return "fa-file-pdf-o";
                    break;
                case "mp4":
                    return "fa-file-video-o";
                    break;
            }
            return "fa-file-o";
        }

        vm.deleteFileFollowUp = function () {
            Modal.confirmDialog("¿Está seguro que desea borrar el archivo?", "Una vez eliminado no lo podrá recuperar.", function () {
                vm.followUp.fileUrl = null;
                vm.followUp.fileName = null;
                vm.followUp.hasFile = false;
                Modal.toast("Archivo eliminado.")
                save();
            })
        }
        vm.archiveFollowUpTask = function (followUpTask) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el registro de esta acción?", "Una vez eliminada no la podrá recuperar.", function () {
                followUpTask.deleted = 1;
                Modal.toast("Acción realizada eliminada.")
                saveTask(followUpTask);
            })
        }

        vm.archiveFollowUpTaskNext = function (followUpTask) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el registro de la próxima acción a realizar?", "Una vez eliminada no la podrá recuperar.", function () {
                followUpTask.deleted = 1;
                Modal.toast("Próxima tarea a realizar eliminada.")
                saveNextTask(followUpTask);
                vm.followUp.hasNextStep = false;
                vm.save()
            })
        }

        function makeid(length, fileName) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        function uploadTaskFile(followUpTask, file) {
            vm.isSaving = true;
            followUpTask.isSavingFileTask = true;
            var fileName = makeid(4, file.name) + " - " + vm.fileName;
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/follow-up/' + vm.house.housenumber + '/' + vm.followUp.id + "/tasks/" + fileName).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        followUpTask.fileprogress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    followUpTask.fileUrl = downloadURL;
                    followUpTask.fileName = file.name;
                    followUpTask.hasFile = true;
                    Modal.toast("Subido.")
                    saveTask(followUpTask);
                });
            });
        }

        function uploadTaskFileNext(followUpTask, file) {
            vm.isSaving = true;
            followUpTask.isSavingFileTask = true;
            var fileName = makeid(4, file.name) + " - " + vm.fileName;
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/follow-up/' + vm.house.housenumber + '/' + vm.followUp.id + "/next-task/" + fileName).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        followUpTask.fileprogress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    followUpTask.fileUrl = downloadURL;
                    followUpTask.fileName = file.name;
                    followUpTask.hasFile = true;
                    Modal.toast("Subido.")
                    saveNextTask(followUpTask);
                });
            });
        }

        function upload(recordFile) {
            vm.isSaving = true;
            vm.isSavingFile = true;
            var fileName = makeid(4, file.name) + " - " + vm.fileName;
            var uploadTask = AditumStorageService.ref().child(globalCompany.getId() + '/follow-up/' + vm.house.housenumber + '/' + vm.followUp.id + "/" + fileName).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    })
                }, 1)
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    vm.followUp.fileUrl = downloadURL;
                    vm.followUp.fileName = recordFile.name;
                    vm.followUp.hasFile = true;
                    Modal.toast("Subido.")
                    save();
                });
            });
        }

        function onSaveSuccess(result) {
            vm.followUp = result;
            vm.lastModification = moment(vm.followUp.lastModification).format("MMM DD, hh:mm a");
            vm.finishDate = moment(vm.followUp.finishDate).format("MMM DD, hh:mm a");
            vm.isSaving = false;
            vm.isSavingFile = false;
            vm.progress = 0;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.initialDate = false;
        vm.datePickerOpenStatus.finishDate = false;
        vm.datePickerOpenStatus.lastModifcation = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
