(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('RecordFile', RecordFile);

    RecordFile.$inject = ['$resource', 'DateUtils'];

    function RecordFile($resource, DateUtils) {
        var resourceUrl = 'api/record-files/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getByCategoryAndHouse': {
                url: "api/record-files/:houseId/:categoryId",
                isArray: true
            },
            'getByCategoryAndHouseAndFolder': {
                url: "api/record-files/:houseId/:categoryId/:folderId",
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dateUploaded = DateUtils.convertDateTimeFromServer(data.dateUploaded);
                        data.lastModification = DateUtils.convertDateTimeFromServer(data.lastModification);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
