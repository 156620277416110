(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PaymentDeleteController', PaymentDeleteController);

    PaymentDeleteController.$inject = ['$uibModalInstance', 'entity', 'Payment', 'CommonMethods', '$stateParams', 'AccountingPeriod', '$timeout', '$scope', 'globalCompany', 'Modal'];

    function PaymentDeleteController($uibModalInstance, entity, Payment, CommonMethods, $stateParams, AccountingPeriod, $timeout, $scope, globalCompany, Modal) {
        var vm = this;
        vm.expense = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.saving = false;
        vm.today = new Date();
        var id = CommonMethods.decryptIdUrl($stateParams.paymentId);

        Payment.get({id: id}, function (result) {
            vm.payment = result;
            AccountingPeriod.getLastPeriodCloseDate({companyId: globalCompany.getId()}, function (result) {
                $timeout(function () {
                    $scope.$apply(function () {
                        vm.accountingPeriodCloseDate = new Date(result.finalDayCloseDate);
                        if (vm.accountingPeriodCloseDate < vm.payment.date) {
                            vm.paymentDeleteDate = vm.payment.date;
                        }
                    });
                });
            });
        });

        vm.deletePayment = function () {
            Modal.confirmDialog("¿Está seguro que desea anular el pago?", "La fecha del asiento de anulación será la seleccionada", function () {
                Payment.deletePayment({id: id, deletionDate: moment(vm.paymentDeleteDate).format()}, function () {
                    Modal.toast("Anulado correctamente");
                    $uibModalInstance.close();
                });
            });
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.originalBillDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
