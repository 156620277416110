(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('MinutesDetailController', MinutesDetailController);

    MinutesDetailController.$inject = ['$state','Modal', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CondominiumRecord', 'Company'];

    function MinutesDetailController($state,Modal, $scope, $rootScope, $stateParams, previousState, entity, CondominiumRecord, Company) {
        var vm = this;

        vm.condominiumRecord = entity;
        vm.previousState = previousState.name;
        $rootScope.active = "records";
        vm.isReady = true;

        vm.textDocumentName = "del documento";
        vm.textDocumentName2 ="documento";

        vm.back = function(){
            $state.go("minutes")
        }

        $rootScope.backState = "minutes";

        vm.showFile = function(){
            $state.go("minutes-detail.file-preview",
                {
                    url: vm.condominiumRecord.fileUrl,
                })
        }
        var unsubscribe = $rootScope.$on('aditumApp:condominiumRecordUpdate', function (event, result) {
            vm.condominiumRecord = result;
        });
        $rootScope.mainTitle = "Detalle del documento";
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
