(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('RevisionConfigDialogController', RevisionConfigDialogController);

    RevisionConfigDialogController.$inject = ['$state', 'Modal', 'globalCompany', 'ParseLinks', '$rootScope', '$timeout', '$scope', '$stateParams', 'entity', 'RevisionConfig', 'Company', 'RevisionTaskCategory'];

    function RevisionConfigDialogController($state, Modal, globalCompany, ParseLinks, $rootScope, $timeout, $scope, $stateParams, entity, RevisionConfig, Company, RevisionTaskCategory) {
        var vm = this;

        vm.revisionConfig = entity;
        vm.clear = clear;
        vm.save = save;
        Modal.enteringForm(save);
        vm.isSavingCategory = false;

        $scope.$on("$destroy", function () {
            Modal.leavingForm();
        });
        if (vm.revisionConfig.id == undefined) {
            $rootScope.mainTitle = 'Crear configuración de revisión';
        } else {
            $rootScope.mainTitle = 'Editar configuración de revisión';
        }
        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        vm.saveCategory = function (item) {
            var accion = "registrarla";
            if (vm.categoryNew) {
                accion = "editarla"
            }
            Modal.confirmDialog("¿Está seguro que desea " + accion + "?", "", function () {
                vm.isSavingCategory = true;
                if (vm.categoryNew) {
                    vm.categoryNew.description = vm.newCategoryTitle;
                    RevisionTaskCategory.update(vm.categoryNew, function (data) {
                        Modal.toast("Listo.")
                        vm.isSavingCategory = false;
                        item.addingCategory = false;
                        loadAll();
                    }, function () {
                        Modal.toast("Ocurrio un error.")
                        vm.isSavingCategory = false;
                    });
                } else {
                    vm.categoryNew = {};
                    vm.categoryNew.description = vm.newCategoryTitle;
                    vm.categoryNew.companyId = globalCompany.getId();
                    vm.categoryNew.deleted = 0;
                    vm.categoryNew.type = 1;
                    vm.categoryNew.order = 1;
                    RevisionTaskCategory.save(vm.categoryNew, function (data) {
                        Modal.toast("Listo.")
                        vm.isSavingCategory = false;
                        item.addingCategory = false;
                        loadAll();
                        vm.categoryNew = undefined;
                    }, function () {
                        Modal.toast("Ocurrio un error.")
                        vm.isSavingCategory = false;
                    });
                }
            })
        }

        vm.editRevCategory = function (item, category) {
            vm.categoryNew = category;
            item.addingCategory = true;
            vm.newCategoryTitle = category.description;
        }

        vm.deleteRevCategory = function (item) {
            Modal.confirmDialog("¿Está seguro que eliminar la categoría?", "SE ELIMINARÄ LA CATEGORÍA EN TODAS LAS REVISIONES REALIZADAS.", function () {
                RevisionTaskCategory.delete({id: item.id},
                    function () {
                        Modal.toast("Listo.")
                        loadAll();
                    });
            })
        }
        vm.searchTerm;
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }
        loadAll();
        vm.newCategory = function (item) {
            vm.categoryNew = undefined;
            vm.newCategoryTitle = undefined;
            item.addingCategory = true;
        }

        vm.cancelCategory = function (item) {
            item.addingCategory = false;
        }

        function loadAll() {
            RevisionTaskCategory.findByCompany({
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.revisionTaskCategories = [];
                for (var i = 0; i < data.length; i++) {
                    data[i].addingCategory = false;
                    vm.revisionTaskCategories.push(data[i]);
                }
            }

            function onError(error) {
            }
        }

        function clear() {
        }

        vm.addTask = function () {
            vm.revisionConfig.configTasks.push({deleted: 0});
        }

        vm.delete = function ($index) {
            Modal.confirmDialog("¿Está seguro que desea quitar esta tarea?", "", function () {
                vm.revisionConfig.configTasks[$index].deleted = 1;
            })
        }
        vm.countConfigTasks = function () {
            var count = 0;
            for (var i = 0; i < vm.revisionConfig.configTasks.length; i++) {
                if (vm.revisionConfig.configTasks[i].deleted == 0) {
                    count++;
                }
            }
            return count;
        }

        function save() {
            var dialog = "";
            if (vm.revisionConfig.id == undefined) {
                dialog = '¿Está seguro que desea crear la configuración de la revisión?';
            } else {
                dialog = '¿Está seguro que desea editar la configuración de la revisión?';
            }
            if (vm.countConfigTasks() == 0) {
                Modal.toast("Debe de agregar al menos una tarea a la configuración de revisión.");
            } else {
                Modal.confirmDialog(dialog, "", function () {
                    Modal.showLoadingBar();
                    vm.isSaving = true;
                    vm.revisionConfig.companyId = globalCompany.getId();
                    vm.revisionConfig.deleted = 0;
                    if (vm.revisionConfig.id !== null) {
                        RevisionConfig.update(vm.revisionConfig, onSaveSuccess, onSaveError);
                    } else {
                        RevisionConfig.save(vm.revisionConfig, onSaveSuccess, onSaveError);
                    }
                })
            }
        }

        function onSaveSuccess(result) {
            Modal.hideLoadingBar();
            $state.go('revision-config');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
