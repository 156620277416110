(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('Egress', Egress);

    Egress.$inject = ['$resource', 'DateUtils'];

    function Egress($resource, DateUtils) {
        var resourceUrl = 'api/egresses/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'deleteEgress': {method: 'GET', url:"api/egress-delete/:id/:deletionDate", isArray: false},
            'get': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                        data.paymentDate = DateUtils.convertDateTimeFromServer(data.paymentDate);
                        data.expirationDate = DateUtils.convertDateTimeFromServer(data.expirationDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'findUntilDatesByCompany': {
                method: 'GET',
                url: 'api/egresses-until/:final_time/byCompany/:companyId',
                isArray: false,
                params: {
                    final_time: '@final_time',
                    companyId: '@companyId',
                }
            },
            'findBetweenDatesByCompany': {
                method: 'GET',
                url: 'api/egresses/between/:initial_time/:final_time/byCompany/:companyId',
                isArray: false,
                params: {
                    initial_time: '@initial_time',
                    final_time: '@final_time',
                    companyId: '@companyId',
                }
            },
            'findBetweenDatesByCompanyAndAccount': {
                method: 'GET',
                url: 'api/egresses/between/:initial_time/:final_time/byCompany/:companyId/andAccount/:accountId',
                isArray: true,
                params: {
                    initial_time: '@initial_time',
                    final_time: '@final_time',
                    companyId: '@companyId',
                    accountId: '@accountId'
                }
            },

            'getEgressToPay': {
                method: 'GET',
                url: 'api/egresses/reportEgressToPay/:final_time/byCompany/:companyId',
                isArray: true,
                params: {
                    final_time: '@final_time',
                    companyId: '@companyId',
                }
            },
            'findBetweenCobroDatesByCompany': {
                method: 'GET',
                url: 'api/egresses/betweenCobro/:initial_time/:final_time/byCompany/:companyId',
                isArray: true,
                params: {
                    initial_time: '@initial_time',
                    final_time: '@final_time',
                    companyId: '@companyId',
                }
            },
            'generateReport': {
                method: 'GET',
                url: 'api/egresses/egressReport/:initial_time/:final_time/:companyId/:empresas/:selectedCampos/:accountsToPay',
                params: {
                    initial_time: '@initial_time',
                    final_time: '@final_time',
                    companyId: '@companyId',
                    empresas: '@empresas',
                    selectedCampos: '@selectedCampos',
                    accountsToPay: '@accountsToPay'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
