(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BlockCommonAreaDetailController', BlockCommonAreaDetailController);

    BlockCommonAreaDetailController.$inject = ['Modal','CommonMethods','$scope', '$rootScope', '$stateParams', 'previousState', 'BlockCommonArea', 'CommonArea', 'Company'];

    function BlockCommonAreaDetailController(Modal,CommonMethods, $scope, $rootScope, $stateParams, previousState, BlockCommonArea, CommonArea, Company) {
        var vm = this;
        vm.isReady = false;

        $rootScope.mainTitle = "Bloqueos del área"
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });
        $rootScope.backState = "common-area-administration.common-area";

        vm.previousState = previousState.name;
        vm.id = CommonMethods.decryptIdUrl($stateParams.id);

        function esEntero(numero) {
            if (numero % 1 == 0) {
                return true;
            } else {
                return false;
            }
        }

        vm.formatHour = function(allTimes){
            var times = allTimes.split(",");
            var initialValue = times[0];
            var finalValue = times[1];
            var item = {};
            if (Math.round(times[0]) > 12) {
                if (esEntero(parseFloat(times[0]))) {
                    item.initialTime = parseFloat(times[0]) - 12 + ":00PM"
                } else {
                    item.initialTime = formatHourToDisplayPM(times[0]);
                }
            } else {
                var twelve = Math.round(times[0]);
                if (twelve == 12) {
                    if (esEntero(parseFloat(times[0]))) {
                        item.initialTime = "12:00PM"
                    } else {
                        item.initialTime = formatHourToDisplayPM(times[0]);
                    }
                } else {
                    if (esEntero(parseFloat(times[0]))) {
                        if (times[0] == 0) {
                            item.initialTime = "12:00AM"
                        } else {
                            item.initialTime = parseFloat(times[0]) + ":00AM"
                        }
                    } else {
                        item.initialTime = formatHourToDisplayAM(times[0]);
                    }
                }
            }
            if (Math.round(times[1]) >= 12) {
                if (times[1] > 12 && times[1] < 13) {
                    if (esEntero(parseFloat(times[1]))) {
                        item.finalTime = parseFloat(times[1]) + ":00PM"
                    } else {
                        item.finalTime = formatHourToDisplayPM(times[1]);
                    }
                } else {
                    if (esEntero(parseFloat(times[1]))) {
                        if (parseFloat(times[1]) == 12) {
                            item.finalTime = "12:00PM"
                        } else {
                            item.finalTime = parseFloat(times[1]) - 12 + ":00PM"
                        }
                    } else {
                        item.finalTime = formatHourToDisplayPM(times[1]);
                    }
                }
            } else {
                if (esEntero(parseFloat(times[1]))) {
                    if (times[1] == 0) {
                        item.finalTime = "12:00AM"
                    } else {
                        item.finalTime = parseFloat(times[1]) + ":00AM"
                    }
                } else {
                    item.finalTime = formatHourToDisplayAM(times[1]);
                }
            }
            return item.initialTime + " - " + item.finalTime;
        }


        function formatHourToDisplayAM(hour) {
            var twelve = Math.round(hour);
            var hourC = (hour - Math.round(hour)).toFixed(2);
            var result = "";
            var sum = (twelve == 0 || twelve == 1) && hour > 13 ? 12 : 0;
            var total = parseFloat(hour.split(".")[0]) + sum;
            if ((hourC == 0.15)) {
                result = total + ":15AM";
            }
            if ((hourC == 0.5 || hourC == -0.5)) {
                result = total + ":30AM";
            }
            if ((hourC == 0.75 || hourC == -0.75)) {
                result = total + ":45AM";
            }
            if ((hourC == 0.25 || hourC == -0.25)) {
                result = total + ":45AM";
            }
            if ((hourC == 0.45 || hourC == -0.45)) {
                result = total + ":45AM";
            }
            return result;
        }

        function formatHourToDisplayPM(hour) {
            var twelve = Math.round(hour);
            var hourC = (Math.round(hour) - hour).toFixed(2);
            var result = "";
            var rest = (twelve == 13 || twelve == 12) && hour < 13 ? 0 : 12;
            var total = parseFloat(hour.split(".")[0]) - rest;
            if ((hourC == 0.75 || hourC == -0.75)) {
                result = total + ":15PM";
            }
            if ((hourC == 0.15 || hourC == -0.15)) {
                result = total + ":15PM";
            }
            if ((hourC == 0.5 || hourC == -0.5)) {
                result = total + ":30PM";
            }
            if ((hourC == 0.25 || hourC == -0.25)) {
                result = total + ":45PM";
            }
            if ((hourC == 0.45 || hourC == -0.45)) {
                result = total + ":45PM";
            }
            return result;
        }


        vm.delete = function(block){
            Modal.confirmDialog("¿Está seguro que desea eliminar este bloqueo?","",function(){
                BlockCommonArea.delete({id:block.id},function(){
                    CommonMethods.deleteFromArray(block,vm.blockingAreas)
                    Modal.toast("Eliminado correctamente.");
                },function(){})
            })
        }

        function defineDayOfWeek(d){
            switch (d) {
                case "L":
                    return "Lunes";
                case "M":
                    return "Martes";
                case "X":
                    return "Miércoles";
                case "J":
                    return "Jueves";
                case "V":
                    return "Viernes";
                case "S":
                    return "Sábado";
                case "D":
                    return "Domingo";
            }
        }

        CommonArea.get({id:vm.id},function(data){
            vm.commonArea = data;
            BlockCommonArea.getByCommonArea({id:vm.id},function(data){
                vm.blockingAreas = data;
                for (var i = 0; i < vm.blockingAreas.length; i++) {
                    var block = vm.blockingAreas[i];
                    block.textHour = "";
                    if(block.scheduled==0){
                       block.textHour = vm.formatHour(block.hours)
                    }else{
                      var days = block.days.split("|")
                        for (var j = 0; j < days.length; j++) {
                            var day = days[j];
                            if(day!=""){
                                var dayT = day.split(";")[0]
                                var hours = day.split(";")[1];
                                block.textHour = block.textHour + defineDayOfWeek(dayT)+ " de " +vm.formatHour(hours) +", "
                            }
                        }
                    }
                }
                vm.isReady = true;
            },function(){

            })
        },function (){

        })



        var unsubscribe = $rootScope.$on('aditumApp:blockCommonAreaUpdate', function(event, result) {
            vm.blockCommonArea = result;
        });

        $scope.$on('$destroy', unsubscribe);
    }
})();
