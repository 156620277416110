(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingFullPeriodController', AccountingFullPeriodController);

    AccountingFullPeriodController.$inject = ['AccountingFullPeriod', 'globalCompany', '$state', 'CommonMethods', 'Modal', '$rootScope'];

    function AccountingFullPeriodController(AccountingFullPeriod, globalCompany, $state, CommonMethods, Modal, $rootScope) {

        var vm = this;
        vm.isReady = false;
        vm.accountingFullPeriods = [];
        $rootScope.active = "accountingPeriod";

        loadAll();
        vm.createMonthClosing = function (period) {
            if (period.accountingPeriods.length == 0) {
                Modal.confirmDialog("¿Está seguro que desea crear el cierre mensual?", "Es NECESARIO que haya configurado las cuentas contables por completo antes de crear el primer cierre mensual", function () {
                    var periodId = CommonMethods.encryptIdUrl(period.id)
                    $state.go('accounting-full-period.newMonthClosing', {periodId: periodId});
                })
            } else {
                var periodId = CommonMethods.encryptIdUrl(period.id)
                $state.go("accounting-full-period.newMonthClosing", {periodId: periodId})
            }
        }
        vm.detailMonth = function (period) {
            var id = CommonMethods.encryptIdUrl(period.id);
            $state.go('accounting-period-detail', {id: id});
        }
        vm.closePeriod = function (period) {
            Modal.confirmDialog("¿Está seguro que desea realizar el cierre contable?", "Se crearán los asientos de cierre y el nuevo periodo", function () {
                var id = CommonMethods.encryptIdUrl(period.id)
                $state.go('accounting-full-period.close', {lastPeriodId: id});
            })
        }

        function loadAll() {
            AccountingFullPeriod.findByCompany({companyId: globalCompany.getId()}, function (result) {
                for (var i = 0; i < result.length; i++) {
                    result[i].isOpen = i === result.length - 1;
                    result[i].haveAnyPeriodOpen = vm.haveAnyPeriodOpen(result[i]);
                    vm.accountingFullPeriods.push(result[i])
                }
                vm.isReady = true;
            });
        }

        vm.haveAnyPeriodOpen = function (period) {
            var isAnyOpen = 0;
            for (var i = 0; i < period.accountingPeriods.length; i++) {
                if (period.accountingPeriods[i].status == 1) {
                    isAnyOpen++;
                }
            }
            return isAnyOpen != 0;
        }
    }
})();
