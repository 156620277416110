(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('EmailConfiguration', EmailConfiguration);

    EmailConfiguration.$inject = ['$resource'];

    function EmailConfiguration($resource) {
        var resourceUrl = 'api/email-configurations/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getEmailConfigurationByCompany':
                {method: 'GET', url: "api/email-configurations/by-company/:companyId"},
            'getEmailActivity':
                {
                    method: 'GET',
                    isArray: true,
                    url: "api/email-configurations/email-activity/:companyId/:toEmail/:subject"
                },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
