(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('Budget', Budget);

    Budget.$inject = ['$resource', 'DateUtils'];

    function Budget ($resource, DateUtils) {
        var resourceUrl =  'api/budgets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'findByCompany': { method: 'GET', isArray: true, url:"api/budgets-by-company/:companyId"},
            'getExecutionAnalysis': { method: 'GET', isArray: false, url:"api/budgets-get-execution-analysis/:companyId/:budgetId/:finalDate/:reportType"},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startingDate = DateUtils.convertDateTimeFromServer(data.startingDate);
                        data.finishDate = DateUtils.convertDateTimeFromServer(data.finishDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
