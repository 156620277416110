(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('budget', {
                parent: 'entity',
                url: '/budget',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.budget.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/budget/budgets.html',
                        controller: 'BudgetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('budget');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('budget-execution-analysis', {
                parent: 'entity',
                url: '/budget-execution-analysis',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.budget.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/budget/budget-execution-analysis.html',
                        controller: 'BudgetExecutionAnalysisController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('budget');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('budget-detail', {
                parent: 'budget',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.budget.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/budget/budget-detail.html',
                        controller: 'BudgetDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('budget');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Budget', 'CommonMethods', function ($stateParams, Budget, CommonMethods) {
                        var id = CommonMethods.decryptIdUrl($stateParams.id);
                        return Budget.get({id: id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'budget',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('budget-detail.edit', {
                parent: 'budget-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/budget/budget-dialog.html',
                        controller: 'BudgetDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('budget.new', {
                parent: 'budget',
                url: '/new',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/budget/budget-dialog.html',
                        controller: 'BudgetDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    year: null,
                                    startingDate: null,
                                    finishDate: null,
                                    deleted: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('budget', null, {reload: 'budget'});
                    }, function () {
                        $state.go('budget');
                    });
                }]
            })
            .state('budget.edit', {
                parent: 'budget',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/budget/budget-dialog.html',
                        controller: 'BudgetDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('budget', null, {reload: 'budget'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('budget.delete', {
                parent: 'budget',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/budget/budget-delete-dialog.html',
                        controller: 'BudgetDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('budget', null, {reload: 'budget'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
