(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FiscalActivityCodeController', FiscalActivityCodeController);

    FiscalActivityCodeController.$inject = ['FiscalActivityCode'];

    function FiscalActivityCodeController(FiscalActivityCode) {

        var vm = this;

        vm.fiscalActivityCodes = [];

        loadAll();

        function loadAll() {
            FiscalActivityCode.query(function(result) {
                vm.fiscalActivityCodes = result;
                vm.searchQuery = null;
            });
        }
    }
})();
