(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('CommonAreaAllReservationsController', CommonAreaAllReservationsController);

    CommonAreaAllReservationsController.$inject = ['$localStorage', '$scope', '$state', 'CommonAreaReservations', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'CommonArea', 'House', 'Resident', '$rootScope', 'CommonMethods', 'globalCompany', 'Modal'];

    function CommonAreaAllReservationsController($localStorage, $scope, $state, CommonAreaReservations, ParseLinks, AlertService, paginationConstants, pagingParams, CommonArea, House, Resident, $rootScope, CommonMethods, globalCompany, Modal) {
        var vm = this;
        $rootScope.active = "reservationAdministration";
        vm.reverse = true;
        vm.loadPage = loadPage;
        vm.isReady = false;
        vm.isConsulting = false;
        vm.showFilterDiv = true;
        $rootScope.mainTitle = "Reservaciones";
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.consult = consult;
        vm.finalListReservations = [];
        vm.itemsPerPage = 30;
        vm.dates = {};
        vm.page = 0;
        vm.houseId = {id: -1};
        vm.areaId = {id: -1};

        vm.links = {
            last: 0
        };

        loadAllAreas();

        function loadAllAreas() {
            CommonArea.query({
                page: 0,
                size: 30,
                companyId: globalCompany.getId()
            }, onSuccessArea, onError);

            function onSuccessArea(data, headers) {
                vm.commonAreas = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };
        vm.searchTerm;
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        }

        vm.clearSearchTerm = function () {
            vm.searchTermArea = '';
        };
        vm.searchTermArea;
        vm.typingSearchTermArea = function (ev) {
            ev.stopPropagation();
        }

        loadAllHouses();

        function loadAllHouses() {
            if ($localStorage.houses) {
                onSuccessHouses($localStorage.houses)
            } else {
                House.getAllHousesClean({
                    companyId: globalCompany.getId()
                }, onSuccessHouses, onError);
            }
        }

        function onSuccessHouses(data) {
            vm.houses = data;
        }

        vm.defineIfAbleToCancel = function (reservation) {
            if (reservation.comments != null) {
                return !(reservation.comments.split("Reserva automática por reservar ").length > 1)
            }
            return true;
        }
        vm.exportingExcel = false;
        vm.tableToExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)))
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                })
                            }
                        var workSheetName = "Reservaciones - " + $rootScope.company.name + " - del " + moment(vm.dates.initial_time).format("L") + " al " + moment(vm.dates.final_time).format("L");
                        if (!table.nodeType) table = document.getElementById(table)
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx))
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1)
                    vm.notExportingExcel = true;
                }, 500)
            })
        }
        loadAll();
        vm.detailProof = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $state.go('payment-proof-detail', {
                id: encryptedId
            })
        };

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadAll() {
            var d = new Date; // get current date
            if (vm.isConsulting == false) {
                vm.dates.initial_time = new Date()
                vm.dates.final_time = new Date()
                vm.dates.final_time.setDate(d.getDate() + 2);
            }
            CommonAreaReservations.findBetweenDatesByCompanyAndCommonAreaAndHouseId({
                initial_time: moment(vm.dates.initial_time).format(),
                final_time: moment(vm.dates.final_time).format(),
                companyId: globalCompany.getId(),
                houseId: vm.houseId.id,
                commonAreaId: vm.areaId.id,
                page: vm.page,
                status: -1,
                size: 30,
            }, onSuccess, onError);

            function sort() {
                var result = [];
                if (vm.predicate !== 'initalDate') {
                    // result.push('initalDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                for (var i = 0; i < data.length; i++) {
                    data[i].schedule = formatScheduleTime(data[i].initialTime, data[i].finalTime);
                    data[i].initalDate = CommonMethods.setTimezone(new Date(data[i].initalDate));
                    vm.finalListReservations.push(data[i])
                    console.log(vm.finalListReservations);
                }
                vm.isReady = true;
            }

            function onError(error) {
                // AlertService.error(error.data.message);
            }
        }

        vm.stopConsulting = function () {
            vm.isReady = false;
            vm.dates = {
                initial_time: undefined,
                final_time: undefined
            };
            vm.houseId = {id: -1};
            vm.areaId = {id: -1};
            vm.page = 0;
            pagingParams.search = null;
            vm.isConsulting = false;
            vm.finalListReservations = [];
            loadAll();
        }

        function consult() {
            vm.isReady = false;
            vm.page = 0;
            vm.isConsulting = true;
            vm.finalListReservations = [];
            loadAll();
        }

        function loadInfoByReservation(data) {
            angular.forEach(data, function (value) {
                value.schedule = formatScheduleTime(value.initialTime, value.finalTime);
            });
            vm.isReady = true;
        }

        function esEntero(numero) {
            if (numero % 1 == 0) {
                return true;
            } else {
                return false;
            }
        }

        function formatScheduleTime(initialTime, finalTime) {
            var times = [];
            times.push(initialTime);
            times.push(finalTime);
            angular.forEach(times, function (value, key) {
                if (value == 0) {
                    times[key] = "12:00AM"
                } else if (value < 12) {
                    if (esEntero(parseFloat(value))) {
                        times[key] = value + ":00AM"
                    } else {
                        var time = value.split(".")[0];
                        var min = value.split(".")[1];
                        if (min == 75) {
                            min = 45;
                        }
                        if (min == 5) {
                            min = 30;
                        }
                        times[key] = time + ":" + min + "AM";
                    }
                } else if (value > 12) {
                    if (esEntero(parseFloat(value))) {
                        times[key] = value - 12 + ":00PM"
                    } else {
                        var time = value.split(".")[0];
                        var min = value.split(".")[1];
                        if (min == 75) {
                            min = 45;
                        }
                        if (min == 5) {
                            min = 30;
                        }
                        times[key] = time - 12 + ":" + min + "PM";
                    }
                } else if (value == 12) {
                    times[key] = value + ":00PM"
                }
            });
            return times[0] + " - " + times[1]
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        vm.denyReservation = function (reservation) {
            Modal.confirmDialog("¿Está seguro que desea rechazar la reservación?", "Una vez registrada esta información no se podrá editar",
                function () {
                    Modal.showLoadingBar()
                    reservation.sendPendingEmail = true;
                    reservation.status = 3;
                    reservation.initalDate = new Date(reservation.initalDate)
                    reservation.initalDate.setHours(0);
                    reservation.initalDate.setMinutes(0);
                    CommonAreaReservations.update(reservation, onDenySuccess, onSaveError);

                });


        };
        vm.aprobeReservation = function () {
            Modal.toast("Para aprobar una solicitud debe ingresar al detalle de la reservación.")

        };
        vm.deleteReservation = function (commonArea) {
            Modal.confirmDialog("¿Está seguro que desea eliminar la solicitud de reservación?", "",
                function () {
                    commonArea.initalDate = new Date(commonArea.initalDate)
                    commonArea.initalDate.setHours(0);
                    commonArea.initalDate.setMinutes(0);
                    Modal.showLoadingBar();
                    commonArea.status = 4;
                    CommonAreaReservations.update(commonArea, onDeleteSuccess, onSaveError);
                });
        };

        vm.cancelReservation = function (reservation) {
            Modal.confirmDialog("¿Está seguro que desea cancelar la reservación?", "Una vez registrada esta información no se podrá editar",
                function () {
                    Modal.showLoadingBar()
                    reservation.sendPendingEmail = true;
                    reservation.status = 11;
                    reservation.initalDate = new Date(reservation.initalDate)
                    reservation.initalDate.setHours(0);
                    reservation.initalDate.setMinutes(0);
                    CommonAreaReservations.update(reservation, onCancelSuccess);
                });
        };

        function onCancelSuccess(result) {
            Modal.hideLoadingBar();
            vm.isReady = false;
            vm.finalListReservations = [];
            Modal.toast("Se ha cancelado la reservación correctamente.")
            loadAll();

        }

        function onDenySuccess(result) {

            loadAll();
            Modal.toast("Se ha rechazado la reservación correctamente.")
            Modal.hideLoadingBar();

        }

        function onDeleteSuccess(result) {

            loadAll();
            Modal.toast("Se eliminó la solicitud de reservación correctamente");
            Modal.hideLoadingBar();
            // $state.go('common-area-administration.common-area-all-reservations');
            //
        }

        function onSaveError(error) {
            Modal.hideLoadingBar();
            Modal.toast("Un error inesperado ocurrió");
            AlertService.error(error.data.message);
        }

        vm.rechargeAll = function () {
            vm.finalListReservations = [];
            loadAll();
        }

        function transition() {
            // $state.transitionTo($state.$current, {
            //     page: vm.page,
            //     sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            //     search: vm.currentSearch
            // });
            loadAll();
        }
    }


})
();
