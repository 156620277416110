(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FilePreviewRecordController', FilePreviewRecordController);

    FilePreviewRecordController.$inject = ['AditumStorageService','$scope', '$sce', 'globalCompany', '$stateParams', '$state', 'RecordCategory', '$uibModalInstance', 'CommonMethods'];

    function FilePreviewRecordController(AditumStorageService,$scope, $sce, globalCompany, $stateParams, $state, RecordCategory, $uibModalInstance, CommonMethods) {

        var vm = this;
        vm.url = $sce.trustAsResourceUrl($stateParams.url);
        if($stateParams.type){
            vm.isImage =  $stateParams.type=="image";
        }else{
            vm.isImage =  false;
        }
        vm.isVideo = false;
        vm.isFile =  !vm.isImage;
        $scope.$on("$destroy", function () {
            $uibModalInstance.close()
        });
        var fileRef = AditumStorageService.storage().refFromURL($stateParams.url);

        fileRef.getMetadata().then(function(metadata) {
            // Verifica el tipo de contenido del archivo
            if (metadata.contentType === 'application/pdf') {
                console.log("El archivo es un PDF.");
                vm.isFile = true;
                vm.isImage = false;
            } else if (metadata.contentType.startsWith('image/')) {
                console.log("El archivo es una imagen.");
                vm.isFile = false;
                vm.isImage = true;
            } else {
                console.log("No se pudo determinar el tipo de archivo.");
            }
        }).catch(function(error) {
            console.error("Error al obtener los metadatos del archivo:", error);
        });

        vm.encodedUrl = encodeURIComponent(vm.url);
        vm.urlFile = "https://docs.google.com/gview?url=" + vm.encodedUrl + "&embedded=true&zoom=100";
        vm.urlFile = $sce.trustAsResourceUrl(vm.urlFile);
        vm.close = function () {
            $uibModalInstance.close();
        }
    }
})();
