(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('AccountingPeriod', AccountingPeriod);

    AccountingPeriod.$inject = ['$resource', 'DateUtils'];

    function AccountingPeriod($resource, DateUtils) {
        var resourceUrl = 'api/accounting-periods/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getAllByCompany': {method: 'GET', url: 'api/accounting-periods-by-company/:companyId', isArray: true},
            'getLastPeriodCloseDate': {
                method: 'GET',
                url: 'api/accounting-period-get-final-date/:companyId',
                isArray: false
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.closeDate = DateUtils.convertDateTimeFromServer(data.closeDate);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
