(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('RecordFileController', RecordFileController);

    RecordFileController.$inject = ['MultiCompany', 'Principal', 'FollowUpNextStep', 'globalCompany', '$stateParams', '$state', 'RecordCategory', '$rootScope', 'AlertService', 'paginationConstants', 'pagingParams', 'House', 'CommonMethods', 'FollowUp', 'Company'];

    function RecordFileController(MultiCompany, Principal, FollowUpNextStep, globalCompany, $stateParams, $state, RecordCategory, $rootScope, AlertService, paginationConstants, pagingParams, House, CommonMethods, FollowUp, Company) {

        var vm = this;
        $rootScope.active = "house-record";
        $rootScope.mainTitle = "Expedientes";
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.isReady = false;
        vm.houseId = CommonMethods.decryptString($stateParams.houseId);
        vm.expadingFinished = false;
        vm.followUpNextSteps = []
        vm.followUps = [];
        if (vm.houseId != -1) {
            House.get({id: vm.houseId}, function (data) {
                vm.house = data;
            })
            vm.onlyCompany = 0;
        } else {
            Company.get({id: globalCompany.getId()},
                function (data) {
                    vm.house = {};
                    vm.house.housenumber = data.name;
                })
            vm.onlyCompany = -1;
        }
        Principal.identity().then((function (data) {
            MultiCompany.getCurrentUserCompany().then(function (data) {
                vm.userId = data.id;
            })
        }))
        vm.goToCategory = function (recordCategoryId) {
            var id = CommonMethods.encryptString(recordCategoryId + "");
            $state.go("record-file.record-category", {categoryId: id})
        }

        vm.goToFollowUp = function (followUpId) {
            var houseId = CommonMethods.encryptString(vm.houseId + "");
            var id = CommonMethods.encryptString(followUpId + "");
            $state.go("follow-up.edit", {houseId: houseId, id: id});
        }

        vm.newFollowUp = function () {
            var id = CommonMethods.encryptString(vm.houseId + "");
            $state.go("record-file.newFollowUp", {houseId: id})
        }

        loadAll();

        function loadAll() {
            RecordCategory.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
                onlyCompany:vm.onlyCompany+""
            }, onSuccess, onError);

            if (vm.houseId != -1) {
                FollowUp.queryByHouse({
                    page: 0,
                    size: 1000,
                    houseId: vm.houseId,
                }, onSuccessFollowUp, onError);
            } else {
                FollowUp.queryByCompanyOnly({
                    page: 0,
                    size: 1000,
                    companyId: globalCompany.getId(),
                }, onSuccessFollowUp, onError);
            }

            function onSuccessFollowUp(data, headers) {
                vm.totalItemsFollowUp = headers('X-Total-Count');
                vm.queryCountFollowUp = vm.totalItemsFollowUp;
                vm.followUps = data;
                vm.followUpsPendings = [];
                vm.followUpsProccess = [];
                vm.followUpsReadies = []
                var date = new Date();
                for (var i = 0; i < vm.followUps.length; i++) {
                    if (date.getTime() >= new Date(vm.followUps[i].dueDate).getTime()) {
                        vm.followUps[i].due = true;
                    } else {
                        vm.followUps[i].due = false;
                    }
                    switch (vm.followUps[i].status) {
                        case "0":
                            vm.followUpsPendings.push(vm.followUps[i]);
                            break;
                        case "1":
                            vm.followUpsProccess.push(vm.followUps[i]);
                            break;
                        case "2":
                            vm.followUpsReadies.push(vm.followUps[i]);
                            break;
                    }
                }
                vm.followUpsReady = true;

                vm.isReady = true;
            }
            if (vm.houseId != -1) {
                FollowUpNextStep.queryByHouseId({
                    page: 0,
                    size: 1000,
                    houseId: vm.houseId,
                }, onSuccessFollowUpNextSteps, onError);
            } else {
                FollowUpNextStep.queryByCompanyOnly({
                    page: 0,
                    size: 1000,
                    companyId: globalCompany.getId(),
                }, onSuccessFollowUpNextSteps, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccessFollowUpNextSteps(data, headers) {
                vm.totalItemsFollowUpNextStep = headers('X-Total-Count');
                vm.queryCountFollowUpNextStep = vm.totalItemsFollowUpNextStep;
                var date = new Date();
                for (var i = 0; i < data.length; i++) {
                    if (vm.defineIfollowUpIsShowed(data[i])) {
                        if (date.getTime() >= new Date(data[i].reminderDate).getTime()) {
                            data[i].due = true;
                        } else {
                            data[i].due = false;
                        }
                        vm.followUpNextSteps.push(data[i])
                    }
                }
                vm.followUpNextStepsReady = true;
                vm.isReady = true;
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.recordCategories = data;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.defineIfollowUpIsShowed = function (fNext) {
            return fNext.name != " " || fNext.name != undefined || fNext.name != null || fNext.description != " " || fNext.description != undefined || fNext.description != null;
        }
        // function loadAll() {
        //     RecordFile.query({
        //         page: pagingParams.page - 1,
        //         size: vm.itemsPerPage,
        //         sort: sort()
        //     }, onSuccess, onError);
        //
        //     function sort() {
        //         var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        //         if (vm.predicate !== 'id') {
        //             result.push('id');
        //         }
        //         return result;
        //     }
        //
        //     function onSuccess(data, headers) {
        //         vm.links = ParseLinks.parse(headers('link'));
        //         vm.totalItems = headers('X-Total-Count');
        //         vm.queryCount = vm.totalItems;
        //         vm.recordFiles = data;
        //         vm.page = pagingParams.page;
        //     }
        //
        //     function onError(error) {
        //         AlertService.error(error.data.message);
        //     }
        // }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
