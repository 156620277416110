(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('OwnerDetailController', OwnerDetailController);

    OwnerDetailController.$inject = ['CommonMethods', 'Modal', '$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Resident', 'User', 'Company', 'House', 'Principal'];

    function OwnerDetailController(CommonMethods, Modal, $state, $scope, $rootScope, $stateParams, previousState, DataUtils, entity, Resident, User, Company, House, Principal) {
        var vm = this;
        $rootScope.active = "resident-all";

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.resident = entity;

        formatResidentH(vm.resident);

        function formatResidentH(r) {
            for (var i = 0; i < r.houses.length; i++) {
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == r.houses[i].id) {
                        r.houses[i].role = defineRole(h.role);
                    }
                }
            }
        }

        vm.editResident = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id)
            $state.go('owner.edit', {
                id: encryptedId
            })
        };

        function defineRole(role) {
            switch (role) {
                case "ROLE_OWNER":
                    return !$rootScope.company.fullControlAccess?"Residente propietario":"Usuario administrador";
                case "ROLE_RENTER":
                    return "Arrendador";
                case "ROLE_TENANT":
                    return "Inquilino";
                case "ROLE_RESIDENT":
                    return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.isReady = false;
        $rootScope.mainTitle = 'Detalle de residente';
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });

        if (vm.resident.isOwner == 1) {
            vm.authorizer = "SI";
        } else {
            vm.authorizer = "NO";
        }
        if (vm.resident.email == "" || vm.resident.email == null) {
            vm.resident.email = "No registrado";
        }
        if (vm.resident.userLogin == "" || vm.resident.userLogin == null) {
            vm.resident.userLogin = "No registrado";
        }
        if (vm.resident.type == 1) {
            vm.resident.type = "Propietario residente";
        } else if (vm.resident.type == 2) {
            vm.resident.type = "Propietario no residente";
        }
        if (vm.resident.phonenumber == "" || vm.resident.phonenumber == null) {
            vm.resident.phonenumber = "No registrado";
        }

        if (vm.resident.houseId != null) {
            House.get({id: vm.resident.houseId}, onSuccessHouses);
        } else {
            vm.isReady = true;
        }

        function onSuccessHouses(house, headers) {
            vm.resident.houseId = house.housenumber;
            if (house.housenumber == 9999) {
                vm.resident.houseId = "Oficina";
            }
            $("#residentInformation").fadeIn(300);
            if (house.securityKey == null) {
                vm.securitykey = "No definida";
            } else {
                vm.securitykey = house.securityKey;
            }
            if (house.emergencyKey == null) {
                vm.emergencykey = "No definida";
            } else {
                vm.emergencykey = house.emergencyKey;
            }
            vm.isReady = true;
        }


        vm.title = vm.resident.name + " " + vm.resident.lastname + " " + vm.resident.secondlastname;
        var unsubscribe = $rootScope.$on('aditumApp:residentUpdate', function (event, result) {
            vm.resident = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
