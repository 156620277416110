(function () {
        'use strict';

        angular
            .module('aditumApp')
            .config(stateConfig);

        stateConfig.$inject = ['$stateProvider'];

        function stateConfig($stateProvider) {
            $stateProvider
                // .state('egress-tabs', {
                //     parent: 'entity',
                //     url: '/bills-to-pay',
                //     data: {
                //         authorities: ['ROLE_ADMIN', 'ROLE_JD','ROLE_MANAGER','ROLE_ACCOUNTANT'],
                //         pageTitle: 'aditumApp.egress.home.title'
                //     },
                //     views: {
                //         'content@': {
                //             templateUrl: 'app/entities/egress/egress-tabs.html',
                //             // templateUrl: 'app/entities/company/commingSoonFinanzes.html',
                //             controller: 'EgressTabsController',
                //             controllerAs: 'vm'
                //         }
                //     },
                //     resolve: {
                //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                //             $translatePartialLoader.addPart('egress');
                //             $translatePartialLoader.addPart('global');
                //             return $translate.refresh();
                //         }]
                //     }
                // })
                .state('bills-to-pay.deleteEgress', {
                    parent: 'bills-to-pay',
                    url: '/delete-egress/{egressId}',
                    data: {
                        authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/egress/egress-delete-dialog.html',
                            controller: 'EgressDeleteController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                entity: function () {
                                    return {
                                        concept: null,
                                        amount: null,
                                        date: null,
                                        reason: null,
                                        deleted: null,
                                        originalBillDate: null,
                                        referenceNumber: null,
                                        numericKey: null,
                                        electronicBillStatus: null,
                                        electronicBillResponse: null,
                                        isElectronicBillSend: null,
                                        id: null
                                    };
                                }
                            }
                        }).result.then(function () {
                            $state.go('bills-to-pay', null, {reload: 'bills-to-pay'});
                        }, function () {
                            $state.go('bills-to-pay');
                        });
                    }]
                })
                .state('bills-to-pay', {
                    parent: 'entity',
                    url: '/bills-to-pay',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_JD', 'ROLE_ACCOUNTANT'],
                        pageTitle: 'aditumApp.egress.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/egress/egresses.html',
                            controller: 'EgressController',
                            controllerAs: 'vm',
                        }
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate($stateParams.sort),
                                ascending: PaginationUtil.parseAscending($stateParams.sort),
                                search: $stateParams.search
                            };
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('egress');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).state('egress-report', {
                url: '/egress-report',
                parent: 'entity',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_JD', 'ROLE_ACCOUNTANT', 'ROLE_JD'],
                    pageTitle: 'Aditum'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/egress/generateReport.html',
                        controller: 'EgressGenerateReportController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('egress');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
                .state('egress-detail', {
                    parent: 'entity',
                    url: '/bill-to-pay-detail/{id}/{pay}',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_JD', 'ROLE_ACCOUNTANT',],
                        pageTitle: 'aditumApp.egress.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/egress/egress-detail.html',
                            controller: 'EgressDetailController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('egress');
                            return $translate.refresh();
                        }],
                        entity: ['$stateParams', 'Egress', 'CommonMethods', function ($stateParams, Egress, CommonMethods) {
                            var id = CommonMethods.decryptIdUrl($stateParams.id)
                            return Egress.get({id: id}).$promise;
                        }],
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'egress',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                }).state('egress-to-pay', {
                parent: 'entity',
                url: '/bills-to-pay-report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_JD', 'ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.egress.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/egress/egressToPayReport.html',
                        controller: 'EgressToPayReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('egress');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
                .state('egress-tabs.edit', {
                    parent: 'egress-tabs',
                    url: '/{id}/edit',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_JD', 'ROLE_ACCOUNTANT'],
                        pageTitle: 'aditumApp.egress.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/egress/egress-dialog-edit.html',
                            controller: 'EgressDialogController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('egress');
                            return $translate.refresh();
                        }],
                        entity: ['$stateParams', 'Egress', 'CommonMethods', function ($stateParams, Egress, CommonMethods) {
                            var id = CommonMethods.decryptIdUrl($stateParams.id)
                            return Egress.get({id: id}).$promise;
                        }],
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'egress',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                })
                .state('bills-to-pay.new', {
                    parent: 'entity',
                    url: '/bill-to-pay/new',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_JD', 'ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/egress/egress-dialog.html',
                            // templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                            controller: 'EgressDialogController',
                            controllerAs: 'vm',
                        }
                    },
                    resolve: {
                        entity: function () {
                            return {
                                date: null,
                                folio: null,
                                account: null,
                                category: null,
                                paymentMethod: null,
                                concept: null,
                                total: 0,
                                reference: null,
                                comments: null,
                                proveedor: null,
                                paymentDate: null,
                                expirationDate: null,
                                id: null
                            };
                        },
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'egress',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
                })
                .state('egress.edit', {
                    parent: 'egress',
                    url: '/{id}/reportPayment',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ACCOUNTANT',]
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/egress/reportPayment.html',
                            // templateUrl: 'app/entities/company/commingSoonFinanzes.html',
                            controller: 'EgressDialogController',
                            controllerAs: 'vm',
                        }
                    },

                    resolve: {
                        entity: ['$stateParams', 'Egress', 'CommonMethods', function ($stateParams, Egress, CommonMethods) {
                            var id = CommonMethods.decryptIdUrl($stateParams.id)
                            return Egress.get({id: id}).$promise;
                        }],
                        previousState: ["$state", function ($state) {
                            var currentStateData = {
                                name: $state.current.name || 'egress',
                                params: $state.params,
                                url: $state.href($state.current.name, $state.params)
                            };
                            return currentStateData;
                        }]
                    }
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/egress/egress-dialog.html',
//                    controller: 'EgressDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: ['Egress', function(Egress) {
//                            return Egress.get({id : $stateParams.id}).$promise;
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('egress', null, { reload: 'egress' });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
                })
                .state('egress.delete', {
                    parent: 'egress',
                    url: '/{id}/delete',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_JD', 'ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/egress/egress-delete-dialog.html',
                            // templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                            controller: 'EgressDeleteController',
                            controllerAs: 'vm',
                            size: 'md',
                            resolve: {
                                entity: ['Egress', function (Egress) {
                                    return Egress.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('egress', null, {reload: 'egress'});
                        }, function () {
                            $state.go('^');
                        });
                    }]
                });
        }

    }

)
();
