(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ChargeSpecificationDialogController', ChargeSpecificationDialogController);

    ChargeSpecificationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ChargeSpecification', 'Charge', 'WaterConsumption'];

    function ChargeSpecificationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ChargeSpecification, Charge, WaterConsumption) {
        var vm = this;

        vm.chargeSpecification = entity;
        vm.clear = clear;
        vm.save = save;
        vm.charges = Charge.query();
        vm.waterconsumptions = WaterConsumption.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.chargeSpecification.id !== null) {
                ChargeSpecification.update(vm.chargeSpecification, onSaveSuccess, onSaveError);
            } else {
                ChargeSpecification.save(vm.chargeSpecification, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:chargeSpecificationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
