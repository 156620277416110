(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('VisitantDetailController', VisitantDetailController);

    VisitantDetailController.$inject = ['CommonMethods', '$uibModalInstance', '$scope', '$rootScope', '$stateParams', 'Visitant', 'VisitantInvitation'];

    function VisitantDetailController(CommonMethods, $uibModalInstance, $scope, $rootScope, $stateParams, Visitant, VisitantInvitation) {
        var vm = this;

        var id = CommonMethods.decryptIdUrl($stateParams.id);
        var authorizedId = CommonMethods.decryptIdUrl($stateParams.authorizedId);
        Visitant.get({id: id}, function (result) {
            vm.visitant = result;
            console.log(vm.visitant)
        })
        VisitantInvitation.get({id: authorizedId}, function (result) {
            vm.authorized = result;
            console.log(vm.authorized)
        })

    }
})();
