(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('VisitorAuthorizationRequest', VisitorAuthorizationRequest);

    VisitorAuthorizationRequest.$inject = ['$resource', 'DateUtils'];

    function VisitorAuthorizationRequest ($resource, DateUtils) {
        var resourceUrl =  'api/visitor-authorization-requests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.requestDate = DateUtils.convertDateTimeFromServer(data.requestDate);
                        data.acceptedDate = DateUtils.convertDateTimeFromServer(data.acceptedDate);
                    }
                    return data;
                }
            },
            'getByHouse': {
                method: 'GET',
                url:'api/visitor-authorization-requests-by-house/:houseId',
                isArray:true
            },
            'isAbleToSend':{
                method:'GET',
                url: 'api/visitor-authorization-is-able-to-send/:houseId',
            },
            'findNew':{
                method:'GET',
                url:'api/visitor-get-new-authorization-by-house-id/:houseId'
            },
            'sendAuthorization': {
                method: 'GET',
                url:"api/visitor-send-authorization-requests/"
            },
            'update': { method:'PUT' }
        });
    }
})();
