(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpTaskDialogController', FollowUpTaskDialogController);

    FollowUpTaskDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FollowUpTask', 'Company', 'House', 'FollowUp', 'AdminInfo'];

    function FollowUpTaskDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FollowUpTask, Company, House, FollowUp, AdminInfo) {
        var vm = this;

        vm.followUpTask = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.companies = Company.query();
        vm.houses = House.query();
        vm.followups = FollowUp.query();
        vm.admininfos = AdminInfo.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.followUpTask.id !== null) {
                FollowUpTask.update(vm.followUpTask, onSaveSuccess, onSaveError);
            } else {
                FollowUpTask.save(vm.followUpTask, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:followUpTaskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.creationDate = false;
        vm.datePickerOpenStatus.lastModification = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
