(function () {
        'use strict';

        angular
            .module('aditumApp')
            .controller('AdjustmentSeatDialogController', AdjustmentSeatDialogController);

        AdjustmentSeatDialogController.$inject = ['ExchangeRateBccr', 'CommonMethods', '$state', 'Modal', 'globalCompany', '$rootScope', '$timeout', '$scope', '$stateParams', 'AccountingSeat', 'AccountingAccount', 'AdministrationConfiguration'];

        function AdjustmentSeatDialogController(ExchangeRateBccr, CommonMethods, $state, Modal, globalCompany, $rootScope, $timeout, $scope, $stateParams, AccountingSeat, AccountingAccount, AdministrationConfiguration) {
            var vm = this;
            vm.datePickerOpenStatus = {};
            vm.openCalendar = openCalendar;
            $rootScope.active = "adjustmentSeat";
            $rootScope.mainTitle = 'Crear asiento de ajuste';
            vm.accountingSeat = {
                compositeSeats: [
                    {debitAmount: 0, creditAmount: 0, searchTerm: ''},
                    {debitAmount: 0, creditAmount: 0, searchTerm: ''}
                ],
                date: new Date()
            };
            vm.bccrUse = true;

            vm.Today = new Date();
            vm.tipoCambio = {};

            vm.chargeBccr = function () {
                ExchangeRateBccr.get({
                    fechaInicio: moment(vm.accountingSeat.date).format(),
                    fechaFinal: moment(vm.accountingSeat.date).format()
                }, function (result) {
                    if (result) {
                        if (vm.secondCurrency == "$") {
                            vm.tipoCambio.venta = result.venta;
                        } else {
                            vm.tipoCambio.venta = result.venta;
                        }
                    }
                })
            }

            vm.chargeBccr();

            vm.totalDebit = 0;
            vm.totalCredit = 0;
            vm.totalBalance = 0;
            vm.addMovement = function () {
                vm.accountingSeat.compositeSeats.push({debitAmount: 0, creditAmount: 0, searchTerm: ''});
            }
            vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());

            vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
            vm.secondCurrency = vm.companyConfig.secondCurrency;
            vm.principalCurrency = vm.companyConfig.currency;
            vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

            vm.accountingSeat.currency = vm.principalCurrency;

            vm.defineConversionAmount = function (seat) {
                var venta = vm.bccrUse ? parseFloat(vm.tipoCambio.venta) : parseFloat(vm.adminConfig.exchangeRate);
                vm.accountingSeat.exchangeRate = venta;
                var amount = seat.debitAmount != 0 ? seat.debitAmount : seat.creditAmount;
                if (vm.accountingSeat.currency == seat.account.currency) {
                    return parseFloat(amount)
                } else {
                    if (seat.account.currency == "$") {
                        return parseFloat(amount) / venta;
                    } else {
                        return parseFloat(amount) * venta;
                    }
                }
            }
            vm.saveAdminConfig = function () {
                vm.adminConfig.exchangeRateDate = moment().format();
                if (vm.adminConfig.id !== null) {
                    AdministrationConfiguration.update(vm.adminConfig, function (result) {
                    }, function () {
                    });
                } else {
                    AdministrationConfiguration.save(vm.adminConfig, function () {
                    }, function () {
                    });
                }
            }
            AdministrationConfiguration.get({
                companyId: globalCompany.getId()
            }).$promise.then(function (result) {
                vm.adminConfig = result;
            })

            vm.showBccrUse = function () {
                var currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
                if (currencies.length !== 2) {
                    return false;
                }
                var showBccrUse = currencies.some(function (moneda) {
                    return moneda.symbol === "₡";
                }) && currencies.some(function (moneda) {
                    return moneda.symbol === "$";
                });
                return showBccrUse;
            }
            vm.removeMovement = function (item) {
                Modal.confirmDialog("¿Está seguro que desea eliminar ese movimiento?", "", function () {
                    CommonMethods.deleteFromArray(item, vm.accountingSeat.compositeSeats);
                    vm.calculateTotalBalance();
                })
            }

            vm.calculateTotalBalance = function () {
                vm.totalDebit = 0;
                vm.totalCredit = 0;
                for (var i = 0; i < vm.accountingSeat.compositeSeats.length; i++) {
                    var seat = vm.accountingSeat.compositeSeats[i];
                    vm.totalDebit += parseFloat(seat.debitAmount) || 0;
                    vm.totalCredit += parseFloat(seat.creditAmount) || 0;
                }
                vm.totalDebit = parseFloat(vm.totalDebit.toFixed(2));
                vm.totalCredit = parseFloat(vm.totalCredit.toFixed(2));
                vm.totalBalance = vm.totalDebit - vm.totalCredit;
                // Convert -0 to 0
                vm.totalBalance = parseFloat(vm.totalBalance.toFixed(2));
                if (vm.totalBalance === -0) {
                    vm.totalBalance = 0;
                    console.log(vm.totalBalance);
                }
            }


            vm.calculateTotalBalanceCredit = function (seat) {
                seat.debitAmount = 0;
                vm.calculateTotalBalance();
            }

            vm.calculateTotalBalanceDebit = function (seat) {
                seat.creditAmount = 0;
                vm.calculateTotalBalance();
            }

            vm.validateAmounts = function () {
                if (vm.totalDebit === 0) {
                    return true;
                } else {
                    return vm.totalDebit !== vm.totalCredit;
                }
            }

            vm.save = save;
            vm.balance = 0;

            vm.typingSearchTerm = function (ev) {
                ev.stopPropagation();
            }
            vm.clearSearchTerm = function (item) {
                item.searchTerm = '';
            };

            $timeout(function () {
                angular.element('.form-group:eq(1)>input').focus();
            });
            loadDetailAccounts();

            function loadDetailAccounts() {
                AccountingAccount.findDetailAccountsByCompanyId({
                    companyId: globalCompany.getId()
                }, onSuccessAccounts, onErrorAccounts);

                function onSuccessAccounts(data, headers) {
                    vm.detailAcccounts = data;
                }

                function onErrorAccounts(error) {
                    Modal.toast("Ocurrio un error cargando las cuentas contables.")
                }
            }

            function save() {
                Modal.confirmDialog("¿Está seguro que desea registrar el asiento de ajuste?", "", function () {
                    vm.isSaving = true;
                    vm.accountingSeat.companyId = globalCompany.getId();
                    vm.accountingSeat.creditAmount = vm.balance;
                    vm.accountingSeat.debitAmount = vm.balance;
                    vm.accountingSeat.transactionType = "MANUAL";
                    vm.accountingSeat.date = moment(vm.accountingSeat.date).format();
                    Modal.showLoadingBar();
                    formatSeats();
                    if (vm.accountingSeat.id !== null) {
                        AccountingSeat.update(vm.accountingSeat, onSaveSuccess, onSaveError);
                    } else {
                        AccountingSeat.save(vm.accountingSeat, onSaveSuccess, onSaveError);
                    }
                })
            }

            function formatSeats() {
                for (var i = 0; i < vm.accountingSeat.compositeSeats.length; i++) {
                    var seat = vm.accountingSeat.compositeSeats[i];
                    seat.companyId = globalCompany.getId();
                    if (seat.account) {
                        if (seat.debitAmount > 0) {
                            seat.debitAccountId = seat.account.id;
                            seat.debitAccountCurrency = seat.account.currency;
                        }
                        if (seat.creditAmount > 0) {
                            seat.creditAccountId = seat.account.id;
                            seat.creditAccountCurrency = seat.account.currency;
                        }
                    }
                }
            }

            function onSaveSuccess(result) {
                $scope.$emit('aditumApp:accountingSeatUpdate', result);
                vm.isSaving = false;
                $state.reload();
                Modal.toast("Se registró el asiento correctamente.")
                Modal.hideLoadingBar();
            }

            function onSaveError() {
                vm.isSaving = false;
            }

            vm.datePickerOpenStatus.date = false;

            function openCalendar(date) {
                vm.datePickerOpenStatus[date] = true;
            }
        }
    }

)
();
