(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetController', BudgetController);

    BudgetController.$inject = ['Budget', 'globalCompany', 'Modal', '$rootScope', 'CommonMethods', '$state'];

    function BudgetController(Budget, globalCompany, Modal, $rootScope, CommonMethods, $state) {

        var vm = this;
        $rootScope.active = "budget";

        vm.budgets = [];
        vm.loadingBudgets = false;
        loadAll();

        vm.deleteSafeBudget = function (budget) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el presupuesto?", "Una vez eliminado ya no podrá recuperarse", function () {
                Budget.delete({id: budget.id}, function () {
                    loadAll();
                    Modal.toast("Presupuesto eliminado correctamente")
                })
            })
        }

        vm.viewDetail = function (budget) {
            var id = CommonMethods.encryptIdUrl(budget.id);
            $state.go("budget-detail", {id: id});
        }

        function loadAll() {
            vm.loadingBudgets = true;
            Budget.findByCompany({companyId: globalCompany.getId()}, function (result) {
                vm.budgets = result;
                vm.searchQuery = null;
                vm.loadingBudgets = false;
            });
        }
    }
})();
