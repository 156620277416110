(function () {
    'use strict';


    angular
        .module('aditumApp')
        .factory('WSVisitorAuthorizationRequest', WSVisitorAuthorizationRequest);

    WSVisitorAuthorizationRequest.$inject = ['StompManager', 'globalCompany','StompManagerSecond'];

    function WSVisitorAuthorizationRequest(StompManager, globalCompany, StompManagerSecond) {
        var SUBSCRIBE_TRACKER_URL = '/topic/visitorAuthorizationRequest/';
        var SEND_ACTIVITY_URL = '/topic/sendVisitorAuthorizationRequest/';

        var SUBSCRIBE_RESPONSE_TRACKER_URL = '/topic/visitorAuthorizationResponse/';
        var SEND_RESPONSE_ACTIVITY_URL = '/topic/sendVisitorAuthorizationResponse/';

        var service = {
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe,
            receiveResponse: receiveResponse,
            sendActivityResponse: sendActivityResponse,
            subscribeResponse: subscribeResponse,
            unsubscribeResponse: unsubscribeResponse,
        };

        return service;

        function receive(houseId) {
            return StompManagerSecond.getListener(SUBSCRIBE_TRACKER_URL + houseId);
        }

        function sendActivity(entity, houseId) {
            StompManager.send(SEND_ACTIVITY_URL + houseId, entity);
        }

        function subscribe(houseId) {
            StompManagerSecond.subscribe(SUBSCRIBE_TRACKER_URL + houseId);
        }

        function unsubscribe(houseId) {
            StompManagerSecond.unsubscribe(SUBSCRIBE_TRACKER_URL + houseId);
        }

        function receiveResponse(houseId) {
            return StompManagerSecond.getListener(SUBSCRIBE_RESPONSE_TRACKER_URL + houseId);
        }

        function sendActivityResponse(entity, houseId) {
            StompManager.send(SEND_RESPONSE_ACTIVITY_URL + houseId, entity);
        }

        function subscribeResponse(houseId) {
            StompManagerSecond.subscribe(SUBSCRIBE_RESPONSE_TRACKER_URL + houseId);
        }

        function unsubscribeResponse(houseId) {
            StompManagerSecond.unsubscribe(SUBSCRIBE_RESPONSE_TRACKER_URL + houseId);
        }
    }
})();
