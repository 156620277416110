(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingPeriodController', AccountingPeriodController);

    AccountingPeriodController.$inject = ['$rootScope', 'AccountingPeriod', 'CommonMethods', '$state', 'globalCompany'];

    function AccountingPeriodController($rootScope, AccountingPeriod, CommonMethods, $state, globalCompany) {

        var vm = this;
        vm.isReady = false;
        $rootScope.active = "accountingPeriod";
        vm.accountingPeriods = [];

        loadAll();

        function loadAll() {
            AccountingPeriod.getAllByCompany({companyId: globalCompany.getId()}, function (result) {
                vm.accountingPeriods = result;
                vm.searchQuery = null;
                vm.isReady = true;
            });
        }

        vm.detail = function (account) {
            var id = CommonMethods.encryptIdUrl(account.id);
            $state.go('accounting-period-detail', {id: id});
        }
    }
})();
