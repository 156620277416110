(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingSeatDialogController', AccountingSeatDialogController);

    AccountingSeatDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccountingSeat', 'Company', 'DebitNote', 'CreditNote', 'Charge', 'Payment', 'Egress', 'House', 'AccountingAccount'];

    function AccountingSeatDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AccountingSeat, Company, DebitNote, CreditNote, Charge, Payment, Egress, House, AccountingAccount) {
        var vm = this;

        vm.accountingSeat = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.companies = Company.query();
        vm.debitnotes = DebitNote.query();
        vm.creditnotes = CreditNote.query();
        vm.charges = Charge.query();
        vm.payments = Payment.query();
        vm.egresses = Egress.query();
        vm.houses = House.query();
        vm.accountingaccounts = AccountingAccount.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.accountingSeat.id !== null) {
                AccountingSeat.update(vm.accountingSeat, onSaveSuccess, onSaveError);
            } else {
                AccountingSeat.save(vm.accountingSeat, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:accountingSeatUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
