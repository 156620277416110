(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('HouseAllDialogController', HouseAllDialogController);

    HouseAllDialogController.$inject = ['DataUtils', 'CompanyConfiguration', 'CommonMethods', '$state', '$rootScope', 'Principal', '$timeout', '$scope', '$stateParams', 'House', 'WSHouse', 'Balance', 'AdministrationConfiguration', 'Modal', 'globalCompany', 'Company'];

    function HouseAllDialogController(DataUtils, CompanyConfiguration, CommonMethods, $state, $rootScope, Principal, $timeout, $scope, $stateParams, House, WSHouse, Balance, AdministrationConfiguration, Modal, globalCompany, Company) {
        var vm = this;
        vm.config = {sufijo: null, prefijo: null, quantity: 1}
        vm.isReady = true;
        vm.notAddCero = false;
        $rootScope.active = "houses-massive";
        vm.type = "1";
        var file;

        vm.defineHouseNumber = function (i) {
            var sufijo = vm.config.sufijo != null ? vm.config.sufijo : "";
            var prefijo = vm.config.prefijo != null ? vm.config.prefijo : "";
            var espacioPrefijo = vm.config.espacioPrefijo ? " " : "";
            var espacioSufijo = vm.config.espacioSufijo ? " " : "";
            i = i + "";
            if (i < 10) {
                if (!vm.notAddCero) {
                    i = "0" + i;
                }
            }
            return prefijo + espacioPrefijo + i + espacioSufijo + sufijo;
        }

        Company.query({
            page: 0,
            size: 100,
        }, onSuccess);

        function onSuccess(data, headers) {
            vm.companies = data;
        }

        vm.save = function () {
            Modal.confirmDialog("¿Está seguro que quiere registrar las casas?", "Revise bien, borrarlas después es complicado", function () {
                Modal.showLoadingBar()
                for (var i = 1; i <= vm.config.quantity; i++) {
                    var house = {
                        desocupationfinaltime: null,
                        desocupationinitialtime: null,
                        due: 0,
                        emergencyKey: null,
                        extension: null,
                        housenumber: vm.defineHouseNumber(i),
                        id: null,
                        isdesocupated: "0",
                        securityKey: null,
                        squareMeters: 0,
                        subsidiaries: [],
                        subsidiaryTypeId: 1,
                        companyId: vm.companyId
                    }
                    House.save(house, function (data) {
                        if (data.housenumber == vm.defineHouseNumber(vm.config.quantity)) {
                            Modal.hideLoadingBar();
                            Modal.toast("Listo :)")
                        }
                    }, function () {
                        Modal.hideLoadingBar();
                        Modal.toast("Error :(")
                    });
                }
            })
        }


        vm.loadCompany = function (companyId) {
            CompanyConfiguration.get({id: companyId}, function (result) {
                vm.companyConfig = result;
            })
        }

        vm.uploadFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            vm.progressUpload = "Leyendo archivo..."
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    vm.progressUpload = "Convirtiendo datos..."
                    $scope.$apply(function () {
                        vm.displayImage = base64Data;
                        vm.displayImageType = $file.type;
                        vm.addingPlantilla = true;
                    });
                });
                file = $file;
                vm.fileName = file.name;
                vm.isReady = 1;
                vm.parseExcel();
            }
        };
        vm.parseExcel = function () {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary'
                });
                vm.progressUpload = "Convirtiendo datos..."
                /* DO SOMETHING WITH workbook HERE */
                var first_sheet_name = workbook.SheetNames[0];
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name];
                vm.houseCharges = XLSX.utils.sheet_to_json(worksheet, {
                    raw: false
                });
                formatHouseCharges(vm.houseCharges);
            };
            reader.onerror = function (ex) {
                Modal.toast("Existe un error con el formato del archivo subido")
                vm.isReady = 1;
            };
            reader.readAsBinaryString(file);
        };

        function vaidateExisting(house,newAmmountsHouse) {
            for (var i = 0; i < newAmmountsHouse.length; i++) {
                if (house.housenumber == newAmmountsHouse[i].housenumber) {
                    return true;
                }
            }
            return false;
        }

        function formatHouseCharges(houses) {
            var newAmmountsHouse = [];
            for (var i = 0; i < houses.length; i++) {
                var newH = houses[i];
                newH.housenumber = newH.filial;
                if (!vaidateExisting(newH,newAmmountsHouse)) {
                    if (newH.monto != undefined) {
                        var m = newH.monto.replace(/,/g, "");
                        if (m == undefined) {
                            m = "FORMATO INVALIDO";
                        } else {
                            newH.due = m;
                        }
                    }
                    if (newH.folio != "" || newH.folio != undefined) {
                        newH.folioNumber = newH.folio;
                    }
                    if (newH.m2 != "" || newH.m2 != undefined) {
                        newH.squareMeters = newH.m2;
                    }

                    if (newH.estado != "" || newH.estado != undefined) {
                        newH.status = defineStatus(newH.estado);
                    }

                    if (newH.alquilada != "" || newH.alquilada != undefined) {
                        newH.hasOwner = newH.alquilada == "si" ? true : false;
                    }
                    if (newH.habitada != "" || newH.habitada != undefined) {
                        newH.isdesocupated = newH.habitada == "si" ? "1" : "0";
                    }
                    newAmmountsHouse.push(newH)
                }
                vm.progressUpload = "Convirtiendo datos..."+i+"/"+houses.length;
                console.log(vm.progressUpload)
            }
            vm.newHouses = newAmmountsHouse;
            vm.allHousesReady = true;
            vm.progressUpload ="";
        }

        vm.saveNewMontos = function () {
            Modal.confirmDialog("¿Está seguro que desea registrar todas las filiales?",
                "", function () {
                    vm.count = 0;
                    vm.creatingHouses = true;
                    if (vm.newHouses.length > 0) {
                        Modal.showLoadingBar();
                        vm.savingNewCharges = true;
                        saveHouse(vm.newHouses[0], vm.count, vm.newHouses.length)
                    }
                })
        }

        function saveHouse(house, count, total) {
            house.companyId = vm.companyId;
            House.find
            House.save(house, function (result) {
                count++;
                if (count == total) {
                    Modal.toast("Guardado correctamente.")
                    vm.addingPlantilla = false;
                    vm.newHouses = [];
                    vm.savingNewCharges = false;
                    Modal.hideLoadingBar();
                    vm.creatingHouses = false;
                } else {
                    saveHouse(vm.newHouses[count], count, total)
                }
            }, function (error) {
                Modal.toast(house.housenumber + " repetida.")
                count++;
                if (count == total) {
                    Modal.toast("Guardado correctamente.")
                    vm.addingPlantilla = false;
                    vm.newHouses = [];
                    vm.savingNewCharges = false;
                    vm.creatingHouses = false;
                    Modal.hideLoadingBar();
                } else {
                    saveHouse(vm.newHouses[count], count, total)
                }
            })
        }

        function defineStatus(status) {
            switch (status) {
                case "en construccion":
                    return "En construcción";
                    break;
                case "construida":
                    return "Construida";
                    break;
                case "lote vacio":
                    return "Lote vacío";
                    break;
            }
            return "Sin definir";
        }
    }
})();
