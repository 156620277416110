(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpModalController', FollowUpModalController);

    FollowUpModalController.$inject = ['Modal', '$state', 'globalCompany', '$timeout', '$scope', '$stateParams', 'FollowUp', 'Company', 'House', 'FollowUpCategory', 'CommonMethods', 'Principal', 'AdminInfo', '$uibModalInstance'];

    function FollowUpModalController(Modal, $state, globalCompany, $timeout, $scope, $stateParams, FollowUp, Company, House, FollowUpCategory, CommonMethods, Principal, AdminInfo, $uibModalInstance) {
        var vm = this;
        moment.locale("es");
        vm.houseId = CommonMethods.decryptString($stateParams.houseId);

        vm.today = moment(new Date()).format("MMM DD, hh:MM a");
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.followUp = {
            creationDate: moment(new Date()).format(),
            status: 1,
            author: vm.autor,
            deleted: 0,
            priority: 1,
            hasNextStep: false,
            lastModifcation: moment(new Date()).format(),
            authorLastModification: vm.autor,
            houseId: vm.houseId,
            companyId: globalCompany.getId(),
            followUpCategoryId: null,
        };

        vm.configCategories = function () {
            clear()
            setTimeout(function () {
                $state.go("record-file.follow-up-category-configuration");
            }, 100)
        }

        Principal.identity().then((function (data) {
            vm.autor = data.firstName + " " + data.lastName;
        }))

        vm.setRecordCategory = function (recordCategory) {
            vm.recordCategory = recordCategory;
        }
        loadAllCategories();


        function loadAllCategories() {
            FollowUpCategory.queryByCompany({
                page: 0,
                size: 1000,
                companyId: globalCompany.getId(),
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.recordCategories = data;
                if (vm.followUp.id == null) {
                    if (vm.recordCategories.length > 0) {
                        vm.recordCategory = vm.recordCategories[0];
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        if (vm.houseId != -1) {
            House.getClean({id: vm.houseId}, function (data) {
                vm.house = data;
            })
        } else {
            Company.get({id: globalCompany.getId()}, function (data) {
                vm.house = {};
                vm.house.id = null;
                vm.house.housenumber = data.name;
            })
        }


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.followUp.author = vm.autor;
            vm.followUp.authorLastModification = vm.autor;
            vm.followUp.followUpCategoryId = vm.recordCategory.id;
            if (vm.followUp.id !== null) {
                FollowUp.update(vm.followUp, onSaveSuccess, onSaveError);
            } else {
                FollowUp.save(vm.followUp, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close();
            Modal.toast("Seguimiento creado.")
            setTimeout(function () {
                var houseId = CommonMethods.encryptString(vm.houseId + "");
                var id = CommonMethods.encryptString(result.id + "");
                $state.go("follow-up.edit", {houseId: houseId, id: id});
            }, 100)
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.initialDate = false;
        vm.datePickerOpenStatus.finishDate = false;
        vm.datePickerOpenStatus.lastModifcation = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
