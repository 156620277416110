(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BudgetDetailController', BudgetDetailController);

    BudgetDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Budget', 'Company', 'BudgetItem', 'Modal', 'CommonMethods'];

    function BudgetDetailController($scope, $rootScope, $stateParams, previousState, entity, Budget, Company, BudgetItem, Modal, CommonMethods) {
        var vm = this;

        vm.budget = entity;
        vm.previousState = previousState.name;
        loadBudgetInfo();

        vm.editing = false;
        vm.expanding = false;

        vm.expand = function () {
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.expanding = !vm.expanding;
                });
            }, 200);

        }


        function loadBudgetInfo() {
            vm.finalBudgetItemsSpent = [];
            vm.finalBudgetItemsIngress = [];
            formatFinalBudgetItem(vm.budget.budgetItemsIngress, vm.finalBudgetItemsIngress);
            formatFinalBudgetItem(vm.budget.budgetItemsSpent, vm.finalBudgetItemsSpent);
        }

        vm.defineRowClass = function (budgetItem) {
            if (budgetItem.isMayorAccount) {
                return "background-lightyellow"
            } else if (budgetItem.isSubAccount) {
                return "background-aliceblue"
            }
        }

        function formatFinalBudgetItem(initialList, toList) {
            for (var i = 0; i < initialList.length; i++) {
                var budgetItem = initialList[i];
                // budgetItem = loadBudgetItemAmount(budgetItem);
                budgetItem.isMayorAccount = true;
                budgetItem.isSubAccount = false;
                budgetItem.isDetailAccount = false;
                toList.push(budgetItem);
                for (var j = 0; j < budgetItem.budgetItemsInCategory.length; j++) {
                    var subAccount = budgetItem.budgetItemsInCategory[j];
                    subAccount.isMayorAccount = false;
                    subAccount.isSubAccount = true;
                    subAccount.isDetailAccount = false;
                    toList.push(subAccount);
                    for (var x = 0; x < subAccount.budgetItemsInCategory.length; x++) {
                        var detailAccount = subAccount.budgetItemsInCategory[x];
                        detailAccount = loadBudgetItemAmount(subAccount.budgetItemsInCategory[x]);
                        detailAccount.isMayorAccount = false;
                        detailAccount.isSubAccount = false;
                        detailAccount.isDetailAccount = true;
                        toList.push(detailAccount);
                    }
                }
            }
        }

        function loadBudgetItemAmount(budgetItem) {
            budgetItem.amountPerMonth = budgetItem.monthAmmounts.split(',').slice(0, -1);
            return budgetItem;
        }

        function saveBudgetItemAmountArray(budgetItem) {
            var monthAmmounts = "";
            for (var i = 0; i < budgetItem.amountPerMonth.length; i++) {
                monthAmmounts = monthAmmounts + budgetItem.amountPerMonth[i] + ",";
            }
            budgetItem.monthAmmounts = monthAmmounts;
            return budgetItem;
        }

        var unsubscribe = $rootScope.$on('aditumApp:budgetUpdate', function (event, result) {
            vm.budget = result;
        });

        vm.copyValueInAll = function (budgetItem, value) {
            Modal.confirmDialog("¿Está seguro que quiere copiar este valor en los otros meses?", "", function () {
                for (var i = 0; i < budgetItem.amountPerMonth.length; i++) {
                    budgetItem.amountPerMonth[i] = value;
                }
                vm.saveBudgetItem(budgetItem);
            })

        }

        vm.saveBudgetItem = function (budgetItem, value, index) {
            budgetItem.amountPerMonth[index] = value;
            budgetItem = saveBudgetItemAmountArray(budgetItem);
            BudgetItem.update(budgetItem, function () {
                vm.loadBudget();
            });
        }

        vm.loadBudget = function () {
            var id = CommonMethods.decryptIdUrl($stateParams.id);
            return Budget.get({id: id}, function (data) {
                vm.budget = data;
                loadBudgetInfo();
            })
        }


        $scope.$on('$destroy', unsubscribe);
    }
})();
