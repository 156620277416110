(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('LoadAutomaticResidentController', LoadAutomaticResidentController);

    LoadAutomaticResidentController.$inject = ['User', 'Resident', 'DataUtils', 'CompanyConfiguration', 'CommonMethods', '$state', '$rootScope', 'Principal', '$timeout', '$scope', '$stateParams', 'House', 'WSHouse', 'Balance', 'AdministrationConfiguration', 'Modal', 'globalCompany', 'Company'];

    function LoadAutomaticResidentController(User, Resident, DataUtils, CompanyConfiguration, CommonMethods, $state, $rootScope, Principal, $timeout, $scope, $stateParams, House, WSHouse, Balance, AdministrationConfiguration, Modal, globalCompany, Company) {
        var vm = this;
        vm.config = {sufijo: null, prefijo: null, quantity: 1}
        vm.isReady = true;
        vm.notAddCero = false;
        $rootScope.active = "resident-massive";
        vm.creatingResidents = false;
        var file;
        vm.allResidents = [];
        vm.log = [];
        vm.loadHouses = function (companyId) {
            Modal.showLoadingBar()
            House.getAllHousesClean({companyId: companyId}).$promise.then(onSuccessHouses);
        }
        vm.type = "1";

        Company.query({
            page: 0,
            size: 100,
        }, onSuccess);

        function onSuccessHouses(data, headers) {
            vm.houses = data;
            Modal.hideLoadingBar();
        }

        function onSuccess(data, headers) {
            vm.companies = data;
        }


        vm.loadCompany = function (companyId) {
            CompanyConfiguration.get({id: companyId}, function (result) {
                vm.companyConfig = result;
            })
        }

        vm.uploadFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            vm.progressUpload = "Leyendo archivo..."
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    vm.progressUpload = "Convirtiendo datos..."
                    $scope.$apply(function () {
                        vm.displayImage = base64Data;
                        vm.displayImageType = $file.type;
                        vm.addingPlantilla = true;
                    });
                });
                file = $file;
                vm.fileName = file.name;
                vm.isReady = 1;
                vm.parseExcel();
            }
        };
        vm.parseExcel = function () {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'binary'
                });
                vm.progressUpload = "Convirtiendo datos..."
                /* DO SOMETHING WITH workbook HERE */
                var first_sheet_name = workbook.SheetNames[0];
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name];
                vm.residents = XLSX.utils.sheet_to_json(worksheet, {
                    raw: false
                });
                vm.addingPlantilla = true;
                formatResidents(vm.residents);
            };
            reader.onerror = function (ex) {
                Modal.toast("Existe un error con el formato del archivo subido")
                vm.isReady = 1;
            };
            reader.readAsBinaryString(file);
        };

        function vaidateExistingResident(resident, list) {
            for (var i = 0; i < list.length; i++) {
                if (resident.identificationnumber == list[i].identificationnumber) {
                    return true;
                }
            }
            return false;
        }


        function valid(resident) {
            var validFisica = (resident.nombre && resident.apellido1 && resident.rol);
            var validJuridica = true;
            if (resident.tipoPersona == "juridica") {
                validJuridica = (resident.cedJuridica && resident.razonSocial)
            }
            return validFisica && validJuridica && resident.filiales!=undefined;
        }

        function defineType(resident) {
            switch (resident.rol.toUpperCase()) {
                case "PROPIETARIO":
                    return 1;
                    break;
                case "RESIDENTE":
                    return 3;
                    break;
                case "ARRENDADOR":
                    return 2;
                    break;
                case "INQUILINO":
                    return 4;
                    break;
            }
            return 1;
        }

        function defineHouse(resident) {
            var houses = [];
            var filiales = resident.filiales.split(',');
            for (var i = 0; i < vm.houses.length; i++) {
                for (var j = 0; j < filiales.length; j++) {
                    if (vm.houses[i].housenumber == filiales[j]) {
                        houses.push(vm.houses[i]);
                    }
                }
            }
            return houses;
        }

        vm.formatIfCompany = function (newR, resident) {
            if (resident.tipoPersona == "juridica") {
                newR.isCompany = true;
                newR.legalCompanyName = resident.razonSocial;
                newR.companyName = resident.razonSocial;
                newR.legalIdentification = resident.cedJuridica;
                newR.companyPhone = resident.telefonoEmpresa;
                newR.companyDirection = resident.direccionEmpresa;
                newR.companyEmail = resident.email;
            } else {
                newR.isCompany = false;
            }
            return newR;
        }

        function toUpperCaseS(s) {
            return s != undefined ? s.toUpperCase() : s;
        }

        function formatResidents(residents) {
            vm.newResidents = [];
            var nResidents = []
            for (var i = 0; i < residents.length; i++) {
                var resident = residents[i];
                if (valid(resident)) {
                    var housesResident = defineHouse(resident);
                    if (housesResident.length > 0) {
                        var houseNumber = "";
                        for (var j = 0; j < housesResident.length; j++) {
                            houseNumber = houseNumber + housesResident[j].housenumber + ", "
                        }
                        var type = defineType(resident);
                        var newR = {
                            name: toUpperCaseS(resident.nombre),
                            lastname: toUpperCaseS(resident.apellido1),
                            secondlastname: toUpperCaseS(resident.apellido2),
                            identificationnumber: resident.cedula,
                            phonenumber: resident.telefonos,
                            email: resident.correo,
                            enabled: 1,
                            deleted: 0,
                            isOwner: 0,
                            companyId: vm.companyId,
                            type: type,
                            houseId: type == 2 ? null : housesResident[0].id,
                            housenumber: houseNumber,
                            rol: resident.rol,
                            houses: housesResident
                        }
                        newR = vm.formatIfCompany(newR, resident);
                        nResidents.push(newR)
                    }
                }
            }
            vm.newResidents = nResidents;
            vm.allResidentsReady = true;
            vm.progressUpload = "";
        }


        vm.sendResidentsInvitations = function () {
            Modal.confirmDialog("¿Está seguro que desea enviar la invitación a todos los residentes?",
                "", function () {
                    vm.count = 0;
                    vm.log = ["Cargando residentes..."];
                    Resident.getResidents({
                        page: 0,
                        size: 100000,
                        companyId: vm.companyId,
                        name: " ",
                        houseId: "empty",
                        owner: 0,
                        enabled: 1,
                    }, function (result) {
                        vm.log.push("Listo.");
                        vm.allResidents = result;
                        vm.creatingResidents = true;
                        if (vm.allResidents.length > 0) {
                            vm.savingNewResidents = true;
                            sendInvitation(vm.allResidents[0], vm.count, vm.allResidents.length)
                        }
                    });
                })
        }

        vm.reSendResidentsInvitations = function () {
            Modal.confirmDialog("¿Está seguro que desea enviar la invitación a todos los residentes?",
                "", function () {
                    vm.count = 0;
                    vm.log = ["Cargando residentes..."];
                    Resident.getResidents({
                        page: 0,
                        size: 100000,
                        companyId: vm.companyId,
                        name: " ",
                        houseId: "empty",
                        owner: 0,
                        enabled: 1,
                    }, function (result) {
                        vm.log.push("Listo.");
                        vm.allResidents = result;
                        vm.creatingResidents = true;
                        if (vm.allResidents.length > 0) {
                            vm.savingNewResidents = true;
                            reSendInvitation(vm.allResidents[0], vm.count, vm.allResidents.length)
                        }
                    });
                })
        }

        function validEmail(resident){
            if(resident.email!=null){
                if(hasCaracterEspecial(resident.email)){
                    return false;
                }
            }
            return true;
        }
        function hasCaracterEspecial(s) {
            var caracteres = [",", "-", "$", "(", ")", "=", "+", "/", ":", "%", "*", "'", "", ">", "<", "?", "¿","{","}","[","]","''"];
            var invalido = 0;
            angular.forEach(caracteres, function (val, index) {
                if (s != undefined) {
                    for (var i = 0; i < s.length; i++) {
                        if (s.charAt(i) == val) {
                            invalido++;
                        }
                    }
                }
            })
            if (invalido == 0) {
                return false;
            } else {
                return true;
            }
        }
        function sendInvitation(resident, count, total) {
            if(!validEmail(resident)){
                vm.log.push("Advertencia " + (parseFloat(count) + 1) + " : El correo " + resident.email + " tiene un mal formato en el sistema.");
                count++;
                if (count == total) {
                    vm.log.push("Invitaciones enviadas correctamente.")
                    vm.addingPlantilla = false;
                    vm.newResidents = [];
                    vm.savingNewResidents = false;
                    vm.creatingResidents = false;
                    vm.count = 0;
                } else {
                    sendInvitation(vm.allResidents[count], count, total)
                }
            }else{
                User.createUserForResident({residentId: resident.id}, function (result) {
                    if (result.login == "emailexist") {
                        vm.log.push("Advertencia " + (parseFloat(count) + 1) + " : El correo " + resident.email + " ya existe en el sistema");
                    }
                    count++;
                    if (count == total) {
                        vm.log.push("Invitaciones enviadas correctamente.")
                        vm.addingPlantilla = false;
                        vm.newResidents = [];
                        vm.savingNewResidents = false;
                        vm.creatingResidents = false;
                        vm.count = 0;
                    } else {
                        sendInvitation(vm.allResidents[count], count, total)
                    }
                }, function (error) {
                    count++;
                    if (count == total) {
                        vm.log.push("Invitaciones enviadas correctamente.")
                        vm.addingPlantilla = false;
                        vm.newResidents = [];
                        vm.savingNewResidents = false;
                        vm.creatingResidents = false;
                        vm.count = 0;
                    } else {
                        sendInvitation(vm.allResidents[count], count, total)
                    }
                    vm.log.push("Advertencia " + (parseFloat(count) + 1) + " : El correo " + resident.email + " tiene un mal formato en el sistema.");
                })
            }
        }

        function reSendInvitation(resident, count, total) {
            if(!validEmail(resident) || resident.userId==null){
                vm.log.push("Advertencia " + (parseFloat(count) + 1) + " : El correo " + resident.email + " tiene un mal formato en el sistema no tiene un usuario ligado aún.");
                count++;
                if (count == total) {
                    vm.log.push("Invitaciones enviadas correctamente.")
                    vm.addingPlantilla = false;
                    vm.newResidents = [];
                    vm.savingNewResidents = false;
                    vm.creatingResidents = false;
                    vm.count = 0;
                } else {
                    reSendInvitation(vm.allResidents[count], count, total)
                }
            }else{
                Resident.resendInvitation({userId:resident.userId},function(){
                    count++;
                    if (count == total) {
                        vm.log.push("Invitaciones enviadas correctamente.")
                        vm.addingPlantilla = false;
                        vm.newResidents = [];
                        vm.savingNewResidents = false;
                        vm.creatingResidents = false;
                        vm.count = 0;
                    } else {
                        reSendInvitation(vm.allResidents[count], count, total)
                    }
                }, function (error) {
                    count++;
                    if (count == total) {
                        vm.log.push("Invitaciones enviadas correctamente.")
                        vm.addingPlantilla = false;
                        vm.newResidents = [];
                        vm.savingNewResidents = false;
                        vm.creatingResidents = false;
                        vm.count = 0;
                    } else {
                        reSendInvitation(vm.allResidents[count], count, total)
                    }
                    vm.log.push("Advertencia " + (parseFloat(count) + 1) + " : El correo " + resident.email + " tiene un mal formato en el sistema.");
                })
            }
        }

        vm.saveResidents = function () {
            Modal.confirmDialog("¿Está seguro que desea registrar todos los residentes?",
                "", function () {
                    vm.count = 0;
                    vm.log = [];
                    vm.creatingResidents = true;
                    if (vm.newResidents.length > 0) {
                        vm.savingNewResidents = true;
                        saveResident(vm.newResidents[0], vm.count, vm.newResidents.length)
                    }
                })
        }

        function saveResident(resident, count, total) {
            resident.principalContact = 0;
            Resident.save(resident, function (result) {
                vm.log.push("Registrado " + (parseFloat(count) + 1) + "/" + vm.newResidents.length)
                count++;
                if (count == total) {
                    Modal.toast("Guardado correctamente.")
                    vm.addingPlantilla = false;
                    vm.newResidents = [];
                    vm.savingNewResidents = false;
                    vm.creatingResidents = false;
                    vm.count = 0;
                    vm.log = [];
                } else {
                    saveResident(vm.newResidents[count], count, total)
                }
            }, function (error) {
                vm.log.push(" Error linea " + (parseFloat(count) + 1) + ": Cédula repetida en la filial")
                count++;
                if (count == total) {
                    Modal.toast("Guardado correctamente.")
                    vm.addingPlantilla = false;
                    vm.newHouses = [];
                    vm.savingNewResidents = false;
                    vm.creatingResidents = false;
                    vm.count = 0;
                    vm.log = [];
                    Modal.hideLoadingBar();
                } else {
                    saveResident(vm.newResidents[count], count, total)
                }
            })
        }
    }
})();
