(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('CondominiumRecordController', CondominiumRecordController);

    CondominiumRecordController.$inject = ['$scope', '$state', '$rootScope', 'CommonMethods', 'globalCompany', 'Modal', 'CondominiumRecord', 'ParseLinks', 'AlertService', 'paginationConstants'];

    function CondominiumRecordController($scope, $state, $rootScope, CommonMethods, globalCompany, Modal, CondominiumRecord, ParseLinks, AlertService, paginationConstants) {

        var vm = this;
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false,
        }
        vm.condominiumRecords = [];
        vm.loadPage = loadPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 0;
        $rootScope.active = "records";
        $rootScope.mainTitle = "Actas condominales";
        vm.links = {
            last: 0
        };
        vm.isReady = false;
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.folderLevels = [];
        vm.currentFolder = -1;
        $rootScope.folderLevels = [];
        $rootScope.currentFolder = null;

        if($rootScope.folderLevels!=null){
            if($rootScope.folderLevels.length!=0){
                vm.folderLevels = $rootScope.folderLevels;
            }
        }

        if($rootScope.currentFolder!=null){
            if($rootScope.currentFolder!=-1) {
                vm.currentFolder = $rootScope.currentFolder;
            }
        }

        vm.addFolder = function(){
            vm.creatingFolder = true;
        }

        vm.resetAll = function (){
            vm.folderLevels = [];
            vm.currentFolder = -1;
            vm.condominiumRecords=[];
            $rootScope.currentFolder = -1;
            $rootScope.folderLevels = [];
            loadAll();
        }

        vm.goToFolderfolderLever = function($index){
            var newFolderLevels = []
            for (var i = 0; i < $index+1; i++) {
                newFolderLevels.push(vm.folderLevels[i]);
            }
            vm.isReady = false;
            vm.currentFolder = vm.folderLevels[$index].id;
            vm.folderLevels = newFolderLevels;
            vm.condominiumRecords = [];
            $rootScope.currentFolder = vm.currentFolder;
            $rootScope.folderLevels = vm.folderLevels;
            loadAll();
        }

        vm.createFolder = function(){
            Modal.confirmDialog("¿Está seguro que desea guardar la carpeta?", "", function () {
                var folderId = vm.currentFolder == -1?null:vm.currentFolder;
                vm.newFolder = {
                    companyId:globalCompany.getId(),
                    name:vm.newFolderName,
                    deleted:0,
                    isFolder:1,
                    status:1,
                    folderId:folderId
                }
                if(vm.recordToEdit){
                    vm.recordToEdit.name = vm.newFolderName;
                    CondominiumRecord.update(vm.recordToEdit, function () {
                        Modal.toast("Listo")
                        vm.condominiumRecords = [];
                        vm.page = 0;
                        vm.recordToEdit = null;
                        vm.creatingFolder = false;
                        vm.newFolderName = null;
                        loadAll()
                    });
                }else {
                    CondominiumRecord.save(vm.newFolder, function () {
                        Modal.toast("Listo")
                        vm.condominiumRecords = [];
                        vm.page = 0;
                        vm.newFolderName = null;
                        vm.creatingFolder = false;
                        loadAll()
                    });
                }
            })
        }

        vm.editFolder = function(record){
            vm.addFolder();
            vm.newFolderName = record.name;
            vm.recordToEdit = record;
        }

        vm.goToFolder = function(folder){
            vm.currentFolder = folder.id;
            $rootScope.currentFolder = folder.id;
            vm.folderLevels.push(folder);
            $rootScope.folderLevels = vm.folderLevels;
            vm.condominiumRecords = [];
            vm.isReady = false;
            loadAll();
        }

        loadAll();

        function loadAll() {
            CondominiumRecord.findByCompany({
                page: vm.page,
                size: 1000,
                sort: sort(),
                companyId: globalCompany.getId(),
                type: 1,
                folderId: vm.currentFolder
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.condominiumRecords.push(data[i]);
                }
                vm.isReady = true;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            vm.page = 0;
            vm.condominiumRecords = [];
            loadAll();
        }

        vm.recordDetail = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('condominium-record-detail', {
                id: encryptedId
            })
        }

        vm.recordEdit = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            console.log(encryptedId);
            $state.go('condominium-record.edit', {
                id: encryptedId
            })
        };

        vm.delete = function (entity) {
            Modal.confirmDialog("¿Está seguro que desea eliminarlo?", "Una vez eliminada no podrá recuperar el archivo o la carpeta", function () {
                CondominiumRecord.delete({id: entity.id}, function () {
                    CommonMethods.deleteFromArray(entity, vm.condominiumRecords);
                    Modal.toast("Se ha eliminado correctamente")
                })
            })
        };
        vm.print = function (path) {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                })
            }, 7000);
            printJS({
                printable: path,
                type: 'pdf',
                modalMessage: "Obteniendo Estado de cuenta"
            })
        };
        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
    }
})();
