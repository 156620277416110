(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('WaterConsumptionController', WaterConsumptionController);

    WaterConsumptionController.$inject = ['$timeout', 'ExchangeRateBccr', 'Resident', 'CommonMethods', '$state', 'WaterConsumption', 'House', 'AdministrationConfiguration', 'globalCompany', '$rootScope', 'Modal', 'Charge', '$mdDialog', '$scope'];

    function WaterConsumptionController($timeout, ExchangeRateBccr, Resident, CommonMethods, $state, WaterConsumption, House, AdministrationConfiguration, globalCompany, $rootScope, Modal, Charge, $mdDialog, $scope) {

        var vm = this;
        vm.isReady = false;
        vm.houses = [];
        vm.autoCalculated = true;
        vm.loadAll = loadAll;
        moment.locale("es");
        vm.today = new Date();
        $rootScope.mainTitle = "Consumo de agua";
        $rootScope.active = "waterConsumption";
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        vm.date = new Date(y, m, 1);
        vm.date.setMonth(vm.date.getMonth() - 1);
        vm.concepDate = new Date(y, m + 1, 1);
        vm.sendEmail = false;
        vm.calcType = 1;
        vm.currentWCIndex = undefined;
        vm.waterConsumptions = [];
        vm.adminConfig = {waterPrice: 0};
        vm.confirming = false;
        vm.fechaCobro = vm.concepDate;
        vm.montoFijo = 0;
        vm.editingPrice = false;
        vm.creatingCharges = false;
        vm.countCharges = 0;
        vm.bccrUse = true;
        vm.addIVA = false;
        vm.bloques = [];
        vm.sendEmailTenant = true;
        vm.fixedAmmount = false;
        vm.companyId = globalCompany.getId()
        vm.hidrantes = false;
        vm.protectionWater = false;
        vm.dueDate = new Date();
        vm.measurementDate = undefined;
        if (vm.companyId == 78) {
            vm.sendEmailTenant = false;
        }

        vm.tipoCambio = "";

        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.secondCurrency = vm.companyConfig.secondCurrency;
        vm.principalCurrency = vm.companyConfig.currency;
        vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
        vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

        vm.showProtection = true;
        var notAllowedIds = [3, 43, 54, 55, 62];

        for (var i = 0; i < notAllowedIds.length; i++) {
            if (notAllowedIds[i] == globalCompany.getId()) {
                vm.showProtection = false;
            }
        }

        vm.tableToExcel = function (table) {
            vm.exportingExcel = true;
            setTimeout(function () {
                var uri = 'data:application/vnd.ms-excel;base64,'
                    ,
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)))
                    }
                    , format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        })
                    }
                var workSheetName = "Consumos de agua";
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML}
                var a = document.createElement('a');
                a.href = uri + base64(format(template, ctx))
                a.download = workSheetName + '.xls';
                //triggering the function
                a.click();
                vm.exportingExcel = false;
            }, 1)
        }

        vm.saveAdminConfig = function () {
            vm.adminConfig.exchangeRateDate = moment().format();
            vm.calculate();
            if (vm.adminConfig.id !== null) {
                AdministrationConfiguration.update(vm.adminConfig, function (result) {
                }, function () {
                });
            } else {
                AdministrationConfiguration.save(vm.adminConfig, function () {
                }, function () {
                });
            }
        }

        vm.defineChargeSpecification = function (wC) {
            if(vm.autoCalculated){
            if (vm.calcType <= 2) {
                specification = specification + vm.defineTablePerCharge(wC);
            }
            var specification = "<div>\n" +
                "<div style=\"width:300px;display: inline-block\">\n" +
                "  <div>\n" +
                "     Medición Anterior: <span style=\"float:right\">" + wC.medicionAnterior + "m³</span>\n" +
                "  </div>\n" +
                "  <div>\n" +
                "     Medición Actual: <span style=\"float:right\">" + wC.medicionActual + "m³</span>\n" +
                "  </div>\n" +
                "  <div>\n" +
                "     Consumo: <span style=\"float:right\">" + wC.consumption + "m³</span>\n" +
                "  </div>";
            if (vm.calcType == 3) {
                specification = specification + "<div>\n" +
                    " Cargo Fijo: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", vm.montoFijo) + "</span>\n" +
                    "  </div>";
                specification = specification + "<div>\n" +
                    " Precio por m³: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", vm.adminConfig.waterPrice) + "</span>\n" +
                    "  </div>";
            }
            if (vm.hidrantes) {
                specification = specification + "<div>\n" +
                    " Cargo por hidrantes: <span style=\"float:right\">" + "C 26 * m³" + "</span>\n" +
                    "  </div>";
            }
            var iva = wC.month - (wC.month / 1.13);
            var totalNoIva = (wC.month / 1.13);
            var totalIva = wC.month;
            if (vm.addIVA) {
                specification = specification + "<div>\n" +
                    " Total a pagar: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", totalNoIva) + "</span>\n" +
                    "  </div>";
                specification = specification + "<div>\n" +
                    " IVA: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", iva) + "</span>\n" +
                    "  </div>";
                specification = specification + "<div>\n" +
                    "Total a pagar + IVA: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", totalIva) + "</span>\n" +
                    "  </div>";
            } else {
                if ($rootScope.currency == "$") {
                    specification = specification + "<div>\n" +
                        "Total a pagar en colones: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", totalIva * vm.tipoCambio) + "</span>\n" +
                        "  </div>";
                    specification = specification + "<div>\n" +
                        "Tipo de cambio: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", vm.tipoCambio) + "</span>\n" +
                        "  </div>";
                    specification = specification + "<div style=\"margin:2.5px 0px\">\n" +
                        "Total a pagar en dólares: <span style=\"float:right\">" + $rootScope.fMoneyBank("$", totalIva) + "</span>\n" +
                        "  </div>";
                } else {
                    specification = specification + "<div style=\"margin:2.5px 0px\">\n" +
                        "Total a pagar: <span style=\"float:right\">" + $rootScope.fMoneyBank("C", totalIva) + "</span>\n" +
                        "  </div>";
                }
            }
            specification = specification + "</div>";
            if (vm.calcType <= 2) {
                specification = specification + vm.defineTablePerCharge(wC);
            }
            specification = specification + "</div>";
            return specification;
            }
        }

        vm.defineTablePerCharge = function (wc) {
            var consumption = wc.consumption;
            var specification = "<div style='width: 300px;margin-left: 40px;display:inline-block;float:right'>"
            specification = specification + "<div >\n" +
                "<b>Tabla calculo del agua:</b>\n" +
                "  </div>";
            specification = specification + " <div>Cargo Fijo: " + $rootScope.fMoneyBank("C", vm.tableCosts.cargoFijo.tipos[vm.tableCosts.tipoSelected].monto) + "</div>";
            if (globalCompany.getId() == 62) {
                specification = specification + " <div>Cargo por hidrantes: " + $rootScope.fMoneyBank("C", 26) + "x m3</div>";
            }
            var index = 0;
            specification = specification + " <div>Calculo por m³ consumidos: </div>";
            if (globalCompany.getId() == 55 && wc.housenumber.charAt(0).toUpperCase() == "L") {
                var bloquesLocales = vm.defineTableBloquesLocalesBrisas();
                for (var i = 0; i < bloquesLocales.length; i++) {
                    if (bloquesLocales[i].min <= parseFloat(consumption) && parseFloat(consumption) <= bloquesLocales[i].max) {
                        index = bloquesLocales[i].indexes;
                        break;
                    }
                }
                for (var i = 0; i <= index; i++) {
                    specification = specification + "<div>" + bloquesLocales[i].text + "</div>";
                }
            } else {
                for (var i = 0; i < vm.bloques.length; i++) {
                    if (vm.bloques[i].min <= parseFloat(consumption) && parseFloat(consumption) <= vm.bloques[i].max) {
                        index = vm.bloques[i].indexes;
                        break;
                    }
                }
                for (var i = 0; i <= index; i++) {
                    specification = specification + "<div>" + vm.bloques[i].text + "</div>";
                }
            }
            return specification + "</div>";
        }

        vm.defineTableBloques = function () {
            vm.bloques = [];
            for (var i = 0; i < vm.tableCosts.bloques.length; i++) {
                var bloq = vm.tableCosts.bloques[i];
                var bloque = {};
                if (bloq.maximo == "∞") {
                    bloq.maximo = 99999;
                }
                var text = "De " + bloq.minimo + "m³ a " + bloq.maximo + "m³";
                text = text + " = " + $rootScope.fMoneyBank("C", bloq.tipos[vm.tableCosts.tipoSelected].monto) + "x m³";
                bloque.text = text;
                if (bloq.maximo == "∞") {
                    bloq.maximo = 99999;
                }
                bloque.max = bloq.maximo;
                bloque.min = bloq.minimo;
                bloque.indexes = i;
                vm.bloques.push(bloque);
            }
        }
        vm.defineTableBloquesLocalesBrisas = function () {
            var bloques = [];
            for (var i = 0; i < vm.tableCosts.bloques.length; i++) {
                var bloq = vm.tableCosts.bloques[i];
                var bloque = {};
                if (bloq.maximo == "∞") {
                    bloq.maximo = 99999;
                }
                var text = "De " + bloq.minimo + "m³ a " + bloq.maximo + "m³";
                text = text + " = " + $rootScope.fMoneyBank("C", bloq.tipos[1].monto) + "x m³";
                bloque.text = text;
                if (bloq.maximo == "∞") {
                    bloq.maximo = 99999;
                }
                bloque.max = bloq.maximo;
                bloque.min = bloq.minimo;
                bloque.indexes = i;
                bloques.push(bloque);
            }
            return bloques;
        }

        vm.calculateTotalToPay = function () {
            var total = 0
            for (var i = 0; i < vm.waterConsumptions.length; i++) {
                if (vm.waterConsumptions[i].month) {
                    total += parseFloat(vm.waterConsumptions[i].month)
                }
            }
            return total;
        }

        vm.calculateTotalConsumption = function () {
            var total = 0
            for (var i = 0; i < vm.waterConsumptions.length; i++) {
                if (vm.waterConsumptions[i].consumptionInt) {
                    total += parseFloat(vm.waterConsumptions[i].consumptionInt)
                }
            }
            return total;
        }


        vm.loadBccrPrice = function () {
            if (vm.bccrUse) {
                ExchangeRateBccr.get({
                    fechaInicio: moment(new Date()).format(),
                    fechaFinal: moment(new Date()).format(),
                }, function (result) {
                    if (result == undefined) {
                        vm.bccrUse = false;
                    } else {
                        vm.tipoCambioVenta = result.venta;
                    }
                })
            }
        }

        vm.loadBccrPrice();

        vm.aresepAmaru = {
            nombre: "Amarú-Aresep",
            bloques: [
                {
                    minimo: 0,
                    maximo: 10,
                    tipos: [{nombre: "domiciliar", monto: 204}, {
                        nombre: "empresarial",
                        monto: 204
                    }, {nombre: "preferencial", monto: 204}, {nombre: "gobierno", monto: 204}]
                },
                {
                    minimo: 11,
                    maximo: 30,
                    tipos: [{nombre: "domiciliar", monto: 234}, {
                        nombre: "empresarial",
                        monto: 234
                    }, {nombre: "preferencial", monto: 234}, {nombre: "gobierno", monto: 234}]
                },
                {
                    minimo: 31,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 293}, {
                        nombre: "empresarial",
                        monto: 293
                    }, {nombre: "preferencial", monto: 293}, {nombre: "gobierno", monto: 293}]
                },
            ],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2000}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2000}, {nombre: "gobierno", monto: 2000}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 9066}, {
                    nombre: "empresarial",
                    monto: 30740
                }, {nombre: "preferencial", monto: 26633}, {nombre: "gobierno", monto: 115825}]
            }
        }

        vm.ayaTable2017 = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 331}, {
                        nombre: "empresarial",
                        monto: 1308
                    }, {nombre: "preferencial", monto: 331}, {nombre: "gobierno", monto: 1308}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 664}, {
                        nombre: "empresarial",
                        monto: 1588
                    }, {nombre: "preferencial", monto: 664}, {nombre: "gobierno", monto: 1588}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 730}, {
                        nombre: "empresarial",
                        monto: 1588
                    }, {nombre: "preferencial", monto: 664}, {nombre: "gobierno", monto: 1588}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 864}, {
                        nombre: "empresarial",
                        monto: 1588
                    }, {nombre: "preferencial", monto: 664}, {nombre: "gobierno", monto: 1588}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1588}, {
                        nombre: "empresarial",
                        monto: 1588
                    }, {nombre: "preferencial", monto: 730}, {nombre: "gobierno", monto: 1588}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1588}, {
                        nombre: "empresarial",
                        monto: 1588
                    }, {nombre: "preferencial", monto: 730}, {nombre: "gobierno", monto: 1588}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1588}, {
                        nombre: "empresarial",
                        monto: 1588
                    }, {nombre: "preferencial", monto: 730}, {nombre: "gobierno", monto: 1588}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 1669}, {
                        nombre: "empresarial",
                        monto: 1669
                    }, {nombre: "preferencial", monto: 730}, {nombre: "gobierno", monto: 1669}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2000}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2000}, {nombre: "gobierno", monto: 2000}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 9066}, {
                    nombre: "empresarial",
                    monto: 30740
                }, {nombre: "preferencial", monto: 26633}, {nombre: "gobierno", monto: 115825}]
            }
        }

        vm.ayaTablePuente = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 10,
                    tipos: [{nombre: "domiciliar", monto: 0}, {
                        nombre: "empresarial",
                        monto: 1620
                    }, {nombre: "preferencial", monto: 424}, {nombre: "gobierno", monto: 1680}]
                },
                {
                    minimo: 11,
                    maximo: 30,
                    tipos: [{nombre: "domiciliar", monto: 398}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 31,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 458}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 61,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 573}, {
                        nombre: "empresarial",
                        monto: 2063
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2143}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 3409}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2055}, {nombre: "gobierno", monto: 2055}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 11211}, {
                    nombre: "empresarial",
                    monto: 38048
                }, {nombre: "preferencial", monto: 34157}, {nombre: "gobierno", monto: 148686}]
            }
        }

        vm.ayaTableMalagaCiruelas2 = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 365}, {
                        nombre: "empresarial",
                        monto: 1620
                    }, {nombre: "preferencial", monto: 424}, {nombre: "gobierno", monto: 1680}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 746}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 823}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 998}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1861}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1866}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1869}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 1968}, {
                        nombre: "empresarial",
                        monto: 2063
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2143}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2000}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2055}, {nombre: "gobierno", monto: 2055}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 11211}, {
                    nombre: "empresarial",
                    monto: 38048
                }, {nombre: "preferencial", monto: 34157}, {nombre: "gobierno", monto: 148686}]
            }
        }

        vm.ayaTable2019 = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 409}, {
                        nombre: "empresarial",
                        monto: 1620
                    }, {nombre: "preferencial", monto: 424}, {nombre: "gobierno", monto: 1680}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 822}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 902}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 1071}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 852}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1964}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1964}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1964}, {
                        nombre: "empresarial",
                        monto: 1964
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2038}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 2063}, {
                        nombre: "empresarial",
                        monto: 2063
                    }, {nombre: "preferencial", monto: 937}, {nombre: "gobierno", monto: 2143}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2001}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2055}, {nombre: "gobierno", monto: 2055}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 11211}, {
                    nombre: "empresarial",
                    monto: 38048
                }, {nombre: "preferencial", monto: 34157}, {nombre: "gobierno", monto: 148686}]
            }
        }
        vm.ayaTable = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 365}, {
                        nombre: "empresarial",
                        monto: 1509
                    }, {nombre: "preferencial", monto: 409}, {nombre: "gobierno", monto: 1620}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 746}, {
                        nombre: "empresarial",
                        monto: 1860
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 823}, {
                        nombre: "empresarial",
                        monto: 1864
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 998}, {
                        nombre: "empresarial",
                        monto: 1868
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1861}, {
                        nombre: "empresarial",
                        monto: 1870
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1866}, {
                        nombre: "empresarial",
                        monto: 1871
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1869}, {
                        nombre: "empresarial",
                        monto: 1872
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 1968}, {
                        nombre: "empresarial",
                        monto: 1970
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 2063}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2000}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2000}, {nombre: "gobierno", monto: 2000}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 11211}, {
                    nombre: "empresarial",
                    monto: 35439
                }, {nombre: "preferencial", monto: 32843}, {nombre: "gobierno", monto: 133453}]
            }
        }

        vm.ayaTableProtected = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 368}, {
                        nombre: "empresarial",
                        monto: 1512
                    }, {nombre: "preferencial", monto: 409}, {nombre: "gobierno", monto: 1620}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 751}, {
                        nombre: "empresarial",
                        monto: 1865
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 829}, {
                        nombre: "empresarial",
                        monto: 1870
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 1005}, {
                        nombre: "empresarial",
                        monto: 1875
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1874}, {
                        nombre: "empresarial",
                        monto: 1883
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1879}, {
                        nombre: "empresarial",
                        monto: 1884
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1882}, {
                        nombre: "empresarial",
                        monto: 1885
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 1964}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 1982}, {
                        nombre: "empresarial",
                        monto: 1984
                    }, {nombre: "preferencial", monto: 902}, {nombre: "gobierno", monto: 2063}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2000}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2000}, {nombre: "gobierno", monto: 2000}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 11211}, {
                    nombre: "empresarial",
                    monto: 35439
                }, {nombre: "preferencial", monto: 32843}, {nombre: "gobierno", monto: 133453}]
            }
        }

        vm.ayaTableMontezuma = {
            nombre: "AYA",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 409}, {
                        nombre: "empresarial",
                        monto: 1509
                    }, {nombre: "preferencial", monto: 409}, {nombre: "gobierno", monto: 1509}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 822}, {
                        nombre: "empresarial",
                        monto: 1829
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1829}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 902}, {
                        nombre: "empresarial",
                        monto: 1829
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1829}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 1071}, {
                        nombre: "empresarial",
                        monto: 1829
                    }, {nombre: "preferencial", monto: 822}, {nombre: "gobierno", monto: 1829}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1964}, {
                        nombre: "empresarial",
                        monto: 1829
                    }, {nombre: "preferencial", monto: 840}, {nombre: "gobierno", monto: 1829}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1964}, {
                        nombre: "empresarial",
                        monto: 1829
                    }, {nombre: "preferencial", monto: 840}, {nombre: "gobierno", monto: 1829}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1964}, {
                        nombre: "empresarial",
                        monto: 1829
                    }, {nombre: "preferencial", monto: 840}, {nombre: "gobierno", monto: 1829}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 2063}, {
                        nombre: "empresarial",
                        monto: 1922
                    }, {nombre: "preferencial", monto: 840}, {nombre: "gobierno", monto: 1922}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 2000}, {
                    nombre: "empresarial",
                    monto: 2000
                }, {nombre: "preferencial", monto: 2000}, {nombre: "gobierno", monto: 2000}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 11211}, {
                    nombre: "empresarial",
                    monto: 35439
                }, {nombre: "preferencial", monto: 32843}, {nombre: "gobierno", monto: 133453}]
            }
        }

        vm.esphTable = {
            nombre: "ESPH",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 318}, {
                        nombre: "empresarial",
                        monto: 761
                    }, {nombre: "preferencial", monto: 322}, {nombre: "gobierno", monto: 769}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 530}, {
                        nombre: "empresarial",
                        monto: 1267
                    }, {nombre: "preferencial", monto: 536}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 530}, {
                        nombre: "empresarial",
                        monto: 1267
                    }, {nombre: "preferencial", monto: 588}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 689}, {
                        nombre: "empresarial",
                        monto: 1267
                    }, {nombre: "preferencial", monto: 588}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1267}, {
                        nombre: "empresarial",
                        monto: 1267
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1267}, {
                        nombre: "empresarial",
                        monto: 1267
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1267}, {
                        nombre: "empresarial",
                        monto: 1267
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 1331}, {
                        nombre: "empresarial",
                        monto: 1331
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1346}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 1200}, {
                    nombre: "empresarial",
                    monto: 1200
                }, {nombre: "preferencial", monto: 1200}, {nombre: "gobierno", monto: 1200}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 9973}, {
                    nombre: "empresarial",
                    monto: 20821
                }, {nombre: "preferencial", monto: 34237}, {nombre: "gobierno", monto: 20821}]
            }
        }

        vm.esphTableNoa = {
            nombre: "ESPH",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "domiciliar", monto: 318}, {
                        nombre: "empresarial",
                        monto: 769
                    }, {nombre: "preferencial", monto: 322}, {nombre: "gobierno", monto: 769}]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "domiciliar", monto: 530}, {
                        nombre: "empresarial",
                        monto: 1281
                    }, {nombre: "preferencial", monto: 536}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 26,
                    maximo: 40,
                    tipos: [{nombre: "domiciliar", monto: 530}, {
                        nombre: "empresarial",
                        monto: 1281
                    }, {nombre: "preferencial", monto: 588}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 41,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 689}, {
                        nombre: "empresarial",
                        monto: 1281
                    }, {nombre: "preferencial", monto: 588}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 61,
                    maximo: 80,
                    tipos: [{nombre: "domiciliar", monto: 1267}, {
                        nombre: "empresarial",
                        monto: 1281
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 81,
                    maximo: 100,
                    tipos: [{nombre: "domiciliar", monto: 1267}, {
                        nombre: "empresarial",
                        monto: 1281
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 101,
                    maximo: 120,
                    tipos: [{nombre: "domiciliar", monto: 1267}, {
                        nombre: "empresarial",
                        monto: 1281
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1281}]
                },
                {
                    minimo: 120,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 1331}, {
                        nombre: "empresarial",
                        monto: 1346
                    }, {nombre: "preferencial", monto: 642}, {nombre: "gobierno", monto: 1346}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 1200}, {
                    nombre: "empresarial",
                    monto: 1200
                }, {nombre: "preferencial", monto: 1200}, {nombre: "gobierno", monto: 0}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 0}, {
                    nombre: "empresarial",
                    monto: 0
                }, {nombre: "preferencial", monto: 0}, {nombre: "gobierno", monto: 0}]
            }
        }

        vm.brisasOeste = {
            nombre: "Condominio Brisas del Oeste",
            bloques: [
                {
                    minimo: 0,
                    maximo: 15,
                    tipos: [{nombre: "Apartamentos", monto: 900}, {
                        nombre: "Locales",
                        monto: 3335
                    }]
                },
                {
                    minimo: 16,
                    maximo: 25,
                    tipos: [{nombre: "Apartamentos", monto: 1215}, {
                        nombre: "Locales",
                        monto: 3596
                    }]
                },
                {
                    minimo: 26,
                    maximo: "∞",
                    tipos: [{nombre: "Apartamentos", monto: 1230}, {
                        nombre: "Locales",
                        monto: 3596
                    }]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "Apartamentos", monto: 900}, {
                    nombre: "Locales",
                    monto: 3335
                }]
            },
            tarifaFija: {
                tipos: [{nombre: "Apartamentos", monto: 0}, {
                    nombre: "Locales",
                    monto: 0
                }]
            }
        }
        vm.barcelona = {
            nombre: "Condominio Barcelona",
            bloques: [
                {
                    minimo: 0,
                    maximo: 10,
                    tipos: [{nombre: "domiciliar", monto: 363}, {
                        nombre: "empresarial",
                        monto: 363
                    }, {nombre: "preferencial", monto: 363}, {nombre: "gobierno", monto: 363}]
                },
                {
                    minimo: 11,
                    maximo: 30,
                    tipos: [{nombre: "domiciliar", monto: 417}, {
                        nombre: "empresarial",
                        monto: 417
                    }, {nombre: "preferencial", monto: 417}, {nombre: "gobierno", monto: 417}]
                },
                {
                    minimo: 31,
                    maximo: 60,
                    tipos: [{nombre: "domiciliar", monto: 521}, {
                        nombre: "empresarial",
                        monto: 521
                    }, {nombre: "preferencial", monto: 521}, {nombre: "gobierno", monto: 521}]
                },
                {
                    minimo: 60,
                    maximo: "∞",
                    tipos: [{nombre: "domiciliar", monto: 782}, {
                        nombre: "empresarial",
                        monto: 782
                    }, {nombre: "preferencial", monto: 782}, {nombre: "gobierno", monto: 782}]
                }],
            tipoSelected: 0,
            cargoFijo: {
                tipos: [{nombre: "domiciliar", monto: 3398}, {
                    nombre: "empresarial",
                    monto: 3398
                }, {nombre: "preferencial", monto: 3398}, {nombre: "gobierno", monto: 3398}]
            },
            tarifaFija: {
                tipos: [{nombre: "domiciliar", monto: 3398}, {
                    nombre: "empresarial",
                    monto: 3398
                }, {nombre: "preferencial", monto: 3398}, {nombre: "gobierno", monto: 3398}]
            }
        }
        if (globalCompany.getId() == 114) {
            vm.tableCosts = vm.ayaTable2017;
        } else if (globalCompany.getId() == 43) {
            vm.tableCosts = vm.ayaTable2019;
        } else if (globalCompany.getId() == 54) {
            vm.tableCosts = vm.barcelona;
        } else if (globalCompany.getId() == 55) {
            vm.tableCosts = vm.brisasOeste;
        } else if (globalCompany.getId() == 62) {
            vm.tableCosts = vm.ayaTableMontezuma;
        } else if (globalCompany.getId() == 86) {
            vm.tableCosts = vm.aresepAmaru;
        } else if (globalCompany.getId() == 112 || globalCompany.getId() == 133) {
            vm.calcType = 2;
            vm.tableCosts = vm.esphTableNoa;
        } else if (globalCompany.getId() == 145) {
            vm.tableCosts = vm.ayaTableMalagaCiruelas2;
        } else if (globalCompany.getId() == 92) {
            vm.tableCosts = vm.ayaTablePuente;
        } else {
            vm.tableCosts = vm.ayaTable;
        }

        vm.defineTableBloques();
        vm.defineTable = function () {
            if (vm.calcType == 2) {
                if (globalCompany.getId() == 112 || globalCompany.getId() == 133) {
                    vm.tableCosts = vm.esphTableNoa;
                } else {
                    vm.tableCosts = vm.esphTable;
                }
            }
            if (vm.calcType == 1) {
                if (globalCompany.getId() == 114) {
                    vm.tableCosts = vm.ayaTable2017;
                } else if (globalCompany.getId() == 43) {
                    vm.tableCosts = vm.ayaTable2019;
                } else if (globalCompany.getId() == 54) {
                    vm.tableCosts = vm.barcelona;
                } else if (globalCompany.getId() == 55) {
                    vm.tableCosts = vm.brisasOeste;
                } else if (globalCompany.getId() == 62) {
                    vm.tableCosts = vm.ayaTableMontezuma;
                } else if (globalCompany.getId() == 86) {
                    vm.tableCosts = vm.aresepAmaru;
                } else if (globalCompany.getId() == 145) {
                    vm.tableCosts = vm.ayaTableMalagaCiruelas2;
                } else if (globalCompany.getId() == 92) {
                    vm.tableCosts = vm.ayaTablePuente;
                } else {
                    vm.tableCosts = vm.ayaTable;
                }
                if (vm.protectionWater) {
                    vm.tableCosts = vm.ayaTableProtected;
                }
            }
            vm.defineTableBloques();
            vm.calculate();
        }

        function defineRole(role) {
            switch (role) {
                case "ROLE_OWNER":
                    return "Residente propietario";
                case "ROLE_RENTER":
                    return "Propietario arrendador";
                case "ROLE_TENANT":
                    return "Inquilino";
                case "ROLE_RESIDENT":
                    return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                    case "ROLE_OWNER":
                        resident.houses[i].role = "Residente propietario";
                        break;
                    case "ROLE_RENTER":
                        resident.houses[i].role = "Propietario arrendador";
                        break;
                    case "ROLE_TENANT":
                        resident.houses[i].role = "Inquilino";
                        break;
                    case "ROLE_RESIDENT":
                        resident.houses[i].role = "Residente autorizado";
                        break;
                }
            }
            return resident;
        }

        function formatResidentList(role, data, houseId) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == houseId) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role)
                    r = vm.defineRoleUser(r);
                    list.push(r)
                }
            }
            return list;
        }

        vm.open = function (waterConsumption) {
            vm.checkedType = 3;
            vm.waterConsumptionSelected = waterConsumption;
            vm.chargeSelectedId = waterConsumption.chargeId;
            vm.residents = [];
            Resident.getResidentsByHouse({
                houseId: waterConsumption.houseId
            }, function (data) {
                vm.owners = formatResidentList("ROLE_OWNER", data, waterConsumption.houseId);
                vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data, waterConsumption.houseId));
                vm.tenants = formatResidentList("ROLE_TENANT", data, waterConsumption.houseId);
                vm.residents = formatResidentList("ROLE_RESIDENT", data, waterConsumption.houseId);
                vm.residents.concat(vm.owners);
                vm.all = vm.owners.concat(vm.tenants);
                vm.all = vm.all.concat(vm.residents)
                $mdDialog.show({
                    templateUrl: 'app/entities/charge/charge-send-email-form.html',
                    scope: $scope,
                    preserveScope: true
                });
            }, onError);

            function onError() {
            }
        };

        vm.ableToSendEmail = function () {
            for (var i = 0; i < vm.all; i++) {
                if (vm.all[i].selected == 1) {
                    return true
                }
            }
            return false;
        }

        vm.selectPrincipalContact = function () {
            angular.forEach(vm.residents, function (resident, i) {
                if (resident.principalContact == 1) {
                    resident.selected = true;
                }
            });
        }
        vm.selectAllContact = function () {
            angular.forEach(vm.residents, function (resident, i) {
                if (resident.email != null) {
                    resident.selected = true;
                } else {
                    resident.selected = false;
                }
            });
        }

        vm.selectTenant = function () {
            angular.forEach(vm.residents, function (resident, i) {
                if (resident.type == 4 && resident.email != null) {
                    resident.selected = true;
                } else {
                    resident.selected = false;
                }
            });
        }

        vm.close = function () {
            $mdDialog.hide();
        };


        vm.editWaterPrice = function () {
            Modal.confirmDialog("¿Está seguro que desea modificar el precio por metro cúbico?", "Esto afectará el calculo de las cuotas de agua de ahora en adelante.", function () {
                vm.isSaving = true;
                Modal.showLoadingBar();
                AdministrationConfiguration.update(vm.adminConfig, function (result) {
                    vm.adminConfig = result;
                    vm.daysToBeDefaulter = result.daysToBeDefaulter;
                    vm.editingWaterPriceToogle();
                    Modal.hideLoadingBar();
                    Modal.toast("Se modifico el precio por metro cúbico.");
                    vm.calculate();
                }, onSaveError);
            })
        };

        vm.editingWaterPriceToogle = function () {
            vm.editingPrice = !vm.editingPrice;
        }

        vm.sendByEmail = function () {
            if (obtainEmailToList() != "") {
                Modal.showLoadingBar();
                var residentsToSendEmails = obtainEmailToList().slice(0, -1);
                Charge.sendChargeEmail({
                    companyId: globalCompany.getId(),
                    houseId: vm.waterConsumptionSelected.chargeId,
                    emailTo: residentsToSendEmails
                }, function (result) {
                    $mdDialog.hide();
                    Modal.hideLoadingBar();
                    Modal.toast("Se envió la cuota por correo correctamente.");
                });
            } else {
                Modal.toast("Selecciona al menos una persona.")
            }
        };

        function obtainEmailToList() {
            var residentsToSendEmails = "";
            angular.forEach(vm.all, function (resident, i) {
                if (resident.selected == true) {
                    if (residentsToSendEmails.indexOf(resident) === -1) {
                        residentsToSendEmails = residentsToSendEmails + resident.id + ",";
                    }
                }
            });
            return residentsToSendEmails;
        }


        loadAll();

        vm.calculate = function () {
            for (var i = 0; i < vm.waterConsumptions.length; i++) {
                var wC = vm.waterConsumptions[i];
                wC.consumptionInt = wC.medicionActualInt - wC.medicionAnteriorInt;
                if (isNaN(wC.consumptionInt)) {
                    wC.consumptionInt = 0;
                }
                if (wC.medicionActualInt == undefined) {
                    wC.medicionActualInt = 0;
                    wC.consumptionInt = 0;
                }
                var fixedAmmountToSum = vm.fixedAmmount && wC.consumptionInt == 0 ? 0 : vm.montoFijo;
                if (wC.status == 0) {
                    if (vm.autoCalculated) {
                        if (vm.calcType == 3) {
                            if (vm.addIVA) {
                                var month = (wC.consumptionInt * parseFloat(vm.adminConfig.waterPrice)) + fixedAmmountToSum;
                                wC.month = month + (month * 0.13);
                            } else {
                                wC.month = (wC.consumptionInt * parseFloat(vm.adminConfig.waterPrice)) + fixedAmmountToSum;
                            }
                        } else {
                            wC.month = vm.calculateToPayBaseInTable(wC);
                        }
                    }
                }
                if (wC.consumptionInt < 0 || !wC.chargeWaterFee) {
                    wC.month = 0;
                }
            }
        };

        vm.calculateToPayBaseInTable = function (wC) {
            var consumo = wC.consumptionInt;
            var consumoRestante = consumo;
            var monto = 0;
            var bloqueAnterior = 0;
            var bloqueMaximo = 0;
            for (var i = 0; i < vm.tableCosts.bloques.length; i++) {
                var bloque = vm.tableCosts.bloques[i];
                bloqueMaximo = bloque.maximo - bloqueAnterior;
                if (consumoRestante != 0) {
                    if (consumoRestante <= bloqueMaximo) {
                        var montoTabla = bloque.tipos[vm.tableCosts.tipoSelected].monto;
                        if (globalCompany.getId() == 55) {
                            if (wC.housenumber.charAt(0).toUpperCase() == "L") {
                                montoTabla = bloque.tipos[1].monto
                            }
                        }
                        monto = monto + (consumoRestante * montoTabla)
                        consumoRestante = 0;
                    } else {
                        if (bloque.maximo != "∞") {
                            var montoTabla = bloque.tipos[vm.tableCosts.tipoSelected].monto;
                            if (globalCompany.getId() == 55) {
                                if (wC.housenumber.charAt(0).toUpperCase() == "L") {
                                    montoTabla = bloque.tipos[1].monto
                                }
                            }
                            monto = monto + (bloqueMaximo * montoTabla);
                            consumoRestante = consumoRestante - bloqueMaximo;
                            bloqueAnterior = bloque.maximo;
                        } else {
                            var montoTabla = bloque.tipos[vm.tableCosts.tipoSelected].monto;
                            if (globalCompany.getId() == 55) {
                                if (wC.housenumber.charAt(0).toUpperCase() == "L") {
                                    montoTabla = bloque.tipos[1].monto
                                }
                            }
                            monto = monto + (consumoRestante * montoTabla)
                        }
                    }
                }
            }
            var montoFinal = 0;


            if (globalCompany.getId() == 3) {
                if (monto == 0) {
                    montoFinal = monto;
                } else {
                    if (vm.principalCurrency == "$") {
                        montoFinal = (monto / vm.tipoCambio) + (vm.tableCosts.cargoFijo.tipos[vm.tableCosts.tipoSelected].monto / vm.tipoCambio);
                    } else {
                        montoFinal = (monto + vm.tableCosts.cargoFijo.tipos[vm.tableCosts.tipoSelected].monto);
                    }
                }
            } else {
                var cargoFijo = vm.tableCosts.cargoFijo.tipos[vm.tableCosts.tipoSelected].monto;
                if (globalCompany.getId() == 55) {
                    if (wC.housenumber.charAt(0).toUpperCase() == "L") {
                        cargoFijo = vm.tableCosts.cargoFijo.tipos[1].monto
                    }
                    if (consumo != 0) {
                        cargoFijo = 0;
                    }
                }
                if (vm.principalCurrency == "$") {
                    montoFinal = (monto / vm.tipoCambio) + (cargoFijo / vm.tipoCambio);
                } else {
                    montoFinal = monto + cargoFijo;
                }
            }

            if (vm.fixedAmmount && consumo == 0) {
                var montoFijo = vm.tableCosts.cargoFijo.tipos[vm.tableCosts.tipoSelected].monto;
                if (vm.principalCurrency == "$") {
                    montoFijo = (montoFijo / vm.tipoCambio);
                }
                montoFinal = montoFinal - montoFijo;
            }
            if (montoFinal < 0) {
                montoFinal = 0;
            }
            if (vm.hidrantes) {
                if ($rootScope.currency == "$") {
                    montoFinal = montoFinal + ((montoFinal / vm.tipoCambio) * 26);
                } else {
                    montoFinal = montoFinal + (consumo * 26);
                }
            }
            if (vm.addIVA) {
                montoFinal = montoFinal + (montoFinal * 0.13);
            }
            return montoFinal;
        }

        function saveWcRecursive(wC, i) {
            setTimeout(function () {
                vm.countCharges = i;
            }, 10)
            if (i < vm.waterConsumptions.length) {
                wC.consumption = (wC.medicionActualInt - wC.medicionAnteriorInt).toFixed(2);
                wC.medicionActual = wC.medicionActualInt + "";
                wC.medicionAnterior = wC.medicionAnteriorInt + "";
                wC.specification = vm.defineChargeSpecification(wC);
                vm.currentWCIndex = i;
                if (wC.month < 0) {
                    wC.month = 0;
                }
                if (wC.status == 0 && wC.month > 0) {
                    wC.status = 1;
                    if (wC.id !== null) {
                        WaterConsumption.update(wC, function () {
                            return saveWcRecursive(vm.waterConsumptions[i + 1], i + 1);
                        }, onSaveError);
                    } else {
                        WaterConsumption.save(wC, function () {
                            return saveWcRecursive(vm.waterConsumptions[i + 1], i + 1);
                        }, onSaveError);
                    }
                    return false;
                } else {
                    return saveWcRecursive(vm.waterConsumptions[i + 1], i + 1);
                }
            } else {

                WaterConsumption.bilAllWaterConsumption({
                    companyId: globalCompany.getId(),
                    date: moment(vm.date).format(),
                    sendEmail: vm.sendEmail,
                    sendEmailTenant: vm.sendEmailTenant,
                    autoCalculated: vm.autoCalculated,
                    chargeDate: moment(vm.fechaCobro).format(),
                    dueDate: moment(vm.dueDate).format(),
                    concept: "Cuota de Agua " + moment(vm.date).format("MMMM YYYY"),
                    currency: vm.principalCurrency,
                    exchangeRate: encodeURIComponent(vm.tipoCambio.toString()),
                }, onSaveSuccess)
            }
        }

        function loadAll() {
            vm.isReady = false;
            vm.waterConsumptions = [];
            var y = vm.date.getFullYear();
            var m = vm.date.getMonth();
            vm.concepDate = new Date(y, m + 1, 1);
            vm.fechaCobro = vm.concepDate;

            WaterConsumption.queryByDate({
                    companyId: globalCompany.getId(),
                    date: moment(vm.date).format()
                },
                function (result) {
                    for (var i = 0; i < result.length; i++) {
                        result[i].consumptionInt = parseFloat(result[i].consumption);
                        result[i].medicionActualInt = parseFloat(result[i].medicionActual);
                        result[i].medicionAnteriorInt = parseFloat(result[i].medicionAnterior);
                        vm.waterConsumptions.push(result[i]);
                    }

                    vm.searchQuery = null;
                    AdministrationConfiguration.get({
                        companyId: globalCompany.getId()
                    }).$promise.then(function (result) {
                        vm.adminConfig = result;
                        vm.isReady = true;
                        var nDate = new Date(y, m + 1, 1);
                        nDate.setDate(nDate.getDate() + result.daysTobeDefaulter);
                        vm.dueDate = nDate;
                        vm.calculate();
                    })
                }
            );
        }

        $timeout(function () {
            vm.tipoCambio = !vm.bccrUse ? vm.adminConfig.exchangeRate : vm.tipoCambioVenta
            vm.calculate();
        }, 1000)

        vm.saveWc = function (wC, i) {
            vm.calculate();
            wC = vm.waterConsumptions[i];
            wC.consumption = (wC.medicionActualInt - wC.medicionAnteriorInt).toFixed(2);
            wC.medicionActual = wC.medicionActualInt + "";
            wC.medicionAnterior = wC.medicionAnteriorInt + "";
            vm.currentWCIndex = i;
            if(vm.autoCalculated) {
                wC.specification = vm.defineChargeSpecification(wC);
            }
            wC.status = 0;
            wC.billed = 0;
            if (wC.id !== null) {
                WaterConsumption.update(wC, function (result) {
                    wC.id = result.id
                }, onSaveError);
            } else {
                WaterConsumption.save(wC, function (result) {
                    wC.id = result.id
                }, onSaveError);
            }
        }

        vm.saveWcDirect = function (wC, i) {
            vm.calculate();
            wC = vm.waterConsumptions[i];
            wC.consumption = (wC.medicionActualInt - wC.medicionAnteriorInt).toFixed(2);
            wC.medicionActual = wC.medicionActualInt + "";
            wC.medicionAnterior = wC.medicionAnteriorInt + "";
            vm.currentWCIndex = i;
            wC.specification = vm.defineChargeSpecification(wC);
            wC.status = 0;
            wC.billed = 0;
            if (wC.id !== null) {
                WaterConsumption.update(wC, function (result) {
                    wC.id = result.id;
                    vm.isSaving = false;
                }, onSaveError);
            } else {
                WaterConsumption.save(wC, function (result) {
                    wC.id = result.id;
                    vm.isSaving = false;
                }, onSaveError);
            }
        }

        vm.saveWcAndCreate = function (wC, i, encryptedId) {
            vm.calculate();
            wC = vm.waterConsumptions[i];
            wC.consumption = (wC.medicionActualInt - wC.medicionAnteriorInt).toFixed(2);
            wC.medicionActual = wC.medicionActualInt + "";
            wC.medicionAnterior = wC.medicionAnteriorInt + "";
            wC.specification = vm.defineChargeSpecification(wC);
            wC.billed = 0;
            wC.status = 0;
            vm.currentWCIndex = i;
            if (wC.id !== null) {
                WaterConsumption.update(wC, function () {
                    $state.go('water-consumption.createCharge', {id: encryptedId})
                }, onSaveError);
            } else {
                WaterConsumption.save(wC, function () {
                    $state.go('water-consumption.createCharge', {id: encryptedId})
                }, onSaveError);
            }
        }

        vm.saveWcC = function (wC) {
            wC.consumption = (wC.medicionActualInt - wC.medicionAnteriorInt).toFixed(2);
            wC.medicionActual = wC.medicionActualInt + "";
            wC.medicionAnterior = wC.medicionAnteriorInt + "";
            wC.specification = vm.defineChargeSpecification(wC);
            wC.billed = 0;
            wC.status = 0;
            if (wC.id !== null) {
                WaterConsumption.update(wC, function (result) {
                    wC.id = result.id;
                    vm.isSaving = false;
                }, onSaveError);
            } else {
                WaterConsumption.save(wC, function (result) {
                    wC.id = result.id;
                    vm.isSaving = false;
                }, onSaveError);
            }
        }

        function onSaveWcSuccess(result) {
            wC.id = result.id;
            vm.isSaving = false;
        }

        vm.createCharge = function (wC, i) {
            var encryptedId = CommonMethods.encryptIdUrl(wC.id);
            vm.saveWcAndCreate(wC, i, encryptedId)
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.createAllCharges = function () {
            Modal.confirmDialog("¿Confirmar la creación de la facturación de todas las cuotas de consumo de agua?", "Por favor verifique que esten correctos los montos y que haya seleccionado las opciones correctas. En caso que si puede proceder a facturar", function () {
                Modal.confirmDialog("¿Está seguro que desea facturar todas las cuotas de consumo de agua?", "TODOS LOS MONTOS Y OPCIONES ESTÁN CORRECTAS Y QUIERO FACTURAR", function () {
                    Modal.showLoadingBar();
                    vm.isSaving = true;
                    vm.creatingCharges = true;
                    for (var i = 0; i < vm.waterConsumptions.length; i++) {
                        var wC = vm.waterConsumptions[i];
                        wC.consumption = (wC.medicionActualInt - wC.medicionAnteriorInt).toFixed(2);
                        wC.medicionActual = wC.medicionActualInt + "";
                        wC.medicionAnterior = wC.medicionAnteriorInt + "";
                        wC.specification = vm.defineChargeSpecification(wC);
                        vm.currentWCIndex = i;
                        if (wC.month < 0) {
                            wC.month = 0;
                        }
                        if (wC.status == 0 && wC.month > 0) {
                            wC.status = 1;
                        }
                    }
                    var measurementDateString = "";
                    if (vm.measurementDate != undefined) {
                        measurementDateString = ", Lectura tomada el: "+moment(vm.measurementDate).format("DD/MM/YYYY")
                    }
                    var createWaterCharges = {
                        companyId: globalCompany.getId(),
                        date: moment(vm.date).format(),
                        sendEmail: vm.sendEmail,
                        sendEmailTenant: vm.sendEmailTenant,
                        autoCalculated: vm.autoCalculated,
                        chargeDate: moment(vm.fechaCobro).format(),
                        dueDate: moment(vm.dueDate).format(),
                        currency: vm.principalCurrency,
                        exchangeRate: null,
                        concept: "Cuota de Agua " + moment(vm.date).format("MMMM YYYY") + measurementDateString,
                    }
                    createWaterCharges.waterConsumptions = vm.waterConsumptions;
                    WaterConsumption.bilAllWaterConsumption(createWaterCharges, onSaveSuccess)
                })
            })
        }
        vm.creatingCharges = false;

        function onSaveSuccess(result) {
            Modal.toast("Listo, se crearán todas las facturas de agua en el lapso del próximo minuto.")
            vm.creatingCharges = false;
            Modal.hideLoadingBar();
            vm.toogleConfirmation();
            vm.isSaving = false;
            vm.loadAll();
        }

        vm.toogleConfirmation = function () {
            vm.confirming = !vm.confirming;
        }
    }
})
();
