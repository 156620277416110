(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('GateDialogController', GateDialogController);

    GateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Gate', 'GateAccess'];

    function GateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Gate, GateAccess) {
        var vm = this;

        vm.gate = entity;
        vm.clear = clear;
        vm.save = save;
        vm.gateaccesses = GateAccess.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.gate.id !== null) {
                Gate.update(vm.gate, onSaveSuccess, onSaveError);
            } else {
                Gate.save(vm.gate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('aditumApp:gateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
