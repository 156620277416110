(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PreviewPictureController', PreviewPictureController);

    PreviewPictureController.$inject = ['$scope', '$sce', 'globalCompany', '$stateParams', '$state', 'RecordCategory', '$uibModalInstance', 'CommonMethods'];

    function PreviewPictureController($scope, $sce, globalCompany, $stateParams, $state, RecordCategory, $uibModalInstance, CommonMethods) {

        var vm = this;
        vm.url = $stateParams.url
        vm.close = function () {
            $uibModalInstance.close();
        }

        if ($stateParams.type == 'pdf') {
            vm.url = $sce.trustAsResourceUrl($stateParams.url);
            vm.encodedUrl = encodeURIComponent(vm.url);
            vm.urlFile = "https://docs.google.com/gview?url=" + vm.encodedUrl + "&embedded=true";
            vm.urlFile = $sce.trustAsResourceUrl(vm.urlFile);
        }
    }
})();
