(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accounting-account', {
                parent: 'entity',
                url: '/accounting-account?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.accountingAccount.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accounting-account/accounting-accounts.html',
                        controller: 'AccountingAccountController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountingAccount');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accounting-account.newBank', {
                parent: 'accounting-account',
                url: '/newBank',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bank-account/bank-account-dialog.html',
                        controller: 'BankAccountDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    description: null,
                                    accountNumber: null,
                                    deleted: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('accounting-account', null, {reload: 'accounting-account'});
                    }, function () {
                        $state.go('accounting-account');
                    });
                }]
            })
            .state('accounting-account.edit-bank', {
                parent: 'accounting-account',
                url: '/edit-bank-account/{id}',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bank-account/bank-account-dialog.html',
                        controller: 'BankAccountDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['BankAccount','CommonMethods', function(BankAccount,CommonMethods) {
                                var id = CommonMethods.decryptIdUrl($stateParams.id)
                                return BankAccount.get({id : id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('accounting-account', null, { reload: 'accounting-account' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('accounting-account-detail', {
                parent: 'accounting-account',
                url: '/accounting-account/{id}',
                data: {
                    authorities: ['ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.accountingAccount.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accounting-account/accounting-account-detail.html',
                        controller: 'AccountingAccountDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountingAccount');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AccountingAccount', function ($stateParams, AccountingAccount) {
                        return AccountingAccount.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'accounting-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('accounting-account-detail.edit', {
                parent: 'accounting-account-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-account/accounting-account-dialog.html',
                        controller: 'AccountingAccountDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['AccountingAccount', function (AccountingAccount) {
                                return AccountingAccount.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('accounting-account.newAccount', {
                parent: 'accounting-account',
                url: '/new-account/:type',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-account/accounting-account-dialog.html',
                        controller: 'AccountingAccountDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    mayorAccountType: "ASSET_ACCOUNTS",
                                    mayorAccountBehavior: "DEBIT",
                                    description: null,
                                    deleted: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('accounting-account', null, {reload: 'accounting-account'});
                    }, function () {
                        $state.go('accounting-account');
                    });
                }]
            })
            .state('accounting-account.edit-account', {
                parent: 'accounting-account',
                url: '/edit-account/{id}/{type}',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-account/accounting-account-dialog.html',
                        controller: 'AccountingAccountDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['AccountingAccount','CommonMethods', function (AccountingAccount, CommonMethods) {
                                var id = CommonMethods.decryptIdUrl($stateParams.id)
                                return AccountingAccount.get({id: id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('accounting-account', null, {reload: 'accounting-account'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('accounting-account.delete', {
                parent: 'accounting-account',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accounting-account/accounting-account-delete-dialog.html',
                        controller: 'AccountingAccountDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['AccountingAccount', function (AccountingAccount) {
                                return AccountingAccount.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('accounting-account', null, {reload: 'accounting-account'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
