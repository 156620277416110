(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('ReceivingClient', ReceivingClient);

    ReceivingClient.$inject = ['$resource'];

    function ReceivingClient ($resource) {
        var resourceUrl =  'api/receiving-clients/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getByCompanyId': { method: 'GET', url:'api/receiving-clients-by-company-id/:companyId' ,isArray: true},
            'getByHouseId': { method: 'GET', url:'api/receiving-clients-by-house-id/:houseId' ,isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
