(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FollowUpNextStepDeleteController',FollowUpNextStepDeleteController);

    FollowUpNextStepDeleteController.$inject = ['$uibModalInstance', 'entity', 'FollowUpNextStep'];

    function FollowUpNextStepDeleteController($uibModalInstance, entity, FollowUpNextStep) {
        var vm = this;

        vm.followUpNextStep = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            FollowUpNextStep.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
