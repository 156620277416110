(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BlockCommonAreaDialogController', BlockCommonAreaDialogController);

    BlockCommonAreaDialogController.$inject = ['globalCompany', 'Modal', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'BlockCommonArea', 'CommonMethods'];

    function BlockCommonAreaDialogController(globalCompany, Modal, $timeout, $scope, $stateParams, $uibModalInstance, entity, BlockCommonArea, CommonMethods) {
        var vm = this;
        vm.blockCommonArea = entity;
        vm.blockCommonArea.deleted = 0;
        vm.blockCommonArea.companyId = globalCompany.getId();
        vm.blockCommonArea.commonAreaId = CommonMethods.decryptIdUrl($stateParams.id)
        vm.clear = clear;
        vm.initialTime = {};
        vm.finalTime = {};
        vm.initialTime.value = "";
        vm.finalTime.value = 24;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.blockCommonArea.specificDay = new Date();
        vm.save = save;
        vm.hours = [];

        vm.daysOfTheWeek = [
            {
                text: "Lunes",
                value: "L",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            },
            {
                text: "Martes",
                value: "M",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            },
            {
                text: "Miercoles",
                value: "X",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            },
            {
                text: "Jueves",
                value: "J",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            },
            {
                text: "Viernes",
                value: "V",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            },
            {
                text: "Sábado",
                value: "S",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            },
            {
                text: "Domingo",
                value: "D",
                selected: false,
                hours: {
                    initialTime: 0,
                    finalTime: 24
                }
            }
        ]


        vm.validHours = function () {
            if (vm.blockCommonArea.scheduled == 0) {
                if (vm.initialTime.value >= vm.finalTime.value) {
                    vm.validHoursDefine = false;
                }
            } else {
                for (var i = 0; i < vm.daysOfTheWeek.length; i++) {
                    var day = vm.daysOfTheWeek[i];
                    if (day.hours.initialTime >= day.hours.finalTime) {
                        vm.validHoursDefine = false;
                    }
                }
            }
            vm.validHoursDefine = true;
        }

        function addHoursToSelect() {
            vm.hours = [];
            var min = 1;
            var max = 23;
            var top = max;
            for (var i = min; i <= top; i++) {
                if (i < 12) {
                    if (i == 0) {
                        var item = {value: i, half: 0, time: '12:00 AM'};
                        vm.hours.push(item);
                    } else {
                        var item = {value: i, half: 0, time: i + ':00 AM'};
                        vm.hours.push(item);
                    }
                } else {
                    if (i == 12) {
                        var item = {value: i, half: 0, time: i + ':00 PM'};
                        vm.hours.push(item);
                    } else {
                        var item = {value: i, half: 0, time: i - 12 + ':00 PM'};
                        vm.hours.push(item);
                    }
                }
            }
        }

        addHoursToSelect();

        vm.minimunDate = new Date();


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            Modal.confirmDialog("¿Está seguro que desea crear el bloqueo del área común?", "Los residentes no podrán reservar en este lapso de tiempo establecido.", function () {
                vm.isSaving = true;
                console.log(vm.blockCommonArea);
                if (vm.blockCommonArea.scheduled == "1") {
                    vm.blockCommonArea.scheduled = true;
                } else {
                    vm.blockCommonArea.scheduled = false;
                }
                if (vm.blockCommonArea.scheduled == 0) {
                    var hours = vm.initialTime.value + "," + vm.finalTime.value;
                    vm.blockCommonArea.hours = hours;
                } else {
                    var days = "";
                    for (var i = 0; i < vm.daysOfTheWeek.length; i++) {
                        var day = vm.daysOfTheWeek[i];
                        if (day.selected) {
                            var separator = "|";
                            console.log(separator)
                            var textValue =  day.value + ";" + day.hours.initialTime + "," + day.hours.finalTime+separator;
                            days = days + textValue;
                        }
                    }
                    vm.blockCommonArea.days = days;
                }
                if (vm.blockCommonArea.id !== null) {
                    BlockCommonArea.update(vm.blockCommonArea, onSaveSuccess, onSaveError);
                } else {
                    BlockCommonArea.save(vm.blockCommonArea, onSaveSuccess, onSaveError);
                }
            })

        }

        function onSaveSuccess(result) {
            Modal.toast("Se ha creado el bloqueo correctamente.")
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.specificDay = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
