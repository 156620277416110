(function () {
    'use strict';
    angular
        .module('aditumApp')
        .factory('House', House);

    House.$inject = ['$resource', 'DateUtils'];

    function House($resource, DateUtils) {
        var resourceUrl = 'api/houses/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getAllHouses': {
                method: 'GET',
                isArray: true,
                url: "api/allHouses/:companyId/:desocupated/:houseNumber"
            },
            'getAllHousesClean': {
                method: 'GET',
                isArray: true,
                url: "api/houses/clean/:companyId"
            },
        });
    }
})();
