(function () {
    'use strict';

    angular
        .module('aditumApp')
        .provider('CommonMethods', CommonMethods);

    function CommonMethods() {
        this.$get = getService;

        function getService($localStorage, House,$rootScope ) {
            return {
                validateName: validateName,
                encryptSDownload:encryptSDownload,
                hasAuthority: hasAuthority,
                waitingMessage: waitingMessage,
                validateLetters: validateLetters,
                validateNumbers: validateNumbers,
                validateRepeat: validateRepeat,
                encryptS: encryptS,
                capitalizeFirstLetter: capitalizeFirstLetter,
                setCompanyId: setCompanyId,
                encryptIdUrl: encryptIdUrl,
                decryptIdUrl: decryptIdUrl,
                encryptString: encryptString,
                decryptString: decryptString,
                validateSpecialCharacters: validateSpecialCharacters,
                getCarBrands: getCarBrands,
                validateSpecialCharactersAndVocals: validateSpecialCharactersAndVocals,
                formatCurrencyInputs: formatCurrencyInputs,
                deleteFromArray: deleteFromArray,
                deleteFromArrayWithId: deleteFromArrayWithId,
                getIndexFromArrayWithId: getIndexFromArrayWithId,
                getCurrentCompanyConfig: getCurrentCompanyConfig,
                getCompanyCurrencies: getCompanyCurrencies,
                getExchangeRateCurrency: getExchangeRateCurrency,
                getInitialConfig: getInitialConfig,
                setInitialConfigReady: setInitialConfigReady,
                exportToExcel: exportToExcel,
                detectMob: detectMob,
                setTimezone: setTimezone,
                getDateString: getDateString,
                getMobileOperatingSystem: getMobileOperatingSystem,
                getFullCurrencyInfo:getFullCurrencyInfo
            };

            function getCompanyCurrencies(companyId) {
                var currency = [
                    {symbol: "₡", name: "Colones (₡ - Costa Rica)"},
                    {symbol: "$", name: "Dólares ($ - Estados Unidos)"},
                    {symbol: "Q", name: "Quetzales (Q - Guatemala)"},
                    {symbol: "L", name: "Lempiras (L - Honduras)"},
                    {symbol: "C$", name: "Córdobas (C$ - Nicaragua)"},
                    {symbol: "BZ$", name: "Dólares Beliceños (BZ$ - Belice)"},
                    {symbol: "€", name: "Euros (€ - Unión Europea)"},
                    {symbol: "ARS", name: "Pesos Argentinos (ARS - Argentina)"},
                    {symbol: "BRL", name: "Reales (BRL - Brasil)"},
                    {symbol: "CLP", name: "Pesos Chilenos (CLP - Chile)"},
                    {symbol: "COP", name: "Pesos Colombianos (COP - Colombia)"},
                    {symbol: "PEN", name: "Soles (PEN - Perú)"},
                    {symbol: "VES", name: "Bolívares (VES - Venezuela)"},
                    {symbol: "DOP", name: "Pesos Dominicanos (DOP - República Dominicana)"},
                    {symbol: "MXN", name: "Pesos Mexicanos (MXN - México)"}
                    // Puedes agregar más elementos según sea necesario
                ];
                var companyConfig = getCurrentCompanyConfig(companyId);
                return currency.filter(function (moneda) {
                    return moneda.symbol === companyConfig.currency || moneda.symbol === companyConfig.secondCurrency;
                });
            }

            function getExchangeRateCurrency(currencies) {
                for (var i = 0; i < currencies.length; i++) {
                    if (currencies[i].symbol != "$") {
                        return currencies[i].symbol;
                    }
                }
            }

            function getFullCurrencyInfo(symbol) {
                var currency = [
                    {symbol: "₡", name: "Colones (₡ - Costa Rica)"},
                    {symbol: "$", name: "Dólares ($ - Estados Unidos)"},
                    {symbol: "Q", name: "Quetzales (Q - Guatemala)"},
                    {symbol: "L", name: "Lempiras (L - Honduras)"},
                    {symbol: "C$", name: "Córdobas (C$ - Nicaragua)"},
                    {symbol: "BZ$", name: "Dólares Beliceños (BZ$ - Belice)"},
                    {symbol: "€", name: "Euros (€ - Unión Europea)"},
                    {symbol: "ARS", name: "Pesos Argentinos (ARS - Argentina)"},
                    {symbol: "BRL", name: "Reales (BRL - Brasil)"},
                    {symbol: "CLP", name: "Pesos Chilenos (CLP - Chile)"},
                    {symbol: "COP", name: "Pesos Colombianos (COP - Colombia)"},
                    {symbol: "PEN", name: "Soles (PEN - Perú)"},
                    {symbol: "VES", name: "Bolívares (VES - Venezuela)"},
                    {symbol: "DOP", name: "Pesos Dominicanos (DOP - República Dominicana)"},
                    {symbol: "MXN", name: "Pesos Mexicanos (MXN - México)"}
                    // Puedes agregar más elementos según sea necesario
                ];
                for (var i = 0; i < currency.length; i++) {
                    if (currency[i].symbol === symbol) {
                        return currency[i];
                    }
                }
            }

            function getDateString(date) {
                return date.getDate() + ";" + (date.getMonth() + 1) + ";" + date.getFullYear();
            }

            function hasAuthority(authority, allowed) {
                var authoritiesAllowed = allowed.split(",");
                for (var i = 0; i < authoritiesAllowed.length; i++) {
                    if (authoritiesAllowed[i] === authority) {
                        return true
                    }
                }
                return false;
            }

            function getMobileOperatingSystem() {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;

                // Windows Phone must come first because its UA also contains "Android"
                if (/windows phone/i.test(userAgent)) {
                    return "Windows Phone";
                }

                if (/android/i.test(userAgent)) {
                    return "Android";
                }

                // iOS detection from: http://stackoverflow.com/a/9039885/177710
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    return "iOS";
                }

                return "unknown";
            }

            function getDeviceInfo(token, promise) {
                $.getJSON('https://api.db-ip.com/v2/free/self', function (data) {
                    var ipinfo = JSON.stringify(data, null, 2);
                    var userDevice = {
                        name: token,
                        operativeSystem: getMobileOperatingSystem(),
                        ip: ipinfo.ipAddress,
                        address: ipinfo.stateProv + ", " + ipinfo.countryName
                    }
                    return userDevice;
                });
            }

            function setTimezone(date) {
                var ianatz = "America/Costa_Rica"
                // suppose the date is 12:00 UTC
                var invdate = new Date(date.toLocaleString('en-US', {
                    timeZone: ianatz
                }));

                // then invdate will be 07:00 in Toronto
                // and the diff is 5 hours
                var diff = date.getTime() - invdate.getTime();

                // so 12:00 in Toronto is 17:00 UTC
                return new Date(date.getTime() - diff); // needs to substract

            }

            function detectMob() {
                return ((window.innerWidth <= 850) && (window.innerHeight <= 900));
            }

            function exportToExcel(tableId, name) {
                var uri = 'data:application/vnd.ms-excel;base64,'
                    ,
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)))
                    }
                    , format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        })
                    }
                if (!table.nodeType) table = document.getElementById(tableId)
                var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
                window.location.href = uri + base64(format(template, ctx))
            }

            function getCurrentCompanyConfig(compaId) {
                if ($localStorage.companiesConfig != undefined) {
                    var companiesConfig = decryptIdUrl($localStorage.companiesConfig);
                    if (companiesConfig == "admin") {
                        return "admin";
                    } else {
                        var companiesArray = companiesConfig.split("|");
                        for (var i = 0; i < companiesArray.length; i++) {
                            var companyId = companiesArray[i].split(";")[0];
                            if (companyId == compaId) {
                                $rootScope.currency = companiesArray[i].split(";")[9];
                                return {
                                    companyId: companyId,
                                    hasContability: companiesArray[i].split(";")[1],
                                    minDate: new Date(companiesArray[i].split(";")[2]),
                                    hasWatches: companiesArray[i].split(";")[3] == "1",
                                    showEstadoResultados: companiesArray[i].split(";")[4] == "true",
                                    showEjecPresu: companiesArray[i].split(";")[5] == "true",
                                    bookCommonArea: companiesArray[i].split(";")[6] == "true",
                                    initialConfiguration: companiesArray[i].split(";")[7],
                                    hasRounds: companiesArray[i].split(";")[8] == "true",
                                    currency: companiesArray[i].split(";")[9],
                                    hasControlAccess: companiesArray[i].split(";")[10] == "1",
                                    tendersWatchWC: companiesArray[i].split(";")[11] == "1",
                                    hasAditumGate: companiesArray[i].split(";")[12] == "1",
                                    hasQrCodeGate: companiesArray[i].split(";")[13] == "1",
                                    allowBtnOpenDoor: companiesArray[i].split(";")[14] == "1",
                                    secondCurrency: companiesArray[i].split(";")[15],
                                }
                            }
                        }
                    }
                } else {
                    return "admin";
                }
            }

            function getInitialConfig(compaId) {
                if ($localStorage.initialConfig != undefined) {
                    var initialConfig = decryptIdUrl($localStorage.initialConfig);
                    if (initialConfig == "admin") {
                        return "admin";
                    } else {
                        var companiesArray = initialConfig.split("|");
                        for (var i = 0; i < companiesArray.length; i++) {
                            var companyId = companiesArray[i].split(";")[0];
                            if (companyId == compaId) {
                                return {
                                    companyId: companyId,
                                    initialConfiguration: companiesArray[i].split(";")[1],
                                    hasContability: companiesArray[i].split(";")[2]
                                }
                            }
                        }
                    }
                } else {
                    return "admin";
                }
            }

            function setInitialConfigReady(compaId) {
                var initialConfig = decryptIdUrl($localStorage.initialConfig);
                var companies = initialConfig.split("|");
                var showInitialConfigArray = "";
                for (var i = 0; i < companies.length; i++) {
                    var companyId = companies[i].split(";")[0];
                    var companyConfig = this.getInitialConfig(companyId)
                    if (compaId != companyId) {
                        showInitialConfigArray += companyConfig.companyId + ";" + companyConfig.initialConfiguration + "|";
                    } else {
                        showInitialConfigArray += companyConfig.companyId + ";" + "1" + "|";
                    }
                }
                $localStorage.initialConfig = this.encryptIdUrl(showInitialConfigArray);
            }

            function deleteFromArrayWithId(object, array) {
                var index = undefined;
                var founded = false;
                angular.forEach(array, function (item, i) {
                    if (parseInt(item.id) === parseInt(object.id)) {
                        index = i;
                        founded = true;
                    } else {
                        if (founded == false) {
                            index = -1;
                        }
                    }
                })
                if (index != -1) {
                    array.splice(index, 1);
                }
            }

            function getIndexFromArrayWithId(object, array) {
                var index = undefined;
                var founded = false;
                angular.forEach(array, function (item, i) {
                    if (parseInt(item.id) === parseInt(object.id)) {
                        index = i;
                        founded = true;
                    }
                })
                return index;
            }


            function deleteFromArray(item, array) {
                var index = array.indexOf(item);
                if (index > -1) {
                    array.splice(index, 1);
                }
            }

            function validateName(items, name) {
                var condition = true;
                angular.forEach(items, function (item, index) {
                    if (item.name.toUpperCase() == name.toUpperCase()) {
                        condition = false;
                    }
                });
                return condition;
            }

            function waitingMessage(message) {
                bootbox.dialog({
                    message: '<div class="text-center gray-font font-15"><img src="../../content/images/waiting-gif1.gif" style="width: 20px; height: 20px;"/>  Por favor espere...</div>',
                    closeButton: false,
                })
            }

            function validateNumbers() {
                jQuery('.numbers').keypress(function (tecla) {
                    if (tecla.charCode < 48 || tecla.charCode > 57) return false;
                });
            }

            function validateRepeat(items, itemToValidate, criteria) {
                var condition = false;
                angular.forEach(items, function (item, index) {
                    switch (criteria) {
                        case 1:
                            if (item.identification_number == itemToValidate) {
                                condition = true;
                            }
                            break;
                        case 2:
                            if (item.email.toUpperCase() == itemToValidate.toUpperCase()) {
                                condition = true;
                            }
                            break;
                        case 3:
                            if (item.house_number == itemToValidate) {
                                condition = true;
                            }
                            break;
                        case 4:
                            if (item.license_plate == itemToValidate) {
                                condition = true;
                            }
                            break;
                        case 5:
                            if (item.email.toUpperCase() == itemToValidate.toUpperCase()) {
                                condition = true;
                            }
                            break;
                    }


                });
                return condition;
            }

            function setCompanyId(id) {
                $localStorage.companyId = encryptIdUrl(id);
                House.getAllHousesClean({
                    companyId: id
                }, function (data) {
                    $localStorage.houses = data;
                });
            }

            function encryptIdUrl(id) {
                return CryptoJS.AES.encrypt(id.toString(), "Ankara06").toString();
            }

            function decryptIdUrl(encryptedId) {
                return CryptoJS.AES.decrypt(encryptedId.toString(), "Ankara06").toString(CryptoJS.enc.Utf8);
            }

            function encryptString(id) {
                return CryptoJS.AES.encrypt(id, "Ankara06").toString();
            }

            function encryptS(data) {
                var key = CryptoJS.enc.Utf8.parse('0060606060606060');
                var ive = CryptoJS.enc.Utf8.parse('0060606060606060');
                var encrypted = CryptoJS.AES.encrypt(data + "", key, {iv: ive});
                return encrypted;
            }

            function encryptSDownload(data) {
                var key = CryptoJS.enc.Utf8.parse('0060606060606060');
                var ive = CryptoJS.enc.Utf8.parse('0060606060606060');
                var encrypted = CryptoJS.AES.encrypt(data + "", key, {iv: ive});
                return encrypted.toString();
            }

            function decryptString(encryptedId) {
                return CryptoJS.AES.decrypt(encryptedId, "Ankara06").toString(CryptoJS.enc.Utf8);
            }

            function validateLetters() {
                $(".letters").keypress(function (key) {
                    if ((key.charCode < 97 || key.charCode > 122) //letras mayusculas
                        &&
                        (key.charCode < 65 || key.charCode > 90) //letras minusculas
                        &&
                        (key.charCode != 45) //retroceso
                        &&
                        (key.charCode != 241) //ñ
                        &&
                        (key.charCode != 209) //Ñ
                        &&
                        (key.charCode != 32) //espacio
                        &&
                        (key.charCode != 225) //á
                        &&
                        (key.charCode != 233) //é
                        &&
                        (key.charCode != 237) //í
                        &&
                        (key.charCode != 243) //ó
                        &&
                        (key.charCode != 250) //ú
                        &&
                        (key.charCode != 193) //Á
                        &&
                        (key.charCode != 201) //É
                        &&
                        (key.charCode != 205) //Í
                        &&
                        (key.charCode != 211) //Ó
                        &&
                        (key.charCode != 218) //Ú

                    )
                        return false;
                });
            }

            function capitalizeFirstLetter(string) {
                if (string != undefined) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                }
            }

            function getCarBrands() {
                var brands = {
                    data: [{

                        brand: "Audi"
                    }, {
                        brand: "Alfa Romeo"
                    }, {
                        brand: "BMW"
                    }, {
                        brand: "BYD"
                    }, {
                        brand: "Chevrolet"
                    }, {
                        brand: "Citroen"
                    }, {
                        brand: "Daewoo"
                    }, {
                        brand: "Daihatsu"
                    }, {
                        brand: "Dodge"
                    }, {
                        brand: "Fiat"
                    }, {
                        brand: "Ford"
                    }, {
                        brand: "Honda"
                    }, {
                        brand: "Hummer"
                    }, {
                        brand: "Hyundai"
                    }, {
                        brand: "Izuzu"
                    }, {
                        brand: "Jaguar"
                    }, {
                        brand: "JAC"
                    }, {
                        brand: "Jeep"
                    }, {
                        brand: "Kia"
                    }, {
                        brand: "Land Rover"
                    }, {
                        brand: "Lexus"
                    }, {
                        brand: "Maserati"
                    }, {
                        brand: "Mazda"
                    }, {
                        brand: "Mercedes Benz"
                    }, {
                        brand: "Mini"
                    }, {
                        brand: "Mitsubishi"
                    }, {
                        brand: "Nissan"
                    }, {
                        brand: "Peugeot"
                    }, {
                        brand: "Porshe"
                    }, {
                        brand: "Renault"
                    }, {
                        brand: "Rolls Royce"
                    }, {
                        brand: "Ssanyong"
                    }, {
                        brand: "Subaru"
                    }, {
                        brand: "Suzuki"
                    }, {
                        brand: "Toyota"
                    }, {
                        brand: "Volkswagen"
                    }, {
                        brand: "Volvo"

                    },]
                }

                return brands;
            }

            function validateSpecialCharacters() {
                jQuery('.specialCharacters').keypress(function (tecla) {
                    if ((tecla.charCode < 48) || (tecla.charCode > 90 && tecla.charCode < 97) || (tecla.charCode > 122 && tecla.charCode < 126) || (tecla.charCode > 57 && tecla.charCode < 65)) return false;
                });
            }

            function validateSpecialCharactersAndVocals() {
                jQuery('.specialCharactersAndVocals').keypress(function (tecla) {
                    if ((tecla.charCode < 48) || (tecla.charCode > 90 && tecla.charCode < 97) || (tecla.charCode > 122 && tecla.charCode < 126) || (tecla.charCode > 57 && tecla.charCode < 65) || tecla.charCode == 97 || tecla.charCode == 101 || tecla.charCode == 105 || tecla.charCode == 111 || tecla.charCode == 117) return false;
                });
            }

            function validateRepeat(items, itemToValidate, criteria) {
                var condition = false;
                angular.forEach(items, function (item, index) {
                    switch (criteria) {
                        case 1:
                            if (item.identificationnumber == itemToValidate) {
                                condition = true;
                            }
                            break;
                        case 2:
                            if (item.email.toUpperCase() == itemToValidate.toUpperCase()) {
                                condition = true;
                            }
                            break;
                        case 3:
                            if (item.housenumber == itemToValidate) {
                                condition = true;
                            }
                            break;
                        case 4:
                            if (item.licenseplate == itemToValidate) {
                                condition = true;
                            }
                            break;
                        case 5:
                            if (item.email.toUpperCase() == itemToValidate.toUpperCase()) {
                                condition = true;
                            }
                            break;
                    }


                });
                return condition;
            }

            function formatCurrencyInputs() {

                var $form = $("#form");
                var $input = $('input.currency')

                $input.on("keyup", function (event) {


                    // When user select text in the document, also abort.
                    var selection = window.getSelection().toString();
                    if (selection !== '') {
                        return;
                    }

                    // When the arrow keys are pressed, abort.
                    if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1) {
                        return;
                    }


                    var $this = $(this);

                    // Get the value.
                    var input = $this.val();

                    var input = input.replace(/[\D\s\._\-]+/g, "");
                    input = input ? parseInt(input, 10) : 0;

                    $this.val(function () {
                        return (input === 0) ? "" : input.toLocaleString("en-US");
                    });
                });

                /**
                 * ==================================
                 * When Form Submitted
                 * ==================================
                 */
                $form.on("submit", function (event) {

                    var $this = $(this);
                    var arr = $this.serializeArray();

                    for (var i = 0; i < arr.length; i++) {
                        arr[i].value = arr[i].value.replace(/[($)\s\._\-]+/g, ''); // Sanitize the values.
                    }
                    ;


                    event.preventDefault();
                });

            }
        }

    }


//
//
//
//        .factory('commonMethods', function($rootScope, $state) {
//
//         return {
//
//             validatebrand:function(items, name) {
//                 var condition = true;
//                 angular.forEach(items, function(item, index) {
//                     if (item.name.toUpperCase() == name.toUpperCase()) {
//                         condition = false;
//                     }
//                 });
//                 return condition;
//             },
//

//             validateSpecialCharacters: function() {
//                 jQuery('.specialCharacters').keypress(function(tecla) {
//                     if ((tecla.charCode < 48) || (tecla.charCode > 90 && tecla.charCode < 97) || (tecla.charCode > 122 && tecla.charCode < 126) || (tecla.charCode > 57 && tecla.charCode < 65)) return false;
//                 });
//             },
//
//             validateRepeat: function(items, itemToValidate, criteria) {
//                 var condition = false;
//                 angular.forEach(items, function(item, index) {
//                     switch (criteria) {
//                         case 1:
//                             if (item.identification_number == itemToValidate) {
//                                 condition = true;
//                             }
//                             break;
//                         case 2:
//                             if (item.email.toUpperCase() == itemToValidate.toUpperCase()) {
//                                 condition = true;
//                             }
//                             break;
//                         case 3:
//                             if (item.house_number == itemToValidate) {
//                                 condition = true;
//                             }
//                             break;
//                         case 4:
//                             if (item.license_plate == itemToValidate) {
//                                 condition = true;
//                             }
//                             break;
//                         case 5:
//                             if (item.email.toUpperCase() == itemToValidate.toUpperCase()) {
//                                 condition = true;
//                             }
//                             break;
//                     }
//
//
//                 });
//                 return condition;
//             },
//             validatePermisson: function(permission_level) {
//                 if ($rootScope.user.permission_level != permission_level) {
//                     $state.go('home');
//                 }
//
//             },
//             moveToLinked: function(item, itemsToLink, itemsLinked) {
//                 var index = itemsToLink.indexOf(item);
//                 itemsToLink.splice(index, 1);
//                 var item = {
//                     id: item.id,
//                     brand:item.name,
//                     last_brand:item.last_name,
//                     second_last_brand:item.second_last_name,
//                     identification_number: item.identification_number
//                 }
//                 itemsLinked.push(item);
//
//             },
//             capitalizeFirstLetter: function(string) {
//                 return string.charAt(0).toUpperCase() + string.slice(1);
//             },
//             moveToLink: function(item, itemsToLink, itemsLinked) {
//                 var index = itemsLinked.indexOf(item);
//                 itemsLinked.splice(index, 1);
//                 itemsToLink.push(item);
//             }
//
//         };
//     })
})();
