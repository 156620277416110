(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('FilePreviewCommonAreaConditionsController', FilePreviewCommonAreaConditionsController);

    FilePreviewCommonAreaConditionsController.$inject = ['$sce', 'globalCompany', '$stateParams', '$state', '$uibModalInstance', 'CommonMethods'];

    function FilePreviewCommonAreaConditionsController($sce, globalCompany, $stateParams, $state, $uibModalInstance, CommonMethods) {
        var vm = this;
        vm.url = $sce.trustAsResourceUrl($stateParams.url);
        vm.type = $stateParams.type;
        vm.isImage = false;
        vm.isVideo = false;
        vm.isFile = true;
        vm.encodedUrl = encodeURIComponent(vm.url);
        vm.urlFile = "https://docs.google.com/gview?url=" + vm.encodedUrl + "&embedded=true";
        vm.urlFile = $sce.trustAsResourceUrl(vm.urlFile);
        vm.close = function () {
            $uibModalInstance.close({});
        }
    }
})();
