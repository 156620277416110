(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingFullPeriodDeleteController',AccountingFullPeriodDeleteController);

    AccountingFullPeriodDeleteController.$inject = ['$uibModalInstance', 'entity', 'AccountingFullPeriod'];

    function AccountingFullPeriodDeleteController($uibModalInstance, entity, AccountingFullPeriod) {
        var vm = this;

        vm.accountingFullPeriod = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccountingFullPeriod.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
