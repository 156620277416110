(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('credit-note', {
                parent: 'entity',
                url: '/credit-note?page&sort&search',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.creditNote.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-note/credit-notes.html',
                        controller: 'CreditNoteController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('creditNote');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('credit-note-detail', {
                parent: 'credit-note',
                url: '/credit-note/{id}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.creditNote.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/credit-note/credit-note-detail.html',
                        controller: 'CreditNoteDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('creditNote');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CreditNote', function ($stateParams, CreditNote) {
                        return CreditNote.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'credit-note',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('credit-note-detail.edit', {
                parent: 'credit-note-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-note/credit-note-dialog.html',
                        controller: 'CreditNoteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CreditNote', function (CreditNote) {
                                return CreditNote.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('houseAdministration.chargePerHouse.newCreditNote', {
                parent: 'houseAdministration.chargePerHouse',
                url: '/newCreditNote/{chargeId}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-note/credit-note-dialog.html',
                        controller: 'CreditNoteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    concept: null,
                                    amount: null,
                                    date: null,
                                    reason: null,
                                    deleted: null,
                                    originalBillDate: null,
                                    referenceNumber: null,
                                    numericKey: null,
                                    electronicBillStatus: null,
                                    electronicBillResponse: null,
                                    isElectronicBillSend: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('houseAdministration.chargePerHouse', null, {reload: 'houseAdministration'});
                    }, function () {
                        $state.go('houseAdministration.chargePerHouse');
                    });
                }]
            })
            .state('credit-note.edit', {
                parent: 'credit-note',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-note/credit-note-dialog.html',
                        controller: 'CreditNoteDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CreditNote', function (CreditNote) {
                                return CreditNote.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-note', null, {reload: 'credit-note'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('credit-note.delete', {
                parent: 'credit-note',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/credit-note/credit-note-delete-dialog.html',
                        controller: 'CreditNoteDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CreditNote', function (CreditNote) {
                                return CreditNote.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('credit-note', null, {reload: 'credit-note'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
