(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('GateController', GateController);

    GateController.$inject = ['Gate'];

    function GateController(Gate) {

        var vm = this;

        vm.gates = [];

        loadAll();

        function loadAll() {
            Gate.query(function(result) {
                vm.gates = result;
                vm.searchQuery = null;
            });
        }
    }
})();
