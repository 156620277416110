(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ActionLogAdminController', ActionLogAdminController);

    ActionLogAdminController.$inject = ['ActionLogAdmin'];

    function ActionLogAdminController(ActionLogAdmin) {

        var vm = this;

        vm.actionLogAdmins = [];

        loadAll();

        function loadAll() {
            ActionLogAdmin.query(function(result) {
                vm.actionLogAdmins = result;
                vm.searchQuery = null;
            });
        }
    }
})();
